import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = (primaryColor) => { 
  let t = createMuiTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: '#ffffff',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#fff',
      },
    },
  })
  return t
}

export default theme;
