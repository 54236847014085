import React from 'react';
import CCE from '../../resources/images/CCE.js';
import {Button, Typography} from '@material-ui/core';

export default (props) => {
    let [loyaltyProgram] = global.get('loyaltyProgram');
    let [viewingReceipt] = global.get('viewingReceipt')

    return (
        <div style={s.container}>
            <div style={s.wrapper}>
                {/* <CCE width={48} />
                TODO: add small logo here
                 */}
                <Button
                    variant='text'
                    size='small'
                    onClick={() => {
                        if (viewingReceipt) {
                            global.set({loyaltyHeader: false, header: true, activePage: "PaymentPage"})	
                        } else {
                            global.set({loyaltyHeader: false, activePage: "RestaurantInfoPage"})
                        }
                    }}
                >
                    <Typography color='primary' variant='button' style={{fontSize: 20}}>Skip</Typography>
                </Button>
            </div>
        </div>
    );
}

const s = {
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
    },
    wrapper: {
        height: 57,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        padding: '0px 8px',
    },
    
}