import packageJson from '../package.json';
import ip from 'ip';
import log from 'loglevel';
import { domains, wsDomains, tryMeDomains, tsysDomains, vgsDomains, vgsKeys } from './actions/config';
import _ from 'lodash';
import ReactGA from 'react-ga'

export default (props) => {
	document.body.style.setProperty("height", window.innerHeight + "px", "important");
	document.getElementsByTagName("html")[0].style.setProperty("height", window.innerHeight + "px", "important");
	document.getElementById("root").style.setProperty("height", window.innerHeight + "px", "important");

	setupEnvironment()
	setupGlobalProperties()
	setupLogging()
	setupGlobalFunctions()
	setupGoogleAnalytics()
}


function setupGlobalFunctions() {
	String.prototype.toProperCase = function(opt_lowerCaseTheRest) {
        return (opt_lowerCaseTheRest ? this.toLowerCase():this).replace(/(^|[\s\xA0])[^\s\xA0]/g, function(s){ return s.toUpperCase(); });
    }

    Array.prototype.replaceItem = function(record, prop = '_id', index) {
        if (!index) index = -1
        if (prop === '_id' && index === -1) {
            index = this.indexOf(record)
        }
        if (index === -1) {
            index = _.findIndex(this, rec => { return rec[prop] === record[prop]})
        }
        if (index > -1) {
            this.splice(index, 1, record)
        }
        return this
    }

    Array.prototype.removeItem = function(record, prop = '_id', index) {
        if (!index) index = -1
        if (prop === '_id' && index === -1) {
            index = this.indexOf(record)
        }
        if (index === -1) {
            index = _.findIndex(this, rec => { return rec[prop] === record[prop]})
        }

        if (index > -1) {
            this.splice(index, 1)
        }
        return this
    }

    Array.prototype.removeItemAtIndex = function(index = -1) {
        if (index > -1) {
            this.splice(index, 1)
        }
        return this
    }

    Array.prototype.sortByProperty = function(prop, desc = true) {
        let arr = this.sort((a, b) => {
            if (a[prop] && b[prop] && a[prop].toLowerCase() > b[prop].toLowerCase()) {
                return 1
            } else {
                return -1
            } 
        })
        if (!desc) {
            arr.reverse()
        }
        return arr
    }

    Object.defineProperty(Object.prototype, 'getVal', {
        value: function(path = '', defaultValue) {
            return getWithNullCheck.apply(this, [this, defaultValue, ...path.split('.')])
        },
        enumerable: false,
    });

    // THIS WILL BREAK TSYS, DO NOT UNCOMMENT
    // Object.defineProperty(Object.prototype, 'has', {
    //     value: function(path = '', defaultValue) {
    //         return !!(getWithNullCheck.apply(this, [this, defaultValue, ...path.split('.')]))
    //     },
    //     enumerable: false,
    // });
                

    Object.defineProperty(Object.prototype, 'setVal', {
        value: function(path = '', newValue) {
            return _.set(this, path, newValue)
        },
        enumerable: false,
    });
}

const setupGlobalProperties = function () {
	//SETUP GLOBAL VARIABLES
	let serverIp = ip.address()
	log.info("   IP: " + serverIp)
	global.serverIp = serverIp
	global.appName = packageJson.name
	global.appVersion = packageJson.version.toString()
	global._ = _
	global.parseJSONArrSafe = parseJSONArrSafe
	global.getAuthnetCustomerProfileId = getAuthnetCustomerProfileId
	global.setAuthnetCustomerProfileId = setAuthnetCustomerProfileId
	global.textPay = (window.location.href.indexOf('textpay.app') > -1)
	global.curbly = (window.location.href.indexOf('curbly.app') > -1)
	global.dashNow = (window.location.href.indexOf('dnow.app') > -1)

	const heartlandUrl = !global.isProd ? 'h-' : 'h.'
	const shift4Url = !global.isProd ? 's-' : 's.'
	const fisUrl = !global.isProd ? 'f-' : 'f.'
	const msgUrl = !global.isProd ? 'm-' : 'm.'
	const elavonUrl = !global.isProd ? 'e-' : 'e.'

	global.branding = (window.location.href.indexOf(heartlandUrl) > -1) ? "heartland" 
                    : (window.location.href.indexOf(shift4Url) > -1) ? 'shift4' 
                    : (window.location.href.indexOf(fisUrl) > -1) ? 'fis' 
                    : (window.location.href.indexOf(msgUrl) > -1) ? 'msg' 
                    : (window.location.href.indexOf(elavonUrl) > -1) ? 'elavon' 
                    : 'dashnow'

                    
	global.primaryColor = global.branding == 'heartland' ? '#BA0C13'
                        : global.branding == "shift4" ? '#007cba' 
                        : global.branding == "fis" ? '#8DC63F'
                        : global.branding == "msg" ? '#3A90CE'
                        : global.branding == "elavon" ? '#0075C9'
                        : global.textPay ? '#E86C00' 
                        : '#9160DC' // default

	global.f = (obj, propsStr, defaultVal = null) => getWithNullCheck.apply(this, [obj, defaultVal, ...propsStr.split('.')]) //GROOVY NULL OPERATOR
	global.formatPhoneNumber = (pnStr) => {
        if (pnStr.length < 4) {
            pnStr = pnStr.replace(/(\d{1,3})/g, "($1");
        } else if (pnStr.length < 7) {
            pnStr = pnStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
        } else if (pnStr.length <= 10) {
            pnStr = pnStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
        }
        return pnStr;
    }

}

function getWithNullCheck(obj, defaultVal, ...props) {
    if (obj instanceof Object) {
        let val = obj[props.shift()];
        let returnVal = (props.length && val) ? getWithNullCheck(val, defaultVal, ...props) : val;
        return returnVal &&
            (
                (returnVal instanceof Object) && (Object.keys(returnVal).length > 0)
                || ((returnVal instanceof Array) && (returnVal.length > 0))
                || (!(returnVal instanceof Object) && !(returnVal instanceof Array))
            )
            ? returnVal : defaultVal
    } else {
        return defaultVal
    }
}

const parseJSONArrSafe = (json) => {
	try {
        if (!json) return []
		const tmp = JSON.parse(json)
		return tmp
	} catch (e) {
		log.error('failed to parse card json')
	}
	return []
}

const getAuthnetCustomerProfileId = (restaurantId) => {
	try {
		let authnetAccs = localStorage.getItem('authnetCustomerProfileIds')
		if (!authnetAccs) { return null }
		authnetAccs = JSON.parse(authnetAccs)
		return authnetAccs[`${restaurantId}`]
	} catch (e) {
		log.error(e)
	}
	return null
}

const setAuthnetCustomerProfileId = (restaurantId, customerPrfileId) => {
	try {
		let authnetAccs = localStorage.getItem('authnetCustomerProfileIds')
		if (!authnetAccs) { authnetAccs = "{}" }
		authnetAccs = JSON.parse(authnetAccs)
		authnetAccs[`${restaurantId}`] = customerPrfileId
		localStorage.setItem('authnetCustomerProfileIds', JSON.stringify(authnetAccs))
		return true
	} catch (e) {
		log.error(e)
	}
	return false
}

const setupGoogleAnalytics = function () {
	if (global.environment === "prod") {
		ReactGA.initialize('UA-149374087-1');
	} else if (global.environment === "stage") {
		ReactGA.initialize('UA-149374087-2');
	} else {
		ReactGA.initialize('UA-149374087-3');
	}
	// console.log("global.environment" + global.environment)
}

const setupEnvironment = function () {
	if (!global.environment) {
		if (process.env.REACT_APP_ENV) {
			global.environment = process.env.REACT_APP_ENV;
		} else {
			global.environment = "stage"
		}
		global.isProd = global.environment === "prod"
		global.isDev = global.environment === "dev"
		// console.log("SETTING ENVIRONMENT = " + global.environment)
	}
	global.dashServerUrl = domains[global.environment];
	global.dashServerWsUrl = wsDomains[global.environment];
    global.vgsUrl = vgsDomains[global.environment];
    global.vgsKey = vgsKeys[global.environment];
	global.dashTsysDeviceId = tsysDomains[global.environment];
	global.dashTsysMerchantId = '888000003302';
	global.tryMeUrl = tryMeDomains[global.environment];
	global.s3Url = domains['s3'] ? domains['s3'].replace("[ENV]", global.isProd ? "prod" : "stage") : '';

	// log.info("global.dashServerUrl " + global.dashServerUrl)
	// log.info("global.dashServerWsUrl " + global.dashServerWsUrl)
	// log.info("global.spreedlyEnvironmentKey " + global.spreedlyEnvironmentKey)
}

const setupLogging = function () {
	if (global.environment === "prod") {
		log.setDefaultLevel("warn")
	} else {
		log.setDefaultLevel("trace")
	}
}