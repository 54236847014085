import React, { useState, useEffect } from 'react';
import LoyaltyRewardsSummary from './LoyaltyRewardsSummary';
import LoyaltyRewardCongratulations from './LoyaltyRewardCongratulations';
import LoyaltyRewardsExplanation from './LoyaltyRewardsExplanation';
import CreateAccountPage from '../CreateAccountPage'

export default (props) => {
	let [ loyaltyProgram ] = global.get('loyaltyProgram');
	let [ loyaltyAccount ] = global.get('loyaltyAccount');
	let [signedIn] = global.get('signedIn');
	const [stepNumber, setStepNumber] = useState(1);

	useEffect(() => {
	}, [])

	const nextStep = () => {
		setStepNumber(stepNumber+1)
	}

	const loader = () => {
		if (stepNumber === 2) {
			if (signedIn && !!loyaltyAccount.hasPhoneNumber) {
				nextStep();
			}
		} else if (stepNumber === 3) {
			let tutorials = JSON.parse(localStorage.getItem('tutorials'));
			if (tutorials && tutorials.includes(loyaltyProgram._id)) {
				nextStep();
			}
		} else if (stepNumber !== 4 && loyaltyAccount) {
			setStepNumber(4)
		}
	};
	loader()

	return (
		<div style={{width: '100%', height: '100%', paddingLeft: '5%', paddingRight: '5%'}}>
			{stepNumber === 1 && <LoyaltyRewardCongratulations nextStep={nextStep} />}
			{stepNumber === 2 && <CreateAccountPage nextStep={nextStep} logo={loyaltyProgram.logo}  />}
			{stepNumber === 3 && <LoyaltyRewardsExplanation />}
			{stepNumber === 4 && <LoyaltyRewardsSummary />}
		</div>
	);
};
