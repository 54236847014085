import React, { useEffect, useState } from 'react';

import { Grid, Typography, Container, Button, Fab, Slide, TextField } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';

import {ReactComponent as Sedan} from '../../resources/images/vehicles/1-sedan.svg';
import {ReactComponent as Midsuv} from '../../resources/images/vehicles/2-midsuv.svg';
import {ReactComponent as Suv} from '../../resources/images/vehicles/3-suv.svg';
import {ReactComponent as Minivan} from '../../resources/images/vehicles/4-minivan.svg';
import {ReactComponent as Pickup} from '../../resources/images/vehicles/5-pickup.svg';
import {ReactComponent as Van} from '../../resources/images/vehicles/6-van.svg';
import {ReactComponent as Hatchback} from '../../resources/images/vehicles/7-hatchback.svg';
import {ReactComponent as Mini} from '../../resources/images/vehicles/8-mini.svg';
import {ReactComponent as Supercar} from '../../resources/images/vehicles/9-supercar.svg';

import log from 'loglevel'
import DStyles from '../../components/DStyles'
import submitPickupInfo from '../../actions/submitPickupInfo';

export default (props) => {
	const [ isNative ] = global.get("isNative")
	const [check] = global.get("check")
	const [ restaurantPhone ] = global.get('restaurantPhone');
	const [showFinalScreen, setShowFinalScreen] = React.useState(false);
	const [ restaurantData ] = global.get("restaurantData")
	const restaurant = check ? check.restaurant : {}
	const pickupLocationQuestion = global.f(restaurant, 'config.features.imHere.pickupLocationQuestion', "")
	const pickupLocations = global.f(restaurant, 'config.features.imHere.pickupLocations', "")
	const [ vehicleType, setVehicleType ] = useState("")
	const [ otherDescription, setOtherDescription ] = useState("")
	const [otherComplete, setOtherComplete ] = useState(false)
	const [ vehicleColor, setVehicleColor ] = useState("")
	let [windowHeight] = global.get('windowHeight');
	const [ rightAlignHeaderLogo ] = global.get('rightAlignHeaderLogo');
	let viewHeight = windowHeight - (rightAlignHeaderLogo ? 57 : 75)
	const [ pickupLocation, setPickupLocation ] = useState("")
	
	const [ feedbackEnabled ]  = global.get('feedbackEnabled')

	const [ selectVehicleEnabled ]  = global.get('selectVehicleEnabled')
	const [ showingSelectVehicle, setShowingSelectVehicle ] = useState(selectVehicleEnabled)  

	const [ selectLocationEnabled ] = global.get("selectLocationEnabled")
	const [ showingSelectLocation, setShowingSelectLocation ] = useState(!selectVehicleEnabled && selectLocationEnabled)  
	

    const styles = `
		.vehicleInfoPage {
			text-align: center;
			padding: 2em;
        }
        
        .vehicleTypeDiv {
			padding-top: 1em;
			padding-bottom: 25px;
		}

		.vehicleTypeDiv svg path {
			fill: #4A4A4A;
		}

		.vehicleTypeDiv .active.white, .vehicleTypeDiv .active.silver {
			background-color: #777777;
			border-radius: 10px;
		}

		.vehicleTypeDiv .active svg path {
			fill: #4A4A4A;
		}

		.vehicleTypeDiv .active > svg {
			width: 70% !important;
			height: inherit !important;
		}

		.vehicleTypeDiv .inactive {
			display: none;
		}

		.vehicleTypeDiv .inactive svg path {
			fill: #CCC;
		}

		.vehicleTypeDiv .white svg path:nth-of-type(1) {
			fill: var(--vehicle-white);
		}
		.vehicleTypeDiv .silver svg path:nth-of-type(1) {
			fill: var(--vehicle-silver);
		}
		.vehicleTypeDiv .gray svg path:nth-of-type(1) {
			fill: var(--vehicle-gray);
		}
		.vehicleTypeDiv .black svg path:nth-of-type(1) {
			fill: var(--vehicle-black);
		}
		.vehicleTypeDiv .gray svg path:nth-of-type(1) {
			fill: var(--vehicle-gray);
		}
		.vehicleTypeDiv .red svg path:nth-of-type(1) {
			fill: var(--vehicle-red);
		}
		.vehicleTypeDiv .blue svg path:nth-of-type(1) {
			fill: var(--vehicle-blue);
		}
		.vehicleTypeDiv .teal svg path:nth-of-type(1) {
			fill: var(--vehicle-teal);
		}
		.vehicleTypeDiv .green svg path:nth-of-type(1) {
			fill: var(--vehicle-green);
		}
		.vehicleTypeDiv .brown svg path:nth-of-type(1) {
			fill: var(--vehicle-brown);
		}
		.vehicleTypeDiv .other svg path:nth-of-type(1) {
			fill: var(--vehicle-other);
		}

		/* WHITE TEXT UNDER VEHICLES */
		.vehicleTypeDiv .white svg path:nth-of-type(2) {
			fill: var(--vehicle-white);
		}
		.vehicleTypeDiv .silver svg path:nth-of-type(2) {
			fill: var(--vehicle-white);
		}


		.vehicleColorDiv {
			padding-top: 40px;
		}

    `


	const submit = () => {
		if (check) {
			if (pickupLocation) {
				setShowingSelectVehicle(false)
				setShowingSelectLocation(false)
				submitApi()
			} else if (vehicleType && (vehicleColor || vehicleType =='other')) {
				setShowingSelectVehicle(false)
				if (selectLocationEnabled) {
					setShowingSelectLocation(true)
				} else {
					submitApi()
				}
			}
		}
	}

	const submitApi = () => {
		submitPickupInfo(check, vehicleType, vehicleColor, pickupLocation, otherDescription, false).then((res) => {}).catch((err) => {
			console.error(err)
		})
	}

	useEffect(() => {
		if (vehicleType && vehicleColor) {
			submit()
		}
	}, [vehicleType, vehicleColor])

	useEffect(() => {
		if (pickupLocation) {
			submit()
		}
	}, [pickupLocation])

	function formatPhoneNumber(phoneNumberString) {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
		var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
		  return '(' + match[1] + ') ' + match[2] + '-' + match[3]
		}
		return null
	}


	useEffect(() => {
		global.set({ allowBack: false })
	}, [check]);

	useEffect(() => {},[]);

	const clearVehicle = () => {
		setVehicleColor("")
		setVehicleType("")
		setOtherComplete(false);
	}

	const vehicleInfo = () => {
		return (
			<div className="vehicleInfoPage">
				<Container className="vehicleTypeDiv">

					{!vehicleType && 
						<Typography gutterBottom={true}>What type of vehicle are you in?</Typography>
					}

					<Grid container spacing={4} alignItems="flex-end">
						<Grid item xs align="center" className={vehicleType == "sedan" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'sedan' ? clearVehicle() : setVehicleType("sedan") }}>
							<Sedan width="100%" height="75px" />
						</Grid>
						<Grid item xs align="center" className={vehicleType == "midsuv" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'midsuv' ? clearVehicle() : setVehicleType("midsuv") }}>
							<Midsuv width="100%" height="75px" />
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs align="center" className={vehicleType == "suv" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'suv' ? clearVehicle() : setVehicleType("suv") }}>
							<Suv width="100%" height="75px" />
						</Grid>
						<Grid item xs align="center" className={vehicleType == "minivan" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'minivan' ? clearVehicle() : setVehicleType("minivan") }}>
							<Minivan width="100%" height="75px" />
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs align="center" className={vehicleType == "pickup" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'pickup' ? clearVehicle() : setVehicleType("pickup") }}>
							<Pickup width="100%" height="75px" />
						</Grid>
						<Grid item xs align="center" className={vehicleType == "van" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'van' ? clearVehicle() : setVehicleType("van") }}>
							<Van width="100%" height="75px" />
						</Grid>
					</Grid>
					<Grid container spacing={4}>
						<Grid item xs align="center" className={vehicleType == "hatchback" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'hatchback' ? clearVehicle() : setVehicleType("hatchback") }}>
							<Hatchback width="100%" height="75px" />
						</Grid>
						<Grid item xs align="center" className={vehicleType == "mini" ? (vehicleColor ? vehicleColor + " active" : "active") : (vehicleType ? "inactive" : "")} onClick={() => { vehicleType == 'mini' ? clearVehicle() : setVehicleType("mini") }}>
							<Mini width="100%" height="75px" />
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						{!vehicleType &&
							<Grid item xs={12} sm={12} align="center" onClick={() => { setVehicleType("other"); }}>
								<Button style={{ backgroundColor: 'var(--vehicle-other)', minWidth: 10, width: '100%', height: 40, color: '#FFF' }}>Other</Button>
							</Grid>
						}
					</Grid>
				</Container>


				{vehicleType && vehicleType != 'other' && !vehicleColor && 
					<>
						<Container className="vehicleColorDiv">

							<Typography gutterBottom={true}>What color is your vehicle?</Typography>

							<Grid container spacing={1} alignItems="flex-end">
								<Grid item xs={4} sm={3} align="center" style={{border: 'solid 1px #888;'}} onClick={() => { setVehicleColor("white");}}>
									<Button style={{backgroundColor: 'var(--vehicle-white)', minWidth: 10, width: '100%', height: 50, border: 'solid 1px #DDD'}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("black");}}>
									<Button style={{backgroundColor: 'var(--vehicle-black)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("silver");}}>
									<Button style={{backgroundColor: 'var(--vehicle-silver)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("gray");}}>
									<Button style={{backgroundColor: 'var(--vehicle-gray)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("red");}}>
									<Button style={{backgroundColor: 'var(--vehicle-red)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("brown");}}>
									<Button style={{backgroundColor: 'var(--vehicle-brown)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("teal");}}>
									<Button style={{backgroundColor: 'var(--vehicle-teal)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("blue");}}>
									<Button style={{backgroundColor: 'var(--vehicle-blue)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={4} sm={3} align="center" onClick={() => { setVehicleColor("green");}}>
									<Button style={{backgroundColor: 'var(--vehicle-green)', minWidth: 10, width: '100%', height: 50}} />
								</Grid>
								<Grid item xs={12} sm={12} align="center" onClick={() => { setVehicleColor("other");}}>
									<Button style={{backgroundColor: 'var(--vehicle-other)', minWidth: 10, width: '100%', height: 50, border: 'solid 1px #DDD', color: '#FFF'}}>Other</Button>
								</Grid>
							</Grid>
						</Container>
					</>
				}
				{ vehicleType == 'other' &&
					<Container className="vehicleColorDiv">
						{!otherComplete && 
							<Typography gutterBottom={true}>Please describe your vehicle</Typography>
						}{otherDescription && otherComplete &&
							<Typography variant="h6" >{otherDescription.toUpperCase()}</Typography>
						}	
						{!otherComplete &&
							<Grid container spacing={1} alignItems="flex-end">
								<Grid item xs={12} sm={12} align="center">
									<TextField onChange={(e) => setOtherDescription(e.target.value)}/>
									<br/>
									<br/>
									<br/>
									<Button style={{ backgroundColor: 'var(--vehicle-other)', minWidth: 10, width: '100%', height: 50, border: 'solid 1px #DDD', color: '#FFF' }} onClick={() => { setOtherComplete(true); submit(); }}>Submit</Button>
								</Grid>
							</Grid>
						}
					</Container>
				}

				

			</div>
		)
	}

    useEffect(() => {
		global.set({ allowBack: false })
	}, [check]);

	// useEffect(() => {},[]);

	
	const locationInfo = () => { 
		return (
			<div>
				<Grid container style={{ paddingTop: '.75em'}} spacing={1}>
					{!pickupLocation && <>
						<Grid item xs={12} style={{paddingBottom: '30px', paddingTop: '1em'}}>
							<Typography align="center">{pickupLocationQuestion}</Typography>
						</Grid>
						<Grid container style={{maxHeight:viewHeight - 80, overflow:'auto'}}>
							{pickupLocations.map(location => {
								let secondColumn = pickupLocations.length > 5
								return (
									<Grid item container style={{padding:12}} justify={"center"} xs={secondColumn? 6: false}>
										<Button variant="contained" style={{width: secondColumn? '75%': '50%'}} color="primary" onClick={(e) => { setPickupLocation(location); submit()}}>
											{location}
										</Button>    
									</Grid>
								)
							})}
						</Grid>
					</>}
					{pickupLocation &&
						<Grid container justify="center">
							<Typography variant="h5" align="center">{pickupLocation}</Typography>
						</Grid>
					}
				</Grid>
			</div>
		);
	}

	
	return (
		<Slide in direction="left">
			<div>
				<DStyles styles={styles} />
				{selectVehicleEnabled && showingSelectVehicle && vehicleInfo()}
				{selectLocationEnabled && showingSelectLocation && locationInfo()}
				{!showingSelectVehicle && !showingSelectLocation && 
						<center>
							{(vehicleColor || vehicleType == 'other') && <div onClick={() => { clearVehicle(); setShowingSelectVehicle(true); }}>{vehicleInfo()}</div>}
							{pickupLocation && locationInfo()}
							<Typography gutterBottom={true} variant="h6" style={{marginTop: '1em'}}>Thank you!</Typography>
							<Typography gutterBottom={true}>A staff member will be out to you shortly</Typography>

							{ selectVehicleEnabled && <Button color="primary" style={{marginTop: '.5em'}} fullWidth={true} onClick={() => { clearVehicle(); setShowingSelectVehicle(true); }} gutterBottom={true}>Update Vehicle</Button> }
							{ selectLocationEnabled && <Button color="primary" style={{marginTop: 15}} fullWidth={true} onClick={() => { setPickupLocation(""); setShowingSelectLocation(true); }} gutterBottom={true}>Update Location</Button>}
							{ feedbackEnabled && <Button color="primary" style={{marginTop: 15}} fullWidth={true} onClick={() => { global.set({activePage: "FeedbackPage"}) }}>Leave Feedback</Button>}

							{restaurantPhone &&
								<Button color="primary" style={{marginTop: 15}} fullWidth={true} onClick={() => { document.location = 'tel:' + formatPhoneNumber(restaurantPhone) }}>Call Restaurant</Button>
							}
						</center>
					}
			</div>
		</Slide>
	);
};