import React from 'react';
import { Grid, Paper, Typography, Table, TableRow, TableCell, TableBody, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'nuka-carousel';


export default (props) => {
	let [restaurantData] = global.get("restaurantData");


	const c = makeStyles((theme) => ({
		row: {
			maxHeight: 64,
			padding: 'auto',
			borderBottom: 'none',
		}
	}))();

	const styles = {
		numberCircle: {
			width: 32,
			height: 32,
			borderRadius: 16,
			background: '#9b999e',
			color: '#fff',
			textAlign: 'center',
			font: '20px Arial, sans-serif',
		},
		carouselImage: {
			width: '84%',
			border: '0px solid transparent',
			borderRadius: 4,
			boxShadow: '1px 1px 3px rgba(0,0,0,0.25), -1px -1px 3px rgba(0,0,0,0.25)',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backfaceVisibility: 'hidden',
			willChange: 'transform',
		},
		carouselImageHeader: {
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			height: 58,
			position: 'absolute',
			top: 0,
			right: 0,
			left: 0,
			color: '#fff',
			fontWeight: '500',
			background: 'linear-gradient(rgba(0,0,0,0.95), transparent)',
			border: '0px solid transparent',
			borderRadius: '4px 4px 0px 0px',
		},
		currentSpecialTitle: {
			position: 'absolute',
			right: 'auto',
			left: 16,
			fontSize: 2 * Math.round(window.innerWidth * 0.054 / 2),
			webKitFontSmoothing: 'antialiased',
			webKitFontSmoothing: 'subpixel-antialiased',
		},
		currentSpecialPrice: {
			position: 'absolute',
			right: 16,
			left: 'auto',
			fontSize: 2 * Math.round(window.innerWidth * 0.054 / 2),
			webKitFontSmoothing: 'antialiased',
			webKitFontSmoothing: 'subpixel-antialiased',
		},
		currentSpecialPriceDollarSign: {
			verticalAlign: 'top',
			marginRight: 2,
			fontSize: 15,
		},
		paginationDots: {
			width: 8,
			height: 8,
			borderRadius: 4,
			border: '0px solid transparent',
			padding: 0,
			margin: '0px 4px',
			outline: 'none',
		},
		logoStyle: {
			height: restaurantData && restaurantData.logoHeight ? restaurantData.logoHeight : 100,
			width: '100%',
			backgroundImage: 'url(' + (restaurantData ? restaurantData.logo : '') + ')',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
		},
	};

	const renderSpecials = () => {
		return (
			<>
				{restaurantData.logo && 
					<Grid container item align="center" style={{paddingTop:0}} xs={12}>
						<div style={styles.logoStyle}></div>
					</Grid>
				}


				{restaurantData.specials && restaurantData.specials.length > 0 &&
					<>
						<Typography variant='h6' align='center' style={{color: 'var(--loyal-text)', lineHeight: 1.1}}>Today's Specials</Typography>
						<Carousel
							style={{padding: '0px 0px 24px', userSelect: 'none', WebkitTapHighlightColor: 'transparent', outline: 'none'}}
							renderTopCenterControls={() => null}
							renderCenterLeftControls={() => null}
							renderCenterRightControls={() => null}
							renderBottomCenterControls={({currentSlide, slideCount, goToSlide}) => {
								let indexes = [];
								
								for(let i = 0; i < restaurantData.specials.length; i++) {
									indexes.push(i);
								}
					
								return (
									<div className="paging-dots">
										{indexes.map((index) => (
											<button
												key={index}
												style={{...styles.paginationDots, ...{backgroundColor: currentSlide === index ? 'var(--purple)' : '#ccc'}}}
											/>
										))}
									</div>
								);
							}}
							slidesToShow={3}
							ease='linear'
							// framePadding={32}
							// cellAlign='center'
							// cellSpacing={16}
							// dragging
							// autoGenerateStyleTag
							transitionMode='scroll3d'
							opacityScale={1}
							speed={100}
							// width='100%'
							// slideWidth={2}
							wrapAround
						>
							{restaurantData.specials.sort((a,b) => {if(a.orderBy < b.orderBy){return -1}else if(a.orderBy > b.orderBy){return 1}else{return 0}}).map((obj, index) => {
								return (
									<div key={`carousel-${index}`} style={{width: 'fit-content', margin: '0 auto', position: 'relative', display: 'flex', flexDirection: 'column', flex: 0, justifyContent: 'flex-start', willChange: 'transform'}}>
										<img height={256} src={obj.image} style={{border: '1px solid transparent', borderRadius: 4}} alt="specials image"/>
										<div className='carousel-fixed-item top'>
											<div style={styles.carouselImageHeader}>
												<div style={styles.currentSpecialTitle}>{obj.title}</div>
												{obj.price &&
													<div style={styles.currentSpecialPrice}>
														<span style={styles.currentSpecialPriceDollarSign}>$</span>
														<span>{(obj.price / 100).toFixed(2)}</span>
													</div>
												}
											</div>
										</div>
									</div>
								);
							})}
						</Carousel>
					</>
				}

				{restaurantData.content && restaurantData.content.length > 0 && restaurantData.content.sort((a,b) => {if(a.orderBy < b.orderBy){return -1}else if(a.orderBy > b.orderBy){return 1}else{return 0}}).map((obj, index) => {
					return (
						<Grid key={index} item align="center" xs={12}>
							<img src={obj.image} width="90%" />
						</Grid>
					)
				})}
				{restaurantData.topSellers && restaurantData.topSellers.length > 0 &&
					<Grid item xs={12} align="center" style={{ width: '100%' }}>
						<Paper style={{width: '90%'}}>
							<Typography align="center" style={{fontSize:"1.8em", fontWeight: '500', color: 'var(--loyal-text)', paddingTop: 8, lineHeight: 1.1}}>
								Top 5 Sellers
							</Typography>
							<Table>
								<TableBody>
									{restaurantData.topSellers.sort((a,b) => {if(a.orderBy < b.orderBy){return -1}else if(a.orderBy > b.orderBy){return 1}else{return 0}}).map((row, index) => {
										return (
											<TableRow key={index} classes={{root: c.row}}>
												<TableCell style={{width: 64, borderBottom: 'none'}}>
													<div style={styles.numberCircle}>
														<div style={{lineHeight: 1.6}}>{row.orderBy}</div>
													</div>
												</TableCell>

												<TableCell style={{borderBottom: 'none', fontSize: window.innerWidth * 0.05, lineHeight: 1, color: 'var(--loyal-text)'}}>{row.title}</TableCell>
											</TableRow>
										);
									})}
									{/* <TableRow key={'TEST'} classes={{root: c.row}}>
										<TableCell>
											<div style={styles.numberCircle}>
												<div style={{lineHeight: 1.6}}>{9}</div>
											</div>
										</TableCell>

										<TableCell style={{borderBottom: 'none', fontSize: window.innerWidth * 0.05, lineHeight: 1}}>This is a long title that takes up two rows</TableCell>
									</TableRow> */}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
				}
			</>
		)
	}

	return (
		<>
			{restaurantData && restaurantData.menuUrl && !(new RegExp("(png|jpg|jpeg|gif)$").test(restaurantData.menuUrl.toLowerCase())) && 
				<iframe width="100%" height="100%" style={{paddingBottom: '57px'}} src={restaurantData.menuUrl} />
			}
			{restaurantData && restaurantData.menuUrl && new RegExp("(png|jpg|jpeg|gif)$").test(restaurantData.menuUrl.toLowerCase()) && 
				<img width="100%" height="auto%" style={{paddingBottom: '57px'}} src={restaurantData.menuUrl} alt="menu"/>
			}
			
			{ restaurantData && !restaurantData.menuUrl &&
				<Slide in direction="left">
					<Grid container direction="column" alignItems="center" spacing={3} style={{position: 'relative', backgroundColor:'var(--gray-background)', overflow: 'hidden', padding: '48px 0px 73px'}}>
						{renderSpecials()}
					</Grid>
				</Slide>
			}
			
		</>
	);
}
