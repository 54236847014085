// import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'
import checkUtils from '../util/checkUtils'

export default async function getCheckListByTable(restaurantId, table, debug) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** Get Check List By Table API ****')
		let url = `${global.dashServerUrl}/api/v1/check/getCheckListByTable`

        const body = {
			restaurantId,
			table,
			deviceId: localStorage.deviceId
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}
		if (debug) log.info('BODY: ' + JSON.stringify(body))
		fetch(url, options, debug).then((res) => {
			if (res.ok) {
				log.info("res")
				log.info(res)
				res.json().then((json) => {
					log.info("json")
					log.info(json)
					if (json.success) {
						let data = json.data
						if (debug) log.info('success');
						resolve(data);
					} else {
						log.error(json.errors)
						reject(json.errors);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				log.info('getSignupReward failed');
				reject("An error occured trying to retrieve info. Please try again.");
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		});








	})
}
