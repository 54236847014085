import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'
import checkUtils from '../util/checkUtils'

export default async function getCheckDetails(checkId, personIdentifiers = [], ticketId, shortUrl, checkNumber, restaurantCode,  debug = false) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** Get Check Details API ****')
		const url = `${global.dashServerUrl}/api/v1/check/getCheckDetails`

		const body = {
			checkId,
			deviceId: localStorage.deviceId,
			ticketId,
			shortUrl,
			checkNumber,
			restaurantCode,
			personIdentifiers,
		}
		dashFetch(url, body, debug).then(({ res, json }) => {
			if (!json.success) {
				if (global.isProd) {
					NotificationManager.info('', json.errors, 3000)
				}
				reject(json.errors)
			} else {
				let check = checkUtils.updateTotals(json.data.check)
				if (json.data.numericAccCode && !localStorage.numericAccCode) {
					localStorage.numericAccCode = json.data.numericAccCode
				}
				resolve(check)
			}
		}).catch((err) => {
			console.error(err)
			reject(err)
		})
	})
}
