import React from 'react';
import Group from './Group.js';

const s = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		fontFamily: 'Roboto',
		fontWeight: 400,
		lineHeight: 1.1
	},
	dNowText: {
		color: '#757575',
		fontSize: window.innerWidth * 0.08,
	},
	spgText: {
		color: '#9E9E9E',
		fontSize: window.innerWidth * 0.05,
	},
	bull: {
		color: '#9E9E9E',
		margin: '0px 4px',
	}
};

export default (props) => {
	return (
		<div style={s.container}>
			<Group width={props.width ? props.width : window.innerHeight * 0.2} height={props.height ? props.height : window.innerHeight * 0.2} viewBox={'-1, -1, 36, 36'} />

			<div style={s.row}>
				<div style={s.dNowText}>dashnow</div>
			</div>

			<div style={s.row}>
				<div style={s.spgText} id="scan">
					scan
				</div>
				<div style={s.bull}>&bull;</div>
				<div style={s.spgText} id="pay">
					pay
				</div>
				<div style={s.bull}>&bull;</div>
				<div style={s.spgText} id="go">
					go
				</div>
			</div>
		</div>
	);
};
