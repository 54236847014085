import React, { useEffect, useState } from 'react';
// import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
// import { NotificationManager } from 'react-notifications';
import Grid from '@material-ui/core/Grid';
import '../../util/modalCSS.css'
import { Button, Typography, Table, TableCell, TableBody, TableRow, CircularProgress, Slide } from '@material-ui/core';
import CardVisa from '../../resources/images/payment-types/Card_Visa.js';
import CardDiscover from '../../resources/images/payment-types/Card_Discover.js';
import CardAmex from '../../resources/images/payment-types/Card_Amex.js';
import CardMastercard from '../../resources/images/payment-types/Card_Mastercard.js';
import CardMaestro from '../../resources/images/payment-types/Card_Maestro.js';
import CreditCard from '../../resources/images/payment-types/Credit_Card.js';
// import CardAmazon from '../../resources/images/payment-types/Card_Amazon.js';
// import CardGoogle from '../../resources/images/payment-types/Card_Google.js';
import DashLoad from '../../assets/Dashnow-Loading.gif';
import Redeem from '../../assets/Redeem.js';
import checkUtils from '../../util/checkUtils';
import ReactGA from 'react-ga';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import TextpayLogo from '../../assets/textpay-logo';

let cardIcons = {
	visa: <CardVisa />,
	discover: <CardDiscover />,
	american_express: <CardAmex />,
	master: <CardMastercard />,
	// amazon: <CardAmazon />,
	// cirrus: require('../resources/images/payment-types/card_cirrus.png'), Can this be used in a restaurant? Seems like it requires a pin
	// 'diners-club': require('../resources/images/payment-types/card_diners-club.png'),
	// jcb: require('../resources/images/payment-types/card_unknown.png'),  Japan
	// unionpay: require('../resources/images/payment-types/card_unknown.png'),  China
	ach: <AccountBalanceIcon/>,
    maestro: <CardMaestro />,
    generic: <CreditCard/>
};


export default (props) => {
	let card = props.card
	let [check] = global.get("check")
	const [loyaltyProgram] = global.get('loyaltyProgram')
    const [restaurantLogoUrl] = global.get('restaurantLogoUrl');
    const [restaurant] = global.get('restaurant');
    const [achEnabled] = useState(check.getVal('restaurant.paymentMethods.ach.enabled', false)); 
    const f = global.f;
	const [ccServiceChargeLabel] = global.get('ccServiceChargeLabel', 'convenience fee')
	const [achServiceChargeLabel] = global.get('achServiceChargeLabel', 'convenience fee')


	const setupModal = (defaultHeight) => {
		let height = defaultHeight
		let rowHeight = 22
		if (check.myTotals.taxes > 0) { height += rowHeight }
		if (check.myTotals.discounts > 0) { height += rowHeight }
		if (check.myTotals.other > 0) { height += rowHeight }
		if (check.myTotals.tip > 0) { height += rowHeight }
		if (check.myTotals.prevPaidAmount > 0) {height += rowHeight}
		// console.log('height ' + height)
		return height
	}
	const onPay = props.onPay
	
	let modalHeight = setupModal(270)
	const [awaitingPaymentResponse, setAwaitingPaymentResponse] = useState(false);
	const GA_CATEGORY_PAYMENT_CLICKED = "Payment clicked"
	const GA_CATEGIRY_PAYMENT_PANEL = "Payment confirm window"

	useEffect(() => {
	}, []);


	const c = makeStyles((theme) => ({
		separator: {
			height: 1,
			width: '100%',
			backgroundColor: '#ccc',
		},

		paymentAmountTable: {
			width: '100%',
		},
		paymentAmountBody: {

		},
		paymentAmountCell: {
			padding: 0,
			borderBottom: 'none',
		},
		paymentAmountRow: {
			marginBottom: 'none'
		},

		topContainer: {
			width: '100%',
			height: 48,
			maxHeight: 48,
		},

		topWrapper: {
			width: '100%',
			height: '100%',
			display: 'inline',
			marginBottom: 'auto',
			marginTop: 'auto',
		},

		topText: {
			height: 16,
			display: 'inline-block',
			position: 'absolute',
			fontSize: 16,
			fontWeight: 400,
			top: 5,
			bottom: 16,
		},

		modalContainer: {
			width: '100%',
			height: '100%',
			backgroundColor: 'var(--mask)',
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: 1400,
			elevation: 100,
        },
        
        dialogWrapper: {
            zIndex: 66600,
            width: '500px',
            height: '300px',
            position:'fixed',
            top:'calc(50vh - 150px)',
            left:'calc(50vw - 250px)',
            border:'1px solid #F5F5F5',
            backgroundColor:'white'
        },

		modalWrapper: {
			width: '100%',
			height: modalHeight,
			position: 'absolute',
			left: 0,
			bottom: 0,
			right: 0,
			// borderTopLeftRadius: 5,
			// borderTopRightRadius: 5,
			backgroundColor: '#FFFFFF',

		},

		form: {
			width: '100%',
			height: 250,
			backgroundColor: '#fff',
			marginTop: 16,
		},

		label: {
			fontSize: 14,
			fontWeight: 600,
			color: 'rgba(0, 0, 0, 0.6)',
			textAlign: 'center',
			marginBottom: 8,
		},


		payButton: {
			alignContent: 'center',
			textAlign: 'center',
			position: 'absolute',
			left: 0,
			right: 0,
			justifyContent: 'center',
		}
	}))();



	const theOnSubmit = async (e) => {
		e.preventDefault(); 
		if (!awaitingPaymentResponse) {
			setAwaitingPaymentResponse(true)
			await onPay()
			setAwaitingPaymentResponse(false)
		}
	}

	const zeroAmountBill = (myTotals) => {
		return myTotals && myTotals.youPay === 0 && myTotals.loyaltyRewardAmount > 0
	}

    // const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    // WORLDPAY doesn't provide card type upon tokenization
    if (card.type === 'generic') {
        card.displayName = ' '
    }

	const closeWindow = () => {
		if (props.isDesktopView) {
			check.myTotals.isPartialPayment = false
			check = checkUtils.updateTotals(check, 0)
		}
		check = checkUtils.applyServiceCharge(check)
		global.set({check})
		global.set({ activePanel: '', selectedPaymentMethod: null });
		
		ReactGA.event({
			category: GA_CATEGIRY_PAYMENT_PANEL,
			action: "CC user clicked cancel"
		});
	}
	return (
		<Grid container id="modalContainer" className={c.modalContainer} onClick={(e) => { if (e.target.id === "modalContainer") { global.set({ activePanel: '' }) } }}>
			<Slide in direction="up">
				<div className={props.isDesktopView ? c.dialogWrapper :  c.modalWrapper}>
					<div className={c.topContainer}>
						<div className={c.topWrapper}>
							<Typography className={c.topText} variant="caption" style={{ marginTop: 8, marginLeft: 14}}>CONFIRM PAYMENT</Typography>
							<div className={c.topText} style={{ color: '#999999', right: 10, left: 'auto', marginTop: 8 }} onClick={() => {closeWindow()}}>
								<Typography variant="caption" color="primary">CANCEL</Typography></div>
						</div>
					</div>
					<div className={c.separator}></div>

					<Grid container style={{padding: 8}}>
						<Grid container style={{paddingTop: 4}}>
							{!zeroAmountBill(check.myTotals) && (
								<Grid container direction='row' style={{marginBottom: 8}}>
									<Grid container direction='column' justify='center' alignItems='center' item xs={3}>
										{cardIcons[card.type]}
									</Grid>
									<Grid container direction='column' align='flex-start' justify='center' item xs={9} style={{height: '100%', paddingLeft: 8}}>
										{card && card.type == 'ach' && <Typography style={{ verticalAlign: 'middle'}} variant='caption'>{card.type.toUpperCase() + ' **** ' + card.ach.accountNumberLast4}</Typography>}
										{card && card.type != 'ach' && <Typography style={{ verticalAlign: 'middle'}} variant='caption'>{(card.displayName ? card.displayName.toUpperCase() : card.type ? card.type.toUpperCase() : ''  )+ ' **** ' + card.lastFour}</Typography>}
									</Grid>
								</Grid>
							)}
							{zeroAmountBill(check.myTotals) && (
								<>
									<Grid item xs={3} style={{ alignContent: 'center', textAlign: 'center', paddingRight: 4}}>
										<Redeem />
									</Grid>
									<Grid item xs={8} style={{ alignContent: 'left', textAlign: 'left', paddingTop: 11}}>
										<Typography style={{ verticalAlign: 'middle', height: '100%'}} variant='caption'>{loyaltyProgram.name} Rewards</Typography>
									</Grid>
								</>
							)}
						</Grid>
						<Grid container>
							<Grid item xs={3} style={{paddingRight: 4, paddingLeft: 4}}></Grid>
							<Grid item style={{ alignContent: 'right', textAlign: 'right', paddingLeft: 8, paddingRight: 4 }} xs={9}>
								<Table className={c.paymentAmountTable}>
									<TableBody className={c.paymentAmountBody}>

										<>	
											{check.myTotals.items != check.myTotals.youPay && !check.myTotals.isPartialPayment &&
												<TableRow className={c.paymentAmountRow} key={"check-subtotal"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>SUBTOTAL</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>$ {checkUtils.formatMoney(check.myTotals.items)}</TableCell>
												</TableRow>}
											{check.myTotals.items != check.myTotals.youPay && check.myTotals.isPartialPayment &&
												<TableRow className={c.paymentAmountRow} key={"check-subtotal"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>SUBTOTAL</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>$ {checkUtils.formatMoney(check.myTotals.total - check.myTotals.taxes - check.myTotals.discounts - check.myTotals.other)}</TableCell>
												</TableRow>}
											{(check.myTotals.taxes - check.myTotals.loyaltyTaxAmountRemoved) > 0 &&
												<TableRow className={c.paymentAmountRow} key={"check-taxes"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>TAXES</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>$ {checkUtils.formatMoney(check.myTotals.taxes - check.myTotals.loyaltyTaxAmountRemoved)}</TableCell>
												</TableRow>}
											{check.myTotals.discounts < 0 &&
												<TableRow className={c.paymentAmountRow} key={"check-discounts"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>DISCOUNTS</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{textAlign: 'right'}}>$ {checkUtils.formatMoney(check.myTotals.discounts)}</TableCell>
												</TableRow>}
											{check.myTotals.other > 0 &&
												<TableRow className={c.paymentAmountRow} key={"check-other"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>OTHER</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>$ {checkUtils.formatMoney(check.myTotals.other)}</TableCell>
												</TableRow>}
											{check.myTotals.tip > 0 &&
												<TableRow className={c.paymentAmountRow} key={"check-tip"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>TIP</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>$ {checkUtils.formatMoney(check.myTotals.tip)}</TableCell>
												</TableRow>}
											{check.myTotals.loyaltyRewardAmount > 0 &&
												<TableRow className={c.paymentAmountRow} key={"check-reward"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>LOYALTY</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>-$ {checkUtils.formatMoney(check.myTotals.loyaltyRewardAmount)}</TableCell>
												</TableRow>}
											{check.myTotals.prevPaidAmount > 0 && !check.myTotals.isPartialPayment &&
												<TableRow className={c.paymentAmountRow} key={"check-previous"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>PREV. PAYMENT</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>-$ {checkUtils.formatMoney(check.myTotals.prevPaidAmount)}</TableCell>
												</TableRow>}   
											{check.myTotals.ccServiceChargeAmount > 0 &&
												<TableRow className={c.paymentAmountRow} key={"check-ccServiceCharge"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>{ccServiceChargeLabel.toUpperCase()}</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>$ {checkUtils.formatMoney(check.myTotals.ccServiceChargeAmount)}</TableCell>
												</TableRow>}   
											{check.myTotals.achServiceChargeAmount > 0 &&
												<TableRow className={c.paymentAmountRow} key={"check-achServiceCharge"}>
													<TableCell className={c.paymentAmountCell}><Typography style={{color: 'gray'}} variant='caption'>{achServiceChargeLabel.toUpperCase()}</Typography></TableCell>
													<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}>$ {checkUtils.formatMoney(check.myTotals.achServiceChargeAmount)}</TableCell>
												</TableRow>}   
										</>                           

										<TableRow className={c.paymentAmountRow} style={{paddingTop:2}}key={"check-total"}>
											<TableCell className={c.paymentAmountCell}><Typography variant='caption'>PAY {check.restaurant.name.toUpperCase()}</Typography></TableCell>
											<TableCell className={c.paymentAmountCell} style={{ textAlign: 'right' }}><Typography variant='body1' noWrap={true} style={{fontSize: 18, paddingLeft: 8}} color="primary">$ {checkUtils.formatMoney(check.myTotals.youPay)}</Typography></TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Grid>
						</Grid>
						{!awaitingPaymentResponse &&
							<Grid item className={c.payButton} style={{bottom: 18}} xs={12}>	{/* TODO absolute position */}
								<Button variant='contained' color='primary' onClick={(e) => { 
										theOnSubmit(e); 
										ReactGA.event({
											category: GA_CATEGIRY_PAYMENT_PANEL,
											action: "CC user clicked Pay"
										});

									}}>
									<Typography>{zeroAmountBill(check.myTotals) ? 'Use Reward' : 'Pay'}</Typography>
								</Button>
							</Grid>
						}
						{awaitingPaymentResponse && 
							<>
								<Grid item className={c.payButton} style={{bottom: 18}} xs={12}>
									{(f(restaurant, 'branding.logo', false) || restaurantLogoUrl || global.branding != 'dashnow' || (global.branding == 'dashnow' && global.textPay)) && <CircularProgress />}
									{global.branding == 'dashnow' && !global.textPay && !restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && <img src={DashLoad} height="80px" alt="Waiting for payment..." />}
								</Grid>
							</>
						}					
					</Grid>

					
				</div>
			</Slide>
		</Grid>
	);
};
