import log from 'loglevel';
import { NotificationManager } from 'react-notifications';
import initAccount from './initAccount';
const uuidv4 = require('uuid/v4');

export default async function(debug) {
	return new Promise(async (resolve, reject) => {
		// if (debug) log.info('register api');

		const url = `${global.dashServerUrl}/api/v1/account/getToken`;
		if (debug) log.info(`url: ${url}`);

		const body = {
			//mobileDeviceInfo: {
			//},
			deviceId: localStorage.deviceId,
		};

		// if (debug) log.info('getToken body');
		// if (debug) log.info(body);

		let headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			//'accountId': localStorage.accountId,
			//'x-access-token': localStorage.token,
			//'tokenTimeout': localStorage.tokenTimeout,
			//'refreshTimeout': localStorage.refreshTimeout,
		}
		if (localStorage.refreshToken) {
			headers['x-access-refresh-token'] = localStorage.refreshToken
		}
		// if (debug) log.info('getToken headers')
		// if (debug) log.info(headers)

		fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(body) }, debug).then(async (res) => {
			if (debug) log.info(`status: ${res.status}`);
			if (res.ok) {
				res.json().then((json) => {
					if (json.success) {
						let data = json.data
						if (debug) log.info('getToken success');
						if (debug) log.info(data)
						/* SAVE ACCOUNT DATA LOCALLY */
						if (global.tryMe) {
							localStorage.accountId = data.accountId
						}
						localStorage.token = data.token
						localStorage.refreshToken = data.refreshToken
						localStorage.tokenTimeout = data.tokenTimeout
						localStorage.refreshTimeout = data.refreshTimeout
						resolve(data.token);
					} else {
						console.error(json.errors)
						reject(json.errors);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				if (global.tryMe) {
					delete localStorage.accountId
					delete localStorage.token
					delete localStorage.refreshToken
					window.location.reload()
				} else {
					await initAccount(true)

					if (!localStorage.token) {
						log.info("NO TOKEN CREATED")
						// global.set({ activePage: "CreateAccountPage" })
						NotificationManager.warning('', "Your Session Timed Out", 3000);
						reject(res);
					} else {
						resolve(localStorage.token);
					}
				}
			}
		}).catch((err) => {
			reject(err.message);
		})
	})

}
