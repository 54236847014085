import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Checkbox, Dialog } from '@material-ui/core';
import ReactGA from 'react-ga';
import createLoyaltyAccount from '../../actions/createLoyaltyAccount';
import Confetti from 'react-dom-confetti';
import { NotificationManager } from 'react-notifications';
import ReactMarkdown from 'react-markdown';
import getRestaurantGroupLocations from "../../actions/getRestaurantGroupLocations.js";
import LoyaltyPrivacyPolicy from '../loyalty/LoyaltyPrivacyPolicy.js';

export default (props) => {
	const [buttonText] = useState(`Join Now`)
	const [showPrivacy, setShowPrivacy] = useState(false);
	const [groupedRestaurants, setGroupedRestaurants] = useState([]);
	let [agreedToLoyaltyTerms] = global.get('agreedToLoyaltyTerms')
	let [loyaltyProgram] = global.get('loyaltyProgram')
	let [signupReward] = global.get('signupReward');
	let [signedIn] = global.get('signedIn')
	let [restaurantId] = global.get("restaurantId")
	let [checkId] = global.get('checkId')
	let [loyaltyAccount] = global.get('loyaltyAccount')
	let [viewingReceipt] = global.get('viewingReceipt')
	let [restaurantData] = global.get("restaurantData")
	let specialsTab = restaurantData && (restaurantData.specials || restaurantData.content || restaurantData.topSellers)

	const confettiConfig = {
		angle: 90,
		spread: 33,
		startVelocity: "50",
		elementCount: 150,
		dragFriction: 0.105,
		duration: 4000,
		stagger: 0,
		width: "8px",
		height: "8px",
		colors: ["#ccc", "var(--purple-text)"],
	};
	const [showConfetti, setShowConfetti] = useState(false);

	if (agreedToLoyaltyTerms === undefined) {
		agreedToLoyaltyTerms = true
		global.set({ agreedToLoyaltyTerms })
	}

	useEffect(() => {
		setTimeout(() => { setShowConfetti(true) }, 500)

		getRestaurantGroupLocations(loyaltyProgram.restaurantGroup)
			.then((res) => {
				// console.log('PARSED RESPONSE:', res);
				setGroupedRestaurants(res);
				// console.log('GROUPED RESTAURANTS:', groupedRestaurants);
			})
			.catch((err) => {
				// console.log(err);
			});
	}, []);


	const acceptGift = async () => {
		if (signedIn && !loyaltyAccount) {
			await createLoyaltyAccount(restaurantId, checkId);
		}
		props.nextStep()
	}

	const declineGift = async () => {
		props.nextStep()
		// if (checkId) {
		// 	global.pageStack.push({ pageName: "LoyaltyWrapper", fn: () => global.set({ footer: true }) }); 

		// 	global.set({
		// 		allowBack: true,
		// 		header: true,
		// 		footer: false,
		// 		activePage: "LoadingPage",
		// 	});
		// } else {
		// 	global.set({
		// 		header: true,
		// 		footer: true,
		// 		activePage: "CheckStackPage",
		// 	});
		// }
	}

	const windowHeight = window.innerHeight
	const logoImageHeight = loyaltyProgram && loyaltyProgram.logoHeight ? loyaltyProgram.logoHeight : windowHeight * 0.15
	
	const s = {
		wrapper: {
			height: '100%',
			marginTop: `${window.innerHeight < 600 ? (100 - logoImageHeight) : -25}px`,
		},
		congratulationsWrapper: {
			marginTop: '5%',
		},
		congratulations: {
			fontSize: window.innerWidth * 0.1,
			fontWeight: '600',
			lineHeight: 1.1,
			letterSpacing: 0.5,
			color: 'var(--loyal-text)',
		},
		earnWrapper: {
			marginTop: '3%',
		},
		rewardValueWrapper: {
			display: 'flex',
			flexDirection: 'row',
			flex: 0,
			alignItems: 'flex-start',
			color: 'var(--purple)',
			lineHeight: 1.1,
		},
		termsWrapper: {
			marginTop: '10%',
		},
		clickHere: {
			fontSize: window.innerWidth * 0.04,
			textDecoration: 'underline',
			display: 'inline-block',
		},
		privacyText: {
			display: 'flex',
			flexDirection: 'column',
			flex: 0,
			fontSize: window.innerWidth * 0.04,
			color: 'gray',
		},
		imageWrapper: {
			marginTop: 4,
			// height: window.innerHeight * 0.15,
			// marginBottom: '5%',
		},
		joinWrapper: {
			height: '15%',
			marginTop: '5%',
			marginBottom: '5%',
		},
	}

	const GA_REWARDS_POLICY = "Rewards Policy";

	return (
		<div style={s.wrapper}>
			<Grid container direction="column" alignItems="center" spacing={0}>

				<Grid container direction='row' justify='center' alignItems='center' style={s.imageWrapper}>
					<img height={logoImageHeight} src={loyaltyProgram.logo} />
				</Grid>


				<Grid container align='center' style={s.joinWrapper}>
					<Grid direction='row' xs={12} justify='center'>
						<Typography style={{ color: '#6F6F6F', fontWeight: '300', fontSize: window.innerWidth * 0.08, lineHeight: 1.1 }}>Let us <span style={{ fontWeight: '500' }}>Thank You</span></Typography>
					</Grid>
				</Grid>


				<Grid xs={12}>
					<Typography variant='h5' style={s.rewardValueWrapper}>$<span style={{ fontSize: 56, fontWeight: '500', marginTop: -8 }}>{signupReward ? signupReward.value / 100 : 0}</span></Typography>
				</Grid>


				<Grid xs={12}>
					<Typography variant='h6' align='center' style={{ lineHeight: 1.1, color: 'var(--loyal-text)' }}>off your meal today</Typography>
				</Grid>


				<Grid container xs={12} direction="row" justify='center' alignItems='center' wrap='nowrap' style={s.termsWrapper}>
					<Grid>
						<Checkbox color="primary" checked={agreedToLoyaltyTerms} value="checkedA" onClick={() => { agreedToLoyaltyTerms = !agreedToLoyaltyTerms; global.set({ "agreedToLoyaltyTerms": agreedToLoyaltyTerms }); }} />
					</Grid>

					<Grid>
						<Typography style={s.privacyText} component={'span'}>I agree to the terms of the&nbsp;
							<Typography style={s.clickHere} onClick={() => {
								setShowPrivacy(true);
								ReactGA.event({
									category: GA_REWARDS_POLICY,
									action: "Show"
								});
							}}>
								{loyaltyProgram.name}&nbsp;Loyalty Program
							</Typography>
						</Typography>
					</Grid>
				</Grid>
				<Grid xs={12} style={{ marginTop: '10%' }}>
					<Button color={agreedToLoyaltyTerms ? "primary" : "primary"} style={{ backgroundColor: (agreedToLoyaltyTerms ? "var(--purple)" : 'var(--light-gray)') }} variant="contained" onClick={() => { (agreedToLoyaltyTerms) ? acceptGift() : NotificationManager.warning('', 'Please agree to the terms of the loyalty program or click no thanks to continue', 3000) }}>{buttonText}</Button>
				</Grid>
				<Grid xs={12} style={{ marginTop: '5%' }}>
					<Button
						variant='text'
						size='medium'
						style={{ color: '#6F6F6F' }}
					>
						<Typography variant='button' onClick={() => viewingReceipt ? global.set({ header: true, activePage: 'PaymentPage' }) : global.set({ activePage: specialsTab ? 'RestaurantInfoPage' : 'CheckStackPage' })}>No Thanks</Typography>
					</Button>

				</Grid>
				<Confetti active={showConfetti} config={confettiConfig} width='100%' height='100%' style={{ position: 'absolute', top: 'auto', bottom: 0, left: 0, right: 0 }} />
			</Grid>
			{showPrivacy &&
				<Dialog
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={showPrivacy}
					onClose={() => { setShowPrivacy(false) }}
					style={{ margin: '10% 2%' }}
				>
					<div style={{ height: '90%', overflowY: 'auto', margin: '20px 20px 0px 20px' }} >
						{loyaltyProgram.termsAndConditions && <ReactMarkdown source={loyaltyProgram.termsAndConditions} />}
						{!loyaltyProgram.termsAndConditions && <LoyaltyPrivacyPolicy />}
					</div>
				</Dialog>
			}
		</div>
	);
}


