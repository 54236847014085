import React, { Component } from 'react';
import log from 'loglevel';
import '../css/splash-screen.css';
import Launch from '../resources/images/Launch';
import Grow from '@material-ui/core/Grow';
import { urlParse } from '../util/urlParser';

function LoadingMessage() {
    let url = urlParse(window.location.href)
	let params = url.params
    let pathname = url.pathname
    let numOfParams = Object.keys(params).length
    let message = params && params.t == 'null' ? "No Check Found" : ""
	let hideSplashLogo = params.geo || params.ExpressResponseCode

    if (!pathname && numOfParams == 0 && window.location.href.indexOf('textpay.app') > -1) {
        document.location.href = 'https://www.textpay.app' // sweet hack for routing to our website
        return <div></div>
	} else if (hideSplashLogo) {
        return <div></div>
    } else {
        return (
            <div className="splash-screen">
                <Launch />
                {!message && <div className="loading-dot">.</div>}
                {message && <span>{message}</span>}
            </div>
        );
    }
}

function withSplashScreen(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                //await app load
                // await auth0Client.loadSession();
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 600)
            } catch (err) {
                log.info(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            // while checking user session, show "loading" message
            if (this.state.loading){
                return (
                    <Grow in={this.state.loading}>
                        {LoadingMessage()}
                    </Grow>
                )
            } 

            // otherwise, show the desired route
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default withSplashScreen;