import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AuthNetForm(props) {
    const [cardType, setCardType] = useState('');
    const [zip, setZip] = useState('');
    let restaurant = props.restaurant;
    let [formToken, setFormToken] = global.get("authnetHostedProfilePageRequestsToken")

    const isProd = global.isProd    
    let paymentProfileId = '1513539813' // customer card
    let apiLoginKey = global.f(restaurant, 'paymentMethods.creditCard.gatewayData.apiLoginKey', '') //merchant
    let transactionKey = global.f(restaurant, 'paymentMethods.creditCard.gatewayData.transactionKey', '') //merchant
    
    useEffect(()=> {
        getHostedProfilePageRequest()
    },[])

    useEffect(()=>{
        if (!window.CommunicationHandler) {
            window.CommunicationHandler = {};
        }
        window.CommunicationHandler.onReceiveCommunication = function (querystr, referrer) {
            // console.log('DIE SLOW', querystr)
            // console.log('DIE SLOW2', referrer)
            props.getAuthnetCards()
        }
        function centerPopup() {
            var d = document.getElementById("divAuthorizeNetPopup");
            d.style.left = "50%";
            d.style.top = "50%";
            var left = -Math.floor(d.clientWidth / 2);
            var top = -Math.floor(d.clientHeight / 2);
            d.style.marginLeft = left.toString() + "px";
            d.style.marginTop = top.toString() + "px";
            d.style.zIndex = "2";
            if (d.offsetLeft < 16) {
                d.style.left = "16px";
                d.style.marginLeft = "0px";
                }
            if (d.offsetTop < 16) {
                d.style.top = "16px";
                d.style.marginTop = "0px";
                }
        }

        function parseQueryString(str) {
            var vars = [];
            var arr = str.split('&');
            var pair;
            for (var i = 0; i < arr.length; i++) {
                pair = arr[i].split('=');
                vars.push(pair[0]);
                vars[pair[0]] = unescape(pair[1]);
            }
            return vars;
        }
        if (!window.AuthorizeNetPopup) window.AuthorizeNetPopup = {};
        if (!window.AuthorizeNetPopup.options) window.AuthorizeNetPopup.options = {
            onPopupClosed: null
        };

        window.AuthorizeNetPopup.closePopup = function () {
            document.getElementById("divAuthorizeNetPopupScreen").style.display = "none";
            document.getElementById("divAuthorizeNetPopup").style.display = "none";
            document.getElementById("iframeAuthorizeNet").src = "empty.html";
            document.getElementById("btnOpenAuthorizeNetPopup").disabled = false;
            if (window.AuthorizeNetPopup.options.onPopupClosed) window.AuthorizeNetPopup.options.onPopupClosed();
        };
   
        window.AuthorizeNetPopup.onReceiveCommunication = function (querystr) {
            var params = parseQueryString(querystr);
            // alert(params);
            switch (params["action"]) {
                case "successfulSave":
                    window.AuthorizeNetPopup.closePopup();
                    props.getAuthnetCards()
                    break;
                case "cancel":
                    window.AuthorizeNetPopup.closePopup();
                    break;
                case "transactResponse":
                    var response = params["response"];
                    document.getElementById("token").value = response;
                    window.AuthorizeNetPopup.closePopup();
                    break;
                case "resizeWindow":
                    var w = parseInt(params["width"]);
                    var h = parseInt(params["height"]);
                    var ifrm = document.getElementById("iframeAuthorizeNet");
                    ifrm.style.width = w.toString() + "px";
                    ifrm.style.height = h.toString() + "px";
                    centerPopup();
                    break;
            }
        }
        
    },[])


    async function getHostedProfilePageRequest() {
        if (formToken) {
            const form = document.getElementById('auth-form')
            if (form) {
                form.action = 'https://test.authorize.net/customer/manage'
                form.submit()
            }
        }
    }

    if(!formToken){
        return (
            <div style={{width:'100%', height:'100%'}}>
                <CircularProgress 
                    style={{
                        position: 'absolute', 
                        top:'50%', 
                        left:'50%', 
                        height:'100px', 
                        width:'100px', 
                        marginTop:'-50px', 
                        marginLeft:'-50px', 
                        opacity:'20%'}}/>
            </div>
        )
    }
     
    const actionUrl = isProd ? "https://accept.authorize.net/customer/addPayment" : "https://test.authorize.net/customer/addPayment"

    return (
        <div className="form-wrapper" style={{ width: '100%', padding: 0 }}>
            <form method="post" id="auth-form" target="load_profile">
                <input type="hidden" name="token" value={formToken} />
                <input type="hidden" name="paymentProfileId" value={paymentProfileId} />
            </form>

            <iframe title="authnet" name="load_profile" id="load_profile" className="embed-responsive-item" width="100%" style={{ minHeight: '100%', height: '85vh', marginBottom:'-5px' }} frameBorder="0" scrolling="no" hidden={!formToken}>
            </iframe>
        </div>
    );

}