import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import dashFetch from '../../actions/dashFetch';
import { makeStyles } from '@material-ui/core/styles';
import AddCardPanel from './AddCardPanel';
import AddACHPanel from './AddACHPanel';
import ConfirmPaymentPanel from './ConfirmPaymentPanel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ApplePayIcon from '../../resources/images/payment-types/Apple_Pay_Mark_RGB_041619.svg';
// import GooglePayIcon from '../../resources/images/payment-types/card_google-pay.png';
import Grid from '@material-ui/core/Grid';
import checkUtils from '../../util/checkUtils';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import makePayment from '../../actions/makePayment';
import { NotificationManager } from 'react-notifications';
import CardVisa from '../../resources/images/payment-types/Card_Visa.js';
import CardDiscover from '../../resources/images/payment-types/Card_Discover.js';
import CardAmex from '../../resources/images/payment-types/Card_Amex.js';
import CardMastercard from '../../resources/images/payment-types/Card_Mastercard.js';
import CardMaestro from '../../resources/images/payment-types/Card_Maestro.js';
import CardAmazon from '../../resources/images/payment-types/Card_Amazon.js';
import CardGoogle from '../../resources/images/payment-types/Card_Google.js';
import CreditCard from '../../resources/images/payment-types/Credit_Card.js';
import AddCreditCard from '../../resources/images/payment-types/Add_Credit_Card.js'
import AddACH from '../../resources/images/payment-types/Add_ACH.js'
import DStyles from '../../components/DStyles'
import DashLoad from '../../assets/Dashnow-Loading.gif';
import ReactGA from 'react-ga';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LoyaltyApplyRewards from '../loyalty/LoyaltyApplyRewards';
import { webSocketMessageHandler } from '../../websocket/WebsocketMessageHandler';
import {Checkbox, Dialog, DialogActions, Slide, Tab, Tabs, DialogTitle, DialogContent} from '@material-ui/core';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsOfUse from '../TermsOfUse';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import updateOptInAccountRestaurant from '../../actions/updateOptInAccountRestaurant';
import EmailReceipt from '../../actions/emailReceipt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItem from '../../components/ListItem';
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import SelectTipTable from './sections/SelectTipTable';
const GA_PRIVACY_POLICY = "Privacy Policy";


let cardIcons = {
	visa: <CardVisa />,
	discover: <CardDiscover />,
	american_express: <CardAmex />,
	master: <CardMastercard />,
	mastercard: <CardMastercard />,
	amazon: <CardAmazon />,
	// cirrus: require('../resources/images/payment-types/card_cirrus.png'), Can this be used in a restaurant? Seems like it requires a pin
	// 'diners-club': require('../resources/images/payment-types/card_diners-club.png'),
	// jcb: require('../resources/images/payment-types/card_unknown.png'),  Japan
	// unionpay: require('../resources/images/payment-types/card_unknown.png'),  China
	maestro: <CardMaestro />,
	'apple-pay': ApplePayIcon,
    'google-pay': <CardGoogle />,
    generic: <CreditCard/>,
	ach: <AccountBalanceIcon />
};

const THIS_PAGE = 'PaymentPage';

export default (props) => {
	const f = global.f
    let cards = localStorage.getItem('savedCards') ? global.parseJSONArrSafe(localStorage.getItem('savedCards')) : [];
	const [selectedPaymentMethod] = global.get('selectedPaymentMethod')
    let [check] = global.get('check');
	const [applePayAvailable] = useState(window.ApplePaySession !== undefined);
	const [showGooglePay, setShowGooglePay] = useState(false);
	const [card, setCard] = global.get('selectedPaymentMethod', {});
	const [creditCardEnabled] = useState(check.getVal('restaurant.paymentMethods.creditCard.enabled', false));
	const [achEnabled] = useState(check.getVal('restaurant.paymentMethods.ach.enabled', false)); 
	const [inProcessOfPayingWithMobileWallet, setInProcessOfPayingWithMobileWallet] = useState(false);
	const [ showPaymentFailureDialog, setShowPaymentFailureDialog ] = useState(false);
	const [ tabValue, setTabValue ] = useState("termsOfUse");
	const [ serverErrorMessage, setServerErrorMessage ] = useState("");
	const [agreeance, setAgreeance] = React.useState(true);
	const [showPrivacy, setShowPrivacy] = React.useState(false);
	let [ loyaltyProgram ] = global.get('loyaltyProgram')
	const [ loyaltyAccount ] = global.get('loyaltyAccount')
	const [ skipReceiptPage ] = global.get('skipReceiptPage')
	const trainingMode = check.restaurant.trainingMode ? true : false
	const GA_CATEGORY_PAYMENT_CLICKED = "Payment Clicked"
	const GA_CATEGORY_PAYMENT_COMPLETE = "Payment Complete"
	const GA_CATEGORY_PAYMENT_ADD_CARD = "Payment add card"
	const [ paymentTerms ]  = global.get('paymentTerms')
	const [ ccServiceChargePercent ]  = global.get('ccServiceChargePercent')
    const [ ccServiceChargeAmount ]  = global.get('ccServiceChargeAmount')
    const [ ccServiceChargeLabel ]  = global.get('ccServiceChargeLabel')
	const [ achServiceChargePercent ]  = global.get('achServiceChargePercent')
    const [ achServiceChargeAmount ]  = global.get('achServiceChargeAmount')
    const [ achServiceChargeLabel ]  = global.get('achServiceChargeLabel')
    const [ showGooglePayDesktop ]  = global.get('showGooglePayDesktop')

	let supportedPaymentTypes = []
	let applePayMerchantId = null;
	let restaurantName = check.restaurant.name
	let restaurant = check.restaurant
	let currency = check.restaurant.currency ? check.restaurant.currency : "USD"
	let gatewayTypeCode = null
	const [ tokenizationPlatform, setTokenizationPlatform ] = useState(global.f(restaurant, 'paymentMethods.creditCard.gatewayData.gatewayTypeCode'));
	const [ achtokenizationPlatform, setAchTokenizationPlatform ] = useState(global.f(restaurant, 'paymentMethods.ach.gatewayData.tokenizationPlatform'));
	let tp = tokenizationPlatform
    tp = tp ? tp.replace('Test', '') : '' // show test gateway cards

	cards = cards && cards.filter(c => {
		return ([tokenizationPlatform, "vgs"].indexOf(c.source) > -1 || (!c.source && tokenizationPlatform == "spreedly" || c.source == tp))
	})

	if (trainingMode || global.tryMe) {
		cards && cards.push({
			_id: "-1",
			type: 'visa',
			lastFour: '1111',
			trainingMode: true,
			displayName: 'Visa (Demo)',
			paymentType: 'creditCard'
		}) && cards.push({
			_id: "-2",
			type: 'ach',
			lastFour: '2222',
			trainingMode: true,
			displayName: 'ACH (Demo)',
			paymentType: 'ach',
			ach: {
				bankName: 'Demo Bank',
				nameOnAccount: 'Def Not Chris',
				routingNumber: '112358',
				routingNumberLast4: '1234',
				accountNumberLast4: '2222',
				accountType: 'checking',
				checkType: 'what is check type'
			}
		})
	}

	for (var key in check.restaurant.paymentMethods) {
		let paymentMethod = check.restaurant.paymentMethods[key]
		if (paymentMethod.enabled && f(paymentMethod, 'gatewayData.enabled') ) {
			supportedPaymentTypes.push(key)
			// console.log("paymentMethod")
			// console.log(paymentMethod)
			// console.log("")
			// console.log("")
			// console.log("")
			if (paymentMethod.gatewayData.supportedCardTypes) {
				paymentMethod.gatewayData.supportedCardTypes.forEach(ct => {
					if(supportedPaymentTypes.indexOf(ct) == -1) supportedPaymentTypes.push(ct)
					
					/**
					 * These are backwards compatibility for cards stored in local storage, don't remove
					 */
					if(ct == 'master' && supportedPaymentTypes.indexOf('mastercard') == -1) supportedPaymentTypes.push('mastercard')
					if(ct == 'american_express' && supportedPaymentTypes.indexOf('amex') == -1) supportedPaymentTypes.push('amex')
				})
			}
		}
		if (key === "ach" && paymentMethod.enabled) {
			supportedPaymentTypes.push("ach")
		}
		if (key === "applePay" && f(paymentMethod, 'gatewayData.applePay.merchantId')) {
			applePayMerchantId = paymentMethod.gatewayData.applePay.merchantId
			gatewayTypeCode = paymentMethod.gatewayData.gatewayTypeCode
			// console.log("applePayMerchantId: " + applePayMerchantId)
			// console.log("gatewayTypeCode: " + gatewayTypeCode)
        }
        if (tokenizationPlatform == 'worldpay') {
            supportedPaymentTypes.push('generic')
        }
    }
    
    useEffect(() => {
		checkVisibility()
    })
    
    let tableEnd
    let [scrollIndicator, setScrollIndicator ] = useState(false)
    let offset = window.innerHeight * (20/-100) + 30
    // let offset = window.innerHeight * (20/-100)

	const isInViewport = () => {
		if (!tableEnd) return false;
		const top = tableEnd.getBoundingClientRect().top;
		return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
	}

    const handleScroll = () => {
		checkVisibility()
	}
	const checkVisibility = () => {
		setScrollIndicator(isInViewport())
    }
    
	const styles = `
		.scrolling-grid {
			overflow-y: auto;
			overflow-x: hidden;
            height: 100%;
            width: 100%;
            // display:flex;
            // align-items: flex-start;
		}

		.scrolling-grid::-webkit-scrollbar {
			width: 0 !important;
			display: none;
			background: transparent;
		}

		.scrolling-grid td {
			 padding-left: 25px;
		}
	`

	const c = makeStyles((theme) => ({
		paymentTable: {
			marginRight: 0,
			marginLeft: 0,
			marginTop: 0,
			marginBottom: '7em',
			width: '100%',
			// height: '100%',
			overflowY: 'hidden',
			'::webkitScrollbar': {
				width: '0 !important',
				display: 'none',
			},
			'& td': {
				'paddingTop': 15,
				'paddingBottom': 15,
			}
		},

		overlayContainer: {
			position: "absolute",
			top: "0px",
			right: "0px",
			left: "0px",
			bottom: "0px",
			background: "#E5E5E599",
			zIndex: 1999,
		},
		clickHere: {
			textDecoration: 'underline',
			display: 'inline-block',
			paddingLeft:'.2em'
		},
		mobileWalletPaymentLoading: {
			position: "absolute",
			// top: "38%",
			// left: "50%",
			// right: "50%",
			zIndex: "2005",
			height: "140px",
			// borderRadius: "9px",
			left: "35%",
			// right: "50%",
			bottom:"50%",
			textAlign: "center",
			// padding: "16px",
			margin: "auto",
		},

		optionItem: {
			height: 64,
			padding: 0,
			width: '100%',
			'line-height': '50px',
			borderBottom: '1px solid #ccc',
		},
		//SORRY GOTTA GO FAST
		optionItemDisabled: {
			height: 64,
			padding: 0,
			width: '100%',
			'line-height': '50px',
			borderBottom: '1px solid #ccc',
		},

		paymentLabel: {
			fontSize: 10,
			paddingLeft: 10
		},
		privacyText: {
			verticalAlign: 'middle',
		},

		paymentLabelSecondary: {},

		lastFour: {
			display: 'block',
			textAlign: 'center',
			color: 'rgba(0, 0, 0, 0.6)',
			marginBottom: 8,
			paddingLeft: 8
		},

		paymentIconsStandard: {
			justifyContent: 'center',
			paddingRight: 10
		},

		paymentIcons: {
			paddingTop: 0,
			paddingRight: 5,
			paddingBottom: 0,
			marginBottom: 0,
		},
		gridItemCenterDialog: {
			textAlign: 'center',
        },
        scrollIndicator: {
			position: 'absolute',
			bottom: '2%',
			right: '46.5%',
			// right: isDesktopView ? '15%' : '46.5%',
			width: '7%',
		}
    }))();
    

	const [activePanel] = global.get('activePanel');

	const showPanel = (activePanel) => {
		if ('addCardPanel' == activePanel) {
			ReactGA.event({
				category: GA_CATEGORY_PAYMENT_ADD_CARD,
				action: "Add Card clicked"
			});
		}
		global.set({ activePanel: activePanel });
	};

	const isActivePanel = (activePanelIn) => {
		return activePanelIn === activePanel;
	};

	const googlePayAvailable = (myTotals) => {
		return showGooglePay && !zeroAmountBill(myTotals)
	} 

	const applePayAvailableF = (myTotals) => {
		return applePayAvailable && !zeroAmountBill(myTotals)
	} 

	const zeroAmountBill = (myTotals) => {
		return myTotals && myTotals.youPay === 0 && myTotals.loyaltyRewardAmount > 0
	}

	const closeAllPanels = () => {
		global.set({ activePanel: '' });
	};


	if (global.checkClient) {
		// REINIT ON MESSAGE TO ENSURE REFERENCE TO CHECK IS CURRENT
		global.checkClient.onmessage = (message) => {
			let updatedCheck = webSocketMessageHandler(JSON.parse(message.data), check);
			if(updatedCheck != null){
				updatedCheck = checkUtils.updateTotals(updatedCheck)
				global.set({ check: updatedCheck })
			}
		}
	}

	const googleCardTypeMap = {
		'visa': 'VISA',
		'master': 'MASTERCARD',
		'american_express': 'AMEX',
		'discover': 'DISCOVER',
	}

	//TODO - Make sure this list always matches the codes we get from spreedly and cardConnect
	let supportedCardTypesGooglePay = (supportedPaymentTypes && supportedPaymentTypes.length > 0) ? supportedPaymentTypes.map((type) => googleCardTypeMap[type]).filter(v => v) : Object.values(googleCardTypeMap)
	const initializeGooglePay = () => {
		const baseCardPaymentMethod = {
			type: 'CARD',
			parameters: {
				allowedCardNetworks: supportedCardTypesGooglePay,
				allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
				billingAddressRequired: true,
				billingAddressParameters: { 
					format: 'MIN' /* MIN | FULL */, 
					phoneNumberRequired: false 
				}
			}
		};

		const googlePayBaseConfiguration = {
			apiVersion: 2,
			apiVersionMinor: 0,
			allowedPaymentMethods: [baseCardPaymentMethod]
		};

		window.googlePayBaseConfiguration = googlePayBaseConfiguration;

		window.googlePayClient
			.isReadyToPay(googlePayBaseConfiguration)
			.then((res) => {
				if (res.result) {
					setShowGooglePay(true);
				}
			})
			.catch((err) => {
				log.info('Error with Google Pay. ' + err);
			});
	};

	const mobilePay = (paymentType) => {
        setInProcessOfPayingWithMobileWallet(true)
		if (paymentType === 'googlePay') { payWithGooglePay() }
        if (paymentType === 'applePay') { showApplePayWindow() }
	}

	const payWithGooglePay = () => {
		// console.log('check.restaurant.paymentMethods')
		// console.log(JSON.stringify(check.restaurant.paymentMethods))
		let gmid = check.getVal('restaurant.paymentMethods.googlePay.gatewayData.googlePay.gatewayMerchantId', '')
		// log.info("check.restaurant.paymentMethods['googlePay'].gatewayData.googlePay.gateway: " +check.restaurant.paymentMethods['googlePay'].gatewayData.googlePay.gateway)
		const paymentDataRequest = Object.assign({}, window.googlePayBaseConfiguration, {
			allowedPaymentMethods: [{
				type: 'CARD',
				tokenizationSpecification: {
					type: 'PAYMENT_GATEWAY',
					parameters: {
						gateway: check.restaurant.paymentMethods['googlePay'].gatewayData.googlePay.gateway, // cardconnect || spreedly
						gatewayMerchantId: gmid
					}
				},
				parameters: {
					allowedCardNetworks: supportedCardTypesGooglePay,
					allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
					billingAddressRequired: true,
					billingAddressParameters: { 
						format: 'FULL' /* MIN | FULL */, 
						phoneNumberRequired: false 
					}
				}
			}],
			transactionInfo: {
				totalPriceStatus: 'FINAL',
				totalPrice: checkUtils.formatMoney(check.myTotals.youPay),
				currencyCode: currency
			},
			merchantInfo: {
				merchantId: '16148093869846558501',
				merchantName: 'DashNow'
			}
		});

		// console.log('paymentDataRequest:')
		// console.log(JSON.stringify(paymentDataRequest))

		window.googlePayClient
			.loadPaymentData(paymentDataRequest)
			.then(function(paymentData) {
				// log.info('GOOGLE PAY PAYMENTDATA');
				// log.info(paymentData);
				let billingInfo = {}
				if (paymentData.paymentMethodData.info.billingAddress) {
					billingInfo = {
						addressLine: paymentData.paymentMethodData.info.billingAddress.address1,
						zipCode: paymentData.paymentMethodData.info.billingAddress.postalCode,
						countryCode: paymentData.paymentMethodData.info.billingAddress.countryCode
					}
				}
				// paymentMethodData {
				// 	description: "Visa •••• 1111"
				// 	info {
				// 		billingAddress: {countryCode: "US", postalCode: "55555", name: "JOHNATHAN DOEBOY"}
				// 		cardDetails: "1111"
				// 		cardNetwork: "VISA"
				// 	}
				// 	Object Prototype
				// 	tokenizationData: {type: "PAYMENT_GATEWAY", token: "{\"signature\":\"bigtoken"}\"}"}
				// 	type: "CARD"
				// }
				const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
				// log.info(paymentToken);
				submitPayment("googlePay", paymentToken, billingInfo);
			})
			.catch(function(err) {
				setInProcessOfPayingWithMobileWallet(false)
                // Log error: { statusCode: CANCELED || DEVELOPER_ERROR }
			});
	};

	const payWithCreditCard = async (card) => {
		if (card && card._id && (["ach", "creditCard"].indexOf(card.paymentType) > -1)) {
			await submitPayment(card.paymentType, card._id);
		} else if (loyaltyProgram && loyaltyProgram._id){
			await submitPayment("loyaltyReward", loyaltyProgram._id);
        } else if (card && card._id) {
			await submitPayment("creditCard", card._id);
		}
    };
    
    useEffect(()=> {
        if (showGooglePayDesktop) {
            payWithGooglePay()
            global.set({showGooglePayDesktop:false})
        }
    },[showGooglePayDesktop])

	const submitPayment = async (paymentMethod, paymentToken, billingInfo = {}) => {
		// log.info("submitPayment")
		const paymentResp = await makePayment(check._id, paymentMethod, paymentToken, check.myTotals, check.itemsBeingPaid, loyaltyAccount, billingInfo, card, true);
		// log.info("paymentResp: " + JSON.stringify(paymentResp))
		let loyaltyAccount2 = loyaltyAccount
        let newRewards
        let accountRestaurant
		if (paymentResp.loyaltyAccount) {
			loyaltyAccount2 = paymentResp.loyaltyAccount
			newRewards = paymentResp.newRewards
		}

		if (paymentResp.success == true) {

			if (global.checkClient && !props.isDesktopView) {
				global.checkClient.onclose = null // ensure the ws doesn't try to reconnect
				global.checkClient.close()
				global.checkClient = null
			}
            global.pageStack.push({ pageName: THIS_PAGE, fn: null });
            
            
            //Conditionally show Email page only if user has not opted in
            let nextPage;
			if ((paymentResp.accountRestaurant && (!paymentResp.accountRestaurant.optInAt || !paymentResp.accountRestaurant.optIn)) || !localStorage.alwaysReceiveEmailedReceipt) {
				nextPage = "EmailReceiptPage"
            } else {
                nextPage = "PaymentCompletePage" 
            }

            accountRestaurant = paymentResp.accountRestaurant

			// console.log("paymentResp.cardDisplay: " + paymentResp.cardDisplay)
			ReactGA.event({
				category: GA_CATEGORY_PAYMENT_COMPLETE,
				action: paymentMethod + " success"
            });

			global.set({ allowBack: false, check: check, activePage: nextPage, previousPaymentString: paymentResp.cardDisplay, loyaltyAccount: loyaltyAccount2, newRewards, paymentAmount: paymentResp.paymentAmount, accountRestaurant});
		} else {
			global.set({ activePanel: "" });
			ReactGA.event({
				category: GA_CATEGORY_PAYMENT_COMPLETE,
				action: paymentMethod + " failure"
			});
			if (paymentResp.errors && paymentResp.errors.length > 0) {
				setServerErrorMessage('Error: ' + paymentResp.errors[0] + '.') 
			}
			if (paymentResp.deleteCard) {
				try {
					let localSavedCards = global.parseJSONArrSafe(localStorage.savedCards) ? global.parseJSONArrSafe(localStorage.savedCards) : []
					localSavedCards = localSavedCards.filter(i => i._id != paymentToken)
					localStorage.setItem('savedCards', JSON.stringify(localSavedCards))
				} catch (e) {
					log.error('failed to delete card')
				}
			}
			setShowPaymentFailureDialog(true);
		}
		setInProcessOfPayingWithMobileWallet(false);
	};


	const paymentTypeSupported = (type) => {
		return trainingMode && type != 'applePay' && type != 'googlePay' ? true : (supportedPaymentTypes.indexOf(type) >= 0)
	}

	const getActiveClass = (type) => {
		if (paymentTypeSupported(type)) {
			return c.optionItem
		} else {
			return c.optionItemDisabled
		}
	}

	useEffect(() => {
		
		if (selectedPaymentMethod) {
			check = checkUtils.applyServiceCharge(check, selectedPaymentMethod ? selectedPaymentMethod.paymentType : '')
			global.set({check})
		}
	}, [selectedPaymentMethod])

	const cardTypeNotSupported = () => {
        global.set({selectedPaymentMethod: null})
		if (trainingMode) {
			NotificationManager.warning("Payment type disbled in demo, choose the 'Visa (Demo)' or 'ACH (Demo)' to continue")
		} else {
            NotificationManager.warning("This payment type is not supported by this location. Please choose another payment type or add a new card.")
		}
	}

	const showPaymentPanel = (card) => {
		setCard(card)
        global.set({selectedPaymentMethod: card})
        global.set({ activePanel: 'confirmPaymentPanel' });
    }

	const appleCardTypeMap = {
		'visa': 'visa',
		'master': 'masterCard',
		'american_express': 'amex',
		'discover': 'discover',
	}

	let supportedCardTypesApplePay = (supportedPaymentTypes && supportedPaymentTypes.length > 0) ? supportedPaymentTypes.map((type) => appleCardTypeMap[type]).filter(v => v) : Object.values(appleCardTypeMap)
	const showApplePayWindow = () => { 
		const request = {
			applicationData: btoa({ checkId: check._id, orderNumber: check._id, supportphone: "8008467730", supportemail: "support@dashnow.com", supportwebsite: "www.dashnow.com" }),
			supportedNetworks: supportedCardTypesApplePay,
			merchantCapabilities: ['supports3DS'],
			requiredBillingContactFields: [ "postalAddress" ], 
			countryCode: 'US', // where the payment is processed. See https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916117-countrycode
			currencyCode: currency,
			total: {
				label: restaurantName,
				type: 'final',
				amount: check.myTotals.youPay / 100
			}
		}

		let appleSession = new window.ApplePaySession(3, request);

		appleSession.onvalidatemerchant = function(event) {
			try {
				const url = global.dashServerUrl + "/api/v1/payment/newApplePayMerchantSession"
				
				const body = {
					merchantId: applePayMerchantId,
					restaurantName: restaurantName,
					gatewayTypeCode: gatewayTypeCode,
					validationURL: event.validationURL,
				}

				let options = {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						'x-access-token': localStorage.token,
					},
					body: JSON.stringify(body),
				}

				fetch(url, options).then(function(response) { return response.json() }).then(function(json) {
					if (json.success) {
						appleSession.completeMerchantValidation(json.data)
					} else {
						NotificationManager.warning('', JSON.stringify(json.errors), 3000);
						setInProcessOfPayingWithMobileWallet(false)
					}
				}).catch((err) => {
					console.error(err)
					setInProcessOfPayingWithMobileWallet(false)
				})
				return false

			} catch (err) {
				console.error(err)
				setInProcessOfPayingWithMobileWallet(false)
			}
		}

		appleSession.onpaymentauthorized = function(event) {
			
			let billingInfo = { }
			// sample billingInfo object:
			// addressLines: ["1111 W 10th Terr"]
			// administrativeArea: "KS"
			// country: "United States"
			// countryCode: "us"
			// familyName: "Bobby"
			// givenName: "Billy"
			// locality: "Overland Park"
			// phoneticFamilyName: ""
			// phoneticGivenName: ""
			// postalCode: "55555"
			// subAdministrativeArea: ""
			// subLocality: ""
			if (event.payment.billingContact){
				billingInfo = {
					addressLine: event.payment.billingContact.addressLines[0],
					zipCode: event.payment.billingContact.postalCode,
					country: event.payment.billingContact.country,
					countryCode: event.payment.billingContact.countryCode,
				}
			}
			submitPayment("applePay", event.payment.token, billingInfo);
			appleSession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
		}

		appleSession.oncancel = function(event) {
			setInProcessOfPayingWithMobileWallet(false)
		}

		appleSession.begin()
	}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};
	
	const handelAddCard = () => {
		trainingMode ? cardTypeNotSupported() : showPanel('addCardPanel')
	};
	
	const handelAddACH = () => {
		trainingMode ? cardTypeNotSupported() : showPanel('addACHPanel')
	};
	
	useEffect(() => {
		closeAllPanels();
		initializeGooglePay();
		if (!achEnabled) {
			check = checkUtils.applyServiceCharge(check)
			global.set({check})
		}
	}, []);

	let ccServiceChargeText = ''
	let achServiceChargeText = ''
	if (ccServiceChargeAmount == achServiceChargeAmount && ccServiceChargePercent == achServiceChargePercent) {
		if (ccServiceChargePercent || ccServiceChargeAmount) {
			ccServiceChargeText = 'This business has added a  '
			
			if (ccServiceChargePercent) {
				ccServiceChargeText += `${(ccServiceChargePercent*100).toFixed(1)}% `
				if (ccServiceChargeAmount) ccServiceChargeText += ` + a `
			}
			if (ccServiceChargeAmount) ccServiceChargeText += `$${checkUtils.formatMoney(ccServiceChargeAmount)}`
			ccServiceChargeText += ` ${ccServiceChargeLabel} to all transactions.`
		}
	} else {
		if (ccServiceChargePercent || ccServiceChargeAmount) {
			ccServiceChargeText = 'This business has added a  '
			
			if (ccServiceChargePercent) {
				ccServiceChargeText += `${(ccServiceChargePercent*100).toFixed(1)}% `
				if (ccServiceChargeAmount) ccServiceChargeText += ` + a `
			}
			if (ccServiceChargeAmount) ccServiceChargeText += `$${checkUtils.formatMoney(ccServiceChargeAmount)}`
			ccServiceChargeText += ` ${ccServiceChargeLabel} to all card transactions.`
		}
		if (achServiceChargePercent || achServiceChargeAmount) {
			achServiceChargeText = 'This business has added a  '
			
			if (achServiceChargePercent) {
				achServiceChargeText += `${(achServiceChargePercent*100).toFixed(1)}% `
				if (achServiceChargeAmount) achServiceChargeText += ` + a `
			}
			if (achServiceChargeAmount) achServiceChargeText += `$${checkUtils.formatMoney(achServiceChargeAmount)}`
			achServiceChargeText += ` ${achServiceChargeLabel} to all ACH transactions.`
		}	
	}

	return (
		<Slide in direction="left">
			<div style={{display: 'block', position: 'relative', height: '100%'}}>
			<div style={{width: '100%', display:'flex', flexDirection:'column', justifyContent:'space-between', paddingTop: props.isDesktopView ? 16 : 0}} className={props.isDesktopView && 'scrolling-grid'}>
				<DStyles styles={styles} />
				<Grid container style={{position: 'relative', top: 0, right: 0, bottom: 0, left: 0}} onScroll={handleScroll} justify="space-between" className={!props.isDesktopView && 'scrolling-grid'}>
					<Grid item xs={12} align="center">
						<Typography variant="subtitle1" style={{ color: '#757575', alignSelf: 'center' }}>
							{!global.textPay ? "You Pay" : "Total"}
						</Typography>
					</Grid>
					<Grid container item xs={12} align="center">
						<Grid item xs={12} container justify="center" style={{ marginTop: 2 }}>
							<Typography color="primary" variant="subtitle1" style={{ marginRight: 4 }}>
								$
							</Typography>
							<Typography color="primary" variant="h3">
								{checkUtils.formatMoney(check.myTotals.youPay)}
							</Typography>
						</Grid>
						{global.f(check, 'description', "") && skipReceiptPage && // dont show them description twice
							<Grid item xs={12} container direction="column" justify="flex-start">
								<Typography variant="h7" style={{ color: '#757575', alignSelf: 'center'}}>
									{global.f(check, 'description', "")}
								</Typography>
							</Grid>
						}
						{(ccServiceChargeText || achServiceChargeText) &&
							<Grid item xs={12} container direction="column" justify="flex-start" style={{marginTop:5, padding: 5, borderTop:'1px solid #ccc', borderBottom:'1px solid #ccc', backgroundColor:'rgb(227 227 227 / 45%)'}}>
								<Typography variant="h7" style={{ color: '#2b2b2b', alignSelf: 'center', fontSize:12}}>
									{ccServiceChargeText} {achServiceChargeText}
								</Typography>
							</Grid>
						}
						{paymentTerms &&
							<Grid item xs={12} container direction="column" justify="flex-start" style={{marginTop:5, padding: 5, borderTop:'1px solid #ccc', borderBottom:'1px solid #ccc', backgroundColor:'rgb(227 227 227 / 45%)'}}>
								<Typography variant="h7" style={{ color: '#2b2b2b', alignSelf: 'center', fontSize:12}}>
									{paymentTerms}
								</Typography>
							</Grid>
						}
						
					</Grid>
					{check.myTotals.loyaltyRewardAmount > 0 && <Grid item xs={12} align="center">
						<Typography variant="overline">
							with ${checkUtils.formatMoney(check.myTotals.loyaltyRewardAmount)} reward applied
						</Typography>
					</Grid>}

					{check.myTotals.dashServiceFeeAmount > 0 && <Grid item xs={12} align="center">
						<Typography variant="overline">
							{check.myTotals.loyaltyRewardAmount > 0 ? "and " : "with "}
							${checkUtils.formatMoney(check.myTotals.dashServiceFeeAmount)} fee applied
						</Typography>
					</Grid>}

					{(<Grid item xs={12} align="center" style={{paddingTop: '.7em'}}>
						<Checkbox color="primary" checked={agreeance} value="checkedA" style={{marginRight: 4}} onClick={() => {setAgreeance(!agreeance)}}/>
						<Typography className={c.privacyText} component={'span'}>Agree to our
							<Typography className={c.clickHere} onClick={() => {
									setShowPrivacy(true);
									ReactGA.event({
										category: GA_PRIVACY_POLICY,
										action: "Show"
									});
								}}>
								Terms of Use
							</Typography>
						</Typography>
					</Grid>)}

					<Dialog
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						open={showPrivacy}
						style={{height: '100%', width:'100%'}}
						onClose={() => {setShowPrivacy(false)}}
						PaperProps={{style:{margin:15}}}
					>
						<DialogTitle style={{padding:"0px 0px"}}>
							<Tabs
								onChange={handleTabChange}
								value={tabValue}
								textColor="primary"
								indicatorColor="primary"
								className={"tabs"}
							>
								<Tab label="Terms Of Use" value="termsOfUse" style={{ width: '50%', fontSize:12 }}/>
								<Tab label="Privacy Policy" value="privacyPolicy" style={{ width: "50%", fontSize:12}}  />
							</Tabs>
						</DialogTitle>
						<DialogContent style={{ height: '93%', overflowY: 'auto',}}>
							{tabValue == 'termsOfUse' &&
								<TermsOfUse/>
							}
							{tabValue == 'privacyPolicy' &&
								<PrivacyPolicy/>
							}
						</DialogContent>
						<DialogActions style={{ marginTop: 0, height: '10%'}}>
							<Button onClick={() => {
									setAgreeance(false); 
									setShowPrivacy(false);
									ReactGA.event({
										category: GA_PRIVACY_POLICY,
										action: "Disagree"
									});
								}} color="primary">
								Disagree
							</Button>
							<Button onClick={() => {
									setAgreeance(true);  
									setShowPrivacy(false);
									ReactGA.event({
										category: GA_PRIVACY_POLICY,
										action: "Agree"
									});
								}} color="primary">
								Agree
							</Button>
						</DialogActions>
					</Dialog>

					{agreeance && <>
						<Grid item xs={12} style={{ marginTop: 5 }} align="center">
							<Typography variant="overline" style={{ fontSize: 15, color: '#757575', lineHeight: 2 }}>
								{f(restaurant, 'config.mobile.paymentMethodString', "Select a payment method")}
							</Typography>
							{(global.tryMe || trainingMode) &&
								<Typography variant="overline" style={{ fontSize: 11, color: 'var(--purple)', lineHeight: 2 }}>
									<br/>
									* Demo mode, you will not be charged *
								</Typography>
							}
						</Grid>

						{inProcessOfPayingWithMobileWallet && 
							<div className={c.overlayContainer}>
								<img src={DashLoad} className={c.mobileWalletPaymentLoading} alt="Waiting for payment..." />
							</div>}

						<div style={{width:'100%', paddingBottom: (props.isDesktopView && !check.paidInFull && selectedPaymentMethod) ? 173 : 0 /* to clear tips table */ }}>
								{/* Apple Pay session can only be created inside a user gesture handler which only fits into mobile process */}
								{(applePayAvailableF(check.myTotals) && ((paymentTypeSupported('applePay')) || trainingMode)) && (
                                    <ListItem 
                                        leftCol={<img src={ApplePayIcon} width={72} height={48} alt="Apple Pay" />}
                                        centerCol={<Typography variant="subtitle2">Apple Pay {(global.tryMe || trainingMode) && " (Demo)"}</Typography>}
                                        rightCol={''}
                                        onClick={() => {
                                            ReactGA.event({
                                                category: GA_CATEGORY_PAYMENT_CLICKED,
                                                action: "Apple Pay"
                                            });
                                            global.set({selectedPaymentMethod:'applePay'})
                                            if (paymentTypeSupported('applePay')) {
                                                mobilePay('applePay')
                                            } else {
                                                cardTypeNotSupported()
                                            }
                                        }}>
                                    </ListItem>
                                )}

                                {(googlePayAvailable(check.myTotals) && (paymentTypeSupported('googlePay') || trainingMode)) && (
                                    <ListItem 
                                        isSelected={selectedPaymentMethod == 'googlePay'}
                                        isNotSelected={selectedPaymentMethod && selectedPaymentMethod !== 'googlePay'}
                                        leftCol={<CardGoogle />}
                                        centerCol={<Typography variant="subtitle2">Google Pay {(global.tryMe || trainingMode) && " (Demo)"}</Typography>}
                                        rightCol={''}
                                        onClick={() => { 
                                            ReactGA.event({
                                                category: GA_CATEGORY_PAYMENT_CLICKED,
                                                action: "Google Pay"
                                            });
                                            global.set({selectedPaymentMethod:'googlePay'}) // Desktop view uses Global state to know which payment method upon clicking tip amount/pay
                                            if (paymentTypeSupported('googlePay') && !props.isDesktopView) {
                                                mobilePay('googlePay') // mobile view initiates Google Pay immediately
                                            } else if (!paymentTypeSupported('googlePay')){
                                                cardTypeNotSupported()
                                            }
                                        }}>
                                    </ListItem>
                                )}

                                {/* RENDER EXISTING CARDS */}
                                {cards.map((cardItem, index) => {
                                    let typeStr = cardItem.type.replace('american_express', 'amex');
                                    let formattedType = typeStr
                                        .replace(/\w+/g, (str) => {
                                            return str.charAt(0).toUpperCase() + str.substr(1);
                                        })
                                        .replace(/[-]/g, ' ');
                                    if (cardItem.trainingMode){
                                        formattedType = cardItem.displayName
                                    }
                                    if (cardItem.type == 'generic') {
                                        formattedType = ''
                                    }

                                    if (typeStr !== 'apple-pay' && typeStr !== 'google-pay' && !cardItem.oneTimeUse) {
                                        return (
                                            <>
                                                <ListItem 
                                                    isSelected={selectedPaymentMethod && selectedPaymentMethod._id && selectedPaymentMethod._id == cardItem._id}
                                                    isNotSelected={( selectedPaymentMethod && selectedPaymentMethod._id && selectedPaymentMethod._id !== cardItem._id) || selectedPaymentMethod == 'applePay' || selectedPaymentMethod == 'googlePay'}
                                                    leftCol={cardIcons[cardItem.type]}
                                                    centerCol={<Typography variant="subtitle2">{formattedType}</Typography>}
                                                    rightCol={
                                                        <>
                                                            <div className="lastFour">{' **** ' + cardItem.lastFour}</div>
                                                            {index + 1 === cards.length &&
                                                                <div ref={(el) => { tableEnd = el; }}></div>
                                                            }
                                                        </>
                                                    }
                                                    onClick={(e) => {
                                                        ReactGA.event({
                                                            category: GA_CATEGORY_PAYMENT_CLICKED,
                                                            action: "Credit Card"
                                                        });
                                                        // console.log('carditem', cardItem)
                                                        paymentTypeSupported(cardItem.type) ? props.isDesktopView ? global.set({selectedPaymentMethod:cardItem}) : showPaymentPanel(cardItem) : cardTypeNotSupported();
                                                    }}>
                                                </ListItem>
                                            </>
                                        );
                                    } else {
                                        return ""
                                    }
                                })}
                                {!global.tryMe && (creditCardEnabled || trainingMode) &&
                                    <ListItem 
                                        leftCol={<AddCreditCard/>}
                                        isNotSelected={selectedPaymentMethod}
                                        centerCol={<Typography variant="subtitle2">Add Credit or Debit Card</Typography>}
                                        onClick={() => handelAddCard()}>
                                    </ListItem>
                                }
								{!global.tryMe &&  (['heartland', 'cardConnect', 'vgs'].indexOf(achtokenizationPlatform) > -1) && (achEnabled || trainingMode) &&
									<ListItem 
										leftCol={<AddACH style={{ paddingLeft:25, height:50, width:50, display:'flex', justifySelf:'center'}}/>}
										leftColStyle={{justifyContent: 'center'}}
										isNotSelected={selectedPaymentMethod}
										centerCol={<Typography variant="subtitle2">Add an ACH Account</Typography>}
										onClick={() => handelAddACH()}>
									</ListItem>
								}

						</div>
					</>}
				</Grid>
                {props.isDesktopView && !check.paidInFull &&
                    <div style={{position: 'absolute', bottom: 0, right: 0, backgroundColor: 'white', width:'100%'}}>
						{selectedPaymentMethod &&
	                        <SelectTipTable isDesktopView/>
						}
                    </div>
                }
                {!scrollIndicator && cards && cards.length > 1 && !props.isDesktopView &&
                    <ExpandMoreIcon className={c.scrollIndicator} />
                }
				{isActivePanel('addCardPanel') && ( 
					<AddCardPanel closeAllPanels={closeAllPanels} showPanel={showPanel} restaurant={check.restaurant} isActivePanel={isActivePanel} next={showPaymentPanel} isDesktopView={props.isDesktopView}/>
				)}
				{isActivePanel('addACHPanel') && ( 
					<AddACHPanel closeAllPanels={closeAllPanels} showPanel={showPanel} restaurant={check.restaurant} isActivePanel={isActivePanel} next={showPaymentPanel} isDesktopView={props.isDesktopView}/>
				)}
				{isActivePanel('confirmPaymentPanel') && card && (card._id|| card.type == 'ach') && (
                    <>
					    <ConfirmPaymentPanel closeAllPanels={closeAllPanels} showPanel={showPanel} isActivePanel={isActivePanel} onPay={() => payWithCreditCard(card)} card={card} isDesktopView={props.isDesktopView}/>
                    </>
				)}
				<Dialog 
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={showPaymentFailureDialog}
					style={{height: '100%'}}
				>	
					<div style={{ height: '90%', overflowY: 'auto', margin:'20px 20px 0px 20px'}} >
						<Grid container>
							<Grid item xs={12} className={c.gridItemCenterDialog}>
								<Typography variant="h6" style={{ fontSize: '1.5em', color: '#424242'}} >
									Payment Failed
								</Typography>
							</Grid>
							<Grid item xs={12} style={{textAlign:"center", padding: '8px', paddingTop: '12px'}}>
								<ErrorOutlineIcon color="primary" style={{fontSize: 50, color: '#C33136'}} />
							</Grid>
							<Grid item xs={12} className={c.gridItemCenterDialog}>
								<Typography variant="b1" style={{ fontSize: '1em', color: '#424242'}} >
									The server was unable to process your transaction. {serverErrorMessage} Please ensure your card is valid and try again.
								</Typography>
							</Grid>
						</Grid>
					</div>
					<DialogActions style={{ marginTop: 0, height: '10%', justifyContent: 'center'}}>
						<Button 
							onClick={() => {
								setShowPaymentFailureDialog(false)
							}} 
							color="primary"
							style={{
								color: 'var(--light-gray)'
							}}
						>
							Try again
						</Button>
					</DialogActions>
				</Dialog>
				{loyaltyProgram && loyaltyAccount && <LoyaltyApplyRewards />}
			</div>
			</div>
		</Slide>
	);
};