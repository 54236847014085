import React, { useEffect, useState } from 'react';
import { useGlobal, setGlobal } from 'reactn';
import { ThemeProvider } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from './pages/Header';
import LoyaltyHeader from './pages/loyalty/LoyaltyHeader.js';
import Footer from './pages/Footer';
import LoadingPage from './pages/LoadingPage';
import CreateAccountPage from './pages/CreateAccountPage';
import initAccount from './actions/initAccount';
import ScanQrCodePage from './pages/ScanQrCodePage';
import AccountManagementPage from './pages/AccountManagementPage';
import CheckStackPage from './pages/pay/CheckStackPage';
import GeoLookupPage from './pages/GeoLookupPage'; 
import ReceiptPage from './pages/pay/ReceiptPage';
import MyBillPage from './pages/pay/MyBillPage';
import PaymentPage from './pages/pay/PaymentPage';
import PaymentCompletePage from './pages/pay/PaymentCompletePage';
import EmailReceiptPage from './pages/pay/EmailReceiptPage';
import PaymentHistoryPage from './pages/PaymentHistoryPage';
import FeedbackPage from './pages/pay/FeedbackPage';
import ImHerePage from './pages/pay/ImHerePage';
import Invoice from './pages/Invoice';

import RestaurantInfoPage from './pages/restaurantInfo/RestaurantInfoPage';
import LoyaltyWrapper from './pages/loyalty/LoyaltyWrapper';
import DesktopViewFrame from './components/DesktopViewFrame';

import { urlParse } from './util/urlParser'
import theme from './theme';
import dash from './dash';
import logTicketScan from './actions/logTicketScan'
import logSmsScan from './actions/logSmsScan'
import logRestaurantScan from './actions/logRestaurantScan'
import logCheckScan from './actions/logCheckScan';
import ReactGA from 'react-ga';
import DStyles from './components/DStyles'
import openCheckWs from './util/openCheckWs';
import openTableWs from './util/openTableWs';
const uuidv4 = require('uuid/v4');

dash(); //intitializes all the goods

global.get = useGlobal;
global.set = setGlobal;
global.pageStack = [];

export default function App(props) {
	let [activePage] = global.get('activePage');
	let [header] = global.get('header');
	let [footer] = global.get('footer');
	let [isNative] = global.get('isNative');
	let [topBottomPadding] = global.get('topBottomPadding');
	let [loyaltyHeader] = global.get('loyaltyHeader');
	let [restaurant] = global.get('restaurant');
	let [check] = global.get('check');
	let [checkId] = global.get('checkId');
	let [personIdentifiers] = global.get('personIdentifiers');
	let [table] = global.get('table');
	let [geo] = global.get('geo');
	let [restaurantId] = global.get('restaurantId');
	let [backOffice] = global.get("backOffice")
	let [rightAlignHeaderLogo] = global.get('rightAlignHeaderLogo');
	let [textToPayEnabled] = global.get('textToPayEnabled')
	let [payAtTableEnabled] = global.get('payAtTableEnabled')
    let [windowHeight] = global.get('windowHeight');
    const [ hasPostedWorldpay, setHasPostedWorldpay ] = useState(false)
    const [isDesktopView] = global.get('isDesktopView')
    // const isDV = useMediaQuery('(min-width:600px)') // Stopped using this since it initially returns false.
    
	const mobileMax = "(min-width: 750px)"
	

    useEffect(()=> {
		if (isDesktopView == undefined) {
        	global.set({isDesktopView: window.matchMedia(mobileMax).matches})
		}
    },[isDesktopView])
	
	window.onresize = () => { // reloads app if we switch from mobile > desktop or vice versa
		let isDV = window.matchMedia(mobileMax).matches
		if (isDV != isDesktopView) {
			document.location.reload()
		}
	};


	useEffect(async () => {
        windowHeight = window.innerHeight;
        if (!isDesktopView) {
            document.body.style.setProperty("height", window.innerHeight + "px", "important");
            document.getElementsByTagName("html")[0].style.setProperty("height", window.innerHeight + "px", "important");
            document.getElementById("root").style.setProperty("height", window.innerHeight + "px", "important");
            // document.getElementById("main-page").style.setProperty("height", window.innerHeight + "px", "important");
        }
		let tryMe = false
		let url = urlParse(window.location.href)
		let pathname = url.pathname
		let params = url.params
		let numOfParams = Object.keys(params).length
		let fromDashboard = (params.fromDashboard == '1')
		if (fromDashboard) localStorage.clear() //KEEP THIS FOREVER

		if (!pathname && numOfParams == 0 && window.location.href.indexOf('textpay.app') > -1) {
			document.location.href = 'https://www.textpay.app' // sweet hack for routing to our website
		}
		let hasToken = !!localStorage.getItem("token")
		let positouchData = window.location.href.split("pd=")
		positouchData = positouchData && positouchData.length > 1 ? positouchData = positouchData[1] : null
		let debug = false

		if (!hasToken || !localStorage.deviceId) {
			const init = async function() {
				let account = await initAccount(debug)
				ReactGA.event({
					category: "Init Account",
					action: "Init Account"
				});
			}
			await init();
		}
		
		let signedIn = (!!localStorage.accountId)
		
		isNative = (params.native == "1");
		// if (isNative) {
			topBottomPadding = "0" // "35"
		// }

		
		let ticketId = params.t;
		if (!ticketId) {
			ticketId = params.ticketId
		}
		let restaurantId = params.r
		if (!restaurantId) {
			restaurantId = params.restaurantId
		}
		let table = params.tb
		if (!table) {
			table = params.table
		}
		let checkNumber = params.cn
		let checkId = params.c
		// 5d7e908dd9a5a7cd757f9bfa
		// restaurantId = "5d7e908dd9a5a7cd757f9bfa" /////////// FOR DEV TESTING REST INFO
		let shortUrl
		let showCustomSplashIcon
		let restaurantCode
		let restaurantLogoUrl
		if (params.z) {
			let zSplit = params.z.split("-")
			showCustomSplashIcon = zSplit.length > 0 ? (zSplit[0] == "1" || zSplit[0] == "2") : false
			restaurantCode = zSplit.length > 1 ? zSplit[1] : ''
        }
        
        // WORLDPAY IFRAME PARAMS LISTENER
        if (params.ExpressResponseCode && !hasPostedWorldpay) {
            var event = new CustomEvent('myCustomEvent', { detail: params })
            window.parent.document.dispatchEvent(event)
            setHasPostedWorldpay(true)
        }

		if (params.rc) {
			restaurantCode = params.rc
		}

		if (positouchData) {
			// See "Guest Check Trailers and Special Print Codes.pdf" for more information
			/*
				example: 	7006&08282012&0830&0827&2&593&
				0.STORE NUMBER: 	7006
				1.DATE:				08282012
				2.PRINT TIME: 		0830
				3.CHECK OPEN TIME: 	0827
				4.CHECK NUMBER: 	2
				5.CHECK TOTAL: 		593 (or $5.93. Always 2 decimal places)
			*/
			checkNumber = positouchData.split("&")[1] + "-" + positouchData.split("&")[4] // MAKE SURE THIS MATCHES IN ParserPOSitouch 
			let rcSplit = window.location.href.split("rc=")
			let zSplit = window.location.href.split("z=") // ['https://dnow.app', '2-xxx&pd=1111&22222&333...']
			if (rcSplit && rcSplit.length > 1) {
				restaurantCode = restaurantCode && restaurantCode.length > 1 ? restaurantCode[1].split('&') : null // urlParse fails when there are '&'s without an '=' 
				restaurantCode = restaurantCode && restaurantCode.length > 1 ? restaurantCode[0] : null
			} else if (zSplit && zSplit.length > 1) {
				zSplit = zSplit[1].split("&pd=") // ['2-xxx', 'pd=1111&2222&3333...']
				zSplit = zSplit && zSplit.length > 1 ? zSplit[0].split('-') : null // ['2', 'xxx'] OR null
				restaurantCode = zSplit.length > 1 ? zSplit[1] : ''
			}
		}

		if(pathname){
			let pathSplit = pathname.split("-")
			if(pathSplit.length == 3){
				shortUrl = pathSplit[0]
				showCustomSplashIcon =  pathSplit[1] == "1" || pathSplit[1] == "2"
				restaurantCode = pathSplit[2]
			} else {
				shortUrl = pathname
			}
		}
		if(!shortUrl){
			shortUrl = params.s
		}
		if (restaurantCode && showCustomSplashIcon) {
			restaurantLogoUrl = `${global.s3Url}/restaurants/${restaurantCode}/`
		}

		if (shortUrl) {
			logSmsScan(shortUrl)
		}
		if (ticketId) {
			logTicketScan(ticketId)
		}
		if (restaurantId && table) {
			logRestaurantScan(restaurantId, table)
		}
		if(restaurantCode && checkNumber){
			logCheckScan(checkNumber, restaurantCode)
		} else if (checkNumber) {
			logCheckScan(checkNumber)
		}
		if (window.location.href.indexOf(global.tryMeUrl) > -1) {
			tryMe = true
		}
		global.tryMe = tryMe
		let header = false;
		let isBypass = false
		activePage = 'LoadingPage';
		if (!ticketId && !restaurantId && !shortUrl && !checkNumber && !restaurantCode) {
			activePage = 'ScanQrCodePage';
			footer = true
        }

		if (window.location.href.toUpperCase().indexOf('ACCOUNTMANAGEMENT') > -1 || window.location.href.toUpperCase().indexOf('ACCOUNT') > -1) {
			activePage = "AccountManagementPage";
			footer = true
		}

		if (footer == undefined) {
			footer = false
		}

		if (params && params.geo) {
			if (params.geo == "0") {
				console.log("geo 0")
				table = '3274'
				activePage = 'LoadingPage'
			} else {
				console.log("geo 1")
				activePage = 'GeoLookupPage'
			}
			geo = true
			header = false
			footer = false
		}
		
		global.set({
			geo: geo,
			isBypass: isBypass,
			isNative: isNative,
			tryMe: tryMe,
			restaurantId: restaurantId,
			activePanel: 'paymentPanel',
			header: header,
			ticketId: ticketId,
			checkId: checkId,
			fromDashboard,
			checkNumber: checkNumber,
			shortUrl: shortUrl,
			showCustomSplashIcon,
			restaurantCode,
			restaurantLogoUrl,
			table: table,
			footer: footer,
			signedIn,
			topBottomPadding: topBottomPadding,
			windowHeight: windowHeight,
			activePage: activePage, // LEAVE THIS AT THE BOTTOM
		});
	}, []);

	useEffect(() => {
		if (check) {
			if (textToPayEnabled || payAtTableEnabled) {
				openCheckWs(check, checkId, personIdentifiers, table, restaurantId)
			}
		}
	}, [check])

	useEffect(() => {
		if (restaurantId && table) {
			if (textToPayEnabled || payAtTableEnabled) {
				openTableWs(restaurantId, table, backOffice)
			}
		}
	}, [table, restaurantId, backOffice])

	let primaryColor = restaurant && restaurant.branding && restaurant.branding.primaryColor ? restaurant.branding.primaryColor : global.primaryColor

	global.primaryColor = primaryColor
	
	document.documentElement.style.setProperty('--primary-color', `${primaryColor}`);
	document.documentElement.style.setProperty('--purple', `${primaryColor}`);
	document.documentElement.style.setProperty('--purple-text', `${primaryColor}`);
	
	if (activePage) {
		ReactGA.pageview("/" + activePage);
		ReactGA.event({
			category: "Page View",
			action: activePage
		});
	}

	let styles = `
		.body {
            padding-bottom: ${topBottomPadding}px;
		}
		.page {
			width: 100%;
			height: 100%;
			margin-top: ${topBottomPadding}px;
            padding-top: ${isDesktopView || (global.textPay && activePage == 'ReceiptPage') ? 0 : rightAlignHeaderLogo ? 57 : 75}px;
            background-color: ${isDesktopView ? 'var(--gray-background)' : ''};
		}

		.no-header {
			margin-top: 0px;
            padding-top: 0px;
		}

		.restaurant-info-wrapper {
			overflow: scroll;
			-ms-overflow-style: none;
			width:  100%;
			height: 100%;
		}
		.restaurant-info-wrapper::-webkit-scrollbar {
			display: none;
		}
	`
	const getClassName = () => {
		if (activePage === 'RestaurantInfoPage') {
			return 'restaurant-info-wrapper'
		} else if (activePage === 'GeoLookupPage') {
			return 'page no-header'
		}
		return 'page'
	}

    function handleActivePage(activePage) {

        const pages = {
            RestaurantInfoPage: <RestaurantInfoPage />,
            LoyaltyWrapper: <LoyaltyWrapper />,
            CreateAccountPage: <CreateAccountPage/>,
            ScanQrCodePage: <ScanQrCodePage/>,
            CreateAccountPage: <CreateAccountPage/>,
            LoadingPage: <LoadingPage isDesktopView={isDesktopView}/>,
            CheckStackPage: <CheckStackPage/>,
			GeoLookupPage: <GeoLookupPage/>,
            ReceiptPage: <ReceiptPage/>,
            MyBillPage: <MyBillPage/>,
            PaymentPage: <PaymentPage isDesktopView={isDesktopView}/>,
            EmailReceiptPage: <EmailReceiptPage/>,
            PaymentCompletePage: <PaymentCompletePage/>,
            FeedbackPage: <FeedbackPage/>,
            ImHerePage: <ImHerePage/>,
            PaymentHistoryPage: <PaymentHistoryPage/>,
            AccountManagementPage: <AccountManagementPage/>
        }

        let display = pages[activePage]

        if (isDesktopView && activePage == 'ReceiptPage'){
            display = <PaymentPage isDesktopView={true}/>
        }

        if (!isDesktopView && activePage == 'ReceiptPage' && global.textPay) {
            display = <Invoice restaurant={restaurant}/>
        }

        if (activePage == 'LoadingPage') {
            return <LoadingPage isDesktopView={isDesktopView}/>
        } else if (isDesktopView) {

            if (!checkId) {
                return <DesktopViewFrame leftView={<PaymentHistoryPage/>} rightView={<AccountManagementPage/>}/>
            }

            display = <DesktopViewFrame leftView={<Invoice restaurant={restaurant}/>} rightView={display}/>

        }
        return display
    }
    
	return (<>
		<ThemeProvider theme={theme(primaryColor)}>
			<DStyles styles={styles} />
			<div className={"body"} style={{height: windowHeight}} >
				{!isDesktopView && header && (!global.textPay || activePage !== 'ReceiptPage') && <Header />}
				{loyaltyHeader && <LoyaltyHeader />}
				<div className={getClassName()}>
                    {handleActivePage(activePage)}
				</div>
                {!isDesktopView && !global.textPay && footer && <Footer />}
			</div>
		</ThemeProvider>
        </>
	);
}
