import React from 'react';

export default (props) => {
    return (
        <svg width="300px" height="60px" viewBox="0 0 340 78" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink">
            <title>Group</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group" transform="translate(-30.000000, -30.000000)">
                    <rect id="Rectangle" x="0" y="0" width="400" height="108"></rect>
                    <polygon id="Path" fill="#C8102E" fill-rule="nonzero" points="66.2818548 30.6278714 66.2818548 54.4233714 43.8214159 54.4233714 43.8214159 30.6278714 31.0205364 30.6278714 31.0205364 90.1558879 43.8214159 90.1558879 43.8214159 64.0829311 66.4389207 64.0829311 66.4389207 90.1558879 79.2398003 90.1558879 79.2398003 30.6278714"></polygon>
                    <g transform="translate(83.244984, 30.627871)" fill="#C8102E" fill-rule="nonzero">
                        <path d="M12.6438135,34.1618565 C12.8794126,29.2928103 15.0783367,24.8164291 20.4971139,24.8164291 C27.5650842,24.8164291 27.7221503,32.1985315 27.7221503,34.1618565 L12.6438135,34.1618565 Z M40.1303648,41.8580908 C40.2874308,38.4026387 40.4444968,32.2770644 37.6958417,26.6226882 C33.9262575,18.8479209 26.8582872,16.5704638 20.8897789,16.5704638 C16.3348647,16.5704638 12.4082146,17.9055248 9.18836141,20.0259159 C2.1989241,24.8949621 0.549731024,32.9838614 0.549731024,39.7376998 C0.549731024,46.4130051 2.12039109,51.9888483 5.88997526,55.9940315 C10.2878235,60.5489457 17.0416617,61.1772097 20.5756469,61.1772097 C26.7012212,61.1772097 32.1199984,59.5280167 35.1827855,56.3081635 C38.5597047,52.7741784 38.9523697,49.2401932 39.1094357,47.2768681 L27.9577492,47.2768681 C26.7797542,51.9103153 23.7955,53.0097773 20.7327129,53.0097773 C12.2511485,53.0097773 12.2511485,44.606746 12.2511485,41.8580908 L40.1303648,41.8580908 L40.1303648,41.8580908 Z" id="Shape"></path>
                        <path d="M68.8734438,39.9732988 C68.8734438,44.44968 68.7163779,47.6695331 66.5959871,50.2611223 C65.417992,51.5961833 63.6117329,52.6171123 61.4128086,52.6171123 C57.4076255,52.6171123 55.5228334,49.4757922 55.5228334,46.7271371 C55.4443005,41.7795579 61.0201437,39.2665017 68.8734438,39.9732988 M81.4387247,59.5280167 C80.7319277,56.0725645 80.4177954,52.5385794 80.4177954,49.0045942 L80.3392625,32.2770644 C80.3392625,30.3922723 80.2607296,25.4446931 77.4335412,22.067774 C73.3498254,17.2772608 66.046256,16.7275297 62.5908037,16.7275297 C58.6641536,16.7275297 53.7951074,17.3557938 49.9469902,20.1044489 C45.5491421,23.245769 45.313543,27.6436172 45.23501,29.9210743 L57.3290926,29.9210743 C57.3290926,28.9001453 57.3290926,27.6436172 58.2714886,26.2300232 C59.3709506,24.6593631 61.1772097,23.9525661 62.904936,23.9525661 C64.0829311,23.9525661 67.3027841,24.3452311 68.3237133,27.4080182 C68.7949109,28.9001453 68.8734438,30.2352064 68.8734438,32.9053285 C64.8682609,32.9053285 57.4861585,32.9053285 52.3815133,35.1827855 C44.685279,38.7167707 43.585817,44.842345 43.585817,48.1407311 C43.585817,51.4391173 44.763812,54.8160364 47.1983351,57.2505596 C48.8475281,58.8212196 51.5961833,60.5489457 57.5646916,60.5489457 C59.8421487,60.5489457 65.417992,60.5489457 69.972906,53.8736404 C69.972906,55.1301685 69.972906,57.0149606 70.3655712,59.5280167 L81.4387247,59.5280167 Z" id="Shape"></path>
                        <path d="M86.3077709,59.5280167 L98.4803865,59.5280167 L98.4803865,39.0309027 C98.4803865,33.8477245 99.1871835,27.4865512 111.595398,28.6645463 L111.595398,17.6699258 C101.70024,17.1987278 99.0301176,22.22484 97.4594573,25.7588252 L97.2238585,17.7484588 L85.7580398,17.7484588 C85.9936386,20.1044489 86.3077709,26.1514902 86.3077709,28.1148153 L86.3077709,59.5280167 L86.3077709,59.5280167 Z" id="Path"></path>
                        <path d="M136.883025,50.9679193 C135.390898,51.1249853 134.605568,51.2035183 132.799309,51.2035183 C127.380532,51.2035183 127.380532,48.8475281 127.301999,44.999411 L127.301999,26.2300232 L137.197157,26.2300232 L137.197157,18.1411238 L127.301999,18.1411238 L127.301999,5.57584325 L115.207916,10.0522244 L115.207916,18.2196568 L115.207916,26.3085561 L115.207916,49.1616602 C115.207916,52.6171123 115.207916,60.3918797 127.459065,60.3918797 C129.42239,60.3918797 132.956375,60.1562807 136.961558,59.0568186 L136.961558,50.9679193 L136.883025,50.9679193 Z" id="Path"></path>
                        <rect id="Rectangle" x="141.202341" y="0" width="12.1726156" height="59.5280167"></rect>
                        <path d="M182.746299,39.9732988 C182.746299,44.44968 182.589233,47.6695331 180.468842,50.2611223 C179.290847,51.5961833 177.484588,52.6171123 175.285664,52.6171123 C171.280481,52.6171123 169.395689,49.4757922 169.395689,46.7271371 C169.317155,41.7795579 174.892999,39.2665017 182.746299,39.9732988 M195.390113,59.5280167 C194.683316,56.0725645 194.369183,52.5385794 194.369183,49.0045942 L194.290651,32.2770644 C194.290651,30.3922723 194.212118,25.4446931 191.384929,22.067774 C187.301213,17.2772608 179.997644,16.7275297 176.542192,16.7275297 C172.615542,16.7275297 167.746496,17.3557938 163.898379,20.1044489 C159.50053,23.245769 159.264931,27.6436172 159.186398,29.9210743 L171.201947,29.9210743 C171.201947,28.9001453 171.201947,27.6436172 172.144344,26.2300232 C173.243806,24.6593631 175.050065,23.9525661 176.777791,23.9525661 C177.955786,23.9525661 181.175639,24.3452311 182.196568,27.4080182 C182.667766,28.9001453 182.746299,30.2352064 182.746299,32.9053285 C178.741116,32.9053285 171.359014,32.9053285 166.254368,35.1827855 C158.558134,38.7167707 157.458672,44.842345 157.458672,48.1407311 C157.458672,51.4391173 158.636667,54.8160364 161.07119,57.2505596 C162.720383,58.8212196 165.469038,60.5489457 171.437547,60.5489457 C173.715004,60.5489457 179.290847,60.5489457 183.845761,53.8736404 C183.845761,55.1301685 183.845761,57.0149606 184.238426,59.5280167 L195.390113,59.5280167 Z" id="Shape"></path>
                        <path d="M199.395296,59.5280167 L211.646445,59.5280167 L211.646445,35.1827855 C211.646445,31.9629324 212.353242,29.9210743 213.138572,28.8216123 C214.473632,26.8582872 216.829623,25.6017591 219.499745,25.6017591 C222.641065,25.6017591 224.525857,27.3294852 225.468253,29.1357443 C226.096517,30.4708053 226.096517,31.9629324 226.096517,33.6906585 L226.096517,59.5280167 L238.504731,59.5280167 L238.504731,37.0675776 C238.504731,29.2928103 238.269133,27.1724192 237.326736,24.6593631 C236.227275,21.753642 232.771822,16.8060627 224.60439,16.8060627 C215.573095,16.8060627 212.274709,22.146307 211.096714,24.0310991 L210.782581,17.7484588 L199.081164,17.7484588 C199.316763,20.6541799 199.316763,25.1305611 199.316763,25.3661601 L199.316763,59.5280167 L199.395296,59.5280167 Z" id="Path"></path>
                        <path d="M255.232262,39.3450347 C255.232262,35.1827855 256.174657,25.3661601 264.263557,25.3661601 C269.760867,25.3661601 273.294852,29.8425413 273.294852,37.9314407 C273.294852,39.9732988 273.137786,44.292614 271.331527,47.7480661 C269.996466,50.3396553 267.169278,52.4600463 263.55676,52.4600463 C257.588252,52.4600463 255.232262,46.2559391 255.232262,39.3450347 M272.98072,23.088703 C271.881258,21.596576 268.818471,17.4343268 261.279303,17.4343268 C250.441748,17.4343268 242.90258,25.9944241 242.90258,39.7376998 C242.90258,52.7741784 249.499352,60.3918797 260.572505,60.3918797 C262.378764,60.3918797 265.598618,60.2348137 268.661405,58.2714886 C271.252994,56.6222955 272.038324,55.0516355 272.823654,53.5595084 L273.137786,59.6065496 L285.467468,59.6065496 C285.310402,58.0358896 285.153336,55.2872345 285.153336,52.8527114 L285.153336,0.157066007 L272.902187,0.157066007 L272.902187,23.088703 L272.98072,23.088703 Z" id="Shape"></path>
                    </g>
                    <line x1="30.9341501" y1="107.503828" x2="369.06585" y2="107.503828" id="Line" stroke="#A7A8AA" stroke-width="0.918836141" stroke-linecap="square"></line>
                </g>
            </g>
        </svg>
        
    )
}