import React from "react";

export default (props) => {
    return (
        <svg height={props.height} viewBox="0 0 144 240" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.745814732">
                <g id="2-1" transform="translate(-239.000000, -164.000000)">
                    <g id="Group" transform="translate(321.000000, 275.722967) rotate(15.000000) translate(-321.000000, -275.722967) translate(247.000000, 145.722967)">
                        <g id="Page-1" transform="translate(0.000000, 0.000000)">
                            <g id="scan-hand-qr-code" transform="translate(0.000000, 0.000000)" opacity="0.95">
                                <g id="Page-1" transform="translate(0.000000, 0.000000)">
                                    <g id="Group-2" transform="translate(0.000000, 0.000000)">
                                        <g id="scan-hand-qr-code" transform="translate(0.000000, 62.050000)">
                                            <rect id="Rectangle" stroke="#000000" strokeWidth="7.14" transform="translate(23.150633, 80.780633) rotate(-45.000000) translate(-23.150633, -80.780633) " x="13.460633" y="64.9706328" width="19.38" height="31.62" rx="9.69"></rect>
                                            <rect id="Rectangle" stroke="#000000" strokeWidth="7.14" transform="translate(23.150633, 106.280633) rotate(-45.000000) translate(-23.150633, -106.280633) " x="13.460633" y="90.4706328" width="19.38" height="31.62" rx="9.69"></rect>
                                            <rect id="Rectangle" stroke="#000000" strokeWidth="7.14" transform="translate(23.150633, 131.780633) rotate(-45.000000) translate(-23.150633, -131.780633) " x="13.4606331" y="115.970633" width="19.38" height="31.62" rx="9.69"></rect>
                                            <line x1="99.140633" y1="163.910633" x2="23.46" y2="163.910633" id="Line" stroke="#000000" strokeWidth="7.14" strokeLinecap="square"></line>
                                            <line x1="100.344233" y1="136.370633" x2="51.9086279" y2="136.370633" id="Line" stroke="#000000" strokeWidth="7.14" strokeLinecap="square"></line>
                                            <line x1="99.96" y1="163.910633" x2="99.96" y2="136.734443" id="Line" stroke="#000000" strokeWidth="7.14" strokeLinecap="square"></line>
                                            <path d="M23.6774675,155.492759 L23.6774675,160.517198 L23.6774675,155.492759 C23.6774675,155.492759 23.6774675,155.492759 23.6774675,155.492759 Z" id="Combined-Shape" stroke="#000000" strokeWidth="7.14" strokeLinecap="square"></path>
                                            <path d="M14.3506677,27.9057462 C15.3576338,28.1326322 16.3540913,28.4532142 17.3400404,28.8674919 L17.3395905,38.1220662 L15.416254,36.3948994 C12.1355823,35.1403769 9.82221068,35.5412809 8.47613929,37.5976111 C7.13006789,39.6539414 7.13006789,41.7359255 8.47613929,43.8435634 L17.3395905,51.9226662 L17.3395905,61.6208262 L2.10267233,48.2469674 C-0.447116862,42.6916563 -0.447116862,37.9884547 2.10267233,34.1373625 C4.65246153,30.2862704 8.73512667,28.2090649 14.3506677,27.9057462 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero"></path>
                                            <path d="M19.2946364,59.3211846 L19.2946364,6.71952139 C19.8311478,2.33449081 22.023618,0.141975525 25.872047,0.141975525 C29.7204759,0.141975525 58.4235132,0.141975525 111.981159,0.141975525 C116.366099,0.645732987 118.558569,2.83824828 118.558569,6.71952139 C118.558569,10.6007945 118.558569,31.1698821 118.558569,68.426784 C115.767688,69.6737613 113.575217,71.6382911 111.981159,74.3203735 C111.458021,55.6529388 111.458021,40.5440922 111.981159,28.9938339 L27.18017,28.9938339 L27.18017,60.5223873 L19.2946364,59.3211846 Z M27.2306331,8.36063312 L27.2306331,20.6006331 L110.870633,20.6006331 L110.870633,8.36063312 L27.2306331,8.36063312 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero"></path>
                                            <rect id="Rectangle" fill="#000000" fillRule="nonzero" x="56.8106331" y="10.4006331" width="25.5" height="8.16"></rect>
                                            <path d="M88.0144485,196.311866 L80.659345,187.443941 C62.9182275,191.494228 51.4419786,191.494228 46.2305984,187.443941 C41.0192183,183.393655 36.8330512,179.400461 33.6720973,175.464359 L44.1803575,175.464359 C48.1808456,182.828517 56.0932905,184.95879 67.9176928,181.85518 C75.0871829,180.455789 80.4850498,179.846637 84.1112938,180.027722 L93.8612568,191.029441 L88.0144485,196.311866 Z" id="Path-4" fill="#000000" fillRule="nonzero"></path>
                                            <path d="M142.125915,178.321743 L122.093293,155.700255 L122.093293,150.322538 L134.669705,134.61743 L134.669705,84.7192277 C130.603322,78.9999334 126.411185,78.9999334 122.093293,84.7192277 C121.678808,99.4525727 121.678808,113.202018 122.093293,125.967563 L120.062114,128.164738 C114.064652,128.333752 109.845603,130.019359 107.404969,133.22156 C104.964334,136.423762 103.510114,139.352071 103.042309,142.006487 L97.1381557,134.61743 C103.071735,125.959515 108.71432,121.630558 114.065909,121.630558 C114.206251,112.374557 114.206251,100.07078 114.065909,84.7192277 C116.357149,76.7147307 121.095058,72.7124822 128.279639,72.7124822 C135.464219,72.7124822 140.079645,76.7147307 142.125915,84.7192277 L142.125915,134.61743 C142.125915,136.028772 137.985124,142.129776 129.703543,152.920444 C137.843855,162.755497 143.727596,169.381557 147.354768,172.798624 L142.125915,178.321743 Z" id="Path-5" fill="#000000" fillRule="nonzero"></path>
                                            <circle id="Oval" fill="#000000" fillRule="nonzero" cx="69.0506331" cy="149.120633" r="6.12"></circle>
                                            <g id="qr" transform="translate(41.820000, 52.020000)">
                                                <rect id="Rectangle" stroke="#000000" strokeWidth="4.08" x="0" y="1.02" width="15.3" height="15.3"></rect>
                                                <rect id="Rectangle" stroke="#000000" strokeWidth="4.08" x="39.78" y="1.02" width="15.3" height="15.3"></rect>
                                                <rect id="Rectangle" stroke="#000000" strokeWidth="4.08" x="39.78" y="40.8" width="15.3" height="15.3"></rect>
                                                <line x1="22.95" y1="7.59631579" x2="22.95" y2="24.2710038" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="32.13" y1="0.456315788" x2="32.13" y2="17.1310038" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="22.95" y1="41.2294737" x2="22.95" y2="56.9232977" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="15.81" y1="40.2363158" x2="15.81" y2="56.9110038" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="39.27" y1="22.7621053" x2="39.27" y2="34.5324733" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="32.64" y1="33.8478947" x2="32.64" y2="40.7139427" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="32.64" y1="48.1547368" x2="32.64" y2="56.0016488" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="15.3" y1="23.5405263" x2="15.3" y2="26.4831183" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="47.94" y1="30.6805263" x2="47.94" y2="33.6231183" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="8.16" y1="47.0005263" x2="8.16" y2="49.9431183" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="15.555" y1="33.6745483" x2="19.2253166" y2="33.6745483" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="25.94625" y1="33.6745483" x2="32.3693039" y2="33.6745483" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="49.40625" y1="33.6745483" x2="55.8293039" y2="33.6745483" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="42.7125" y1="22.698637" x2="55.558608" y2="22.698637" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="24.03375" y1="24.2359111" x2="32.2919622" y2="24.2359111" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="3.6975" y1="23.4745483" x2="12.8732914" y2="23.4745483" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="8.415" y1="39.7945483" x2="12.0853166" y2="39.7945483" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="8.415" y1="56.8759111" x2="12.0853166" y2="56.8759111" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="24.54375" y1="56.8759111" x2="25.4613291" y2="56.8759111" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="22.695" y1="0.775911154" x2="26.3653166" y2="0.775911154" id="Line-3" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <line x1="8.16" y1="32.6668421" x2="8.16" y2="33.6477061" id="Line-2" stroke="#000000" strokeWidth="4.08" strokeLinecap="square"></line>
                                                <rect id="Rectangle" fill="#000000" fillRule="nonzero" x="43.86" y="5.1" width="7.14" height="7.14"></rect>
                                                <rect id="Rectangle" fill="#000000" fillRule="nonzero" x="43.86" y="44.88" width="7.14" height="7.14"></rect>
                                                <rect id="Rectangle" fill="#000000" fillRule="nonzero" x="4.08" y="44.88" width="7.14" height="7.14"></rect>
                                                <rect id="Rectangle" fill="#000000" fillRule="nonzero" x="4.08" y="5.1" width="7.14" height="7.14"></rect>
                                            </g>
                                        </g>
                                        <g id="NFC" transform="translate(68.720000, 32.090000) rotate(-90.000000) translate(-68.720000, -32.090000) translate(36.720000, 0.090000)" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.42905273">
                                            <g id="Group-6" transform="translate(31.562325, 31.582337) rotate(90.000000) translate(-31.562325, -31.582337) translate(12.562325, 18.082337)">
                                                <path d="M8.81266553,20.4028638 C11.609006,18.3170796 15.4019893,17.1452637 19.3570027,17.1452637 C23.3120161,17.1452637 27.1049993,18.3170796 29.9013398,20.4028638" id="Shape"></path>
                                                <path d="M13.9562446,26.2322534 C16.9865937,24.4034253 21.8988643,24.4034253 24.9292134,26.2322534" id="Shape"></path>
                                                <path d="M4.66351172,14.1619878 C8.56020086,10.5832213 13.8457076,8.57263184 19.3570027,8.57263184 C24.8682978,8.57263184 30.1538045,10.5832213 34.0504937,14.1619878" id="Shape"></path>
                                                <path d="M0,7.06384863 C5.01977224,2.54099041 11.8286398,0 18.9283711,0 C26.0281024,0 32.8369699,2.54099041 37.8567422,7.06384863" id="Shape"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}