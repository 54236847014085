import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.width} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M8.12226556,13.6426986 C6.77819166,13.4569217 6.68133175,12.8477972 6.68133175,11.9788046 L6.68133175,4.6918821 L6.68133175,4.6918821 C6.68133175,4.16448055 7.40179866,4.16448055 7.40179866,4.6918821 L7.40179866,11.0718514 L7.40179866,11.0718514 C7.40179866,11.5060022 8.12226556,11.5771695 8.12226556,11.0718514 L8.12226556,4.6918821 L8.12226556,4.6918821 C8.12226556,4.15219906 8.84288519,4.15219906 8.84288519,4.6918821 L8.84288519,11.0718514 L8.84288519,11.0718514 C8.84288519,11.4806035 9.56854465,11.558075 9.56854465,11.0718514 L9.56854465,4.6918821 L9.56854465,4.6918821 C9.56854465,4.20525564 10.2859571,4.16613573 10.2859571,4.6918821 L10.2859571,11.0718514 L10.2859571,11.0718514 C10.2859571,11.5420049 11,11.5420049 11,11.0718514 L11,4.6918821 L11,4.6918821 C11,4.16537408 11.7510974,4.18707315 11.7510974,4.6918821 L11.7510974,11.9788046 L11.7510974,11.9788046 C11.7510974,13.0567008 11.2627173,13.6277249 10.2859571,13.6918768 L10.2848918,21.8534862 C10.8421549,21.9498042 11.4152155,22 12,22 C13.1026531,22 14.1636235,21.8215348 15.155602,21.4919133 L15.1559888,18.7669114 L15.1559888,18.7669114 C15.1559888,18.2881468 14.9187094,18.019203 14.4441507,17.9600801 C14.4138639,12.211832 14.4138639,8.58386564 14.4441507,7.07618085 C14.4744374,5.56849606 15.4222313,4.63246179 17.2875323,4.26807804 L17.2875383,20.4893413 C20.1172622,18.7231322 22,15.5814346 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,16.1471229 4.52446643,19.7046251 8.12051779,21.2196251 C8.1165553,17.4003085 8.11705964,14.8748651 8.12226556,13.6426986 Z" id="Combined-Shape" fill={props.cardColor}></path>
            </g>
        </svg>
    );
}