import log from 'loglevel';
var Url = require('url-parse');
const queryString = require('query-string');

export const urlParse = (params) => {
    var url = new Url(params);
    log.info(url)
    var pathname = url.pathname.replace(/\//g, "");
    var queryObject = queryString.parse(url.query.toString());
    log.info(queryObject)
    return { url: url, params: queryObject, pathname: pathname };
}
