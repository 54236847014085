import React from 'react';

export default (props) => {
    const opacity = props.opacity ? props.opacity : 0.85
    return (
        <svg width={props.width} height={props.height} opacity={opacity} version="1.1" viewBox="0 0 307.86295 247.70953" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <filter id="filter-1" x="-.05" y="-.075" width="1.101" height="1.149">
                    <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.607874774   0 0 0 0 0.607874774   0 0 0 0 0.607874774  0 0 0 0.5 0" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g transform="translate(14.708 16.622)" fill="none" fillRule="evenodd">
                <g id="paid" transform="rotate(-15 233.84 29.936)" fill={props.fill || "#f00"} filter="url(#filter-1)">
                    <g id="PAID" transform="translate(0,17.01)" fontSize="109.35">
                        <g aria-label="PAID">
                            <path d="m22.254 30.147v29.954h13.562q7.5285 0 11.64-3.8977 4.1113-3.8977 4.1113-11.106 0-7.1547-4.1113-11.052-4.1113-3.8977-11.64-3.8977zm-10.785-8.8633h24.347q13.402 0 20.236 6.0869 6.8878 6.0335 6.8878 17.727 0 11.8-6.8878 17.833-6.8344 6.0335-20.236 6.0335h-13.562v32.036h-10.785z" />
                            <path d="m97.165 31.909-14.63 39.671h29.313zm-6.0869-10.625h12.227l30.381 79.717h-11.213l-7.2615-20.45h-35.934l-7.2615 20.45h-11.373z" />
                            <path d="m145.38 21.283h10.786v79.717h-10.786z" />
                            <path d="m188.41 30.147v61.99h13.028q16.499 0 24.134-7.4751 7.6887-7.4751 7.6887-23.6 0-16.018-7.6887-23.44-7.6353-7.4751-24.134-7.4751zm-10.786-8.8633h22.158q23.173 0 34.012 9.6642 10.839 9.6108 10.839 30.114 0 20.61-10.892 30.274-10.892 9.6642-33.958 9.6642h-22.158z" />
                        </g>
                    </g>
                    <path id="Rectangle" d="m0 0h235.71v19.44h-235.71v-19.44z" fillRule="nonzero" />
                    <path d="m0 140.94h235.71v19.44h-235.71v-19.44z" fillRule="nonzero" />
                </g>
            </g>
        </svg>

    );
}
