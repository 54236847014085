import log from 'loglevel';
import dashFetch from './dashFetch';

export default async function (paymentId, email=null, debug = true) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('email old receipt api');

		const url = `${global.dashServerUrl}/api/v1/account/emailOldReceipt`;

		if (debug) log.info('fetching from url: ' + url);

		const body = {
			paymentId,
			email
		};

		if (debug) log.info('BODY: ' + JSON.stringify(body))
		dashFetch(url, body, debug).then((res) => {
			if (res.success) {
				log.info("res")
				log.info(res)
				res.json().then((json) => {
					log.info("json")
					log.info(json)
					if (json.success) {
						if (debug) log.info('success');
					}
					let data = json.data
					resolve(data);
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				log.info('emailing old receipt failed');
				reject("An error occured trying to retrieve info. Please try again.");
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		});
	})
}