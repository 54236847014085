import dashFetch from '../actions/dashFetch'
import log from 'loglevel';
import logTicketScan from '../actions/logTicketScan';

export default async function getTicketIdFromFallback(fallbackCode, restaurantId, debug) {
	return new Promise(async (resolve, reject) => {
		logTicketScan(null, fallbackCode)

		let out = {
			error: null,
			receipt: null
		};
		let ticketId = ''
		
		if (debug) log.info('getCheckIdFromFallback api');

		const url = `${global.dashServerUrl}/api/v1/check/getTicketIdFromFallback`;
		if (debug) log.info(`url: ${url}`);

		const body = {
			fallbackCode: fallbackCode,
			restaurantId: restaurantId,
			deviceId: localStorage.deviceId,
		};
		if (debug) log.info(body);

		dashFetch(url, body, debug).then(({ res, json }) => {
			if (!json.success) {
				reject(json.errors)
			} else {
				if (debug) log.info(res.status);

				 log.info(json);

				// Handle errors
				if (!json.success) {
					// token is bad, need to log back in
					if (json.hasOwnProperty('auth') && !json.auth) {
						// token is no good.  Need to login
						let errorObj = {
							type: 'auth',
							message: 'Invalid token'
						}
						out.error = errorObj;
						reject(out);
					} else {
						let errorObj = {
							type: 'check',
							message: 'Unable to find check with code'
						}
						out.error = errorObj;
						resolve(out);
					}
				}
				if (json.success) {
					ticketId = json.data.ticketId;
					if (ticketId) {
						log.info("CHECK ID: " + ticketId)
					}
					out = ticketId
					resolve(out)
				}
			}
		}).catch((err) => { reject(err) });
	});
}