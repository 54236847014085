import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Tabs,
	Tab,
	Badge,
	Grid
} from '@material-ui/core';
import RewardIcon from '../resources/images/RewardIcon.js';
import checkUtils from '../util/checkUtils'
import MobilePay from '../resources/images/MobilePay.js';
import Settings from '@material-ui/icons/Settings'
import Specials from '../resources/images/Specials.js';
import Menu from '../resources/images/Menu.js';
import ReceiptsIcon from '../resources/images/ReceiptsIcon.js';
import CreditCardIcon from '@material-ui/icons/CreditCard';

export default (props) => {

	const bottomBackgroundColor = "#f5f5f5";

	let [loyaltyProgram] = global.get("loyaltyProgram")
	let [backOffice] = global.get("backOffice")
	let [geo] = global.get("geo")
	let [finishedPaying] = global.get("finishedPaying", false)
	let payNowTab = finishedPaying ? false : true
	let historyTab = true
	let accountManagementTab = true
	let [restaurantData] = global.get("restaurantData")
	let specialsTab = (restaurantData && !restaurantData.menuUrl) && restaurantData && (restaurantData.specials || restaurantData.content || restaurantData.topSellers)
	let menuTab = !!(restaurantData && restaurantData.menuUrl)
	let bottomPad = 0;

	// console.log('specialsTab: ' + specialsTab)
	// console.log('menuTab: ' + menuTab)

	if (window.matchMedia('(display-mode: standalone)').matches && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
		bottomPad = 80;
	}

	const c = makeStyles((theme) => ({
		root: {
			position: 'fixed',
			left: '0px',
			bottom: '0px',
			height: '57px',
			width: '100%',
			backgroundColor: bottomBackgroundColor,
			// borderTop: '1px solid var(--divider)',
			paddingBottom: bottomPad,
			zIndex: 6,
		},
		indicatorStyle: {
			position: 'absolute',
			top: 0,
			backgroundColor: 'var(--purple)'
		},
		badgePosition: {
			top: 4,
			right: -6,
		},
		tWrapper: {
			height: '100%',
			// minHeight: 46,
			fontSize: 10,
		},
		labelIcon: {
			lineHeight: 1,
			minHeight: 42,
		}
	}))();

	const [activePage, setActivePage] = global.get('activePage');
	let [checks] = global.get('checks');

	const [footerTabIndex, setFooterTabIndex] = global.get('footerTabIndex')

	useEffect(() => {
		if (!footerTabIndex) {
			setFooterTabIndex(0)
		}
	}, []);

	// tab indexes
	let tabIndexes = {}
	let startIndex = 0
	if (loyaltyProgram) tabIndexes["loyaltyProgram"] = startIndex++
	if (payNowTab) tabIndexes["payNow"] = startIndex++
	if (specialsTab && !menuTab) tabIndexes["specials"] = startIndex++
	if (menuTab) tabIndexes["menu"] = startIndex++
	if (historyTab) tabIndexes["history"] = startIndex++
	if (accountManagementTab) tabIndexes["accountManagement"] = startIndex++


	if (footerTabIndex !== tabIndexes["loyaltyProgram"] && activePage === "LoyaltyWrapper") {
		setFooterTabIndex(tabIndexes["loyaltyProgram"])
	} else if (footerTabIndex !== tabIndexes["payNow"] && activePage === "CheckStackPage") {
		setFooterTabIndex(tabIndexes["payNow"])
	} else if (footerTabIndex !== tabIndexes["specials"] && activePage === "RestaurantInfoPage" && !menuTab) {
		setFooterTabIndex(tabIndexes["specials"])
	} else if (footerTabIndex !== tabIndexes["menu"] && activePage === "RestaurantInfoPage" && menuTab) {
		setFooterTabIndex(tabIndexes["menu"])
	} else if (footerTabIndex !== tabIndexes["history"] && activePage === "PaymentHistoryPage") {
		setFooterTabIndex(tabIndexes["history"])
	}

	const sumCheckTotals = (checks) => {
		return checks.reduce((val, check) => {
			return (!check.closed) ? (check.totals ? check.totals.total : check.total) + val : val
		}, 0)
	}

	return (
		<div className={c.root}>
			<Tabs
				onChange={(event, newTabIndex) => {
					setFooterTabIndex(newTabIndex);
				}}
				value={footerTabIndex}
				textColor="primary"
				indicatorColor="primary"
				classes={{
					indicator: c.indicatorStyle,
				}}
				className={"tabs"}
				style={{
					height: 57,
				}}
				TabIndicatorProps={{
					color: "primary",
				}}
				variant="fullWidth"
			>
				{loyaltyProgram && (
					<Tab
						disableRipple={true}
						label="Rewards"
						icon={
							<RewardIcon
								width={24}
								medallionColor={footerTabIndex === tabIndexes["loyaltyProgram"] ? "#545454" : "#9e9e9e"}
								strapColor={footerTabIndex === tabIndexes["loyaltyProgram"] ? "var(--purple)" : "#9e9e9e"}
							/>
						}
						onClick={() => {
							global.set({
								header: false,
							});
							setActivePage("LoyaltyWrapper");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
				{backOffice && !finishedPaying && (
					<Tab
						disableRipple={true}
						label="Pay Now"
						icon={
							<Badge
								color="primary"
								badgeContent={!geo && checks && checks[0] && (checks[0].total || checks[0].totals) ? `$${Math.floor(checkUtils.formatMoney(sumCheckTotals(checks)))}` : 0}
								overlap="rectangle"
								classes={{
									badge: c.badgePosition,
								}}
							>
								<Grid container direction="column" alignItems="center" spacing={0}>
									<div
										style={{
											paddingLeft: 6,
											lineHeight: 1,
										}}
									>
										<MobilePay
											width={24}
											cellColor={footerTabIndex === tabIndexes["payNow"] ? "#545454" : "#9e9e9e"}
											cardColor={footerTabIndex === tabIndexes["payNow"] ? "var(--purple)" : "#9e9e9e"}
										/>
									</div>
								</Grid>
							</Badge>
						}
						onClick={() => {
							global.set({
								header: true,
							});
							setActivePage("CheckStackPage");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
				{!backOffice && !finishedPaying && (
					<Tab
						disableRipple={true}
						label="Pay Now"
						icon={
							<MobilePay
								width={24}
								cellColor={footerTabIndex === tabIndexes["payNow"] ? "#545454" : "#9e9e9e"}
								cardColor={footerTabIndex === tabIndexes["payNow"] ? "var(--purple)" : "#9e9e9e"}
							/>
						}
						onClick={() => {
							global.set({
								header: false,
							});
							setActivePage("ScanQrCodePage");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
				{specialsTab && !menuTab && (
					<Tab
						disableRipple={true}
						label="Specials"
						icon={
							<Specials
								width={24}
								starColor={footerTabIndex === tabIndexes["specials"] ? "var(--purple)" : "#9e9e9e"}
								otherColor={footerTabIndex === tabIndexes["specials"] ? "#545454" : "#9e9e9e"}
							/>
						}
						onClick={() => {
							global.set({
								header: false,
							});
							setActivePage("RestaurantInfoPage");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
				{menuTab && (
					<Tab
						disableRipple={true}
						label="Menu"
						icon={
							<Menu
								width={24}
								cardColor={footerTabIndex === tabIndexes["menu"] ? "var(--purple)" : "#9e9e9e"}
								otherColor={footerTabIndex === tabIndexes["menu"] ? "#545454" : "#9e9e9e"}
							/>
						}
						onClick={() => {
							global.set({
								header: false,
							});
							setActivePage("RestaurantInfoPage");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
				{historyTab && !finishedPaying && (
					<Tab
						disableRipple={true}
						label="Receipts"
						icon={
							<ReceiptsIcon
								height={24}
								cellColor={footerTabIndex === tabIndexes["history"] ? "#545454" : "#9e9e9e"}
								cardColor={footerTabIndex === tabIndexes["history"] ? "var(--purple)" : "#9e9e9e"}
							/>
						}
						onClick={() => {
							global.set({
								header: false,
							});
							setActivePage("PaymentHistoryPage");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
				{historyTab && finishedPaying && (
					<Tab
						disableRipple={true}
						label="Receipts"
						icon={
							<Badge
								color="primary"
								badgeContent={1}
								overlap="rectangle"
								classes={{
									badge: c.badgePosition,
								}}
							>
								<Grid container direction="column" alignItems="center" spacing={0}>
									<div
										style={{
											paddingLeft: 6,
											lineHeight: 1,
										}}
									>
										<ReceiptsIcon
											height={24}
											cellColor={footerTabIndex === tabIndexes["history"] ? "#545454" : "#9e9e9e"}
											cardColor={footerTabIndex === tabIndexes["history"] ? "var(--purple)" : "#9e9e9e"}
										/>
									</div>
								</Grid>
							</Badge>
						}
						onClick={() => {
							global.set({
								header: true,
							});
							setActivePage("PaymentHistoryPage");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
				{accountManagementTab && (
					<Tab
						disableRipple={true}
						label="CARDS"
						icon={
							<CreditCardIcon
								width={24}
								style={{
									color: footerTabIndex === tabIndexes["accountManagement"] ? "#545454" : "#9e9e9e",
								}}
							/>
						}
						onClick={() => {
							global.set({
								header: false,
							});
							setActivePage("AccountManagementPage");
						}}
						classes={{
							wrapper: c.tWrapper,
							labelIcon: c.labelIcon,
						}}
					/>
				)}
			</Tabs>
		</div>
	);
};
