import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.width} viewBox="0 0 41 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="navigation1" transform="translate(-507.000000, -25.000000)">
                    <g id="navigation" transform="translate(-1.000000, 0.000000)">
                        <g id="nav-items" transform="translate(68.000000, 24.000000)" opacity="0.75">
                            <g id="specials" transform="translate(412.000000, 0.000000)">
                                <g id="Group-4" transform="translate(27.909091, 0.909091)">
                                    <rect id="Rectangle" stroke={props.otherColor} strokeWidth="3" x="1.6031941" y="1.6031941" width="37.8648649" height="37.8648649" rx="4"></rect>
                                    <rect id="Rectangle" fill={props.otherColor} x="20.2260442" y="26.4176904" width="14.034398" height="3.40540541" rx="1.7027027"></rect>
                                    <rect id="Rectangle" fill={props.otherColor} x="20.2260442" y="11.6609337" width="14.034398" height="3.40540541" rx="1.7027027"></rect>
                                    <polygon id="Path-5" fill={props.starColor} /* This is the color for the top star */ points="18.1621622 10.9398649 14.0331081 10.9398649 12.4864865 6.81081081 10.9398649 10.9398649 6.81081081 10.9398649 10.1594595 13.5222973 8.61283784 18.1621622 12.4864865 15.3243243 16.3601351 18.1621622 14.8135135 13.5222973"></polygon>
                                    <polygon id="Path-5" fill={props.starColor} /* This is the color for the bottom star */ points="18.1621622 25.4902334 14.0331081 25.4902334 12.4864865 21.3611794 10.9398649 25.4902334 6.81081081 25.4902334 10.1594595 28.0726658 8.61283784 32.7125307 12.4864865 29.8746929 16.3601351 32.7125307 14.8135135 28.0726658"></polygon>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}