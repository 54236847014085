import log from 'loglevel';
import dashFetch from './dashFetch';
import { NotificationManager } from 'react-notifications';

export default async (check, ratings, comment, accountRestaurantId, debug) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (debug) log.info('***** Sumbmit Feedback API ****');

			const url = `${global.dashServerUrl}/api/v1/feedback/submit`;
			const body = {
				feedback: {
					restaurant: check.restaurant._id,
					check: check._id,
					ratings,
					comment
				},
				accountRestaurantId: accountRestaurantId,
			};

			if (global.tryMe) {
				resolve(true)
			} else {
				dashFetch(url, body, debug).then(({ res, json }) => {
					if (json.success) {
						resolve(true)
					} else {
						NotificationManager.warning('', json.errors, 3000);
						resolve(false)
					}
				}).catch((err) => { reject(err) })
			}
		} catch (e) {
			log.info(e)
		}
	})
};
