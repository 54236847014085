import log from 'loglevel';
import dashFetch from './dashFetch';

export default async function (cardIdBeingDeleted, debug = false) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('email old receipt api');

		const url = `${global.dashServerUrl}/api/v1/account/deleteEncryptedCard`;

		if (debug) log.info('fetching from url: ' + url);

		const body = {
            encryptedCardId: cardIdBeingDeleted,
		};

		if (debug) log.info('BODY: ' + JSON.stringify(body))
		dashFetch(url, body, debug).then((res) => {
			let json = res.json
			if (json.success) {
				if (debug) log.info('success');
			}
			resolve(json);
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		});
	})
}