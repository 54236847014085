import React from 'react';
import DashLoad from '../../assets/Dashnow-Loading.gif'
import { urlParse } from '../../util/urlParser'
import CurblyLogo from '../../assets/curbly-logo';
import HeartlandLogoFull from '../../assets/heartland-logo-full';
import Shift4LogoFull from '../../assets/shift4-logo-full';
import TextPayLogo from '../../assets/textpay-logo';

const s = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
        alignItems: 'center'
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		fontFamily: "Roboto",
		fontWeight: 400,
		lineHeight: 1.1,
	},
	dNowText: {
		color: '#757575',
		fontSize: 32,
	},
	spgText: {
		height: 20,
		color: '#9E9E9E',
		fontSize: 20,
		marginLeft: 4,
		marginRight: 4,
	},
	bull: {
		marginTop: 8,
		color: '#9E9E9E',
	},
};

export default (props) => {

	let url = urlParse(window.location.href)
	let pathname = url.pathname
	let params = url.params
	let shortUrl
	let showCustomSplashIcon
	let restaurantCode
	let restaurantLogoUrl
	if (params.z) {
		let zSplit = params.z.split("-")
		showCustomSplashIcon = zSplit.length > 0 ? (zSplit[0] === "1" || zSplit[0] === "2") : false
		restaurantCode = zSplit.length > 1 ? zSplit[1] : ''
	}

	if(pathname){
		let pathSplit = pathname.split("-")
		if(pathSplit.length == 3){
			shortUrl = pathSplit[0]
			showCustomSplashIcon =  pathSplit[1] === "1" || pathSplit[1] === "2"
			restaurantCode = pathSplit[2]
		} else {
			shortUrl = pathname
		}
	}
	if(!shortUrl){
		shortUrl = params.s
	}
	if (restaurantCode && showCustomSplashIcon) {
		restaurantLogoUrl = `${global.s3Url}/restaurants/${restaurantCode}/`
		global.set({restaurantLogoUrl: restaurantLogoUrl})
	}

	return (
		<div style={s.container}>
			{showCustomSplashIcon && 
				<>
					<img src={restaurantLogoUrl + "logo-splash.png"} width="275px" alt="Loading..." style={{position:'absolute', top:'27%'}}/>
					<br/><br/>
				</>
			}

			{!showCustomSplashIcon && global.dashNow &&
				<>
					{/* <Group width={136} height={136} viewBox={'0, -1, 35, 35'} /> */}
					<img src={DashLoad} height="250px" alt="Loading..." />

					<div style={s.row}>
						<div style={s.dNowText}>
							dashnow
						</div>
					</div>

					<div style={s.row}>
						<div style={s.spgText} id="scan">scan</div>
						<div style={s.bull}>&bull;</div>
						<div style={s.spgText} id="pay">pay</div>
						<div style={s.bull}>&bull;</div>
						<div style={s.spgText} id="go">go</div>
					</div>
					<br/><br/>
				</>
			}
			{!showCustomSplashIcon && global.curbly &&
				<CurblyLogo style={{ marginTop: 3}} />
			}
			{!showCustomSplashIcon && global.textPay && <>
				{/* {global.branding == 'heartland' && <> //TYLER MISSES THIS CODE and thinks its better for the splash to have logo and text
					<div style={s.row}>
						<HeartlandLogoFull style={{ marginTop: 3}} />
					</div>
				</>}
				{global.branding == 'shift4' && <>
					<div style={s.row}>
						<Shift4LogoFull style={{ marginTop: 3}} />
					</div>
				</>}	
				{global.branding == 'dashnow' && <>
					<div style={s.row}>
						<TextPayLogo style={{ marginTop: 3}} />
					</div>
				</>} */}
				<div style={s.row}>
					<div style={s.dNowText}>
						text
					</div>
					<span style={{ fontSize: 32, color:global.primaryColor}}>
						pay
					</span>
				</div>
			</>}
        </div>
	);
}
