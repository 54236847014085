import React from 'react'
import DStyles from '../../components/DStyles'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Ripple({ color, className, style, size }) {
  const circles = [...Array(2)].map((_, index) => (
    <div
      key={index}
      style={{
        borderColor: `${color}`,
        borderWidth: size * 0.05,
      }}
    />
  ))


  const styles = `
	.lds-ripple {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	
	.lds-ripple div {
		position: absolute;
		border: 4px solid #fff;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}
	
	.lds-ripple div:nth-child(2) {
		animation-delay: -0.5s;
	}
	
	@keyframes lds-ripple {
		0% {
		top: 45%;
		left: 45%;
		width: 0;
		height: 0;
		opacity: 1;
		}
	
		100% {
		top: 0px;
		left: 0px;
		width: 90%;
		height: 90%;
		opacity: 0;
		}
	}
  `

  return (
	  <>
		<DStyles styles={styles} />
		<div className="lds-ripple" style={{ width: size, height: size, ...style }}>
		{circles}
		</div>
	</>
  )
}

Ripple.propTypes = {
  /** hex color */
  color: PropTypes.string,
  /** class name  */
  className: PropTypes.string,
  /** style object */
  style: PropTypes.object,
  /** size in pixel */
  size: PropTypes.number,
}

Ripple.defaultProps = {
  color: 'var(--primary-color)',
  className: '',
  style: {},
  size: 80,
}