
import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 200 160" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink">
            <title>fis-logo</title>
            <desc>Created with Sketch.</desc>
            <g id="fis-logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group" transform="translate(37.000000, 44.000000)">
                    <g id="fis-company-fidelity-national-information-services-inc-corporate-logo" fill-rule="nonzero">
                        <path d="M161.376667,25.0827808 C161.37745,24.1195516 160.99682,23.1955046 160.318593,22.5141206 C159.640367,21.8327366 158.720161,21.4498928 157.760612,21.4498928 L116.007724,21.4498928 C108.394821,21.4498928 102.206201,27.3392606 102.206201,34.9828452 C102.206201,42.6264298 108.394821,48.514316 116.006248,48.514316 L149.539622,48.514316 C152.804403,48.514316 155.456669,51.507152 155.456669,54.7814924 C155.456669,58.0558327 152.804403,61.0516319 149.541098,61.0516319 L107.794113,61.0516319 C105.825894,61.0915546 104.25111,62.7046441 104.25111,64.680816 C104.25111,66.6569878 105.825894,68.2700773 107.794113,68.31 L149.542574,68.31 C157.155477,68.31 163.35,62.4235954 163.35,54.7814924 C163.35,47.1364262 157.155477,41.2529848 149.542574,41.2529848 L116.007724,41.2529848 C112.750322,41.2529848 110.095105,38.255704 110.095105,34.9828452 C110.095105,31.7085049 112.750322,28.7156689 116.007724,28.7156689 L157.759136,28.7156689 C158.718685,28.7156692 159.638891,28.332825 160.317117,27.6514411 C160.995344,26.9700571 161.375975,26.0460101 161.375191,25.0827808 M87.7419818,5.28117046 C87.7419818,3.39439 86.7392427,1.65093294 85.1114864,0.707542687 C83.4837301,-0.235847562 81.4782517,-0.235847562 79.8504954,0.707542687 C78.2227391,1.65093294 77.22,3.39439 77.22,5.28117046 C77.22,8.19788015 79.5754259,10.5623405 82.4809909,10.5623405 C85.3865559,10.5623405 87.7419818,8.19788015 87.7419818,5.28117046" id="Shape" fill="#8DC63F"></path>
                        <path d="M65.835,10.89 C62.8278095,10.89 60.39,8.45219046 60.39,5.445 C60.39,2.43780954 62.8278095,0 65.835,0 C68.8421905,0 71.28,2.43780954 71.28,5.445 C71.28,8.45219046 68.8421905,10.89 65.835,10.89" id="Path" fill="#A3CF62"></path>
                        <path d="M49.005,10.89 C45.9978095,10.89 43.56,8.45219046 43.56,5.445 C43.56,2.43780954 45.9978095,0 49.005,0 C52.0121905,0 54.45,2.43780954 54.45,5.445 C54.45,8.45219046 52.0121905,10.89 49.005,10.89" id="Path" fill="#B9D989"></path>
                        <path d="M32.175,10.89 C29.1678095,10.89 26.73,8.45219046 26.73,5.445 C26.73,2.43780954 29.1678095,0 32.175,0 C35.1821905,0 37.62,2.43780954 37.62,5.445 C37.62,8.45219046 35.1821905,10.89 32.175,10.89" id="Path" fill="#CFE5AE"></path>
                        <path d="M15.3480556,10.89 C12.3408656,10.8916867 9.90168841,8.45524553 9.9,5.44805555 C9.89831334,2.44086556 12.3347545,0.00168839766 15.3419445,0 C17.2872553,-0.00109078349 19.085379,1.03571147 20.0589798,2.71985424 C21.0325806,4.40399701 21.0337454,6.47961895 20.0620354,8.16485337 C19.0903253,9.85008779 17.2933664,10.8889075 15.3480556,10.89" id="Path" fill="#E5F1D4"></path>
                        <g id="Group" transform="translate(0.000000, 20.790000)" fill="#8DC63F">
                            <path d="M59.6972737,0.380452641 C59.5832458,0.380452641 59.4751415,0.404122935 59.3670372,0.412999295 L59.3670372,0.380452641 L23.7429646,0.380452641 C9.59018516,0.380452641 -3.55271368e-15,9.63553772 -3.55271368e-15,23.7741004 L-3.55271368e-15,43.5461931 C-3.55271368e-15,45.7289326 1.77123921,47.4983926 3.95617351,47.4983926 C6.14110781,47.4983926 7.91234702,45.7289326 7.91234702,43.5461931 L7.91234702,23.7741004 C7.91234702,13.9716398 13.847718,7.62948028 23.7429646,7.62948028 L59.3670372,7.62948028 L59.3670372,7.59693362 C59.4751415,7.60728937 59.5832458,7.62948028 59.6972737,7.62948028 C61.6994247,7.62948028 63.325432,6.00510633 63.325432,4.00496646 C63.325432,2.00482659 61.6994247,0.380452641 59.6972737,0.380452641" id="Path"></path>
                            <path d="M11.8722227,23.7741004 C11.8722221,25.7763433 13.4961256,27.3999398 15.5003811,27.401573 L51.7790031,27.401573 C52.7417639,27.401573 53.6650493,27.0192999 54.3455462,26.3389316 C55.0260431,25.6585633 55.4079474,24.7358938 55.4071627,23.7741004 C55.4071627,22.8125633 55.0254765,21.8902739 54.3450222,21.2102257 C53.664568,20.5301775 52.7415072,20.1481072 51.7790031,20.1481072 L15.4989002,20.1481072 C13.4952228,20.1497397 11.8715583,21.7724352 11.8707419,23.7741004 M79.1560496,4.00496646 L79.1560496,43.5432343 C79.1560496,45.7276785 80.9278043,47.4989784 83.1144444,47.5006117 C85.3013243,47.498979 87.0735035,45.7279179 87.0743202,43.5432343 L87.0743202,4.00496646 C87.0739276,2.95632532 86.6564597,1.95081127 85.9137963,1.20972627 C85.1711329,0.468641256 84.1641398,0.0527217161 83.1144444,0.0535055914 C80.9296791,0.0526892589 79.1576848,1.82091684 79.1560496,4.00348706" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}