import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox={props.viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="0%" y1="2.89792388%" x2="95.8914621%" y2="97.1020761%" id="linearGradient">
                    <stop stopColor="#3023AE" offset="0%"></stop>
                    <stop stopColor="#C86DD7" offset="100%"></stop>
                </linearGradient>
                <path d="M26.7287755,31.6122203 L26.7287755,4.86674293 L20.9052191,4.86674293 L20.9052191,16.2881364 C19.144609,14.933821 17.1582797,14.2115194 15.1719504,14.2115194 C9.16781867,14.2115194 4.96944082,19.087055 4.96944082,26.0392076 C4.96944082,27.5218585 5.1603913,28.9100608 5.51908451,30.1766658 C2.12694847,27.0015008 3.55271368e-14,22.4384077 3.55271368e-14,17.3677916 C3.55271368e-14,7.77582518 7.61115925,1.42108547e-14 17,1.42108547e-14 L34,1.42108547e-14 L34,17.3677916 C34,23.2632523 31.1247805,28.4726409 26.7287755,31.6122203 Z M10.8832849,26.0392076 C10.8832849,21.9762613 13.1404773,19.1773427 16.4359782,19.1773427 C18.0160128,19.1773427 19.5960475,19.8545004 20.9052191,21.2088159 L20.9052191,30.8695993 C19.5960475,32.2239147 18.0160128,32.9010724 16.4359782,32.9010724 C13.1404773,32.9010724 10.8832849,30.1021539 10.8832849,26.0392076 Z" id="path-2"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group">
                    <g id="d">
                        <mask id="mask-3" fill="white">
                            <use xlinkHref="#path-2"></use>
                        </mask>
                        <use id="Combined-Shape" fill="url(#linearGradient)" xlinkHref="#path-2"></use>
                    </g>
                </g>
            </g>
        </svg>
    );
}