import React, {useEffect} from 'react'
import { Button, Paper, Dialog, DialogActions, TextField, Slide } from '@material-ui/core';

export default function DesktopViewFrame(props) {

    const [finishedPaying] = global.get('finishedPaying')
    
    return (
        <div style={{background: 'var(--gray-background)', height:'100%', display:'flex', justifyContent:'center', alignItems:'stretch', maxWidth:1024, margin:'auto'}}>

            <div style={{padding: finishedPaying ? '16px 16px 16px 16px' : '16px 0px 16px 16px', display:'flex', flexGrow:3, flexBasis: '60%', minWidth:'60%'}}>
                <Paper style={{width:'100%'}}>{props.leftView}</Paper>
            </div>

            {!finishedPaying &&
                <div style={{padding:'16px', display:'flex', flexGrow:2, flexBasis: '40%'}}>
                    <Paper style={{width:'100%'}}>{props.rightView}</Paper>
                </div>
            }

        </div>
    )
}