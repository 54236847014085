import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import DashLogoStatic from '../../resources/images/DashLogoStatic';
import TextPayLogo from '../../assets/textpay-logo';
import CurblyLogo from '../../assets/curbly-logo';
import { Grid, Typography, TextField, Button, Slide } from '@material-ui/core';
import Stars from '../../components/stars';
import submitFeedback from '../../actions/submitFeedback';
import Shift4LogoFull from '../../assets/shift4-logo-full';
import HeartlandLogoFull from '../../assets/heartland-logo-full';
import fisLogo from '../../assets/fis-logo';
import msgLogo from '../../assets/msg-logo';

export default (props) => {
	const [foodRating, setFoodRating] = React.useState(0);
	const [serviceRating, setServiceRating] = React.useState(0);
	const [ isNative ] = global.get("isNative")
	const [check] = global.get("check")
	const [atmosphereRating, setatmosphereRating] = React.useState(0);
	const [review, setReview] = React.useState("");
	const [imHereEnabled] = global.get('imHereEnabled');
	const [restaurantLogoUrl] = global.get('restaurantLogoUrl');
	const [restaurantName] = global.get('restaurantName')
	const [feedbackEnabled] = global.get('feedbackEnabled')
	const [textToPayEnabled] = global.get('textToPayEnabled')
	const [payAtTableEnabled] = global.get('payAtTableEnabled')
    const [restaurant] = global.get('restaurant');
    const [ratings, setRatings] = useState([])
    const f = global.f;

	const [showFinalScreen, setShowFinalScreen] = React.useState(!feedbackEnabled);

	let logoWidth = 'calc(117px + (130 - 117) * ((100vw - 300px) / (1600 - 300)))';
	let logoHeight = 'calc(114px + (138 - 114) * ((100vw - 300px) / (1600 - 300)))';

	const useStyles = makeStyles((theme) => createStyles({
		gridContainer: {
			margin: '0px 5%',
		},
		ratingTitle: {
			fontSize: 'calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)))',
			margin: 0,
		},
		feedbackPage: {
			display: 'flex',
			flex: 1,
			margin: 0,
		},
		logoWrapper: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: 55,
			marginBottom: 35,
			alignItems: 'center',
		},
		scanAgain : {
			position: 'absolute',
			bottom: 30,
			left: 0,
			right: 0
		},
		gridItemCenter: {
			textAlign: 'center',
			marginTop: 20,
			marginBottom: 20,
		},
		emailInput: {
			width: '100%',
			lineHeight: 2,
			fontSize: 16
		},
		gridItemCenterDialog: {
			textAlign: 'center',
		},
		toggleButton: {
			marginLeft: 5,
			marginTop: 6,
		}, 
		receiptCell: {
			padding: '5px 5px 5px 5px',
			border: 'none'
		},
		dialogPaper: {
			minHeight: '40vh',
			minWidth: '80vw',
			overflow: 'hidden',
		},
	}));
	const classes = useStyles(props);

	const sendSubmitFeedback = () => {
		const debug = null;
		var accountRestaurantId = null

		if (Object.keys(ratings).length > 0){
			submitFeedback(check, ratings, review, accountRestaurantId, debug)
				.then((res) => {

				}).catch((err) => {
					console.error(err)
				})
        }
        
		let showFooter = textToPayEnabled || payAtTableEnabled ? true : false
		global.set({footer: showFooter, finishedPaying: true, footerTabIndex: -1})
		setShowFinalScreen(true)
	}
    
	useEffect(() => {
        global.set({ allowBack: false })
	}, [check]);
    
	const starColor = '#fdd835';
	const starSize = '25px';
	const starSpacing = '5px';
    const starCount = 5;
    
    const handleRatingChange = (obj) => {
        const newObj = { questionText: obj.questionText, fieldType: obj.fieldType, value: obj.value }
        let ratingsClone = [...ratings]
        ratingsClone = ratingsClone.filter((e)=> e.questionText !== obj.questionText)
        setRatings( [ ...ratingsClone, newObj ] )
    }

    let feedbackQuestions = f(restaurant, 'config.mobile.feedbackQuestions')
    if (!feedbackQuestions || !feedbackQuestions.length) {
        feedbackQuestions = [{ questionText: 'Food', fieldType: 'stars', value: null }, { questionText: 'Service', fieldType: 'stars', value: null }, { questionText: 'Atmosphere', fieldType: 'stars', value: null }, { questionText: 'Additional Feedback', fieldType: 'string', value: '' }]
    }

    const findRating = (questionText) => {
        let ratingIndex = ratings.findIndex((r) => r.questionText === questionText)
        if (ratingIndex !== -1  && ratings[ratingIndex] && ratings[ratingIndex]['value']) {
            return ratings[ratingIndex]['value']
        } else {
            return 0
        }
    }
    
	return (
		<Slide in direction="left">
			<div className={classes.feedbackPage}>
				{!showFinalScreen &&(
					<Grid container spacing={3} className={classes.gridContainer}>
						<Grid item xs={12}>
							<Typography variant="h5" style={{color:'var(--text)', margin:0}} align="center">
								{!imHereEnabled && "How was your visit?"}
							</Typography>
						</Grid>

                        {feedbackQuestions && feedbackQuestions.length &&
                            feedbackQuestions.map((item, index) => {

                                if (!item.questionText || !item.fieldType) {
                                    return
                                }

                                return (
                                    <Grid container direction='row' justify='space-between' alignItems='center' style={{padding: '8px 16px'}}>
                                        <Typography variant="subtitle1" className={classes.ratingTitle} autoCapitalize>
                                            {item.questionText}
                                        </Typography>

                                        {item.fieldType === 'stars' &&
                                            <Stars
                                                numberOfStars={starCount}
                                                rating={findRating(item.questionText)}
                                                starDimension={starSize}
                                                starRatedColor={starColor}
                                                starHoverColor={starColor}
                                                starSpacing={starSpacing}
                                                changeRating={(value) => handleRatingChange({ questionText: item.questionText, value: value, fieldType: item.fieldType })}
                                                name="food"
                                            />
                                        }
                                        {item.fieldType === 'string' &&
                                            <Grid container xs={12} direction='column' justify='center' alignItems='center' style={{padding: '0px 0px 8px 0px'}}>
                                                <TextField
                                                    id="outlined-full-width"
                                                    // label={item.questionText}
                                                    style={{width: '100%', margin: '0px 0px 8px 0px'}}
                                                    margin='none'
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(event) => handleRatingChange({ questionText: item.questionText, value: event.target.value, fieldType: item.fieldType })}
                                                    rows="4"
                                                    multiline
                                                />
                                            </Grid>
                                        }
						            </Grid>
                                )
                            })
                        }

						<Grid item xs={12} style={{ textAlign: 'center'}}>
							<Button variant="contained" color="primary" component="span" onClick={() => sendSubmitFeedback()}>
								<Typography variant="subtitle1">
									Submit Feedback
								</Typography>
							</Button>
						</Grid>

					</Grid>)}
				{showFinalScreen &&
					<Grid container align="center">
						<Grid item xs={12} className={classes.logoWrapper}>
							{(f(restaurant, 'branding.logo', false) || restaurantLogoUrl) && <img src={f(restaurant, 'branding.logo', false) ? f(restaurant, 'branding.logo', false) : restaurantLogoUrl + "logo.png"} width="225px" alt="logo"/>}
							{!restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && global.dashNow && <DashLogoStatic width={logoWidth} height={logoHeight} viewBox="-1, -1, 36, 36" />}
							{!restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && global.curbly && <CurblyLogo style={{width: '160px'}} />}
							{!restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && global.textPay && global.branding == 'dashnow' && <TextPayLogo width={160} style={{height: '36px', width: '160px'}} />}
							{!restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && global.textPay && global.branding == 'heartland' && <HeartlandLogoFull width={160} style={{height: '36px', width: '160px'}} />}
							{!restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && global.textPay && global.branding == 'shift4' && <Shift4LogoFull width={160} style={{height: '36px', width: '160px'}} />}
							{!restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && global.textPay && global.branding == 'fis' && <fisLogo width={160} style={{height: '36px', width: '160px'}} />}
							{!restaurantLogoUrl && !f(restaurant, 'branding.logo', false) && global.textPay && global.branding == 'msg' && <msgLogo width={160} style={{height: '36px', width: '160px'}} />}
							
						</Grid>
						<Grid item xs={12} className={classes.gridItemCenter}>
							<Typography variant="subtitle1" style={{ marginTop: 5}}>
								{(foodRating > 0 || serviceRating > 0 || atmosphereRating > 0 || review) && "Thank you for your feedback!"}
								{!global.textPay && !(foodRating > 0 || serviceRating > 0 || atmosphereRating > 0 || review) && `Thank you for visiting ${restaurantName}!`}
								{global.textPay && `Thank you for your payment.`}
							</Typography>
						</Grid>
					</Grid>
				}
			</div>
		</Slide>
	);
};