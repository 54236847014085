import React from 'react';
//import log from 'loglevel';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import theme from './theme';
import './css/app.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import withSplashScreen from './components/withSplashScreen';


ReactDOM.render(
	<Router>
		<ThemeProvider theme={theme('#7746C1')}>
			<CssBaseline />
			<Route path="/:checkId?" component={withSplashScreen(App)} />
			<NotificationContainer/>
		</ThemeProvider>,
	</Router>,
	document.querySelector('#root'),
);