import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ReactGA from "react-ga";
import { NotificationManager } from 'react-notifications'
import { ClickAwayListener, Grid, InputAdornment } from '@material-ui/core';
import checkUtils from '../../../util/checkUtils'
import log from 'loglevel'

//<EnterAmountModal label={'Tip Amount'} checkTotal={checkTotals.total} clickButton={clickedTipButton} show={setShowCustomTip} maxAmount={999}/>

export default (props) => {
	const f = global.f
	let [check] = global.get("check")
	let [restaurant] = global.get("restaurant")
    const [ isDesktopView ] = global.get('isDesktopView')
	const [amountValue, setAmountValue] = React.useState(0);
	const [amountFormatted, setAmountFormatted] = React.useState("");
	const [percentageFormatted, setPercentageFormatted] = React.useState("");
	const TIP_SELECTED_GA_ACTION = 'Tip Selected';

	const handleSetAmount = (e) => {
		let amount = Math.min(parseInt(e.target.value.replace(/\D/g, '')), props.checkTotal)
		if (!amount) amount = 0
		let percentage = amount / props.checkTotal * 100
		setValues(amount, percentage)
	}

	const handleSetPercentage = (e) => {
		let percentage = Math.min(parseInt(e.target.value.replace(/\D/g, '')) / 10, 100)
		if (!percentage) percentage = 0
		let amount = Math.round(percentage / 100 * props.checkTotal)
		setValues(amount, percentage)
	}

	const setValues = (amount, percentage) => {
		if (percentage > 0 && amount == 0) {
			amount = 1
		}
		setAmountValue(amount)
		setAmountFormatted(checkUtils.formatMoney(amount))
		setPercentageFormatted(percentage.toFixed(1))
	}

	const c = makeStyles((theme) => ({
		tipButton: {
			width: '100%',
			boxShadow: 'none',
			backgroundColor: global.f(restaurant, 'branding.primaryColor') ? global.f(restaurant, 'branding.primaryColor') : '#eeeeee',
			height: '100%',
            paddingTop: 10,
            '&:hover': {
                backgroundColor:global.f(restaurant, 'branding.primaryColor') ? global.f(restaurant, 'branding.primaryColor') : '#eeeeee'
            },
		},
		modalContainer: {
			width: '100%',
			height: '100%',
			background: 'var(--mask)',
			position: 'fixed',
			top: 0,
			left: 0,
            elevation: 100,
		},
		centeredModal: { // isDesktopView
			width: '500px',
			height: '200px',
            position: 'absolute',
			top: 'calc(50% - 100px)',
            left: 'calc(50% - 200px)',
            padding: 16,
            backgroundColor: 'rgba(96%, 96%, 96%, 0.925)',
            display:'flex',
            // flexDirection:'column',
            justifyContent:'space-around',
            alignItems:'center'
		},
		modalWrapper: { // !isDesktopView
			width: '100%',
			height: 'auto',
            position: 'absolute',
			left: 0,
			bottom: 0,
            right: 0,
            padding: 8,
            backgroundColor: 'rgba(96%, 96%, 96%, 0.925)',
            display:'flex',
            justifyContent:'space-around',
            alignItems:'center'
		},
	}))();

	const onKeyUp = (event) => { if (event.key === 13) props.clickButton(amountValue, "custom")}

	const [checked, setChecked] = React.useState(false)

	log.info(`checked = ${checked ? 'yes' : 'nope'}`)
	return (
		<div className={c.modalContainer}> 
			<ClickAwayListener onClickAway={() => props.show(false)}>
				<div className={isDesktopView ? c.centeredModal : c.modalWrapper}>
					<Grid container spacing={1} alignItems="center">
						<Grid item xs={12}>
							<Typography variant="body">{props.label}</Typography>
						</Grid>
						<Grid item xs>
							<TextField
								autoFocus
								placeholder="0.00"
								value={amountFormatted}
								onChange={handleSetAmount}
								onKeyUp={onKeyUp}
								InputProps={{
								  startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
							/>
						</Grid>

						<Grid item xs>
							<TextField
								placeholder="0.0"
								value={percentageFormatted}
								onChange={handleSetPercentage}
								onKeyUp={onKeyUp}
								InputProps={{
								  endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
							/>
						</Grid>

						<Grid item>
							<Button color="primary" variant="contained"
								onClick={() => {
									props.clickButton(amountValue, "custom")
									props.show(false);
									ReactGA.event({
										category: TIP_SELECTED_GA_ACTION,
										action: 'Custom',
										label: ((amountValue / props.checkTotal) * 100).toFixed(0) + "%"
									});
								}}>
								<Typography variant="subtitle1" >Done</Typography>
							</Button>
						</Grid>
					</Grid>

				</div>
			</ClickAwayListener>
		</div>
	)

}
