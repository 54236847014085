import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.width} viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="2b-specials" transform="translate(-184.000000, -11.000000)" fillRule="nonzero">
                    <g id="Group" transform="translate(98.000000, -20.000000)">
                        <g id="Group-6">
                            <g id="Group-3">
                                <g id="mobile-card" transform="translate(86.000000, 31.000000)">
                                    <path d="M25,23 L25,34 C25,34.552 24.552,35 24,35 L5,35 C4.448,35 4,34.552 4,34 L4,7 C4,6.448 4.448,6 5,6 L13,6 L13,2 L4,2 C1.791,2 0,3.791 0,6 L0,42 C0,44.209 1.791,46 4,46 L25,46 C27.209,46 29,44.209 29,42 L29,23 L25,23 Z M14.5,43 C13.122,43 12,41.878 12,40.5 C12,39.122 13.122,38 14.5,38 C15.878,38 17,39.122 17,40.5 C17,41.878 15.878,43 14.5,43 Z" id="Shape" fill={props.cellColor} /* this is the black that makes up the cell phone #545454 */></path>
                                    <path d="M43.964,0 L17.036,0 C15.913,0 15,0.947 15,2.111 L15,18.888 C15,20.053 15.913,21 17.036,21 L43.965,21 C45.087,21 46,20.053 46,18.889 L46,2.111 C46,0.947 45.087,0 43.964,0 Z M17.036,2 L43.958,2 C43.972,2.01 44,2.048 44,2.111 L44,6 L17,6 L17,2.111 C17,2.042 17.034,2.004 17.036,2 Z M43.964,19 L17.046,19.002 C17.034,18.996 17,18.958 17,18.889 L17,11 L44,11 L44,18.889 C44,18.958 43.966,18.996 43.964,19 Z" id="Shape" fill={props.cardColor} /* This is the purple color of the card #A47BD8 */></path>
                                    <path d="M28,14 L22,14 C21.448,14 21,14.448 21,15 C21,15.552 21.448,16 22,16 L28,16 C28.552,16 29,15.552 29,15 C29,14.448 28.552,14 28,14 Z" id="Path" fill={props.cardColor}></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}