import React from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Group from '../resources/images/Group';
import MenuIcon from '@material-ui/icons/Menu';
import { NotificationManager } from 'react-notifications';
import { Grid } from '@material-ui/core';
import TextPayLogo from '../assets/textpay-logo';
import CurblyLogo from '../assets/curbly-logo';
import DStyles from '../components/DStyles'
import ImHerePage from './pay/ImHerePage';
import HeartlandLogo from '../assets/heartland-logo';
import Shift4Logo from '../assets/shift4-logo';
import FisLogo from '../assets/fis-logo';
import MsgLogo from '../assets/msg-logo';
import ElavonLogo from '../assets/elavon-logo';

const useStyles = makeStyles((theme) => ({
	root: {
		'box-shadow': 'none'
	},
	menuButton: {
		height: 48,
		width: 48,
		marginRight: theme.spacing(2),
		zIndex: 1
	},
	title: {
		width: '100%',
		textAlign: 'center',
		paddingLeft: 45,
		paddingRight: 45,
		position: 'absolute',
		left: 0
	}
}));



export default (props) => {
	const classes = useStyles();
	const [ allowBack ] = global.get('allowBack');
    const [ check ] = global.get('check');
    const [ geo ] = global.get('geo');
	const [ restaurantName ] = global.get('restaurantName');
    const [restaurant] = global.get('restaurant')
    const [restaurantLogoUrl] = global.get('restaurantLogoUrl');
	const [ rightAlignHeaderLogo ] = global.get('rightAlignHeaderLogo');
	const [ restaurantPhone ] = global.get('restaurantPhone');
	const [ userIsWaiter ] = global.get('userIsWaiter');
	const [isNative] = global.get('isNative');
	const [topBottomPadding] = global.get('topBottomPadding');
	const [ menuOpen, setMenuOpen ] = React.useState(false);
	const [ burgerOpen, setBurgerOpen ] = React.useState(false);
	const [ anchorEl, setAnchorEl ] = React.useState(null);
	let [restaurantData] = global.get("restaurantData");
    const [ burgerAnchor, setBurgerAnchor ] = React.useState(null);
    const f = global.f;

	const c = makeStyles((theme) => ({
		centerLogo: {
			height: restaurantData && restaurantData.logoHeight ? restaurantData.logoHeight : 75,
			width: '100%',
			backgroundImage: (f(restaurant, 'branding.headerLogo', false) || restaurantLogoUrl) ? f(restaurant, 'branding.headerLogo', false) ? 'url(' + f(restaurant, 'branding.headerLogo', false)+ ')' : 'url(' + restaurantLogoUrl + "logo-header.png" + ')' : "",
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'auto 60px',
			maxHeight: 75,
		},
		rightLogo: {
			position: 'absolute',
			top: 0,
			right: 16
		},
		header: {
			boxShadow: 'none',
			marginTop: topBottomPadding,
			height: rightAlignHeaderLogo ? 57 : 75,
		},
		toolbar: {
			height: '100%'
		}
	}))();

	function showHideMenu(event) {
		if (!global.isProd) {
			setMenuOpen(!menuOpen);
			setAnchorEl(event.currentTarget);
		}
	}

	function showHideBurger(event) {
		setBurgerOpen(!burgerOpen);
		setBurgerAnchor(event.currentTarget);
	}

	function handleClose(activePage) {
		setAnchorEl(null);
		setMenuOpen(false);
		if (activePage) {
			global.set({ activePage });
		}
	}

	function formatPhoneNumber(phoneNumberString) {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
		var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
		  return '(' + match[1] + ') ' + match[2] + '-' + match[3]
		}
		return null
	}

	const goBack = () => {
        let newPage = {}
        if (global.textPay) {
            newPage.pageName = 'ReceiptPage'
        } else if (global.pageStack && global.pageStack.length > 0) {
			newPage = global.pageStack.pop();
			log.info(newPage);
		}
        global.set({ activePage: newPage.pageName, allowBack: global.pageStack.length > 0 });
        if (newPage.fn) newPage.fn();
	};
    
	const showWaiterMenu = () => false && !allowBack; //Disable server menu

    const [ activePage ] = global.get('activePage');
	const showRestaurantName = (ap) => [ 'ReceiptPage', 'MyBillPage', 'PaymentPage', 'EmailReceiptPage', 'PaymentCompletePage', 'FeedbackPage', 'CheckStackPage', 'ImHerePage' ].includes(ap);

	// log.info('ALLOWBACK: ' + allowBack);
	// log.info('useriswaiter: ' + typeof userIsWaiter);

	return (
		<div> 
			<AppBar position="fixed" color="secondary" className={c.header}>
				<Toolbar className={c.toolbar}>
					{allowBack && (
						<IconButton
							edge="start"
							className={classes.menuButton}
							color="primary"
							aria-label="Menu"
							onClick={() => {
								allowBack && goBack();
							}}
						>
							<KeyboardArrowLeft />
						</IconButton>
					)}
					{!allowBack && rightAlignHeaderLogo && (
            			<Grid item className={classes.menuButton} />
					)}
					<Container className={classes.title}>
						{!rightAlignHeaderLogo && (f(restaurant, 'branding.headerLogo', false) || restaurantLogoUrl) && 
							<>
								<Grid container direction='row' justify='center' alignItems="center" style={allowBack ? {marginRight: 50}  : {}}>
									<div className={c.centerLogo}></div>
									{/* <img src={restaurantLogoUrl + "logo-header.png"} height={restaurantData && restaurantData.logoHeight ? 90 : 75} style={{marginTop: 10}} /> */}
								</Grid>
							</>
						}
						{geo && restaurant.locationInfo.addressLine1 && 
							<Typography
								display="block"
								variant="overline"
								style={{ fontSize: 12, color: 'var(--purple)', lineHeight: 'normal' }}
							>{restaurant.locationInfo.addressLine1}</Typography>
						}
						{(rightAlignHeaderLogo || !(f(restaurant, 'branding.headerLogo', false) || restaurantLogoUrl)) && 
							<>
								{showRestaurantName(activePage) &&
									<Typography
										display="block"
										variant="overline"
										style={{ fontSize: 16, color: 'var(--purple)', lineHeight: 'normal' }}
									>{restaurantName}</Typography>
								}
								{showRestaurantName(activePage) && restaurant && restaurant.locationName &&
									<Typography
										display="block"
										variant="overline"
										style={{ fontSize: 12, color: 'var(--purple)', lineHeight: 'normal' }}
									>{restaurant.locationName}</Typography>
								}
							</>
						}
					</Container>
					<div>
						<IconButton className={c.rightLogo} edge="end" color="inherit" onClick={showHideMenu}>
							{(f(restaurant, 'branding.headerLogo', false) || restaurantLogoUrl) && rightAlignHeaderLogo && <img src={f(restaurant, 'branding.headerLogo', false) ? f(restaurant, 'branding.headerLogo', false) : restaurantLogoUrl + "logo-header.png"} height="34px" />}
							{!(f(restaurant, 'branding.headerLogo', false) || restaurantLogoUrl) &&  <>
								{global.dashNow &&
									<Group width={34} height={34} viewBox={'0, 0, 34, 34'} />
								}{global.textPay && global.branding == "dashnow" &&
									
									<div>{/* <TextPayLogo width={34} height={34} style={{ marginTop: 3,}} /> */}</div>
								}
								{global.textPay && global.branding == "heartland" &&
									<HeartlandLogo width={30} height={28} style={{ marginTop: 3,}} />
								}
								{global.textPay && global.branding == "shift4" &&
									<Shift4Logo width={34} height={34} style={{ marginTop: 3,}}/>
								}
								{global.textPay && global.branding == "fis" &&
									<FisLogo width={60} height={44} style={{ marginTop: 3,}}/>
								}
								{global.textPay && global.branding == "msg" &&
									<MsgLogo width={60} height={44} style={{ marginTop: 3,}}/>
								}
								{global.textPay && global.branding == "elavon" &&
									<ElavonLogo width={60} height={44} style={{ marginTop: 3,}}/>
								}
									{global.isDev && (
										<Menu
											id="menu-appbar"
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right'
											}}
											anchorEl={anchorEl}
											keepMounted
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right'
											}}
											open={menuOpen}
											onClose={() => {
												handleClose(activePage);
											}}
										>
											<MenuItem onClick={() => handleClose('CreateAccountPage')}>
												CreateAccountPage
											</MenuItem>
											<MenuItem onClick={() => handleClose('ScanQrCodePage')}>ScanQrCodePage</MenuItem>
											<MenuItem onClick={() => handleClose('ReceiptPage')}>ReceiptPage</MenuItem>
											<MenuItem onClick={() => handleClose('MyBillPage')}>MyBillPage</MenuItem>
											<MenuItem onClick={() => handleClose('PaymentPage')}>PaymentPage</MenuItem>
											<MenuItem onClick={() => handleClose('FeedbackPage')}>FeedbackPage</MenuItem>

											<MenuItem onClick={() => handleClose('ThankYouPage')}>ThankYouPage</MenuItem>
										</Menu>
									)}
								</>
							}
						</IconButton>
					</div>
				</Toolbar>
				{showRestaurantName && check && check.pickupDetails.phoneNumber &&
					<Divider light /> 
				}
			</AppBar>
		</div>
	);
};
