// https://reactjsexample.com/react-component-for-reading-qr-codes-from-webcam/

import React, { useEffect } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from "lodash";
import { Grid, Typography, TextField } from '@material-ui/core';

const KEY_CODE = {
	backspace: 8,
	left: 37,
	up: 38,
	right: 39,
	down: 40
};

export default (props) => {
	const fields = props.numberOfInputs
	const [values, setValues] = React.useState([]);
	const [autofocusIndex, setAutofocusIndex] = React.useState();
	let iRefs = [];
	for (let i = 0; i < fields; i++) {
		iRefs.push(React.createRef());
	}
	let id = +new Date();

	const c = makeStyles((theme) => ({
		root: {
			height: '100%'
		},
		wrapper: {
			height: '100%'
		},
		fallbackWrapper: {
			textAlign: "center",
		},
		fallbackInput: {
			// textAlign: "center",
			width: "80%",
			height: "12%",

		},
		inputWrapper: {
			display: 'flex',
			justifyContent: 'center',
			paddingRight: "3%",
			paddingLeft: "3%",
			marginLeft: "3%",
			marginRight: "3%",

		},
		fallbackSubmit: {
			marginTop: '5%'
		},
		singleInputWrapper: {
			padding: 2
		},
		input: {
			paddingLeft: '.0em',
			// paddingLeft: '.7em',
			paddingRight: '.0em',
			textAlign: 'center',
			verticalAlign: 'center',

		},
		image: {
			width: "65%",
			marginLeft: -3,
			marginTop: -85

		}

	}))();


	const validInput = value => {
		return (value.length >= fields) ? true : false
	}

	const handleChange = (e, index) => {

		let next;
		const value = e.target.value.toUpperCase();

		let valuesCopy = _.cloneDeep(values)
		if (value.length > 1) {
			let nextIndex = value.length + index - 1;
			if (nextIndex >= fields) {
				nextIndex = fields - 1;
			}
			next = iRefs[nextIndex];
			const split = value.split('');
			split.forEach((item, i) => {
				const cursor = index + i;
				if (cursor < fields) {
					valuesCopy[cursor] = item;
				}
			});
			setAutofocusIndex(nextIndex);
			setValues(valuesCopy);
		} else {
			next = iRefs[index + 1];
			valuesCopy[index] = value;
			setAutofocusIndex(index + 1);
			setValues(valuesCopy);
		}
		props.handler(valuesCopy.join(''))
		if (validInput(valuesCopy.join(''))) {
			props.submit(valuesCopy.join(''))
		} else if (next) {
			// console.log(next)
			next.current.focus();
		}

	}

	useEffect(() => {
		let val = []
		for (let x = 0; x < fields; x++) {
			log.info('6 times')
			val.push('')
			
		};
		setValues(val);
	}, []);



	const onKeyDown = (e, index) => {
		const prevIndex = index - 1;
		const nextIndex = index + 1;
		const prev = iRefs[prevIndex];
		const next = iRefs[nextIndex];
		const vals = [...values];
		switch (e.keyCode) {
			case KEY_CODE.backspace:
				e.preventDefault();
				if (values[index]) {
					vals[index] = '';
					if (prev) {
						prev.current.focus();
					}
					setAutofocusIndex(prevIndex);
					setValues(vals);
				} else if (prev) {
					vals[prevIndex] = '';
					prev.current.focus();
					setAutofocusIndex(prevIndex);
					setValues(vals);
				}
				break;
			case KEY_CODE.left:
				e.preventDefault();
				if (prev) {
					prev.current.focus();
					setAutofocusIndex(prevIndex);
					setValues(vals);
				}
				break;
			case KEY_CODE.right:
				e.preventDefault();
				if (next) {
					next.current.focus();
					setAutofocusIndex(nextIndex);
					setValues(vals);
				}
				break;
			case KEY_CODE.up:
			case KEY_CODE.down:
				e.preventDefault();
				break;
			default:
				break;
		}
	};


	return (
		<div className={c.root}>
			<Grid container className={c.fallbackWrapper}>
				<Grid item className={c.inputWrapper} xs={12}>
					{values.length > 0 && values.map((val, index) => (

						<Grid item className={c.singleInputWrapper}>
							<div>
								{fields % (index + 1) === 2 &&
									<Grid item style={{ display: 'inline-block', marginRight: '.2em', float: 'left', marginLeft: '-.2em', marginTop: 30 }}>
										<Typography>-</Typography>
									</Grid>
								}
							</div>
							<div>
								<TextField
									id="outlined-bare"
									autoFocus={index === autofocusIndex}
									className={c.fallbackInput}
									margin="normal"
									variant="outlined"
									key={`${id}-${index}`}
									data-id={`${id}-${index}`}
									value={val}
									type={props.type ? props.type  : 'text'}
									onChange={(e) => { handleChange(e, index) }}
									ref={iRefs[index]}
									onKeyDown={(e) => { onKeyDown(e, index) }}
									InputProps={{
										inputProps: { className: c.input },
										'aria-label': 'bare'
									}}
								/>

							</div>
						</Grid>
					))}
				</Grid>
			</Grid>
		</div>
	);
};
