import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/styles';
import { Table, TableRow,TableCell,TableBody, Dialog, DialogActions, Grid, Typography, TextField, Button, Slide } from '@material-ui/core';
import checkUtils from '../../util/checkUtils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Confetti from 'react-dom-confetti';
import log from 'loglevel'
import Present from '../../resources/images/Present';
import getCheckDetails from '../../actions/getCheckDetails'

export default (props) => {
	const f = global.f
	const [check] = global.get("check")
	const [loyaltyAccount] = global.get("loyaltyAccount")
	const [loyaltyProgram] = global.get("loyaltyProgram")
	const [newRewards] = global.get("newRewards")
	const [previousPaymentString] = global.get("previousPaymentString");
	const [paymentAmount] = global.get("paymentAmount");
	const [showConfetti, setShowConfetti] = useState(false);
	const [ openEarnedRewardDialog, setOpenEarnedRewardDialog] = useState(true);
	const [points, setPoints] = useState(0);
	const [imHereEnabled] = global.get('imHereEnabled');
    const [isDesktopView] = global.get('isDesktopView')
    const [geo] = global.get('geo')

	const confettiConfig = {
		angle: 90,
		spread: 33,
		startVelocity: "50",
		elementCount: 150,
		dragFriction: 0.105,
		duration: 4000,
		stagger: 0,
		width: "8px",
		height: "8px",
		colors: ["#ccc", "var(--purple-text)"],
	};

	const useStyles = makeStyles((theme) => createStyles({ 
		paymentCompletePage: {
			marginTop: window.innerHeight * 0.015,
			marginLeft: window.innerWidth * (isDesktopView ? 0.02 : 0.08),
			marginRight: window.innerWidth * (isDesktopView ? 0.02 : 0.08),
		},
		gridContainer: {
			margin: '0px 2%',
		},
		ratingTitle: {
			marginLeft: 12
		},
		toggleButton: {
			marginLeft: 28,
			marginTop: 6,
		},
		feedbackPage: {
			display: 'flex',
			flex: 1,
			margin: 0,
		},
		logoWrapper: {
			display: 'flex',
			flexDirection: 'column',
			marginTop: '2em',
			alignItems: 'center',
		},
		scanAgain : {
			position: 'absolute',
			bottom: 30,
			left: 0,
			right: 0
		},
		gridItemCenter: {
			textAlign: 'center',
			marginTop: 20,
			marginBottom: 20,
		},
		emailInput: {
			width: '100%',
			lineHeight: 2,
			fontSize: 16
		},
		gridItemCenterDialog: {
			textAlign: 'center',
		},
		toggleButton: {
			marginLeft: 5,
			marginTop: 6,
		}, 
		receiptCell: {
			padding: '5px 5px 5px 5px',
			border: 'none'
		},
		dialogPaper: {
			minHeight: '40vh',
			minWidth: '80vw',
			overflow: 'hidden',
		},
	}));
	const classes = useStyles(props);


	const nextPage = () => {
		if (imHereEnabled) {
			global.set({activePage: "ImHerePage"})
		} else {
			global.set({header: false, activePage: "FeedbackPage"})
		}
		
	};

	const getRewardsTotal = (rewardArr) => {
		let total = 0
		rewardArr.forEach(reward => {
			if (reward.rewardType == 'cash') {
				total += reward.value
			}
		});
		return total
	}

	const animRewardPts = () => {
		setTimeout(() => {
			if (loyaltyAccount) {
				setPoints(loyaltyAccount.rewardsBalance.points);
			}
		}, 500);
	}

	useEffect(() => {
		global.set({ allowBack: false })
		setTimeout(() => {
			if (newRewards) {
				setShowConfetti(true);
			}
		}, 750);
	}, [check]);

	useEffect(() => {
		animRewardPts();
	},[]);
	
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		if (geo) {
	// 			if (check !== undefined && check !== "") {
	// 				getCheckDetails(check._id).then((check) => {
	// 					let tmpChk = {...check, paidInFull:true}
	// 					global.set({
	// 						check: tmpChk,
	// 						header: true,
	// 						activePage: 'ReceiptPage',
	// 					})
	// 				})
	// 			}
	// 		}
	// 	},300)
	// },[geo]);

	return (
		<Slide in direction="left">
			<div className={classes.paymentCompletePage}>
				<Grid container>
					<Grid item xs={12} className={classes.gridItemCenterDialog}>
						<Typography variant="h6" style={{ fontSize: '1.5em', color: '#424242'}} >
							Payment Complete
						</Typography>
						
					</Grid>
					<Grid item xs={12} style={{textAlign:"center", padding: '8px', paddingTop: '12px'}}>
						<CheckCircleIcon color="primary" style={{fontSize: 50}} />
					</Grid>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell className={classes.receiptCell}>
									<Typography align="left" variant="body1" style={{fontSize: 15, color:'var(--text)'}} color="primary">
										Amount: 
									</Typography>
								</TableCell>
								<TableCell className={classes.receiptCell}>
									<Typography align="right" variant="body1" style={{fontSize: 15, color:'var(--text)'}} color="primary">
										${checkUtils.formatMoney(paymentAmount)}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className={classes.receiptCell}>
									<Typography align="left" variant="body1" style={{fontSize: 15, color:'var(--text)'}} color="primary">
										Card: 
									</Typography>
								</TableCell>
								<TableCell className={classes.receiptCell}>
									<Typography align="right" style={{fontSize: 15, color:'var(--text)'}} color="primary">
										{previousPaymentString}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Grid>
				<div style={{ height: 1, width: '100%', backgroundColor: '#ccc', margin: '8px 0px' }} />

				{loyaltyAccount && loyaltyProgram && 
					<div styl={{ display: 'flex' }}>
						<div style={{ width: '100%', flexDirection: 'column', flex: 1}}>
							{newRewards && newRewards.pointsEarned > 0 && newRewards.cashEarned <= 0 &&
								<Typography style={{ width: '100%', textAlign: 'center', margin: '10% auto 16px', fontSize: window.innerWidth * 0.055, color: 'var(--light-gray)' }}>You just earned <span style={{ color: 'var(--purple)', fontWeight: '500' }}>{newRewards.pointsEarned}</span> points!</Typography>
							}
							<div style={{ width: '100%', flexDirection: 'column', flex: 1 }}>
								<div style={{ display: 'inline-flex', width: '100%' }}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 6, alignItems: 'flex-end', justifyContent: 'center' }}>
										<div style={{ position: 'relative', display: 'flex', height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: 8 }}>
											<CircularProgress variant="static" size={80} thickness={5} value={100} style={{ strokeLinecap: 'round', color: '#E0E0E0', position: 'absolute', left: 0, right: 0 }} />
											<CircularProgress variant="static" size={80} thickness={5} value={points} style={{ strokeLinecap: 'round' }} />
											<div style={{ position: 'absolute', top: 'auto', bottom: 'auto', left: 0, right: 0, fontSize: 32, fontWeight: '600', color: 'rgba(0,0,0,0.6)', textAlign: 'center' }}>
												<div style={{ color: 'var(--purple-text)', lineHeight: 1 }}>{loyaltyAccount.rewardsBalance.points}</div>
												<div style={{ fontSize: 12, fontWeight: '300', lineHeight: 1 }}>points</div>
											</div>
										</div>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column', flex: 6, justifyContent: 'center', marginLeft: 8 }}>
										<div style={{ flexDirection: 'row' }}><span style={{ color: 'var(--purple-text)', fontWeight: '600' }}>{loyaltyProgram.rewardConversion.numberOfPoints - loyaltyAccount.rewardsBalance.points}</span> points to</div>
										<div style={{ flexDirection: 'row' }}>your next <span style={{ color: 'var(--purple-text)', fontWeight: '600' }}>${checkUtils.formatMoney(loyaltyProgram.rewardConversion.amount)}</span></div>
									</div>
								</div>
							</div>
						</div>
						<Grid container justify="center">
							<Typography style={{ fontSize: window.innerWidth * 0.04 }} variant="overline">Your Rewards Balance:</Typography>
							<Typography style={{ fontSize: window.innerWidth * 0.04, fontWeight: 600 }} color="primary" variant="overline">&nbsp;${checkUtils.formatMoney(loyaltyAccount.rewardsBalance.cash)}</Typography>
						</Grid>
					</div>
				}

				<div style={{ display: 'flex' }}>
					<Grid container direction='row' justify='center' style={{marginTop: '10%'}}>
						{imHereEnabled && !global.textPay && <>
								<Typography gutterBottom={true}>Let the restaurant know you’ve arrived!</Typography>
								<Button onClick={nextPage} variant='contained' size='medium' color='primary' style={{fontSize: 18, paddingLeft: 32, paddingRight: 32}} >I'M HERE</Button>
							</>
						}

						{!imHereEnabled &&
							<Button onClick={nextPage} variant='contained' size='medium' color='primary' style={{fontSize: 18, paddingLeft: 32, paddingRight: 32}} >CONTINUE</Button>
						}
					</Grid>
				</div>

				{newRewards && newRewards.cashEarned > 0 && //See if reward is returned from make payment
					<Dialog
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
						open={openEarnedRewardDialog}
						classes={{paper: classes.dialogPaper}}
					>
						<div style={{position: 'relative', display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', padding: 16}}>
							<div style={{borderRadius: '50%', backgroundColor: 'var(--purple-text)', padding: 16, marginBottom: 16}}>
								<Present width={96} />
							</div>
							<Typography style={{fontSize: 28, fontWeight: '600', color: 'rgba(0,0,0,0.6)', marginBottom: 8}}>Congratulations</Typography>
							<Typography variant='h6' style={{color: 'rgba(0,0,0,0.38)'}}>You just earned</Typography>
							<div style={{display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'center'}}>
								<div style={{flexDirection: 'column', flex: 0, justifyContent: 'flex-start'}}>
									<typography style={{fontSize: 24, color: 'var(--purple-text)'}}>$</typography>
								</div>
								<div style={{flexDirection: 'column', flex: 0, justifyContent: 'flex-start'}}>
									<typography style={{fontSize: 48, fontWeight: '500', lineHeight: 1, color: 'var(--purple-text)'}}>{checkUtils.formatMoney(newRewards.cashEarned)}</typography>
								</div>
							</div>
							<Typography variant='h6' style={{color: 'rgba(0,0,0,0.38)', marginBottom: 16}}>off your next meal</Typography>
							<div style={{textAlign: 'center', color: 'rgba(0,0,0,0.38)', marginBottom: 16}}>
								<Typography variant='subtitle2'>at participating</Typography>
								<Typography variant='subtitle2'>{loyaltyProgram.name} restaurants</Typography>
							</div>
							<Button 
								style={{width: '60%', backgroundColor: 'var(--purple-text)', color: '#fff', padding: '12px 16px', fontSize: 16, fontWeight: '400', lineHeight: 1}}
								onClick={() => setOpenEarnedRewardDialog(false)}
							>
								CONTINUE
							</Button>
							<Confetti active={showConfetti} config={confettiConfig} width='100%' height='100%' style={{position: 'absolute', top: 'auto', bottom: 0, left: 0, right: 0}} />
						</div>
					</Dialog>
				}

			</div>
		</Slide>
	);
};