import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'

export default async function getWorldpayToken(transactionId, accountId, debug) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** getWorldpayToken API ****')
		const url = `${global.dashServerUrl}/api/v1/gateway/getWorldpayToken`;

		const body = {
			transactionId,
            accountId
        }

        // console.log('in getWorldpayToken')
        // console.log(body)

		dashFetch(url, body, debug).then(({ res, json }) => {
			if (!json.success) {
                NotificationManager.warning('', json.errors, 3000)
				reject(json.errors)
			} else {
				resolve(json);
			}
		}).catch((err) => { reject(err) });
	})
}