import React, { useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/styles';
import { Grid, Typography, TextField, Button, Slide, Checkbox } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import EmailReceipt from '../../actions/emailReceipt';
import ReactGA from 'react-ga';
import updateOptInAccountRestaurant from '../../actions/updateOptInAccountRestaurant';

export default (props) => {
    const [check] = global.get("check")
    const [accountRestaurant] = global.get("accountRestaurant")
    const [optInEnabled] = global.get("optInEnabled")
	const [email, setEmail] = React.useState(localStorage.emailAddress ? localStorage.emailAddress : check.getVal('customer.email') ? check.getVal('customer.email') : '');	
	const [optInChecked, setOptInChecked] = React.useState(true);	
	const alwaysReceiveEmailedReceipt = React.useState(true);
	const GA_EMAIL_PAYMENT = "Email receipt"

	const useStyles = makeStyles((theme) => createStyles({
		gridContainer: {
			margin: '0px 2%',
		},
		emailReceiptPage: {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			alignItems: 'center',
			margin: 0,
		},
		emailInput: {
			width: '100%',
			lineHeight: 2,
			fontSize: 16
		},
		gridItemCenterDialog: {
			textAlign: 'center',
			margin: '15% 0px 10%',
			padding: '0px 10%'
		},
	}));
	const classes = useStyles(props);

	const validateEmail = (emailAddress) => (/^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress))

	const submit = () => {

		if (optInEnabled) {
			updateOptInAccountRestaurant(accountRestaurant, optInChecked )
		}

		if (validateEmail(email)) {
            localStorage.alwaysReceiveEmailedReceipt = true
            localStorage.emailAddress = email
            EmailReceipt(email, alwaysReceiveEmailedReceipt, check._id, false).then((res) => {}).catch((err) => { console.error(err) })
			global.set({activePage: "PaymentCompletePage"})
			ReactGA.event({
				category: GA_EMAIL_PAYMENT,
				action: "Emailed receipt"
			});
		} else {
			NotificationManager.warning("Please enter a valid email in order to receive your receipt!")
			ReactGA.event({
				category: GA_EMAIL_PAYMENT,
				action: "Invalid email address entered"
			});
		}
	};


	useEffect(() => {
		global.set({ allowBack: false })

	}, [check]);


	if (global.tryMe) {
		global.set({activePage: "PaymentCompletePage"})
	}

	return (
		<Slide in direction="left">
			<div className={classes.emailReceiptPage}>
				<div>
					<Grid item container xs={12} justify='center' className={classes.gridItemCenterDialog}>
						<Typography style={{ fontSize: window.innerWidth * 0.05 > 30 ? 30 : window.innerWidth * 0.05 }}>
							Please tell us where to
						</Typography>
						<Typography style={{ fontSize: window.innerWidth * 0.05 > 30 ? 30 : window.innerWidth * 0.05 }}>
							email your receipt
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.gridItemCenterDialog}>
						<TextField
							type="email"
							// autoFocus="true"
							autoComplete="email"
							className={classes.emailInput}
							placeholder={''}
							margin="normal"
							label="Email"
							value={email}
							onChange={(ev) => setEmail(ev.target.value)}
							onKeyUp={(event) => {if(event.key === 'Enter') submit();}}
						/>
					</Grid>
					{optInEnabled &&
						<Grid container justify="center" alignItems="center">
							<Checkbox
								checked={optInChecked}
								color="primary"
								onChange={()=>setOptInChecked(!optInChecked)}
								name="optIn"
							/>
				
							<Typography style={{marginLeft:'10px', textAlign:'left', fontSize:'.95em', color:"#484848"}}>I would like to receive special offers, <br/> discounts and announcements</Typography>
						</Grid>
					}
					<br/>	
					<br/>
					<Grid container direction='row' xs={12} justify='space-around' style={{margin: '8px 0px', padding: '0px 5%'}}>
						<Grid>
							<Button style={{textAlign: 'center', backgroundColor: 'gray', color: '#fff', padding: '8px 16px', fontSize: 16, fontWeight: '400', lineHeight: 1}} onClick={() => global.set({activePage: "PaymentCompletePage"})}>
								SKIP
							</Button>
						</Grid>
						<Grid>
							<Button style={{textAlign: 'center', backgroundColor: 'var(--purple)', color: '#fff', padding: '8px 16px', fontSize: 16, fontWeight: '400', lineHeight: 1, whiteSpace: 'nowrap'}} onClick={() => { submit();}}>
								SEND RECEIPT
							</Button>
						</Grid>
					</Grid>
				</div>
			</div>
		</Slide>
	);
};