import log from 'loglevel';
import dashFetch from './dashFetch';
import { NotificationManager } from 'react-notifications';

export default async (body, debug = false) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (debug) log.info('***** Update Account API ****');

			const url = `${global.dashServerUrl}/api/v1/account/userUpdateAccount`;
			if (debug) log.info(JSON.stringify(body));
			dashFetch(url, body, debug)
				.then(({ res, json }) => {
					if (json.success) {
						resolve({...json})
					} else {
						NotificationManager.warning('', json.errors, 3000)
					}
				})
				.catch((err) => {
					reject(err)
				});
		} catch (e) {
			log.info(e)
		}
	});
};
