import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import deleteEncryptedCard from '../actions/deleteEncryptedCard';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, Dialog, DialogActions,
    Table, TableBody, TableRow, TableCell, 
    IconButton } from '@material-ui/core';
import CardVisa from '../resources/images/payment-types/Card_Visa.js';
import CardDiscover from '../resources/images/payment-types/Card_Discover.js';
import CardAmex from '../resources/images/payment-types/Card_Amex.js';
import CardMastercard from '../resources/images/payment-types/Card_Mastercard.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardMaestro from '../resources/images/payment-types/Card_Maestro.js';
import CardAmazon from '../resources/images/payment-types/Card_Amazon.js';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotificationManager } from 'react-notifications';
import DStyles from '../components/DStyles'

export default (props) => {
    let cardIcons = {
        visa: <CardVisa />,
        discover: <CardDiscover />,
        american_express: <CardAmex />,
        master: <CardMastercard />,
        amazon: <CardAmazon />,
        maestro: <CardMaestro />,
    };


	let supportedPaymentTypes = []
    const trainingMode = false
    const cards = localStorage.getItem('savedCards') ? global.parseJSONArrSafe(localStorage.getItem('savedCards')) : [];
    const [ stateCards, setStateCards ] = useState(cards)
    const [cardIdBeingDeleted, setCardIdBeingDeleted ] = useState(null)
    const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
    const [ cardBeingDeleted, setCardBeingDeleted ] = useState(false)
    const [ isDesktopView ] = global.get('isDesktopView')
	
	//SIMILAR TO componentWillMount() function, only runs once
	useEffect(() => {
    },[]);
    
    const setShowDelete = (encryptedCard) => {
        setCardIdBeingDeleted(encryptedCard._id)
        setShowDeleteDialog(true)
    }


	const paymentTypeSupported = (type) => {
		return trainingMode ? true : (supportedPaymentTypes.indexOf(type) >= 0)
	}

	const getActiveClass = (type) => {
		if (paymentTypeSupported(type)) {
			return c.optionItem
		} else {
			return c.optionItemDisabled
		}
    }

    const handleDeleteEncryptedCard = async () => {
        setCardBeingDeleted(true)
        // console.log('friend o mine')
        try {
			let deleteCardResp = await deleteEncryptedCard(cardIdBeingDeleted, true)
			// console.log(deleteCardResp)
            NotificationManager.warning('', deleteCardResp.success ? 'Card was successfully deleted' : 'Card did not delete successfully', 3000)
            
            if (deleteCardResp.success) {
				let tempCards = []
                cards.forEach((card) => {
                    if (card._id.toString() !== cardIdBeingDeleted.toString()) {
                        tempCards.push(card)
                    }
				})
				
                localStorage.setItem('savedCards', JSON.stringify(tempCards))
				setStateCards(tempCards)
				
            } else {

            }
        } catch (e) {
            NotificationManager.warning('', "Failed to delete card")
        }
        setCardBeingDeleted(false)
        setShowDeleteDialog(false)
    }
    
    const c = makeStyles((theme) => ({
		paymentTable: {
			marginRight: 0,
			marginLeft: 0,
			marginTop: 10,
			marginBottom: '7em',
			width: '100%',
			// height: '100%',
			overflowY: 'hidden',
			'::webkitScrollbar': {
				width: '0 !important',
				display: 'none',
			},
			'& td': {
				'paddingTop': 15,
				'paddingBottom': 15,
			}
		},

		overlayContainer: {
			position: "absolute",
			top: "0px",
			right: "0px",
			left: "0px",
			bottom: "0px",
			background: "#E5E5E599",
			zIndex: 1999,
		},
		mobileWalletPaymentLoading: {
			position: "absolute",
			// top: "38%",
			// left: "50%",
			// right: "50%",
			zIndex: "2005",
			height: "140px",
			// borderRadius: "9px",
			left: "35%",
			// right: "50%",
			bottom:"50%",
			textAlign: "center",
			// padding: "16px",
			margin: "auto",
		},

		optionItem: {
			height: 64,
			padding: 0,
			width: '100%',
			'line-height': '50px',
			borderBottom: '1px solid #ccc',
		},
		//SORRY GOTTA GO FAST
		optionItemDisabled: {
			height: 64,
			padding: 0,
			width: '100%',
			'line-height': '50px',
			borderBottom: '1px solid #ccc',
		},

		paymentLabel: {
			fontSize: 10,
			paddingLeft: 10
		},

		paymentLabelSecondary: {},

		lastFour: {
			display: 'block',
			textAlign: 'center',
			color: 'rgba(0, 0, 0, 0.6)',
			marginBottom: 8,
			paddingLeft: 8
		},

		paymentIconsStandard: {
			justifyContent: 'center',
			paddingRight: 10
		},

		paymentIcons: {
			paddingTop: 0,
			paddingRight: 5,
			paddingBottom: 0,
			marginBottom: 0,
		},
		gridItemCenterDialog: {
			textAlign: 'center',
		},
	}))();

	const styles = `

		.logo-wrapper img {
			height: 60px !important;
		}
		.receipt-html-wrapper {
			overflow: scroll;
		}
	`

	return (
		<div style={{padding: "0 10px 64px 10px", marginTop: !isDesktopView ? '-25px' : 16, overflowY: "auto", height: "100%"}}>
			<DStyles styles={styles} />
			<Grid container >
				<Grid item xs={12}>
                    <Typography align="center" variant="h6">
                        Saved Payment Methods
                    </Typography>
				</Grid>

                <Grid container className={"scrolling-grid"}>
					<Table className={c.paymentTable}>
						<TableBody>
                            {/* RENDER EXISTING CARDS */}
                            {stateCards.map((card, index) => {
                                let typeStr = card.type.replace('american_express', 'amex');
                                let formattedType = typeStr
                                    .replace(/\w+/g, (str) => {
                                        return str.charAt(0).toUpperCase() + str.substr(1);
                                    })
                                    .replace(/[-]/g, ' ');
                                if(card.trainingMode){
                                    formattedType = card.displayName
                                }

                                return (
                                    <TableRow
                                        className={getActiveClass(card.type)}
                                        key={index}
                                    >
                                        <TableCell direction="column" justify="center" className={c.paymentIcons}>
                                            {cardIcons[card.type]}
                                        </TableCell>
                                        <TableCell align="left" className={c.paymentLabelSecondary}>
                                            <div className="lastFour">{' **** ' + card.lastFour}</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton onClick={() => { setShowDelete(card) }} aria-label="delete">
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Dialog 
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={showDeleteDialog}
				style={{height: '100%'}}
			>	
				<div style={{ height: '100%', overflowY: 'auto', margin:'20px 20px 0px 20px'}} >
					<Grid container alignContent="center" alignItems="center">
						<Grid item xs={12}>
							{ !cardBeingDeleted && 
								<Typography >
									Are you sure you want to delete this card?
								</Typography>
							}
							{ cardBeingDeleted && 
								<Grid container style={{paddingLeft: '25%'}}>
									<CircularProgress color="primary" />
								</Grid>
							}
						</Grid>
					</Grid>
				</div>
				<DialogActions style={{ marginTop: 0, height: '10%', justifyContent: 'center', }}>
					<Button 
						onClick={() => {
							handleDeleteEncryptedCard()
						}} 
						color="primary"
						style={{
							color: 'var(--light-gray)'
                        }}
                        disabled={cardBeingDeleted}
					>
						Yes
					</Button>
                    <Button 
						onClick={() => {
							setShowDeleteDialog(false)
						}} 
						color="primary"
						style={{
							color: 'var(--light-gray)'
                        }}
                        disabled={cardBeingDeleted}
					>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};