import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'

export default async function saveCardToken(type, token, lastFour, source, debug, expMonth = null, expYear = null, fullName = null, zipCode = null, oneTimeUse = false, restaurantId, addressLine1, achData, additionalTokenData) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** Save Card Token API ****')
		const url = `${source == 'vgs' ? global.vgsUrl : global.dashServerUrl}/api/v1/account/saveCardToken`;

		if (achData && achData.accountNumber) {
			delete achData.accountNumber
		}
		const body = {
			token,
			lastFour,
			type,
			paymentType: type == 'ach' ? 'ach' : '',
			source,
			expMonth,
            expYear,
            fullName,
            zipCode,
            oneTimeUse,
            restaurantId,
            addressLine1,
			ach: achData,
			additionalTokenData
        }

        // console.log('in savecardtoken')
        // console.log(body)

		dashFetch(url, body, debug).then(({ res, json }) => {
			if (!json.success) {
                NotificationManager.warning('', json.errors, 3000)
				reject(json.errors)
			} else {
                if (oneTimeUse === false) {
                    var savedCards = localStorage.savedCards ? global.parseJSONArrSafe(localStorage.getItem("savedCards")) : [];
                    savedCards.push(json.encryptedCard);
                    localStorage.setItem("savedCards", JSON.stringify(savedCards));
                }
				resolve({success:true, card: json.encryptedCard});
			}
		}).catch((err) => { reject(err) });
	})
}