import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import saveCardToken from '../../actions/saveCardToken';
import { NotificationManager } from 'react-notifications';
import '../../util/modalCSS.css'
import { Button, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';

export default (props) => {
    const [ ccToken, setCCToken ] = useState('');
    const [awaitingPaymentResponse, setAwaitingPaymentResponse] = useState(false);
    const [isOneTimeUseCard, setIsOneTimeUseCard] = useState(false)
    const [ fromDashboard ] = global.get('fromDashboard')

	const c = makeStyles((theme) => ({
		separator: {
			height: 1,
			width: '100%',
			backgroundColor: '#ccc',
        },
        formContent: {
            margin:'auto',
            marginTop:'10px',
            textAlign: 'left',
            position:'relative'
        },
        securityFAQ: {
			marginTop: '.11em',
			color: 'var(--light-gray)'
        },
        label: {
            paddingLeft:'8px',
            paddingTop:'10px',
            fontWeight: '1000',
            lineHeight: '18.59px',
            fontSize: '13px',
            color: '#555',
            fontFamily : 'Roboto, Arial, sans-serif',
            fontStretch:'ultra-expanded',
            letterSpacing:'0.14994px'
        },
        cardholderInput: {
            width:'306px !important',
            marginLeft:'8px'
        },
        zipCode: {
            marginLeft:'8px',
            position:'absolute',
            top: '237px',
            width: '234px !important',
            zIndex: 999,
            right:'0px'
        }
		}
    ))();

    useEffect(()=> {
        window.addEventListener('message', function(event) {
            var token = JSON.parse(event.data);
            var mytoken = document.getElementById('mytoken');
            mytoken.value = token.message;
            token.cardType = findCardType(token.message)
            token.lastFour = extractLastFour(token.message)
            token.expMonth = token.expiry.slice(4)
            token.expYear = token.expiry.substring(0, 4)
            token.source = 'cardConnect'
            setCCToken(token)
            }, false);
    },[])

    const handleSubmit = async () => {
        const zipCode = document.querySelector('#zipCode').value
        const fullName = document.querySelector('#customerFullName').value
        const tokenObj = ccToken;
        if (!zipCode || !fullName || !tokenObj) {
            return NotificationManager.warning('', 'Please complete all fields', 3000);
        }
        setAwaitingPaymentResponse(true)
        let resp;
        try {
            resp = await saveCardToken(tokenObj.cardType, tokenObj.message, tokenObj.lastFour, tokenObj.source, true, tokenObj.expMonth, tokenObj.expYear, fullName, zipCode, (isOneTimeUseCard || fromDashboard));
            if(!resp.success){
                NotificationManager.warning('Error', resp.errors )
            }
        } catch {
            NotificationManager.warning('Error', "An error occurred saving card info" )
        }
        setAwaitingPaymentResponse(false)
        window.closeAllPanels();
        if(resp && resp.card){
            props.next(resp.card)
        }
    }

    const extractLastFour = (token) => {
        let lastIndex = token.length;
        let startIndex = lastIndex - 4;
        return token.substring(startIndex, lastIndex)
    }

    const findCardType = (token) => {
        const cardTypeIdentifier = token.charAt(1)
        if(cardTypeIdentifier === "3"){
            return 'american_express'
        }
        if(cardTypeIdentifier === "4"){
            return 'visa'
        }
        if(cardTypeIdentifier === "5"){
            return 'master'
        }
        if(cardTypeIdentifier === "6"){
            return 'discover'
        }
    }

    //https://developer.cardconnect.com/guides/cardsecure
    // Consider the following information when handling tokens:

    // Tokens never expire. In the event that a card is re-swiped or manually keyed on a terminal a device, the same token is generated for that card.
    // Tokens can be alpha-numeric. The format of the token is driven by the specific key format used.
    // Credit card tokens are typically 16-digit numeric tokens, where: 
        // The 1st digit is always 9.
        // The 2nd and 3rd digits represent the 1st and 2nd digits of the card number. You can use these to determine the card type, as follows:
        // 3X= Amex
        // 4X= Visa
        // 5X= Mastercard
        // 6X= Discover
        // The last 4 digits of the token represent the last 4 digits of the card.
        // Electronic check (ACH) tokens are typically 16-digit numeric tokens, where: 
        // The 1st digit is always 9.
        // The 2nd and 3rd digits represent the 1st and 2nd digits of the routing number.
        // The last 4 digits of the token represent the last 4 digits of the account number.

    const URLencodedCSS = 'input+%7B%0D%0Aborder%3A+1px+solid%3B+%0D%0Abackground-color%3A+white%3B+%0D%0Acolor%3A+black%3B+%0D%0Aheight%3A+30px%3B+%0D%0Aborder-color%3A+%23bbb3b9+%23c7c1c6+%23c7c1c6%3B%0D%0Amargin-bottom%3A10px%3B%0D%0Apadding%3A+6px+12px%3B%0D%0A%7D%0D%0Alabel+%7B%0D%0Atext-transform%3A+uppercase%3B%0D%0Afont-weight%3A+bold%3B%0D%0Aline-height%3A18.59px%3B%0D%0Afont-size%3A13px%3B%0D%0Acolor%3A+%23555%3B%0D%0Afont-family+%3A+Roboto%2C+Arial%2C+sans-serif%3B%0D%0Aletter-spacing%3A0.14994px%3B%0D%0A%7D%0D%0A%23ccnumfield+%7B%0D%0Awidth%3A+280px%3B%0D%0A%7D%0D%0Aselect+%7B%0D%0Aheight%3A44px%3B%0D%0Amargin-bottom%3A10px%3B%0D%0A%7D%0D%0A%23ccexpirymonth%7B%0D%0Awidth%3A130px%3B%0D%0Aheight%3A+44px%3B%0D%0Aborder%3A+1px+solid%3B+%0D%0Aborder-color%3A+%23bbb3b9+%23c7c1c6+%23c7c1c6%3B%0D%0Acontent%3A%27month%27%3B%0D%0A%7D%0D%0A%23ccexpiryyear%7B%0D%0Aborder%3A+1px+solid%3B%0D%0Awidth%3A144px%3B%0D%0Aheight%3A+44px%3B+%0D%0Aborder-color%3A+%23bbb3b9+%23c7c1c6+%23c7c1c6%3B%0D%0Acontent%3A%27year%27%0D%0A%7D%0D%0A'
    const queryParams = 'formatinput=true&useexpiry=true&usemonthnames=true&usecvv=true&tokenizewheninactive=true'

    const url = global.isProd ? `https://fts.cardconnect.com:8443` : `https://fts-uat.cardconnect.com`
	return (
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'relative'}}>
            <div style={{width:'320px'}}>

                <div className={c.formContent}>
                    <form name="order" method="post" style={{margin:'auto', display:'inline-block'}}>

                        <label className={c.label}>CARDHOLDER NAME</label>
                        <input className={c.cardholderInput} type="text" id="customerFullName"></input>

                        <iframe title="cardconnect" id="tokenframe3" name="tokenframe" src={`${url}/itoke/ajax-tokenizer.html?${queryParams}&css=${URLencodedCSS}`} frameBorder="0" scrolling="no" width="320" height="220" style={{margin:'auto'}}></iframe>
                        
                        <input type="hidden" name="mytoken" id="mytoken"/>
                        <input type="hidden" name="myexpiry" id="myexpiry"/>
                        <input type="hidden" name="myerror" id="myerror"/>

                        <label className={c.label} style={{position:'absolute', top:'209px', zIndex:99, left:'72px'}}>ZIP CODE</label>
                        <input type='tel' id="zipCode" name="zipCode" className={c.zipCode} maxLength="5"/>

                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', zIndex:99, width:'100%'}}>
                            {!fromDashboard &&
                                <FormControlLabel
                                    label={<span style={{fontSize:'13px', alignSelf:'center'}}>Save card</span>}
                                    control={
                                        <Checkbox checked={!isOneTimeUseCard} onChange={()=>setIsOneTimeUseCard(!isOneTimeUseCard)} color="primary" inputProps={{ 'aria-label': 'Retain card for future purchases' }}/>
                                    }
                                />
                            }
                            <Button disabled={awaitingPaymentResponse} variant="contained" color="primary" onClick={handleSubmit} style={{width:'300px', marginBottom:16, marginTop:2}}>{ awaitingPaymentResponse ? <CircularProgress size={24} style={{color:'white'}}/> : 'ADD CARD'}</Button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
	);
};