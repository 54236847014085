import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.width} viewBox="0 0 159 177" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="CityCenterEATS_JustC_mark" transform="translate(-227.000000, -284.000000)">
                    <g id="Group" transform="translate(227.220600, 283.999500)">
                        <path d="M92.046,40.042 C65.19,40.042 44.925,60.549 44.925,88.384 C44.925,116.216 64.702,136.725 92.046,136.725 C107.915,136.725 119.146,130.134 125.004,122.563 L157.967,145.515 C146.245,162.361 123.784,176.766 92.046,176.766 C38.578,176.766 0,139.409 0,88.384 C0,37.356 39.554,0 92.046,0 C117.926,0 142.829,10.255 154.792,26.612 L122.565,50.296 C116.706,43.948 104.743,40.042 92.046,40.042" id="Fill-1" fill="#3AAB47"></path>
                        <path d="M103.7355,107.9419 C101.1925,109.2119 98.0445,110.0419 94.3015,110.0419 C82.0515,110.0419 73.1915,100.8529 73.1915,88.3839 C73.1915,75.9129 82.2695,66.7259 94.3015,66.7259 C96.2305,66.7259 98.1105,66.9419 99.8695,67.3179 L116.4245,55.1319 C110.5475,51.1469 102.5565,48.7859 94.3015,48.7859 C70.7845,48.7859 53.0635,65.5229 53.0635,88.3839 C53.0635,111.2439 70.3475,127.9809 94.3015,127.9809 C105.2975,127.9809 113.7745,124.1059 119.4965,118.8669 L103.7355,107.9419 Z" id="Fill-3" fill="#84BF41"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}