import React, {useState, useEffect} from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Drawer } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { NotificationManager } from 'react-notifications';
import checkUtils from '../../../util/checkUtils'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import _ from "lodash";
import ReactGA from "react-ga";
import EnterAmountModal from '../sections/EnterAmountModal';


export default (props) => {
	const f = global.f
	let [check] = global.get("check")
	let [restaurant] = global.get("restaurant")
	let debug = true
	const [hasNonDashPayments] = global.get("hasNonDashPayments");
	const [tipsEnabled] = global.get("tipsEnabled");
	const [tipPercentagesEnabled] = global.get("tipPercentagesEnabled");
	const [customTipEnabled] = global.get("customTipEnabled");
	const [noTipButtonEnabled] = global.get("noTipButtonEnabled");
	const [customTip, setCustomTip] = React.useState(0);
	const [showCustomTip, setShowCustomTip] = useState(false);
	const [showPartialPayment, setShowPartialPayment] = useState(false);
	const [customTipFormatted, setCustomTipFormatted] = React.useState("");
	const onMyBillPage = props.page === "MyBillPage"
	const onReceiptPage = props.page === "ReceiptPage"
	const [ loyaltyProgram ] = global.get('loyaltyProgram');
	const [ loyaltyAccount ] = global.get('loyaltyAccount');
    const [ hasPersonIdentifiers ] = global.get('hasPersonIdentifiers');
    const [selectedPaymentMethod] = global.get('selectedPaymentMethod')
    const [ showGooglePayDesktop ]  = global.get('showGooglePayDesktop')
	const [orderTerminology] = global.get('orderTerminology')
    const [ isDesktopView ] = global.get('isDesktopView')
    const [ allowPartialPayments ] = global.get('allowPartialPayments')
	const TIP_SELECTED_GA_ACTION = 'Tip Selected';

	let checkTotals = check.myTotals
	if (onMyBillPage) {
		checkTotals = check.myTotals
	}

	const clickedTipButton = (tipAmount, name="") => {
        log.info("TIP : " + tipAmount);
		log.info("tipButtonName: " + name)

		check.myTotals.tipAmountSelected = tipAmount
		check.myTotals.tipButtonName = name
        if (props.isDesktopView) {
            if (!selectedPaymentMethod) {
                NotificationManager.warning("", 'Please select a payment method', 3000);
            } else if (selectedPaymentMethod == 'googlePay') {
               global.set({showGooglePayDesktop:true})
            } else {
                global.set({activePanel: 'confirmPaymentPanel'})
            }
        }
        
		check.myTotals.isPartialPayment = false
		if (onMyBillPage) { // if the user is on the select items receipt page
			let checkWithTip = checkUtils.updateTotals(check, tipAmount)
			log.info(checkWithTip)
			// global.pageStack.push({ pageName: props.page, fn: moveBackToYourBill })
			global.pageStack.push({ pageName: props.page, fn: null })
			checkWithTip = checkUtils.applyServiceCharge(checkWithTip, selectedPaymentMethod ? selectedPaymentMethod.paymentType : '')
			global.set({ allowBack: true, check: checkWithTip, finalizedItemChoices: true })
			if (loyaltyProgram && !loyaltyAccount) {
				global.set({ header: false, footer: false, activePage: "LoyaltyWrapper" })	
			} else {
				global.set({ activePage: "PaymentPage" })
			}
		} else if (!check.hasLockedItemsByOthers || hasNonDashPayments) { // if others have already selected items, redirect to select items tab
			check.items.forEach((item) => {
				item.locked = true
				item.lockedByMe = true
				item.lockedBy = localStorage.firstName + " " + localStorage.lastName
				item.lockedByInitials = localStorage.initials
			})
			let checkWithTip = checkUtils.updateTotals(check, tipAmount)
			checkWithTip = checkUtils.applyServiceCharge(checkWithTip, selectedPaymentMethod ? selectedPaymentMethod.paymentType : '')
			global.pageStack.push({ pageName: props.page, fn: null })
			if (loyaltyProgram && !loyaltyAccount) {
				global.set({ allowBack: true, check: checkWithTip, lockedAll: true, header: false, footer: false, activePage: "LoyaltyWrapper" })
			} else {
				global.set({ allowBack: true, check: checkWithTip, lockedAll: true, activePage: "PaymentPage" })
			}			
		}
		
		toggleShowTip(false);
		
	}

    const clickedPartialAmount = (amt, name="") => {
		let checkClone = checkUtils.setPartialAmount(check, amt)
		checkClone = checkUtils.applyServiceCharge(checkClone, selectedPaymentMethod ? selectedPaymentMethod.paymentType : '')
        if (isDesktopView) {
            global.set({check: checkClone, activePanel: 'confirmPaymentPanel' })
        } else {
            global.set({check: checkClone, activePage: 'PaymentPage', allowBack: true,})
        }
        setShowPartialPayment(false)
    }

	const toCurrency = (number) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2
		})
		return formatter.format(number / 100);
	}

	const c = makeStyles((theme) => ({
		root: {
            height: '100%',
            width: '100%',
		},
		tipButton: {
			width: '100%',
			boxShadow: 'none',
			backgroundColor: global.f(restaurant, 'branding.primaryColor') ? global.f(restaurant, 'branding.primaryColor') : '#eeeeee',
			height: '100%',
            paddingTop: 10,
            '&:hover': {
                backgroundColor:global.f(restaurant, 'branding.primaryColor') ? global.f(restaurant, 'branding.primaryColor') : '#eeeeee'
            },
		},
		tipButtonPrimary: {
			width: '100%',
			boxShadow: 'none',
			backgroundColor: global.f(restaurant, 'branding.primaryColor') ? global.f(restaurant, 'branding.primaryColor') : 'var(--purple)',
			height: '100%',
            paddingTop: 10,
            '&:hover': {
                backgroundColor:global.f(restaurant, 'branding.primaryColor') ? global.f(restaurant, 'branding.primaryColor') : 'var(--purple)'
            },
		},
		tipButtonText: {
            lineHeight: 1,
            color: (global.f(check,'restaurant.branding.primaryColor')) ? '#FFFFFF' : 'var(--text)',
		},
		tipButtonTextAmount: {
			color: (global.f(check,'restaurant.branding.primaryColor')) ? '#FFFFFF' : 'var(--text)',
			lineHeight: 1,

		},
		modalContainer: {
			width: '100%',
			height: '100%',
			background: 'var(--mask)',
			position: 'fixed',
			top: 0,
			left: 0,
            elevation: 100,
		},
		modalWrapper: {
			width: '100%',
			height: 'auto',
            position: 'absolute',
			left: 0,
			bottom: 0,
            right: 0,
            padding: props.textPayMobile ? 0 : isDesktopView ? 16 : 8,
            backgroundColor: 'rgba(96%, 96%, 96%, 0.925)',
		},
	}))();

	const toggleShowTip = (value) => {
		if (value != showCustomTip) {
			setShowCustomTip(value);
		}
    }

    const btnDisabled = props.isDesktopView && props.disableButtons ? true : false

	const tipButtonPercentages = check.restaurant.config.mobile.tipButtonPercentages 
	const tipPercentageLeft = (typeof tipButtonPercentages.left ===  "number") ? tipButtonPercentages.left * .01 : .25;
	const tipPercentageCenter = (typeof tipButtonPercentages.center === "number") ? tipButtonPercentages.center * .01 : .20;
	const tipPercentageRight = (typeof tipButtonPercentages.right === "number") ? tipButtonPercentages.right * .01 : .15
	const tipPercentageLeftStr = tipPercentageLeft > 0 ? (tipPercentageLeft * 100).toFixed(0) + '%' : "CASH"
	const tipPercentageCenterStr = tipPercentageCenter > 0 ? (tipPercentageCenter * 100).toFixed(0) + '%' : "CASH"
	const tipPercentageRightStr = tipPercentageRight > 0 ? (tipPercentageRight * 100).toFixed(0) + '%' : "CASH"
	const tipBasisAmount = check.restaurant.config.mobile.tipsIncludeTax ? (checkTotals.items + checkTotals.taxes) : checkTotals.items
    const autoTip = f(check, "totals.autoTip", 0) > 0


	return (
		<Grid container spacing={1} style={{padding: 8}}>
			{tipsEnabled && <>
				<Grid item xs={12} align="center">
					<Typography variant="button" style={{color: 'var(--text)', lineHeight: 1.5}}>{ props.isDesktopView && !selectedPaymentMethod ? 'Please select a payment method' : props.isDesktopView ? 'Select a tip' :  autoTip ? "Tip already applied" : (tipPercentagesEnabled? "Please select a tip to begin" : "Please enter a tip to begin")}</Typography>
				</Grid>
				{tipPercentagesEnabled && !autoTip &&
					<Grid container item align="center" spacing={1} style={{height: 80}}>
						<Grid item xs={4} align="center">
							<Button variant="contained" component="span" disabled={btnDisabled} className={c.tipButton} onClick={() => {
									clickedTipButton(Math.round(tipBasisAmount * tipPercentageLeft, 2), "left");
									ReactGA.event({
										category: TIP_SELECTED_GA_ACTION,
										action: 'Left',
										label: tipPercentageLeftStr,
									});
									}}>
								<div>
									<Typography variant="h6" className={c.tipButtonText}>{tipPercentageLeftStr}</Typography>
									<Typography variant="overline" className={c.tipButtonTextAmount}>${checkUtils.formatMoney(tipBasisAmount * tipPercentageLeft)}</Typography>
								</div>
							</Button>
						</Grid>
						<Grid item xs={4} align="center">
							<Button variant="contained" component="span" disabled={btnDisabled} className={c.tipButton} onClick={() => {
								clickedTipButton(Math.round(tipBasisAmount * tipPercentageCenter, 2), "center")
								ReactGA.event({
									category: TIP_SELECTED_GA_ACTION,
									action: 'Center',
									label: tipPercentageCenterStr
								});
							}}>
								<div>
									<Typography variant="h6" className={c.tipButtonText}>{tipPercentageCenterStr}</Typography>
									<Typography variant="overline" className={c.tipButtonTextAmount}>${checkUtils.formatMoney(tipBasisAmount * tipPercentageCenter)}</Typography>
								</div>
							</Button>
						</Grid>
						<Grid item xs={4} align="center">
							<Button variant="contained" component="span" disabled={btnDisabled} className={c.tipButton} onClick={() => { 
								clickedTipButton(Math.round(tipBasisAmount * tipPercentageRight, 2), "right"); 
								ReactGA.event({
									category: TIP_SELECTED_GA_ACTION,
									action: 'Right',
									label: tipPercentageRightStr
								});
								} }>
								<div>
									<Typography variant="h6" className={c.tipButtonText}>{tipPercentageRightStr}</Typography>
									<Typography variant="overline" className={c.tipButtonTextAmount}>${checkUtils.formatMoney(tipBasisAmount * tipPercentageRight)}</Typography>
								</div>
							</Button>
						</Grid>
					</Grid>
				}
				{(customTipEnabled || noTipButtonEnabled) && 
					<Grid container item align="center" spacing={1} style={{height: 80}}>
						{customTipEnabled &&
							<Grid item xs={(noTipButtonEnabled && !autoTip) ? 8 : 12} align="center">
								<Button variant="contained" component="span" disabled={btnDisabled} className={c.tipButton} onClick={() => { 
										toggleShowTip(true); 
									}}
								>
									<Typography variant="subtitle1" className={c.tipButtonText}>{autoTip ? 'Additional Tip' : 'Custom Tip'}</Typography>
								</Button>
							</Grid>
						}
						{noTipButtonEnabled && !autoTip && 
							<Grid item xs={customTipEnabled ? 4 : 12} align="center">
								<Button variant="contained" component="span" disabled={btnDisabled} className={c.tipButton} onClick={() => { 
										clickedTipButton(0, "noTip")
									}}
								>
									<Typography variant="subtitle1" className={c.tipButtonText}>Cash Tip</Typography>
								</Button>
							</Grid>
						}
					</Grid>
				}

			</>}
			{(!tipsEnabled || (tipsEnabled && autoTip)) && <>
				<Grid item container spacing={1}>
					<Grid item xs>
						<Button disabled={isDesktopView && !selectedPaymentMethod} variant="contained" color="primary" className={c.tipButtonPrimary} component="span" style={{fontSize:'1.4em', height: 60}} onClick={()=> {clickedTipButton(0, !tipsEnabled ? "tipNotEnabled" : "autoTip")}}>
							<Typography variant="subtitle1" className={c.tipButtonText}>{`${allowPartialPayments ? 'Pay In Full' : global.textPay ? `Pay ${orderTerminology}` : 'Pay'}`}</Typography>
						</Button>
					</Grid>
					{allowPartialPayments &&
						<Grid item xs>
							<Button disabled={isDesktopView && !selectedPaymentMethod} variant="contained" className={c.tipButton} component="span" style={{fontSize:'1.4em', height: 60, backgroundColor:'var(--light-gray)'}} onClick={()=> setShowPartialPayment(true)}>
								<Typography color="secondary" variant="subtitle1" className={c.tipButtonText}>{`Pay Partial`}</Typography>
							</Button>
						</Grid>
					}
				</Grid>
			</>}
			{showCustomTip && customTipEnabled &&
                <EnterAmountModal label={'Tip Amount'} checkTotal={checkTotals.total} clickButton={clickedTipButton} show={setShowCustomTip}/>
            }
            {showPartialPayment &&
                <EnterAmountModal label={'Partial Payment'} checkTotal={checkTotals.total} clickButton={clickedPartialAmount} show={setShowPartialPayment} minAmount={0} maxAmount={checkTotals.total}/>
            }
		</Grid>
	)

}
