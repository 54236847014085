import React from 'react';

export default (props) => {
    return (
        <svg width="72" height="48" version="1.1" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g fillRule="evenodd">
                <rect x="0" y="0" width="72" height="48" rx="10" ry="10" fill="#486070" strokeWidth="1.9282" />

                <path d="M432.1,423.2c0-3,0-5.6,0-8.6c0-2.4,1.2-4.1,3.7-4c4.8,0.7,11.5,1.3,16.3,0.4c6.3-1.3,10.8-5.7,13.4-11.8    c3.7-8.5,6.2-15.4,7.8-20l-47.5-117.6c-0.8-2-1-5.7,3-5.7h16.6c3.2,0,4.5,2,5.1,4l34.4,95.6l32.9-95.6c0.7-2,2.1-4,5.1-4h15.6    c3.9,0,3.7,3.7,3,5.7l-47.1,121.4c-6.1,16.2-14.2,41.8-32.5,46.3c-9.2,2.4-20.8,1.5-27.5-1.3C432.7,426.9,432.1,424.7,432.1,423.2    z" fill="#FAFAFA" />
                <path d="M409.9,373.9c0,2.2-1.8,4-4,4h-11.7c-2.5,0-4.2-1.9-4.6-4l-1.2-8c-5.4,4.6-12,8.5-19.2,11.3    c-13.8,5.3-29.7,6.3-43.1-2.1c-9.7-6-14.9-17.7-14.9-29.7c0-9.4,2.9-18.5,9.3-25.3c8.5-9.2,20.8-13.7,35.7-13.7    c9,0,21.8,1,31.2,4.1v-16c0-16.3-6.8-23.4-25-23.4c-13.9,0-24.4,2.1-39.1,6.6c-2.3,0.1-3.7-1.7-3.7-3.9v-9.2c0-2.2,1.9-4.3,3.9-5    c10.5-4.6,25.5-7.5,41.3-8c20.7,0,45.2,4.7,45.2,36.4L409.9,373.9L409.9,373.9z M387.3,350.5v-24.2c-7.9-2.2-20.9-3.1-25.9-3.1    c-8,0-16.7,1.9-21.2,6.7c-3.4,3.6-5,8.7-5,13.8c0,6.5,2.2,12.9,7.4,16.1c6.1,4.1,15.4,3.6,24.2,1.1    C375.4,358.5,383.4,354.3,387.3,350.5z" fill="#FAFAFA" />
                <path d="M243.7,252.1c-13.8,0-28.3,6.4-39.5,16.4l-1.2-8.5c-0.3-2.2-2.1-4-4.6-4h-12c-2.2,0-4,1.8-4,4    c0,54.5,0,108.9,0,163.4c0,2.2,1.8,4,4,4h15.7c2.2,0,4-1.8,4-4v-56.4c9.9,8.9,22.9,13.9,36.7,13.9c32.8,0,52.3-28.5,52.3-63.8    C294.9,283.9,282.3,252.1,243.7,252.1z M259.4,353.4c-6.1,6-12.9,7.7-23.2,7.7c-9.2,0-21.2-4.8-30.1-11.4v-66.9    c9.4-7.2,21.3-11,31.3-11c26,0,33,20.4,33,43.8C270.4,331.3,267.5,345.3,259.4,353.4z" fill="#FAFAFA" />


                <path d="M540.3,483.8c-50.5,37.3-123.9,57.2-187,57.2c-88.4,0-168.2-32.7-228.4-87.1c-4.8-4.3-0.5-10.1,5.1-6.8    c65,37.8,145.5,60.6,228.5,60.6c56.1,0,117.6-11.6,174.4-35.7C541.5,468.4,548.8,477.6,540.3,483.8z" />
                <path d="M561.4,459.8c-6.5-8.2-42.8-3.9-59.1-2c-5,0.6-5.7-3.7-1.2-6.8c28.9-20.3,76.4-14.5,81.9-7.7    c5.5,6.8-1.5,54.4-28.5,77.1c-4.2,3.5-8.1,1.6-6.3-3C554.2,502.2,567.8,468.1,561.4,459.8z" />

                <path d="M432.1,423.2c0-3,0-5.6,0-8.6c0-2.4,1.2-4.1,3.7-4c4.8,0.7,11.5,1.3,16.3,0.4c6.3-1.3,10.8-5.7,13.4-11.8    c3.7-8.5,6.2-15.4,7.8-20l-47.5-117.6c-0.8-2-1-5.7,3-5.7h16.6c3.2,0,4.5,2,5.1,4l34.4,95.6l32.9-95.6c0.7-2,2.1-4,5.1-4h15.6    c3.9,0,3.7,3.7,3,5.7l-47.1,121.4c-6.1,16.2-14.2,41.8-32.5,46.3c-9.2,2.4-20.8,1.5-27.5-1.3C432.7,426.9,432.1,424.7,432.1,423.2    z" />
                <path d="M409.9,373.9c0,2.2-1.8,4-4,4h-11.7c-2.5,0-4.2-1.9-4.6-4l-1.2-8c-5.4,4.6-12,8.5-19.2,11.3    c-13.8,5.3-29.7,6.3-43.1-2.1c-9.7-6-14.9-17.7-14.9-29.7c0-9.4,2.9-18.5,9.3-25.3c8.5-9.2,20.8-13.7,35.7-13.7    c9,0,21.8,1,31.2,4.1v-16c0-16.3-6.8-23.4-25-23.4c-13.9,0-24.4,2.1-39.1,6.6c-2.3,0.1-3.7-1.7-3.7-3.9v-9.2c0-2.2,1.9-4.3,3.9-5    c10.5-4.6,25.5-7.5,41.3-8c20.7,0,45.2,4.7,45.2,36.4L409.9,373.9L409.9,373.9z M387.3,350.5v-24.2c-7.9-2.2-20.9-3.1-25.9-3.1    c-8,0-16.7,1.9-21.2,6.7c-3.4,3.6-5,8.7-5,13.8c0,6.5,2.2,12.9,7.4,16.1c6.1,4.1,15.4,3.6,24.2,1.1    C375.4,358.5,383.4,354.3,387.3,350.5z" />
                <path d="M243.7,252.1c-13.8,0-28.3,6.4-39.5,16.4l-1.2-8.5c-0.3-2.2-2.1-4-4.6-4h-12c-2.2,0-4,1.8-4,4    c0,54.5,0,108.9,0,163.4c0,2.2,1.8,4,4,4h15.7c2.2,0,4-1.8,4-4v-56.4c9.9,8.9,22.9,13.9,36.7,13.9c32.8,0,52.3-28.5,52.3-63.8    C294.9,283.9,282.3,252.1,243.7,252.1z M259.4,353.4c-6.1,6-12.9,7.7-23.2,7.7c-9.2,0-21.2-4.8-30.1-11.4v-66.9    c9.4-7.2,21.3-11,31.3-11c26,0,33,20.4,33,43.8C270.4,331.3,267.5,345.3,259.4,353.4z" />



            </g>
        </svg>
    );
}