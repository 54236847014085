import log from 'loglevel';
import getToken from './getToken';
import { NotificationManager } from 'react-notifications';
import initAccount from './initAccount';

export default async function(url, body, debug) {
	return new Promise(async (resolve, reject) => {
		let res = {};
		try {
			if (debug) log.info(`FETCHING FROM: ${url}`);
			if (debug) log.info('BODY');
			if (debug) log.info(body);
			if (!localStorage.accountId) {
				await initAccount()
			}
			let token = localStorage.token;
			const currentTime = Math.floor((new Date()).getTime() / 1000);
			const tokenTimeout = localStorage.tokenTimeout;
			const refreshTimeout = localStorage.refreshTimeout;
			
			if ((tokenTimeout < currentTime) || !token) {
				token = await getToken(debug);
			}

			if (token) {
				let options = {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						'x-access-token': token,
					},
					body: JSON.stringify(body),
					cache: "no-store",
				}
				fetch(url, options, debug).then((res) => {
					if (debug) log.info(`STATUS: ${res.status}`);
					if (debug) log.info(`RESPONSE:`);
					if (debug) log.info(JSON.stringify(res));
					if (res.status === 401) {
						log.info("401 ERROR")
						res.json().then(async (json) => {
							if (debug) log.info("JSON RESPONSE");
							if (debug) log.info(json);
							localStorage.setItem("token", "")
							await initAccount()
							window.location.reload()
							reject(json.userMessage);
						}).catch((err) => {
							reject(err.message);
						})
					}
					if (!res) {
						if (debug) console.error('(dashFetch) Could not fetch endpoint');
					} else if (res.ok) {
						res.json().then((json) => {
							if (debug) log.info("JSON RESPONSE");
							if (debug) log.info(json);
							if (json.userMessage) {
								NotificationManager.warning('', json.userMessage, 3000);
							}
							resolve({ res: res, json: json })
						}).catch((err) => {
							reject(err.message);
						})
					} else {
						reject("An error occurred trying to connect to the Dash Server");
					}
				}).catch((err) => {
					reject(err.message);
				});
			}
		} catch (err) {
			if (debug) console.error(err);
			reject(err);
		}
	})
}