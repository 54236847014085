import React, {useState, useEffect} from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import Grid from '@material-ui/core/Grid';
import checkUtils from '../../../util/checkUtils'
import DStyles from '../../../components/DStyles'
import { Typography } from '@material-ui/core';
import Paid from '../../../assets/Paid';
import moment from 'moment'
import { NotificationManager } from 'react-notifications';

const ENTIRE_BILL_TAB = 0

export default (props) => {
	const f = global.f
	let [check] = global.get("check");
	const [hasNonDashPayments] = global.get("hasNonDashPayments");
	const [totalOnlyMode] = global.get("totalOnlyMode");
    const [displayItemModifiers] = global.get("displayItemModifiers");
	const [displayItemName] = global.get("displayItemName");
	const [displayItemDescription] = global.get("displayItemDescription");
    const [tipPercentagesEnabled] = global.get("tipPercentagesEnabled");
	const [checkCancelled] = global.get("checkCancelled");
	const [orderTerminology] = global.get('orderTerminology')
    const [restaurant] = global.get('restaurant')
    let [activePage] = global.get("activePage")
	let [geo] = global.get('geo');
    const [renderItems, setRenderItems] = useState(displayItemModifiers ? check.items : check.itemsGrouped)
    const [finishedPaying] = global.get('finishedPaying')
    const [isDesktopView] = global.get('isDesktopView')
    const showCurrencyPrefix = global.f(restaurant,'config.mobile.showCurrencyPrefix', false)
    const currency = global.f(restaurant, 'currency', '')
	const phone = restaurant.getVal('locationInfo.phone')
    
	if (activePage === "CheckStackPage") {
		check = props.check
    }
    
    let tabIndex = props.tabIndex
    useEffect(()=> {
        if (check) {
            const i = displayItemModifiers ? check.items : check.itemsGrouped
            
            if (tabIndex !== ENTIRE_BILL_TAB) {
                i = check.itemsBeingPaid
            }

            if (i) {
                setRenderItems(i)
            }
        }
    }, [check])

	let containerHeight = "100%"
	if (props.containerHeight) {
		containerHeight = props.containerHeight
	}
	let discountsAndDutiesRows = 0;
	// log.info("check.totals: " + JSON.stringify(check.totals))

    let checkTotals = check.myTotals

	if (!check.paidInFull) {
        checkTotals = check.myTotals
    } else {
		checkTotals = check.paidItemsTotals
	}

	const autoTip = f(check, "totals.autoTip", 0)
	const autoTipNotInSubTotal = f(check, "totals.autoTipNotInSubTotal", false)

	if (checkTotals.taxes > 0) discountsAndDutiesRows++
	if (checkTotals.discounts < 0) discountsAndDutiesRows++
	if (checkTotals.other > 0) discountsAndDutiesRows++
	if (autoTip > 0 && autoTipNotInSubTotal) discountsAndDutiesRows++
	if ((hasNonDashPayments && (checkTotals.prevPaidAmount > 0))) discountsAndDutiesRows++
	if (check.paidInFull) discountsAndDutiesRows += 2
	if (check.paidInFull && check.totals.loyaltyRewardsAmount > 0 ) discountsAndDutiesRows++

	const checkTotalsTotalRowPercent = 8
	let heightOfTotalsTable = (checkTotalsTotalRowPercent * (2 + discountsAndDutiesRows))

	const maxItemTableHeight = 100 - heightOfTotalsTable

	const styles = `
		.receiptTableGrid {
			width: 100%;
			padding-top: 5px;
			overflow: auto;
			max-height: ${maxItemTableHeight}%;
		}

		.receiptTableGrid::-webkit-scrollbar {
			display: none;
			width: 0 !important
		}

		.receiptTable {
            width: 100%;
            font-size:4;
		}

		.receiptTable tbody tr:last-child > td {
			border-bottom: none;
		}

		overlay {
			position:'absolute';
			height: '100%';
			width: '100%';
			zIndex: 10;
			/* marginLeft: '15%'; */
			backgroundColor: 'rgba(0,0,0,0)';
		}

		@media (max-device-height: 600px){
			.overlay {
				padding-right: 12%;
			}
		}

		@media (min-device-width: 400px){
			.overlay {
				padding-left: 6.5%;
			}
		}

		.receiptTable td {
			line-height: 1.2;
			padding-left: 4px;
			padding-right: 0px;
			padding-bottom: 5px;
			padding-top: 5px;
			font-size: 15px;
			border-bottom: none;
			/* color: var(--purple-text); */
		}
		.receiptTable th:last-child, .receiptTable td:last-child {
				padding-right: 4px;
				text-align: right;
		}

		.receiptTableTotals tbody td {
			padding: 2px 2px 2px 4px;
			border: none;
		}

		.receiptTableTotalsRow {
			borderTop: 1px solid var(--divider);
		}

		.receiptTableTotalsRow td {
			color: var(--purple-text);
			font-weight: 500;
			font-size: 18px;
		}

		.receiptTableTotals th:last-child, td:last-child {
			padding-right: 10px;
			text-align: right;
		}
	`

	const s = {
		totalsCell: {
			paddingRight: 4,
			paddingLeft: 2,
		},
		grandTotalCell: {
			paddingRight: 4,
			paddingLeft: 2,
			fontSize: 25,
		},
    }

	let tipsHeight = tipPercentagesEnabled ? 120 : 90

    const prevPaidAmount = global.f(check, 'myTotals.prevPaidAmount', 0)
    const balanceDue = global.f(check, 'totals.due', 0)

	return (
		<Grid container spacing={0} style={{height: props.textPayMobile ? `calc(100% - ${tipsHeight}px)` : '100%', display:'flex', flexDirection:'column', justifyContent:'space-between', flexWrap:'nowrap', overflow: 'hidden'}}>
			{check.paidInFull && 
				<div style={{position:'absolute', top:'calc(50% - 123px)', left: 'calc(50% - 153px)'}}>
					<div container style={{height:'100%'}} align="center" onClick={() => NotificationManager.warning("", "The check has already been paid in full!", 3000)} className={"overlay"}>
						<Paid width={307} height={247} opacity={0.3} fill={f(check, 'totals.paidDashNow', false)? 'var(--purple)' : 'darkgray'}/>
					</div>
				</div>
			}

			{checkCancelled && <>
				<div style={{display: 'flex', justifyContent:'space-between', padding:4, borderBottom:'1px solid #ccc', borderTop:'1px solid #ccc'}}>
					<Typography color="inherit" variant="subtitle1" style={{  }}>
						This {orderTerminology} has been cancelled by the merchant. Please contact them{phone ? ` at ${phone} ` : ''} for further details.
					</Typography>
				</div>
			</>}

			{(!totalOnlyMode || global.textPay) && !checkCancelled && <>
				<DStyles styles={styles} />
				{/* RECEIPT ITEMS TABLE */}
				<Grid item xs={12} className={"receiptTableGrid"}>
                    {/* Header */}
					<div style={{display: props.invoicePage ? 'none' : 'flex', justifyContent:'space-between', padding:4, borderBottom:'1px solid #ccc'}}>
						<span style={{fontWeight:500}}>{!geo ? '# ': ''}{check.checkNumberDisplay ? check.checkNumberDisplay : check.orderNumber ? check.orderNumber : check.checkNumber}</span>
						<span style={{fontWeight:500}}> {check.createdAt ? moment(check.createdAt).format('MM/DD') : ''}</span>
					</div>


					<div style={{display: !props.invoicePage ? 'none' : 'flex', justifyContent:'space-between', padding:4, borderBottom:'1px solid #ccc'}}>
						<span style={{fontWeight:500, fontSize: props.textPayMobile ? 12 : ''}}>Item</span>
						<span style={{fontWeight:500, fontSize: props.textPayMobile ? 12 : ''}}> Amount</span>
					</div>

					<Table className={"receiptTable"}>
						<TableBody style={{ width: '100%' }}>
							{renderItems.map((item, index) => {
								let itemDisplayName = ""
								if(displayItemName && item.name){
									itemDisplayName += item.name
								}
								if(displayItemDescription && item.description){
									if(itemDisplayName != ""){
										itemDisplayName += " - "
									}
									itemDisplayName += item.description
								}
								//Don't show the row if there is no name or price
								if (!((!itemDisplayName || itemDisplayName.trim() == "") && (!item.price || item.price == 0))) {
									return (
										[
											<TableRow key={index+"item"}>
												{check.persists  && <TableCell style={{fontSize: props.textPayMobile ? 12 : ''}}>{item.qty && item.qty > 1 ? item.qty : ' '}<span style={{fontWeight:500}}>{check.orderNumber}</span></TableCell>}
												{!check.persists && <TableCell style={{fontSize: props.textPayMobile ? 12 : '', whiteSpace: 'pre-line', overflowWrap: 'anywhere'}}>{item.qty && item.qty > 1 ? item.qty : ' '} {itemDisplayName}</TableCell>}
												<TableCell style={{fontSize: props.textPayMobile ? 12 : ''}}>{(item.price && item.price != 0) ? checkUtils.formatMoney(item.price) : ""}</TableCell>
											</TableRow>,
											<>{check.persists &&
												<TableRow key={index+"item"}>
													<TableCell style={{fontSize: props.textPayMobile ? 12 : ''}}>{itemDisplayName}</TableCell>
													<TableCell style={{fontSize: props.textPayMobile ? 12 : ''}}></TableCell>
												</TableRow>}
											</>,
											<TableRow key={index+"modifier"}>
												{displayItemModifiers && item.modifierString && <>
													<TableCell style={{fontSize: '1em', paddingLeft: '2em'}}>{'- '}{item.modifierString}</TableCell>
													<TableCell></TableCell>
												</>}
											</TableRow>
										]
									);
								}
							})}
						</TableBody>
					</Table>
				</Grid>

                {!checkCancelled && (global.textPay ?
                    <div>
						{check.customerMessage && <div style={{paddingTop: 8, paddingBottom: 8}}>{check.customerMessage}</div>}
						<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingTop: 4}}>
							<div style={{fontSize:12, fontWeight:500, paddingRight:8}}>SubTotal</div>
							<div style={{fontSize:18, fontWeight:  600, color: `${f(restaurant, 'branding.primaryColor', 'var(--purple)')}`}}>
								<span style={{fontSize:12, position:'relative', bottom:4}}> {(showCurrencyPrefix && currency == 'USD') ? 'USD$' : '$'} </span>{checkUtils.formatMoney(check.totals.subTotal)}
							</div>
						</div>
						{ check.totals.discountsAndDuties.discountsTotal > 0 && 
							<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingTop: 4}}>
								<div style={{fontSize:12, fontWeight:500, paddingRight:8}}>Discount</div>
								<div style={{fontSize:18, fontWeight:  600, color: `${f(restaurant, 'branding.primaryColor', 'var(--purple)')}`}}>
									<span style={{fontSize:12, position:'relative', bottom:4}}> {(showCurrencyPrefix && currency == 'USD') ? 'USD$' : '$'} </span>{checkUtils.formatMoney(check.totals.discountsAndDuties.discountsTotal)}
								</div>
							</div>}
						{ check.totals.discountsAndDuties.taxesTotal > 0 && 
							<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingTop: 4}}>
								<div style={{fontSize:12, fontWeight:500, paddingRight:8}}>Tax</div>
								<div style={{fontSize:18, fontWeight:  600, color: `${f(restaurant, 'branding.primaryColor', 'var(--purple)')}`}}>
									<span style={{fontSize:12, position:'relative', bottom:4}}> {(showCurrencyPrefix && currency == 'USD') ? 'USD$' : '$'} </span>{checkUtils.formatMoney(check.totals.discountsAndDuties.taxesTotal)}
								</div>
							</div>}
						{ check.totals.ccServiceChargeAmount > 0 && 
							<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingTop: 4}}>
								<div style={{fontSize:12, fontWeight:500, paddingRight:8}}>Previous {restaurant.getVal('config.mobile.serviceCharges.ccServiceChargeLabel')}</div>
								<div style={{fontSize:18, fontWeight:  600, color: `${f(restaurant, 'branding.primaryColor', 'var(--purple)')}`}}>
									<span style={{fontSize:12, position:'relative', bottom:4}}> {(showCurrencyPrefix && currency == 'USD') ? 'USD$' : '$'} </span>{checkUtils.formatMoney(check.totals.ccServiceChargeAmount)}
								</div>
							</div>}
						{check.totals.achServiceChargeAmount > 0 && 
							<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingTop: 4}}>
								<div style={{fontSize:12, fontWeight:500, paddingRight:8}}>Previous {restaurant.getVal('config.mobile.serviceCharges.achServiceChargeLabel')}</div>
								<div style={{fontSize:18, fontWeight:  600, color: `${f(restaurant, 'branding.primaryColor', 'var(--purple)')}`}}>
									<span style={{fontSize:12, position:'relative', bottom:4}}> {(showCurrencyPrefix && currency == 'USD') ? 'USD$' : '$'} </span>{checkUtils.formatMoney(check.totals.achServiceChargeAmount)}
								</div>
							</div>}
						{check.totals.paid > 0 && 
							<div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', paddingTop: 4}}>
								<div style={{fontSize:12, fontWeight:500, paddingRight:8}}>Paid</div>
								<div style={{fontSize:18, fontWeight:  600, color: `${f(restaurant, 'branding.primaryColor', 'var(--purple)')}`}}>
									<span style={{fontSize:12, position:'relative', bottom:4}}> {(showCurrencyPrefix && currency == 'USD') ? 'USD$' : '$'} </span>{checkUtils.formatMoney(check.totals.paid)}
								</div>
							</div>}
						{!check.persists &&
							<div style={{borderTop: '1px solid var(--divider)', display:'flex', justifyContent:'flex-end', alignItems:'center', paddingTop: 4}}>
								<div style={{fontSize:12, fontWeight:500, paddingRight:8}}>Balance Due</div>
								<div style={{fontSize:18, fontWeight:  600, color: `${f(restaurant, 'branding.primaryColor', 'var(--purple)')}`}}>
									<span style={{fontSize:12, position:'relative', bottom:4}}> {(showCurrencyPrefix && currency == 'USD') ? 'USD$' : '$'} </span>{checkUtils.formatMoney(balanceDue)}
								</div>
							</div>
						}
                    </div>
                    :
                    <>
                        {/* RECEIPT TOTALS TABLE */}
                        <Grid item xs={12} style={{height: `${heightOfTotalsTable}%`, width: '100%', overflowY: 'auto', borderTop: '1px solid var(--divider)'}}>
                            <Table className={"receiptTableTotals"} style={{marginTop: 5}}>
                                <TableBody>
                                    <TableRow key={"check-subtotal"}>
                                        <TableCell>SubTotal</TableCell>
                                        <TableCell style={s.totalsCell}>{checkUtils.formatMoney(checkTotals.items)}</TableCell>
                                    </TableRow>
                                    {checkTotals.taxes > 0 &&
                                        <TableRow key={"check-taxes"}>
                                            <TableCell>Taxes</TableCell>
                                            <TableCell style={s.totalsCell}>{checkUtils.formatMoney(checkTotals.taxes)}</TableCell>
                                        </TableRow>}
                                    {checkTotals.discounts < 0 &&
                                        <TableRow key={"check-discounts"}>
                                            <TableCell>Discounts</TableCell>
                                            <TableCell style={s.totalsCell}>{checkUtils.formatMoney(checkTotals.discounts)}</TableCell>
                                        </TableRow>}
                                    {checkTotals.other > 0 &&
                                        <TableRow key={"check-other"}>
                                            <TableCell>Other</TableCell>
                                            <TableCell style={s.totalsCell}>{checkUtils.formatMoney(checkTotals.other)}</TableCell>
                                        </TableRow>}
                                    {(!check.paidInFull && autoTip > 0 && autoTipNotInSubTotal) &&
                                        <TableRow key={"check-autoTip"}>
                                            <TableCell>Gratuity</TableCell>
                                            <TableCell style={s.totalsCell}>{checkUtils.formatMoney(autoTip)}</TableCell>
                                        </TableRow>}
                                    {(!check.paidInFull && (checkTotals.prevPaidAmount > 0)) &&
                                        <TableRow key={"check-prevpayment"}>
                                            <TableCell>Previous Payment</TableCell>
                                            <TableCell style={s.totalsCell}>- {checkUtils.formatMoney(checkTotals.prevPaidAmount)}</TableCell>
                                        </TableRow>}
                                    <TableRow className={"receiptTableTotalsRow"} key={"check-total"}>
                                        <TableCell>Total</TableCell>
                                        <TableCell style={s.totalsCell}>$ {checkUtils.formatMoney(checkTotals.total)}</TableCell>
                                    </TableRow>
                                    {check.paidInFull && 
                                    <>
                                        {(check.totals.tips > 0 || autoTip > 0) &&
                                            <TableRow className={"receiptTableTotalsRow"} key={"check-tip"}>
                                                <TableCell>Tip</TableCell>
                                                <TableCell style={s.totalsCell}>$ {checkUtils.formatMoney(check.totals.tips + (autoTip > 0 ? autoTip : 0))}</TableCell>
                                            </TableRow>
                                        }
                                        {check.totals.loyaltyRewardsAmount > 0 &&
                                            <TableRow className={"receiptTableTotalsRow"} key={"check-loyalty"} style={{ borderTop: '1px solid var(--divider)' }}>
                                                <TableCell style={s.grandTotalCell}>LOYALTY</TableCell>
                                                <TableCell style={s.grandTotalCell}>- $ {checkUtils.formatMoney(check.totals.loyaltyRewardsAmount)}</TableCell>
                                            </TableRow>}
                                        <TableRow className={"receiptTableTotalsRow"} key={"check-total-paid"} >
                                            <TableCell style={s.grandTotalCell}>TOTAL PAID:</TableCell>
                                            <TableCell style={s.grandTotalCell}>$ {checkUtils.formatMoney(checkTotals.totalPaid)}</TableCell>
                                        </TableRow>
                                    </>
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    
                    </>
				)}
			</>}
			{(totalOnlyMode && !global.textPay && !checkCancelled) && <>

				<Grid container item xs={12} style={{ height: '90%'}} align="center">
					{check.paidInFull && !isDesktopView &&
						<div style={{display:'flex', justifyContent:'space-between', padding:4, borderBottom:'1px solid #ccc', width:'100%', height:'30px'}}>
							<span style={{fontWeight:500}}># {check.orderNumber ? check.orderNumber : check.checkNumber}</span>
							<span style={{fontWeight:500}}> {check.createdAt ? moment(check.createdAt).format('MM/DD') : ''}</span>
						</div>
					}
					<Grid item xs={12} style={{height: '10%'}}>
						<Typography variant="h5" style={{ paddingTop:'10%', paddingBottom:'10px', color: '#757575', alignSelf: 'center' }}>
							Total
						</Typography>
					</Grid>
					<Grid item xs={12} container justify="center" style={{ marginTop: 2 }}>
						<Typography color="primary" variant="subtitle1" style={{ marginRight: 4 }}>
							$
						</Typography>
						<Typography color="primary" variant="h3">
							{checkUtils.formatMoney(check.totals.total)}
						</Typography>
					</Grid>
					{check.description &&
						<Grid item xs={12} container direction="column" justify="flex-start">
							<Typography variant="h6" style={{ color: '#757575', alignSelf: 'center'}}>
								{check.description}
							</Typography>
						</Grid>
					}
					{check.paidInFull && (check.totals.tips > 0 || autoTip > 0) &&
						<Grid item xs={12} container justify="center" style={{ marginTop: 2 }}>
							<Typography color="primary" variant="h5">
								Tip: &nbsp;
							</Typography>
							<Typography color="primary" variant="subtitle1" style={{ marginRight: 4 }}>
								$
							</Typography>
							<Typography color="primary" variant="h5">
								{checkUtils.formatMoney(check.totals.tips + (autoTip > 0 ? autoTip : 0))}
							</Typography>
						</Grid>
					}
					{check.totals.loyaltyRewardsAmount > 0 &&
						<div style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}}>
							<Typography color="primary" variant="h5">
								Loyalty: &nbsp;
							</Typography>
							<Typography color="primary" variant="h5">
							- $ {checkUtils.formatMoney(check.totals.loyaltyRewardsAmount)}
							</Typography>
						</div>
					}
					
					{check.paidInFull && checkTotals.totalPaid != check.totals.total && !isNaN(checkTotals.totalPaid) &&
						<Grid item xs={12} container justify="center" style={{ marginTop: 2 }}>
							<Typography color="primary" variant="h4">
								Paid: &nbsp;
							</Typography>
							<Typography color="primary" variant="h4">
								$ {checkUtils.formatMoney(checkTotals.totalPaid)}
							</Typography>
						</Grid>
					}
				</Grid>

			</>}
		</Grid>
	)
}
