import React, { useEffect, useState } from 'react';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
// import log from 'loglevel'
import { Typography, Grid } from '@material-ui/core';
// import checkUtils from '../../util/checkUtils';
import Power from '../../components/PoweredByDashnow.js';
import RewardBag from '../../resources/images/RewardBag.js';
import RewardMoney from '../../resources/images/RewardMoney.js';

export default (props) => {
	const [activeStep, setActiveStep] = useState(0);
	let [loyaltyProgram] = global.get('loyaltyProgram')
	let [checkId] = global.get('checkId')
	let [checks] = global.get('checks')
	let [viewingReceipt] = global.get('viewingReceipt')


	//Logo Small 
	//loyaltyProgram.logoSmall  (URL or blank)

	useEffect(() => {
		let tutorials = JSON.parse(localStorage.getItem("tutorials"))
		if (tutorials && tutorials.includes(loyaltyProgram._id)) {
			// setStepCount(1)
		}
		global.set({loyaltyHeader: true});
	},[]);

	function handleNext() {
		if (activeStep == 1) {
			if (viewingReceipt) {
				global.set({header: true, activePage: "PaymentPage"})	
			} else if (checks || checkId) {
				global.set({activePage: "LoadingPage"})	
			} else {
				global.set({activePage: "RestaurantInfoPage"})
			}
			global.set({loyaltyHeader: false});
		} else {
			setActiveStep(activeStep + 1);	
		}
	}

	function handleBack() {
		setActiveStep(activeStep - 1);
	}

	// const setRestaurantTutorialTaken = () => {
	// 	var tutorials = localStorage.tutorials ? JSON.parse(localStorage.getItem("tutorials")) : [];
	// 	tutorials.push(loyaltyProgram._id);
	// 	log.info("TUTORIALS " + tutorials)
	// 	localStorage.setItem("tutorials", JSON.stringify(tutorials));
	// }

	// const getTwoWordTitle = (word1, word2) => {
	// 	return (
	// 		<Grid container justify="center">
	// 			<Typography color="primary" variant="h5" style={{ fontWeight: '500' }}>{word1}</Typography>
	// 			<Typography color="primary" variant="h5" style={{ fontWeight: '300' }}>&nbsp;{word2}</Typography>
	// 		</Grid>
	// 	);
	// }


	// const earnRewardsTip = () => { return ( 
	// 		<div>
	// 			{getTwoWordTitle("Earn", "Rewards")}
	// 			<Grid container justify="center">
	// 				<Typography variant="h6" style={{fontWeight: '300', fontSize: 'small', top: 5}} color="primary">$</Typography>
	// 				<Typography variant="h5" color="primary">1</Typography>
	// 				<Typography variant="h6" style={{fontWeight: '300'}}>&nbsp;=&nbsp;</Typography>
	// 				<Typography variant="h5" color="primary">1&nbsp;</Typography>
	// 				<Typography variant="h6" style={{fontWeight: '300'}}>point</Typography>
	// 			</Grid>
	// 			<Typography variant="body1" style={styles.explanationText} align="center">
	// 				When you pay the bill using your phone at one of our participating restaurants.
	// 			</Typography>
	// 		</div> 
	// 	) 
	// }

	const earnPointsTip = () => {
		return ( 
			<div style={styles.tipWrapper}>
				<div style={styles.imgWrapper}>
					<RewardBag height={window.innerHeight * 0.2} />
				</div>

				<Grid container direction='column' alignItems="center" style={{margin: '5% 0px'}}>
					<Grid item container direction='row' justify='center' spacing={0}>
						<Typography color='primary' variant='h4' style={{fontWeight: '300'}}><span style={{fontWeight: '500'}}>Earn</span> Points</Typography>
					</Grid>
					<Grid item container direction='row' justify='center' alignItems='flex-start' spacing={0} style={{marginTop: '5%'}}>
						<div style={{fontSize: 20, color: 'var(--loyal-text)'}}>$</div>
						<div style={{fontSize: 32, color: 'var(--loyal-text)', marginRight: 4}}>1</div>
						<div style={{fontSize: 32, color: 'var(--loyal-text)', marginRight: 4}}>=</div>
						<div style={{fontSize: 32, color: 'var(--loyal-text)', marginRight: 4}}>1</div>
						<div style={{fontSize: 32, color: 'var(--loyal-text)'}}> point</div>
					</Grid>
				</Grid>
				<Grid container justify="center">
					<Typography align="center" style={styles.explainText}>When you pay your bill using your phone at one of our participating restaurants</Typography>
				</Grid>
			</div> 
		) 
	}

	const getRewardedTip = () => { 
		return ( 
			<div style={styles.tipWrapper2}>
				<div style={styles.imgWrapper}>
					<RewardMoney height={window.innerHeight * 0.15} />
				</div>

				<Grid item container direction='row' justify='center' spacing={0} style={{margin: '5% 0px'}}>
					<Typography color='primary' variant='h4' style={styles.getRewardedText}><span style={{fontWeight: '500'}}>Get</span> Rewarded</Typography>
				</Grid>
				
				<Grid item container direction='row' justify='center' alignItems='flex-start' spacing={0} style={{marginTop: '8%'}}>
					<div style={{fontSize: `${Math.round(window.innerWidth * 0.075)}px`, color: 'var(--loyal-text)', marginRight: 4}}>Receive</div>
					<div style={{fontSize: 20, color: 'var(--purple)', lineHeight: 1}}>$</div>
					<div style={{fontSize: 40, marginRight: 4, color: 'var(--purple)', lineHeight: 1}}>5</div>
					<div style={{fontSize: `${Math.round(window.innerWidth * 0.075)}px`, color: 'var(--loyal-text)'}}>for</div>
				</Grid>

				<Grid item container direction='row' justify='center' alignItems='center' spacing={0}>
					<div style={{fontSize: `${Math.round(window.innerWidth * 0.075)}px`, lineHeight: 1.1, marginRight: 4, color: 'var(--loyal-text)'}}>every</div>
					<div style={{fontSize: 40, lineHeight: 1.1, color: 'var(--purple)', marginRight: 4}}>100</div>
					<div style={{fontSize: `${Math.round(window.innerWidth * 0.075)}px`, lineHeight: 1.1, color: 'var(--loyal-text)'}}>points</div>
				</Grid>
			</div>
		) 
	}

	return (
		<>
			<div style={styles.dialogWrapper}>
				<div style={styles.contentWrapper}>
					{activeStep === 0 && earnPointsTip()}
					{activeStep === 1 && getRewardedTip()}
				</div>
			</div>

			<MobileStepper
				variant="dots"
				steps={2}
				// position="static"
				activeStep={activeStep}
				style={styles.mobileStepper}
				nextButton={
					<Button
						size="large"
						onClick={handleNext}
						color="primary"
						disabled={false}
					>
						{activeStep === 1 ? 'Done' : 'Next'}
					</Button>
				}
				backButton={
					<Button
						size="large"
						onClick={handleBack}
						color="primary"
						disabled={activeStep === 0}
					>
						Prev
					</Button>
				}
			/>


			<div style={styles.power}>
				<Power />
			</div>
		</>
	);
}


const styles = {
	dialogWrapper: {
		width: '100%',
		height: '100%',
		position: 'relative'
	},
	tipWrapper: {
		marginTop: '5%',
		width: '100%',
		height: '100%',
	},
	tipWrapper2: {
		marginTop: '15%',
		width: '100%',
		height: '100%',
	},
	imgWrapper: {
		width: '100%',
		height: 'auto',
		textAlign: 'center',
		margin: '0px auto',
	},
	getRewardedText: {
		fontWeight: '300',
		lineHeight: 1.1,
	},
	mobileStepper: {
		width: '80%',
		position: 'absolute',
		right: 0,
		bottom: '19%',
		left: 0,
		backgroundColor: 'transparent',
		margin: '0px auto'
	},
	power: {
		position: 'absolute',
		right: 0,
		bottom: 60,
		left: 0,
	},
	contentWrapper: {
		height: '100%'
	},
	explainText: {
		margin: '10%', 
		marginTop: '2%',
		fontSize: `${Math.round(window.innerWidth * 0.0475)}px`,
		color: 'var(--loyal-text)',
	},
};