import log from 'loglevel';
import dashFetch from './dashFetch';

export default async function(restaurantId, checkId, debug = true) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('createLoyaltyAccount api');

		const url = `${global.dashServerUrl}/api/v1/loyalty/createLoyaltyAccount`;

		if (debug) log.info('fetching from url: ' + url);

		const body = {
			restaurantId,
			checkId
		};

		if (debug) log.info('BODY: ' + JSON.stringify(body))
		dashFetch(url, body, debug).then(({ res, json }) => {
			if (json.success) {
				global.set({'loyaltyAccount': json.data.loyaltyAccount})
				resolve(true)
			} else {
				// NotificationManager.warning('', json.errors, 3000)
				resolve(false)
			}
		}).catch((err) => { reject(err) })
	})
}