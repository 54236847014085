import log from 'loglevel';
import _ from "lodash";
import getCheckDetails from '../actions/getCheckDetails'
import { NotificationManager } from 'react-notifications';
import checkUtils from '../util/checkUtils';

export const webSocketMessageHandler = (webSocketJSON, check) => {
	log.info('Message from WS: ')
	log.info(webSocketJSON);
	const f = global.f
	const receivedWSAction = webSocketJSON.action;
	const wsMessageData = webSocketJSON.data;
	const receivedWSSubAction = wsMessageData.subAction;
	const webSocketDeviceId = wsMessageData.deviceId
	let loggedInDeviceId = localStorage.deviceId;
	const lockedBy = wsMessageData.lockedBy
	let lockedByInitials = ""
	if (wsMessageData.lock) {
		if (lockedBy !== undefined && lockedBy !== "") {
			if (lockedBy.length >= 3) {
				let names = _.split(lockedBy.toUpperCase(), ' ', 2);
				if (names[0] === 'UNDEFINED' || names[1] === 'UNDEFINED') {
					lockedByInitials = ''
				} else {
					lockedByInitials = names[0].substring(0, 1) + names[1].substring(0, 1)
				}
			} else {
				lockedByInitials = (lockedBy === undefined) ? '' : lockedBy
			}
		}
	}

	if (global.tryMe && receivedWSAction === "updateAccount") {
		if (wsMessageData.fullName) {
			localStorage.fullName = wsMessageData.fullName
		}
		if (wsMessageData.firstName) {
			localStorage.firstName = wsMessageData.firstName
		}
		if (wsMessageData.lastName) {
			localStorage.lastName = wsMessageData.lastName
		}
		if (wsMessageData.initials) {
			localStorage.initials = wsMessageData.initials
		}
	} else if (receivedWSAction === "checkUpdated") {
		// THIS ACTION MAY RUN INTO ASYNCHRONOUS ISSUES
		// BE SURE TO ONLY UPDATE WHEN _ALL_ USERS NEED TO GET NEW INFORMATION
		log.info("UPDATING CHECK")
		let checkId = check._id
		let webSocketUserMessage = wsMessageData.userMessage
		if (checkId !== undefined && checkId !== "") {
			getCheckDetails(checkId).then((check) => {
				if (wsMessageData.redirectUserToReceiptPage) {
					global.set({
						activePage: 'ReceiptPage',
						header: true,
					})
					// if (global.checkClient !== null) {
					// 	global.checkClient.onclose = null // ensure the ws doesn't try to reconnect
					// 	global.checkClient.close()
					// 	global.checkClient = null
					// }
					log.info('pageStack: ' + global.pageStack)
					let checkStackPageBack = global.pageStack.filter(p => p && p.pageName === 'CheckStackPage')
					if (checkStackPageBack) {
						global.allowBack = true
						global.pageStack = [checkStackPageBack[0]]
					}
				}
				let hasNonDashPayments = !!(f(check, 'totals.paidOtherCards', false) || f(check, 'totals.paidCash', false))
				let totalOnlyMode = !!check.totalValidationFailed

				global.set({
					hasNonDashPayments: hasNonDashPayments,
					totalOnlyMode: totalOnlyMode,
					check: check,
					restaurant: check ? check.restaurant : null,
					checkId: check._id
				});
				NotificationManager.warning('', webSocketUserMessage, 3000)
				
				return check
			}).catch((err) => {
				if (global.pageStack.length > 0) {
					console.error(err);
					NotificationManager.warning('', 'A problem occured, please scan again', 3000);
				}
				global.set({
					activePage: "ScanQrCodePage",
				});
			})
		}
	} else if (receivedWSAction === "checkClosed") {
		log.info("CHECK CLOSED")
		let checkId = check._id
		let webSocketUserMessage = wsMessageData.userMessage
		
		if (checkId !== undefined && checkId !== "") {
			if (global.checkClient !== null) {
				global.checkClient.onclose = null // ensure the ws doesn't try to reconnect
				global.checkClient.close()
				global.checkClient = null
			}
			NotificationManager.warning('', webSocketUserMessage, 3000)
			getCheckDetails(checkId).then((check) => {
				if (!check.paidInFull) {
					global.set({
						footer: true,
						header: true,
						check: null,
						checks: null,
						checkId: null,
						allowBack: false,
						pageStack: [],
						activePage: 'LoadingPage',
					});				
				}
			})
			
		}
	} else if (receivedWSAction === "paymentMade") {
		const paidItemIds = wsMessageData.paidItemIds; // [itm_id1, itm_id2, ...]
		const deviceId = wsMessageData.deviceId
		const totals = wsMessageData.totals
		const webSocketUserMessage = wsMessageData.userMessage
		const paidByInitials = wsMessageData.paidByInitials
		const paidBy = wsMessageData.paidBy
		log.info("paidItemIds")
		log.info(paidItemIds)
		paidItemIds.forEach(id => {
			let index = _.findIndex(check.items, { _id: id });
			const item = check.items[index]
			log.info("ITEM: ")
			log.info(JSON.stringify(item))
			log.info("PAYING ONE")
			item.locked = true;
			item.lockedBy = paidBy;
			item.lockedById = null;
			item.lockedByMe = false;
			item.lockedByInitials = paidByInitials;
			item.paidBy = paidBy;
			item.paidByInitials = paidByInitials;
			item.paid = true;
		})

		// console.log(check.totals)
		if (totals) {
			// console.log(totals)
			check.totals.paid = totals.paid ? totals.paid : 0
			check.totals.paidOtherCards = totals.paidOtherCards ? totals.paidOtherCards : 0
			check.totals.paidCash = totals.paidCash ? totals.paidCash : 0
			check.totals.paidDashNow = totals.paidDashNow ? totals.paidDashNow : 0
			check.totals.due = totals.due ? totals.due : 0
        }
        
        let hasNonDashPayments = !!(f(check, 'totals.paidOtherCards', false) || f(check, 'totals.paidCash', false))

        // For DesktopView
        if (wsMessageData.paidInFull !== null && wsMessageData.paidInFull !== undefined) {
            check.paidInFull = wsMessageData.paidInFull 
        }

        check = checkUtils.updateTotals(check)

		global.set({
            check,
			hasNonDashPayments: hasNonDashPayments,
		});

		const senderIsReciever = localStorage.deviceId === deviceId
		if (!senderIsReciever) {
			NotificationManager.warning('', webSocketUserMessage, 3000)
		}
	} else if (receivedWSAction === "tableUpdated") {
		NotificationManager.warning('', wsMessageData.userMessage, 3000)
		return true
	}
	return check
}
