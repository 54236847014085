import log from 'loglevel';
import _ from "lodash";

export default class checkUtils {

	static updateTotals(check, tip) {
		const f = global.f
		log.info("UPDATING CHECK TOTALS")

		let othersWhoHaveLocks = new Set();
		let allUnpaidItemsTotal = 0
		let lockedItemsTotal = 0

        

		check.checkHasSomePaidItems = false
		check.hasLockedItemsByOthers = false
		check.hasSelectedItems = false

		if (tip !== undefined) {
			check.tip = tip
		} else {
			check.tip = check.tip ? check.tip : global.f(check, 'myTotals.tip', 0)
		}


		const nanAmountCheck = (amt) => {
			return isNaN(amt) ? 0 : parseInt(amt)
		}


		// ONLY REMOVE THE AMOUNT IF THERE ARE NON DASHNOW PAYMENTS
		let nonDashPaidAmount = f(check, 'totals.paidOtherCards', 0) + f(check, 'totals.paidCash', 0)
		let prevPaidAmount = nonDashPaidAmount + check.totals.paidDashNow - check.totals.ccServiceChargeAmount - check.totals.achServiceChargeAmount
		check.totals.nonDashPaidAmount = nonDashPaidAmount
		// console.log('nonDashPaidAmount: ' + nonDashPaidAmount)
		check.othersWhoHaveLocks = "";
        let isPartialPayment = global.f(check, 'myTotals.isPartialPayment')
        let partialTotal = global.f(check, 'myTotals.youPay')
		check.myTotals = {
			items: 0,
			taxes: 0,
			discounts: 0,
			other: 0,
			total: 0,
			dashServiceFeeAmount: f(check, 'restaurant.dashServiceFeeAmount', 0),
			tip: check.tip,
			youPay: 0,
			loyaltyRewardAmount: f(check, 'myTotals.loyaltyRewardAmount', 0),
			loyaltyTaxAmountRemoved: f(check, 'myTotals.loyaltyTaxAmountRemoved', 0),
			ccServiceChargeAmount: 0,
			achServiceChargeAmount: 0,
			prevPaidAmount,
			isPartialPayment: isPartialPayment || check.totals.paidDashNow > 0,
			tipAmountSelected: global.f(check, 'myTotals.tipAmountSelected', 0),
			tipButtonName: global.f(check, 'myTotals.tipButtonName', "")
		}

		check.itemsBeingPaid = []
		let subTotal = 0
		if (!check.totalValidationFailed) {
			check.items.forEach((i) => {
				subTotal += nanAmountCheck(i.price)
				allUnpaidItemsTotal += nanAmountCheck(i.price)
				
				lockedItemsTotal += nanAmountCheck(i.price)
				check.hasSelectedItems = true
				check.myTotals.items += nanAmountCheck(i.price)
				check.myTotals.taxes += nanAmountCheck(i.discountsAndDuties.taxesTotal)
				check.myTotals.discounts += nanAmountCheck(i.discountsAndDuties.discountsTotal)
				check.myTotals.other += nanAmountCheck(i.discountsAndDuties.otherTotal)
				check.myTotals.total += nanAmountCheck(i.totalPrice)
				check.itemsBeingPaid.push(i)
				
			})
		} else {
			subTotal = check.totals.subTotal
			check.itemsLockedByMe = check.items
			check.myTotals.items = check.totals.total
			check.myTotals.taxes = check.totals.discountsAndDuties.taxesTotal
			check.myTotals.discounts = check.totals.discountsAndDuties.discountsTotal
			check.myTotals.other = check.totals.discountsAndDuties.otherTotal
			check.myTotals.total = check.totals.total
		}
			
		if (!check.totalValidationFailed && f(check, "totals.autoTipNotInSubTotal", false) && f(check, "totals.autoTip", 0) > 0) {
			log.info("adding autoTip to totals")
			check.myTotals.total += check.totals.autoTip
		}

		check.myTotals.total -= prevPaidAmount


		log.info('check.totals: ')
		log.info(check.totals)
		log.info('check.myTotals: ')
		log.info(check.myTotals)

		if (!check.myTotals.tip) {
			check.myTotals.tip = 0
		}

		if (check.paidInFull) {
			let loyaltyTaxAmountRemoved = f(check, 'totals.loyaltyTaxAmountRemoved', 0)
			check.paidItemsTotals = {
				items: subTotal,
				taxes: check.totals.discountsAndDuties.taxesTotal - loyaltyTaxAmountRemoved,
				discounts: check.totals.discountsAndDuties.discountsTotal,
				other: check.totals.discountsAndDuties.otherTotal,
				total:check.totals.total - loyaltyTaxAmountRemoved,
				totalPaid: check.totals.tips + check.totals.autoTip + check.totals.total - check.totals.loyaltyRewardsAmount - loyaltyTaxAmountRemoved
            }
		}

        if (partialTotal > 0 && isPartialPayment) {
            check.myTotals.total = partialTotal
        }

		check.myTotals.youPay = getYouPay(check)

		_.forEach(Array.from(othersWhoHaveLocks), function(el, index) {
			if (check.othersWhoHaveLocks !== "") {
				if (index + 1 === othersWhoHaveLocks.size) {
					check.othersWhoHaveLocks += " and "
				} else {
					check.othersWhoHaveLocks += ", "
				}
			}
			check.othersWhoHaveLocks += el
		})

		check.totals.subTotal = subTotal

		if (lockedItemsTotal > 0) {
			check.totals.selectedItemsTotal = lockedItemsTotal
		} else {
			check.totals.selectedItemsTotal = allUnpaidItemsTotal
		}
		check.items = _.sortBy(check.items, ['paid'])
		return check

	}

	static setPartialAmount = (check, partialAmount) => {
		let partialPercentage = partialAmount / check.totals.total
		check.myTotals.taxes = Math.round(partialPercentage * check.totals.discountsAndDuties.taxesTotal)
		check.myTotals.discounts = Math.round(partialPercentage * check.totals.discountsAndDuties.discountsTotal)
		check.myTotals.other = Math.round(partialPercentage * check.totals.discountsAndDuties.otherTotal)
		check.myTotals.total = partialAmount
        check.myTotals.isPartialPayment = true
		check.myTotals.youPay = getYouPay(check)
		return check
	}

	static applyReward = (check, rewardAmount) => {
		if (!check.myTotals.loyaltyRewardAmount || check.myTotals.loyaltyRewardAmount === 0) {
		
			let taxToRemove
			if (rewardAmount <= check.myTotals.items) {
				taxToRemove = Math.round((check.totals.discountsAndDuties.taxesTotal / check.totals.subTotal) * rewardAmount)
			} else {
				rewardAmount = check.myTotals.items
				taxToRemove = check.myTotals.taxes
			}
			log.info('TaxToremove - ' + taxToRemove)
			const youPay = check.myTotals.youPay - rewardAmount - taxToRemove
			return { ...check, myTotals: {...check.myTotals, youPay: youPay, loyaltyRewardAmount: rewardAmount, loyaltyTaxAmountRemoved: taxToRemove },  }
		}
		return check
	}

	static undoRewardApplied = (check) => {
		if (!check.myTotals.loyaltyRewardAmount || check.myTotals.loyaltyRewardAmount === 0) {
			return check
		}
		const youPay = check.myTotals.youPay + check.myTotals.loyaltyRewardAmount + check.myTotals.loyaltyTaxAmountRemoved
		return { ...check, myTotals: { ...check.myTotals, youPay: youPay, loyaltyRewardAmount: 0, loyaltyTaxAmountRemoved: 0 } }
	}

	static applyServiceCharge = (check, paymentType = '') => {
		log.info('PAYMENT_TYPE = ' + paymentType)
		check = this.undoApplyServiceCharge(check)
		
		let serviceChargePercent = 0
		let serviceChargeAmount = 0
		if (paymentType == 'ach') {
			serviceChargePercent = check.getVal('restaurant.config.mobile.serviceCharges.achServiceChargePercent', 0)
			serviceChargeAmount = check.getVal('restaurant.config.mobile.serviceCharges.achServiceChargeAmount', 0)
		} else if (paymentType == 'creditCard') {
			serviceChargePercent = check.getVal('restaurant.config.mobile.serviceCharges.ccServiceChargePercent', 0)
			serviceChargeAmount = check.getVal('restaurant.config.mobile.serviceCharges.ccServiceChargeAmount', 0)
		}

		let totalAmount = 0
		if (serviceChargePercent && serviceChargePercent > 0) {
			totalAmount += (check.myTotals.youPay * serviceChargePercent)
		}
		if (serviceChargeAmount && serviceChargeAmount > 0) {
			totalAmount += serviceChargeAmount
		}
		totalAmount = Math.round(totalAmount)

		if (paymentType == 'ach') {
			check.myTotals.achServiceChargeAmount = totalAmount
		} else if (paymentType == 'creditCard'){
			check.myTotals.ccServiceChargeAmount = totalAmount
		}

		check.myTotals.youPay = getYouPay(check)
		return check
	}

	static undoApplyServiceCharge = (check) => {
		check.myTotals.ccServiceChargeAmount = 0
		check.myTotals.achServiceChargeAmount = 0
		check.myTotals.youPay = getYouPay(check)
		return check
	} 

	static formatMoney = (checkPrice) => { 
        let num = checkPrice/100
		return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	};

}

const getYouPay = (check) => {
	return (check.myTotals.total 
		+ check.myTotals.dashServiceFeeAmount 
		+ check.myTotals.tip 
		+ check.myTotals.ccServiceChargeAmount
		+ check.myTotals.achServiceChargeAmount
		- check.myTotals.loyaltyRewardAmount 
		- check.myTotals.loyaltyTaxAmountRemoved 
	)
} 
