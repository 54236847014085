import React, { useState, useEffect, useRef } from 'react';
import log from 'loglevel';
// When importing from material, be sure to use the method below
// https://material-ui.com/guides/minimizing-bundle-size/
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { NotificationManager } from 'react-notifications';
import _ from "lodash";
import checkUtils from '../../util/checkUtils'
import DStyles from '../../components/DStyles'
import ReceiptTable from './sections/ReceiptTable';
import SelectTipTable from './sections/SelectTipTable';
import ArrowUp from '../../resources/images/arrow-up2.svg';
import ArrowDown from '../../resources/images/arrow-down2.svg';
import ReactGA from 'react-ga';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Slide } from '@material-ui/core';

export default (props) => {
	
	const THIS_PAGE = "ReceiptPage"
	let selectItemsTab = useRef(null);

	let [check] = global.get("check")
	let [checks] = global.get("checks")
	let [splitCheckEnabled] = global.get("splitCheckEnabled")
	let [lockedAll] = global.get("lockedAll")
	let [loyaltyProgram] = global.get("loyaltyProgram")
	let [loyaltyAccount] = global.get("loyaltyAccount")
	let [activePage] = global.get("activePage")
	const [ isNative ] = global.get("isNative")
	const [ hasPersonIdentifiers ] = global.get('hasPersonIdentifiers');
	const [hasNonDashPayments] = global.get("hasNonDashPayments");
	const [showFirstTimeTipOverlay, setShowFirstTimeTipOverlay] = useState(!localStorage.hideFirstTimeTipOverlay? true : false)
	const [tabIndex, setTabIndex] = useState(hasNonDashPayments ? 0 : check.hasLockedItemsByOthers ? 1 : 0)
	const [scrollIndicator, setScrollIndicator] = useState(false);
	const [bottomDrawer, setBottomDrawer] = useState(!loyaltyAccount);
	const [swipeDir, setSwipeDir] = useState('Left');
	const ENTIRE_BILL_TAB = 0,
		SELECT_ITEMS_TAB = 1
	let debug = true
	let gridTabHeight = splitCheckEnabled ? 8.5 : 0
	let gridTableWrapperHeight = tabIndex === ENTIRE_BILL_TAB ? `${70 - gridTabHeight}` : '70' // as percentpercent
	let gridReceiptFooterHeight = tabIndex === ENTIRE_BILL_TAB ? `30` : `${30 - gridTabHeight}`
	const GA_CATEGORY_RECEIPT_TAB_CLICKED = "Receipt Tab Clicked"
	const [imHereEnabled] = global.get('imHereEnabled');
	const [payAtTableEnabled] = global.get('payAtTableEnabled')

	const styles = `
		.gridTableWrapper {
			height: ${gridTableWrapperHeight}%;
			padding: 0px 15px 0px 15px;
		}

		.gridTableWrapper::-webkit-scrollbar {
			display: none;
			width: 0 !important
		}

		.gridTabs {
			height: ${gridTabHeight}%;
		}

		.root {
			height: 100%;
		}

		.gridRoot {
			height: 100%;
		}
		.receiptFooter {
			height: ${gridReceiptFooterHeight}%;
		}

		.tabs: {
			border-bottom: 1px solid var(--divider);
		}

		.overlay-container {
			position: absolute;
			top: 0px;
			right: 0px;
			left: 0px;
			bottom: 0px;
			background: #E5E5E599;
            z-index: 1999;
            width:100%;
		}

		.overlay-content {
			position: relative;
			top: 38%;
			z-index: 2005;
			width: 268px;
			height: auto;
			background: #858585;
			border-radius: 9px;
			color: #FFF;
			text-align: center;
			padding: 16px;
			margin: auto;
		}


		@media (min-device-height: 800px){
		    .overlay-content {
				height: auto;
			}
			.overlay-wrapper {
				height: 100%;
				vertical-align: middle;
			}
		}
		/*
		@media (max-device-height: 600px){
		    .overlay-content {
		        height: auto;
		    }
		}
		*/

		.overlay-message {
			font-family: 'Roboto';
			font-weight: 400;
			font-size: 15px;
			color: #FFFFFF;
			letter-spacing: 0;
			line-height: 21px;
		}

		.overlay-button {
			font-family: 'Roboto';
			font-weight: 500;
			font-size: 15px;
			color: #FFFFFF;
			letter-spacing: 0;
			text-align: center;
			padding: 2px 4px;
		}

		.overlay-arrow-up {
			z-index: 2000;
			position: absolute;
			top: ${gridTabHeight-2}%;
			bottom: 62%;
		    right: 12%;
			margin-top: 50px;
			text-align: right;
			overflow: hidden;
			padding-right: 30px;
		}

		.overlay-arrow-up img {
			width: 218px;
		    height: 173px;
			transform: rotate(-25deg);
			transform-origin: top right;
		}

		.overlay-arrow-down {
			z-index: 2000;
			position: absolute;
		    bottom: ${gridReceiptFooterHeight}%;
			top: 45%;
		    left: 20%;
		    right: 0px;
			width: 218px;
		    /* height: 173px; */
			overflow: hidden;
			padding-left: 30px;
			vertical-align: bottom;
			display: flex;
			align-items: flex-end;
			margin-bottom: -20px;
		}

		.overlay-arrow-down img {
			width: 218px;
		    height: 173px;
			transform: rotate(-25deg);
			transform-origin: bottom left;
		}
		.scrollIndicator {
			position: absolute;
			bottom: 19%;
			right: 46.5%;
			width: '7%';
		}

	`

	const hideFirstTimeTipOverlay = () => {
		localStorage.hideFirstTimeTipOverlay = true
		setShowFirstTimeTipOverlay(false)
	}

	const navigateBackToScan = () => {
		if (isNative) {
			window.location = "dashnow://dashnow"
		} else {
			window.location = window.location.pathname
		}
	}

	//SIMILAR TO componentWillMount() function, only runs once
	useEffect(() => {
		//IF ITEMS HAVE ALREADY BEEN SELECTED BY ANYONE, START THE USER ON THE SELECT ITEMS TAB
		let hasLockedItemsByMe = _.includes(_.map(check.items, (item) => !item.paid && item.locked), true)

		global.set({ footer: false })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	//COMING FROM CHECK STACK PAGE
	if (checks) {
		global.set({ allowBack: true})
	} else {
		//JOE TODO - RESEARCH WHETHER THIS NEEDS TO BE ENABLED, WE HAD TO COMMENT THIS CODE OUT TO ALLOW GOING BACK TO THE CHECK STACK PAGE
		// if (activePage == THIS_PAGE) {
			// global.set({ allowBack: false})
		// }
	}
	global.set({viewingReceipt: true})


	//
	if (global.tableClient) {
		global.tableClient.onclose = null // ensure the ws doesn't try to reconnect
		global.tableClient.close()
		global.tableClient = null
	}

	const selectItemsExplanation = () => {
		if (splitCheckEnabled) {
			let message = hasNonDashPayments ? 'Non Dashnow payments have been applied to this bill. The remaining balance cannot be split.' : "Click the select items tab to split the bill"
			NotificationManager.warning('', message, 3000)
		}
	}

	return (
		<Slide in direction="up">
			<div className={"root"} >
				{showFirstTimeTipOverlay && splitCheckEnabled &&
					<div className={"overlay-container"} onClick={() => hideFirstTimeTipOverlay()}>

						<div className={"overlay-arrow-up"}>
							<img src={ArrowUp} alt="Arrow Up" />
						</div>
						<div className={"overlay-content"}>
							<div className="overlay-message">Splitting with friends? No problem! Simply select your items or choose a tip to pay the entire bill.</div>
							<Button className={"overlay-button"} onClick={() => hideFirstTimeTipOverlay()}>Got it</Button>
						</div>
						<div className={"overlay-arrow-down"}>
							<img src={ArrowDown} alt="Arrow Down" />
						</div>
					</div>
				}
				<DStyles styles={styles} />
				<Grid className={"gridRoot"} container spacing={0}>
					{!splitCheckEnabled &&
						<div></div>
					}
					
					{(tabIndex === ENTIRE_BILL_TAB || (!splitCheckEnabled && tabIndex === SELECT_ITEMS_TAB)) && <>
							<Grid item xs={12} className={"gridTableWrapper"} onClick={!check.paidInFull ? selectItemsExplanation : ''} align="center">
								<ReceiptTable check={check} tabIndex={tabIndex} />
							</Grid>
							<Grid item xs={12} align="center" className={"receiptFooter"}>
								{ !(check.paidInFull || check.imHereForOnlineNotPaidInFull) && 
									<SelectTipTable check={check} page={THIS_PAGE} selectItemsTab={SELECT_ITEMS_TAB} setTabIndex={setTabIndex} />
								}
									
								{imHereEnabled && (check.paidInFull || check.imHereForOnlineNotPaidInFull) &&
									<>
										<div style={{height:'100%', paddingTop: '20%', paddingBottom: '15%'}}>
											<Typography gutterBottom={true}>Let the restaurant know you’ve arrived!</Typography>
											<Button onClick={() => {global.set({activePage: "ImHerePage"})}} variant='contained' size='medium' color='primary' style={{fontSize: 18, paddingLeft: 32, paddingRight: 32}} >I'M HERE</Button>
										</div>
									</>
								}

								{check.paidInFull && !imHereEnabled && payAtTableEnabled &&
									<>
										<div style={{height:'100%', paddingTop: '30%', paddingBottom: '15%'}}>
											<Button component="span" onClick={navigateBackToScan} variant="contained" color="primary" style={{borderBottom:'5%'}}>
												<Typography variant="subtitle1" >
													Scan Again
												</Typography>
											</Button>
										</div>
									</>
								}
										
							</Grid>
						</>
					}

				</Grid>
			</div>
		</Slide>
	);
};
