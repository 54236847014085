import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.width} viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="2b-specials" transform="translate(-111.000000, -1047.000000)" fillRule="nonzero">
                    <g id="popup" transform="translate(18.000000, 1028.000000)">
                        <g id="Group" transform="translate(25.000000, 19.000000)">
                            <g id="Group-2">
                                <g id="Group-9" transform="translate(68.000000, 0.000000)">
                                    <g id="medal">
                                        <path d="M23,16 C14.7157288,16 8,22.7157288 8,31 C8,39.2842712 14.7157288,46 23,46 C31.2842712,46 38,39.2842712 38,31 C37.9906315,22.7196124 31.2803876,16.0093685 23,16 Z M27.7,38.236 L23,35.764 L18.3,38.236 L19.2,33 L15.4,29.292 L20.656,28.528 L23,23.764 L25.352,28.528 L30.608,29.292 L26.8,33 L27.7,38.236 Z" id="Shape" fill={props.medallionColor}></path>
                                        <path d="M20.705,14.174 L13.9,0.553 C13.7297741,0.212330638 13.3808256,-0.00207880518 13,5.55109818e-16 L1,5.55109818e-16 C0.653381965,-0.000161557784 0.331400201,0.179182345 0.149068406,0.473969056 C-0.0332633877,0.768755767 -0.049938316,1.13693838 0.105,1.447 L9.642,20.52 C12.364822,17.0473269 16.3327537,14.7712269 20.705,14.174 L20.705,14.174 Z" id="Path" fill={props.strapColor} /* This is the color of the left strap A47BD8 */></path>
                                        <path d="M45.851,0.475 C45.6689053,0.179797736 45.346847,3.54580834e-05 45,4.44084128e-16 L33,4.44084128e-16 C32.6191744,-0.00207880518 32.2702259,0.212330638 32.1,0.553 L25.3,14.174 C29.6704432,14.7724629 33.636376,17.048445 36.358,20.52 L45.9,1.447 C46.0530676,1.13637119 46.0345307,0.768660372 45.851,0.475 Z" id="Path" fill={props.strapColor} /* This is the color of the right strap A47BD8 */></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}