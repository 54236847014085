import React from  'react';

export default (props) => {
    return (
        <svg height={props.height} viewBox="0 0 868 966" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="98.1344691%" y1="50%" x2="0%" y2="50%" id="linearGradient-1">
                    <stop stop-color="#C2C4C3" offset="0%"></stop>
                    <stop stop-color="#E4E5E9" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="21.5132674%" y1="0%" x2="78.4867326%" y2="100%" id="linearGradient-2">
                    <stop stop-color="#FFDBAC" offset="0%"></stop>
                    <stop stop-color="#FEAB2F" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="0%" y1="16.1002716%" x2="100%" y2="83.8997284%" id="linearGradient-3">
                    <stop stop-color="#FFDFF4" offset="0%"></stop>
                    <stop stop-color="#8386DD" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-4">
                    <stop stop-color="#B2B7F1" offset="0%"></stop>
                    <stop stop-color="#5B6DD5" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-5">
                    <stop stop-color="#FFC171" offset="0%"></stop>
                    <stop stop-color="#F18902" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="21.7888261%" y1="10.3967829%" x2="79.263444%" y2="93.3822253%" id="linearGradient-6">
                    <stop stop-color="#C4AFE9" offset="0%"></stop>
                    <stop stop-color="#5C70D6" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="23.8862619%" y1="14.0726815%" x2="79.2437358%" y2="89.7615353%" id="linearGradient-7">
                    <stop stop-color="#FFD6F6" offset="0%"></stop>
                    <stop stop-color="#FFF8F8" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="39.9899056%" y1="0%" x2="39.9899056%" y2="109.712518%" id="linearGradient-8">
                    <stop stop-color="#FFB43E" offset="0%"></stop>
                    <stop stop-color="#FED8A5" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Reward-Bags">
                    <ellipse id="Oval" fill="url(#linearGradient-1)" cx="511" cy="947" rx="275" ry="19"></ellipse>
                    <rect id="Rectangle" fill="url(#linearGradient-2)" x="354" y="245" width="471" height="624"></rect>
                    <rect id="Rectangle" fill="#F28D02" x="435" y="786" width="315" height="10" rx="1"></rect>
                    <path d="M759.562878,834.202291 L836.327612,834.002608 C836.879894,834.001171 837.328773,834.447721 837.330209,835.000003 C837.330212,835.00087 837.330213,835.001738 837.330213,835.002605 L837.330213,843 C837.330633,843.552285 836.882918,844 836.330633,844 C836.330493,844 836.330353,844 836.330213,843.999579 L759.027297,843.967058 C758.762227,843.966946 758.508045,843.861598 758.320611,843.674165 L754.707107,840.06066 C754.316582,839.670136 754.316582,839.036971 754.707107,838.646447 L758.858372,834.495181 C759.045271,834.308282 759.298563,834.202979 759.562878,834.202291 Z" id="Rectangle" fill="#F38E04" transform="translate(795.665106, 839.000000) rotate(225.000000) translate(-795.665106, -839.000000) "></path>
                    <rect id="Rectangle" fill="url(#linearGradient-3)" x="198" y="482" width="470" height="387"></rect>
                    <rect id="Rectangle" fill="#7A80DE" x="275" y="786" width="315" height="10" rx="1"></rect>
                    <rect id="Rectangle" fill="url(#linearGradient-4)" x="197" y="511" width="471" height="10" rx="1"></rect>
                    <rect id="Rectangle" fill="url(#linearGradient-5)" x="354" y="275" width="471" height="10" rx="1"></rect>
                    <path d="M191.109451,834.490612 L267.874184,834.290929 C268.426467,834.289492 268.875346,834.736041 268.876782,835.288324 C268.876785,835.289191 268.876786,835.290058 268.876786,835.290925 L268.876786,843.288321 C268.877206,843.840605 268.429491,844.288321 267.877206,844.288321 C267.877066,844.288321 267.876926,844.288321 267.876786,844.2879 L190.57387,844.255379 C190.3088,844.255267 190.054618,844.149919 189.867184,843.962485 L186.25368,840.348981 C185.863155,839.958457 185.863155,839.325292 186.25368,838.934767 L190.404945,834.783502 C190.591844,834.596603 190.845136,834.4913 191.109451,834.490612 Z" id="Rectangle" fill="#7A80DE" transform="translate(227.211679, 839.288321) rotate(-45.000000) translate(-227.211679, -839.288321) "></path>
                    <path d="M602.094984,834.949505 L678.859718,834.749821 C679.412001,834.748385 679.86088,835.194934 679.862316,835.747217 C679.862318,835.748084 679.86232,835.748951 679.86232,835.749818 L679.86232,843.747213 C679.86274,844.299498 679.415025,844.747213 678.86274,844.747213 C678.8626,844.747213 678.86246,844.747213 678.86232,844.746792 L601.559404,844.714271 C601.294334,844.71416 601.040151,844.608811 600.852718,844.421378 L597.239214,840.807873 C596.848689,840.417349 596.848689,839.784184 597.239214,839.39366 L601.390479,835.242394 C601.577378,835.055495 601.83067,834.950192 602.094984,834.949505 Z" id="Rectangle" fill="#7A80DE" transform="translate(638.197213, 839.747213) rotate(225.000000) translate(-638.197213, -839.747213) "></path>
                    <path d="M432.327899,407.820739 C352.263379,407.820739 357.220628,486.745272 357.220628,551 L374,551 L374,560 L334,560 L334,551 L347.718,550.999669 L347.714572,549.872437 C347.387435,503.336183 331.534509,397.944669 432.327899,397.944669 C534.139404,397.944669 516.871962,505.476057 516.871962,551 L531,551 L531,560 L491,560 L491,551 L506.953928,551 L506.953928,551 C506.953928,487.009715 512.392419,407.820739 432.327899,407.820739 Z" id="Combined-Shape" fill="#857282"></path>
                    <path d="M589.327899,172.820739 C509.263379,172.820739 514.220628,251.745272 514.220628,316 L531,316 L531,325 L491,325 L491,316 L504.718,315.999669 L504.714572,314.872437 C504.387435,268.336183 488.534509,162.944669 589.327899,162.944669 C691.139404,162.944669 673.871962,270.476057 673.871962,316 L688,316 L688,325 L648,325 L648,316 L663.953928,316 L663.953928,316 C663.953928,252.009715 669.392419,172.820739 589.327899,172.820739 Z" id="Combined-Shape" fill="#857282"></path>
                    <circle id="Oval" fill="url(#linearGradient-6)" cx="119" cy="323" r="119"></circle>
                    <polygon id="Star" fill="url(#linearGradient-7)" points="119.5 373.611269 74.5344282 392.3898 78.4987467 343.822115 46.7441765 306.8602 94.1598319 295.622251 119.5 254 144.840168 295.622251 192.255823 306.8602 160.501253 343.822115 164.465572 392.3898"></polygon>
                    <circle id="Oval" fill="#B8A4E4" cx="844" cy="546" r="21"></circle>
                    <circle id="Oval" fill="#B8A4E4" cx="767" cy="489" r="10"></circle>
                    <rect id="Rectangle" fill="#B9A5E4" transform="translate(855.712895, 433.812095) rotate(44.000000) translate(-855.712895, -433.812095) " x="844.712895" y="428.312095" width="22" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#B9A5E4" transform="translate(854.712895, 390.812095) rotate(-44.000000) translate(-854.712895, -390.812095) " x="843.712895" y="385.312095" width="22" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#CAB5E9" transform="translate(813.712895, 433.812095) scale(-1, 1) rotate(44.000000) translate(-813.712895, -433.812095) " x="802.712895" y="428.312095" width="22" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#CAB5E9" transform="translate(812.712895, 390.812095) scale(-1, 1) rotate(-44.000000) translate(-812.712895, -390.812095) " x="801.712895" y="385.312095" width="22" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#B9A5E4" transform="translate(653.712895, 47.812095) rotate(-57.000000) translate(-653.712895, -47.812095) " x="642.712895" y="42.3120948" width="22" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#C3AEEA" transform="translate(588.712895, 21.312095) rotate(90.000000) translate(-588.712895, -21.312095) " x="568.212895" y="15.8120948" width="41" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#CDB3EE" transform="translate(470.712895, 89.312095) rotate(33.000000) translate(-470.712895, -89.312095) " x="450.212895" y="83.8120948" width="41" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#B9A5E4" transform="translate(709.712895, 89.312095) rotate(-30.000000) translate(-709.712895, -89.312095) " x="689.212895" y="83.8120948" width="41" height="11" rx="0.5"></rect>
                    <rect id="Rectangle" fill="#CAB2ED" transform="translate(525.712895, 47.812095) rotate(-123.000000) translate(-525.712895, -47.812095) " x="514.712895" y="42.3120948" width="22" height="11" rx="0.5"></rect>
                    <path d="M197,646.271853 C197.101268,648.520508 197.170671,653.740213 197.208207,661.930966 C197.257106,672.601228 203.346084,672.601228 207.964301,679.566511 C223.868645,703.553748 249.672423,724.275361 285.375635,741.731351 C269.805893,743.731873 256.783783,744.732133 246.309304,744.732133 C230.597586,744.732133 206.269156,738.969432 197.257106,733.489802 C197.085702,728.932338 197,717.7659 197,699.990488 L197,646.271853 Z" id="Rectangle" fill="#BFAAE7"></path>
                    <path d="M197,560 C222.040276,579.048574 233.373609,590.048574 231,593 C230.346967,593.812003 219.013634,593.99292 197,593.542751 L197,560 Z" id="Rectangle" fill="#BFADE8"></path>
                    <path d="M119.277392,481.052042 C124.193836,481.052042 214.911833,568.619591 211.529526,574 C210.666677,574.851159 197.395743,575.276739 171.716723,575.276739 C167.97743,608.14935 174.059956,636.579274 189.964301,660.566511 C205.868645,684.553748 231.672423,705.275361 267.375635,722.731351 C205.578748,728.392774 157.689893,714.216296 123.709069,680.201917 C89.728245,646.187537 69.364814,611.212478 62.6187758,575.276739 C39.3355762,575.760214 27.5893895,575.334635 27.3802157,574 C22.8277255,568.498372 114.360947,481.052042 119.277392,481.052042 Z" id="Rectangle" fill="url(#linearGradient-8)"></path>
                </g>
            </g>
        </svg>
    );
}