import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink">
            <title>Group 2</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="shift4-logo" fill="#0082CA">
                    <g id="Group-2" transform="translate(0.169425, 0.101920)">
                        <path d="M18.6857755,3.90798505e-14 C17.4627354,3.90798505e-14 16.2396954,0.127400004 15.0930953,0.356720012 L15.0930953,4.50996015 C15.0930953,4.50996015 22.4313356,5.09600017 23.2976556,13.1986404 L30.1772558,3.94940013 C26.9922557,1.47784005 22.9918956,3.90798505e-14 18.6857755,3.90798505e-14 L18.6857755,3.90798505e-14 Z M31.0435759,7.13440024 L31.0435759,11.7717604 L27.4254157,11.7717604 L31.0435759,7.13440024 Z" id="Shape"></path>
                        <path d="M33.922816,7.92428026 L33.922816,17.4538006 L30.9926159,17.4538006 L30.9926159,13.9375605 L19.0934555,13.9375605 C19.0934555,13.9375605 19.3482555,7.87332026 12.4941353,7.87332026 C9.51297515,7.87332026 6.86305507,9.80980032 6.78661506,11.7462804 C6.63373506,14.8548405 11.0417752,16.4091205 15.6536554,17.3518806 C18.3800154,17.8869606 21.2847355,19.2628806 22.4568156,20.8171607 C24.1384956,23.0084408 23.9856156,25.2252008 23.9856156,25.2252008 C23.9856156,25.2252008 24.5461757,32.2576811 15.0421353,33.7100411 L15.0421353,36.9460012 C16.2142154,37.1753212 17.4117754,37.3027212 18.6348155,37.3027212 C28.9287358,37.3027212 37.2606961,28.970761 37.2606961,18.6768406 C37.2861761,17.8869606 37.4900161,13.0457604 33.922816,7.92428026 L33.922816,7.92428026 Z" id="Path"></path>
                        <path d="M3.21941495,8.15360027 C3.21941495,8.15360027 -3.63470528,17.8105206 2.65885493,26.3718009 C5.74193503,29.837081 11.0672552,30.270241 13.9719753,30.015441 C15.9339354,29.837081 17.2588954,29.174601 17.9978154,28.5376009 C19.4501755,27.2636009 20.9025355,24.8175208 17.1060154,22.5498007 C12.7744153,20.0272807 5.30877501,20.9190807 2.93913494,15.7211605 C2.93913494,15.7211605 1.25745488,12.8674004 3.21941495,8.15360027" id="Path"></path>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}