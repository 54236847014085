import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 49 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink">
            <title>Path</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Group" transform="translate(-31.000000, -30.000000)">
                    <rect id="Rectangle" x="0" y="0" width="400" height="108"></rect>
                    <polygon id="Path" fill="#C8102E" fill-rule="nonzero" points="66.2818548 30.6278714 66.2818548 54.4233714 43.8214159 54.4233714 43.8214159 30.6278714 31.0205364 30.6278714 31.0205364 90.1558879 43.8214159 90.1558879 43.8214159 64.0829311 66.4389207 64.0829311 66.4389207 90.1558879 79.2398003 90.1558879 79.2398003 30.6278714"></polygon>
                </g>
            </g>
        </svg>
        
    )
}