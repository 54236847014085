import React from 'react'
import { Button, Paper, Dialog, DialogActions, TextField, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


//props.isSelected
//props.isNotSelected (another list item is selected)

//props.leftCol
//props.centerCol
//props.rightCol

const useStyles = makeStyles((theme) => ({
    item: {
        '&:hover': {
            backgroundColor:'rgb(226, 232, 240)'
        },
        transition: '0.4s',
        marginBottom:2,
        marginTop:2,
        cursor: 'pointer'
    },
    itemSelected: {
        '&:hover': {
            backgroundColor:'rgb(226, 232, 240)'
        },
        transition: '0.4s',
        marginBottom:2,
        marginTop:2,
        cursor: 'pointer',
        backgroundColor:'rgb(185, 192, 199)'
    },
    itemNotSelected : {
        '&:hover': {
            backgroundColor:'rgb(226, 232, 240)'
        },
        transition: '0.1s',
        marginBottom:2,
        marginTop:2,
        cursor: 'pointer',
        opacity:'.4'
    }
}))

export default function ListItem(props) {
    const c = useStyles();

    return (
        <div className={props.isSelected ? c.itemSelected : props.isNotSelected ? c.itemNotSelected : c.item} onClick={props.onClick} 
            style={{width:'100%', height:70, display:'flex', justifyContent:'space-between', alignItems:'center', padding:'8px 16px'}}
        >
            <div style={{display:'flex', alignItems:'center'}}>
                <div className="column" style={{...props.leftColStyle, display:'flex', alignItems:'center', minWidth:80 }}> {props.leftCol} </div>
                <div className="column" style={{dispaly:'flex', justifyContent:'flex-start', paddingLeft:10}}> {props.centerCol} </div>
            </div>
           <div className="column" style={{textAlign:'right', flexBasis:'20%'}}> {props.rightCol} </div>
            
        </div>
    )
}