import React, { useEffect } from 'react';
import log from 'loglevel';
import getCheckDetails from '../actions/getCheckDetails'
import getCheckIdFromCheckNumber from '../actions/getCheckIdFromCheckNumber'
import getCheckListByTable from '../actions/getCheckListByTable'
import { NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import Launch from '../resources/images/Launch';
import '../css/splash-screen.css';
import ReactGA from 'react-ga';
import getRestaurantData from '../actions/getRestaurantData';
import getLoyaltyAccountInfo from '../actions/getLoyaltyAccountInfo';
import getLoyaltyProgram from '../actions/getLoyaltyProgram';
import createLoyaltyAccount from '../actions/createLoyaltyAccount';
import getSignupReward from '../actions/getSignupReward'; 
import checkUtils from '../util/checkUtils';

export default (props) => {
	const f = global.f
	let debug = true
	let [ticketId] = global.get("ticketId")
	let [checkNumber] = global.get('checkNumber')
	let [restaurantCode] = global.get('restaurantCode')
	let [shortUrl] = global.get("shortUrl")
	let [check] = global.get("check")
	let [checkId] = global.get("checkId")
	let [restaurantId] = global.get("restaurantId")
	let [backOffice] = global.get("backOffice")
	let [signupReward] = global.get('signupReward');
	let [table] = global.get("table")
	let [activePage] = global.get("activePage")
	let [header] = global.get("header")
	let [footer] = global.get("footer")
	let [payNowPageText] = global.get("payNowPageText")
	let [hasNonDashPayments] = global.get("hasNonDashPayments")
	let [locationInfo] = global.get("locationInfo")
	const GA_LOADING_RECEIPT = "Loading Receipt"
	const GA_SCAN_CHECK = "Scan Check";
	const GA_SCAN_TABLE_TENT = "Scan Table Tent";

	const c = makeStyles((theme) => ({
		overlayContainer: {
			position: "absolute",
			top: "0px",
			right: "0px",
			left: "0px",
			bottom: "0px",
			background: "#E5E5E599",
			zIndex: 1999,
		},
		mobileWalletPaymentLoading: {
			position: "absolute",
			zIndex: "2005",
			height: "140px",
			left: "35%",
			bottom:"50%",
			textAlign: "center",
			margin: "auto",
		},
	}))();

	let [checks] = global.get("checks")

	useEffect(() => {
		let initData = async () => {
			
			log.info("***** LOADING PAGE *****")
			log.info("  checkId: " + checkId)
			log.info("  restaurantId: " + restaurantId)
			log.info("  restaurantCode: " + restaurantCode)
			log.info("  table: " + table)

			let restaurant
			let restaurantData
			let loyaltyProgram
			let loyaltyAccount
			let restaurantName
			let restaurantPhone
			let personIdentifiers = []
			
			
			let check
			let hasLoyalty = false
			let hasPersonIdentifiers = false
			let lockedAll = false
			let imHereEnabled = false
			let callAheadCheck = false
			let selectVehicleEnabled = false
			let selectLocationEnabled = false
			let feedbackEnabled = true
			let splitCheckEnabled = true
			let optInEnabled = true
			let totalOnlyMode = false
			let tipsEnabled = true
			let skipReceiptPage = false
			let tipPercentagesEnabled = true
			let customTipEnabled = true
			let noTipButtonEnabled = true
			let displayItemModifiers = false
			let displayItemName = true
			let displayItemDescription = true
			let textToPayEnabled = true // NOT textPay this is if textToPay
			let ccServiceChargeAmount = 0
			let ccServiceChargePercent = 0
			let ccServiceChargeLabel = ''
			let achServiceChargeAmount = 0
			let achServiceChargePercent = 0
			let achServiceChargeLabel = ''
			let paymentTerms = ""
			let payAtTableEnabled = true
            let rightAlignHeaderLogo = false
			let allowPartialPayments = false
            let paidInFull = false
			let closed = false
			let cancelled = false
			let orderTerminology = "Order"
			if (restaurantId || restaurantCode) {
				restaurantData = await getRestaurantData(restaurantId, restaurantCode)
				if (restaurantData) {
					restaurantId = restaurantData.restaurant._id
					restaurant = restaurantData.restaurant
					restaurantName = restaurantData.name
                    restaurantPhone = restaurantData.phone
                    if (props.isDesktopView == false) {
                        loyaltyProgram = restaurantData.loyaltyProgram
                    }
					if (loyaltyProgram) hasLoyalty = true
					backOffice = restaurantData.backOffice
					if (locationInfo) {
						restaurant.locationInfo = locationInfo
					}

					if (restaurant && restaurant.branding && restaurant.branding.primaryColor) {
						let primaryColor = restaurant.branding.primaryColor
						global.primaryColor = primaryColor
						document.documentElement.style.setProperty('--primary-color', `${primaryColor}`);
						document.documentElement.style.setProperty('--purple', `${primaryColor}`);
						document.documentElement.style.setProperty('--purple-text', `${primaryColor}`);
					}
				}
			}
			
			if (restaurantId) {
				loyaltyAccount = await getLoyaltyAccountInfo(restaurantId) //todo move this into resturantData
				global.set({viewingReceipt: false})
				
				log.info("  loyaltyProgram: " + JSON.stringify(loyaltyProgram))
				log.info("  loyaltyAccount: " + JSON.stringify(loyaltyAccount))

				if (loyaltyProgram && props.isDesktopView == false) {
                    activePage = "LoyaltyWrapper"
				} 
				footer = true;
			} 
			if (restaurantData && !loyaltyProgram) {
				if (restaurantData.specials || restaurantData.content || restaurantData.topSellers || restaurantData.menuUrl) {
					activePage = "RestaurantInfoPage"
					header = false;
				} else {
					activePage = backOffice ? 'CheckStackPage' : 'ScanQrCodePage'
					header = true;
				}
				footer = true;
			}

			if (checkId || shortUrl || ticketId || (checkNumber && restaurantCode)) {
				log.info("GETTING CHECK ID FROM SHORT URL: " + shortUrl);
				// checkId = await getCheckIdFromShortUrl(shortUrl, debug)
				try {
					check = await getCheckDetails(checkId, personIdentifiers, ticketId, shortUrl, checkNumber, restaurantCode) //pass it all in
					
					ReactGA.event({
						category: GA_SCAN_CHECK,
						action: "Scanned check"
					});
				} catch (err) {
					console.error(err)
					if (global.pageStack.length > 0) {
						NotificationManager.warning('', 'Problem scanning reciept', 3000);
					}
					activePage = "ScanQrCodePage"
					ReactGA.event({
						category: GA_LOADING_RECEIPT,
						action: 'Receipt Not Found' 
					});
				}
				// retry logic
				if (!check) {
                    let timeLeft = 30000
                    const interval = 5000

                    async function retryUntilTimeout(){
                        activePage = 'LoadingPage'
                        const res = await getCheckDetails(checkId, personIdentifiers, ticketId, shortUrl, checkNumber, restaurantCode) //pass it all in
                        if(!res && timeLeft > 0){
                            timeLeft -= interval
                            setTimeout(async()=> await retryUntilTimeout(), interval)
                        }
                        if (!res && timeLeft <= 0) {
							//TODO: check logic
                            global.set({activePage:'CheckStackPage'})
                        }
                        if (res) {
                            // global.set({checkId})
							// initData() // seems like extra work
							check = res
                        }
                    }

                    await retryUntilTimeout()
                  
					// footer = true // disabled to keep the page a static error page
					header = true
					payNowPageText = 'Your bill is not ready. Please ask your server to reprint your bill.'
				}
				log.info("  CHECK ID: " + checkId);
			}

			if (check) {
				checkId = check._id
				table = check.table
				restaurantId = check.getVal('restaurant._id', "")
				personIdentifiers = check.personIdentifiers
				hasPersonIdentifiers = personIdentifiers && (personIdentifiers.length > 0) // what to do...this might need to happen over yonder on server
				backOffice = check.getVal('restaurant.backOffice', "")
				
				hasNonDashPayments = !!(f(check, 'totals.paidOtherCards', false) || f(check, 'totals.paidCash', false))
				restaurantName = check.restaurant ? check.restaurant.name : ""
				restaurantPhone = check.restaurant && check.restaurant.locationInfo ? check.restaurant.locationInfo.phone : ""
				
                if (props.isDesktopView == false) {
                    hasLoyalty = check.getVal('restaurant.hasLoyalty', hasLoyalty)
                }
				
				if (hasLoyalty) loyaltyAccount = await getLoyaltyAccountInfo(restaurantId)

				callAheadCheck = check.getVal('pickupDetails.phoneNumber', false)
				imHereEnabled = callAheadCheck && check.getVal('restaurant.config.features.imHere.enabled') // replaced curbside enabled
				selectVehicleEnabled = imHereEnabled && check.getVal('restaurant.config.features.imHere.selectVehicleEnabled')
				selectLocationEnabled = imHereEnabled && check.getVal('restaurant.config.features.imHere.selectLocationEnabled')
				feedbackEnabled = check.getVal('restaurant.config.mobile.allowFeedback', false)
				allowPartialPayments = check.getVal('restaurant.config.mobile.allowPartialPayments', false)
				optInEnabled = check.getVal('restaurant.config.mobile.allowOptIn', false)
				totalOnlyMode = !!check.totalValidationFailed
				splitCheckEnabled = check.getVal('restaurant.config.mobile.allowSplitPayments', false) && !callAheadCheck && !totalOnlyMode
				tipsEnabled = !!check.getVal('restaurant.config.mobile.allowTips')
				skipReceiptPage = !!check.getVal('restaurant.config.mobile.skipReceiptPage')
				tipPercentagesEnabled = !!check.getVal('restaurant.config.mobile.allowTipPercentages')
				customTipEnabled = !!check.getVal('restaurant.config.mobile.allowCustomTip')
				noTipButtonEnabled = !!check.getVal('restaurant.config.mobile.allowNoTipButton')
				displayItemModifiers = !!check.getVal('restaurant.config.mobile.displayItemModifiers')
				displayItemName = !!check.getVal('restaurant.config.mobile.displayItemName')
				displayItemDescription = !!check.getVal('restaurant.config.mobile.displayItemDescription')
				textToPayEnabled = !!check.getVal('restaurant.config.features.textToPay.enabled')
				payAtTableEnabled = !!check.getVal('restaurant.config.features.payAtTable.enabled')
				rightAlignHeaderLogo = !!check.getVal('restaurant.branding.rightAlignHeaderLogo')
				ccServiceChargePercent = check.getVal('restaurant.config.mobile.serviceCharges.ccServiceChargePercent', 0)
                ccServiceChargeAmount = check.getVal('restaurant.config.mobile.serviceCharges.ccServiceChargeAmount', 0)
                ccServiceChargeLabel = check.getVal('restaurant.config.mobile.serviceCharges.ccServiceChargeLabel', '')
				achServiceChargePercent = check.getVal('restaurant.config.mobile.serviceCharges.achServiceChargePercent', 0)
                achServiceChargeAmount = check.getVal('restaurant.config.mobile.serviceCharges.achServiceChargeAmount', 0)
                achServiceChargeLabel = check.getVal('restaurant.config.mobile.serviceCharges.achServiceChargeLabel', '')
                paymentTerms = check.getVal('restaurant.config.mobile.paymentTerms')
				orderTerminology = check.getVal('restaurant.config.features.textToPay.orderTerminology')
                paidInFull = check.getVal('paidInFull')
				closed = check.getVal('closed')
				cancelled = check.getVal('cancelled')
			
				log.info('callAheadCheck ' + callAheadCheck)
				log.info('totalOnlyMode ' + totalOnlyMode)
				log.info('imHereEnabled ' + imHereEnabled)
				log.info('feedbackEnabled ' + feedbackEnabled)
				log.info('splitCheckEnabled ' + splitCheckEnabled)
				log.info('tipsEnabled ' + tipsEnabled)
				log.info('skipReceiptPage ' + skipReceiptPage)



				if (callAheadCheck && !textToPayEnabled) {
					activePage = "ImHerePage" // For curbly stuff
				} else if (skipReceiptPage && !check.paidInFull) {
					check.items.forEach((item) => {
						item.locked = true
						item.lockedByMe = true
						item.lockedBy = localStorage.firstName + " " + localStorage.lastName
						item.lockedByInitials = localStorage.initials
					})
					check = checkUtils.updateTotals(check, 0) // dumb hack to get the you pay set
					activePage = "PaymentPage"
				} else {
					activePage = "ReceiptPage"
				}
				// activePage = "PaymentCompletePage"
				header = true;
				ReactGA.event({
					category: GA_LOADING_RECEIPT,
					action: 'Receipt Found' 
				});
			} else if (restaurantId && table) {
				let res
				try {
					if(backOffice){
						res = await getCheckListByTable(restaurantId, table, debug)
						checks = res.checks
					}
					ReactGA.event({
						category: GA_SCAN_TABLE_TENT,
						action: "Scanned table tent"
					});
				} catch (err) {
					console.error(err)
				}
				if (backOffice && checks && checks.length > 0) {
					activePage = "CheckStackPage"
					header = true;
					ReactGA.event({
						category: GA_LOADING_RECEIPT,
						action: 'Receipt Found' 
					});
				} else {
					if (restaurantData && (restaurantData.specials || restaurantData.content || restaurantData.topSellers || restaurantData.menuUrl)) {
						activePage = "RestaurantInfoPage"
						header = false;
					} else {
						activePage = backOffice ? 'CheckStackPage' : 'ScanQrCodePage'
						header = true;
					}
					ReactGA.event({
						category: GA_LOADING_RECEIPT,
						action: 'Receipt Not Found' 
					});
				}
			} else if (!restaurantId && !restaurantCode) {
				activePage = "ScanQrCodePage"
			}

			if (hasLoyalty && restaurantId && !signupReward && !loyaltyAccount) { 
				let signupRewardData = await getSignupReward(restaurantId)
				log.info("  getting signup reward " + JSON.stringify(signupRewardData))
				if (signupRewardData) {
					signupReward = signupRewardData.signupReward
					if (signupReward) {
						global.set({signupReward})
						log.info("  GOT Signup reward. Amount: " + signupReward.value)
					}
				}
			}

			global.set({
				checks,
				check,
				checkId,
				payNowPageText,
				restaurant: restaurant ? restaurant : (check ? check.restaurant : null),
				restaurantId,
				personIdentifiers,
				hasPersonIdentifiers,
				backOffice,
				restaurantName,
				restaurantPhone,
				restaurantData,
				loyaltyProgram,
				allowPartialPayments,
				loyaltyAccount, 
				header,
                footer,
                finishedPaying: paidInFull || closed || cancelled,
				checkCancelled: cancelled,
				orderTerminology: orderTerminology,
				hasNonDashPayments,
				totalOnlyMode,
				displayItemModifiers,
				displayItemName,
				displayItemDescription,
				lockedAll,
				splitCheckEnabled,
				optInEnabled,
				tipsEnabled,
				tipPercentagesEnabled,
				customTipEnabled,
				noTipButtonEnabled,
				imHereEnabled,
				textToPayEnabled,
				payAtTableEnabled,
				rightAlignHeaderLogo,
				callAheadCheck,
				feedbackEnabled,
				skipReceiptPage,
				ccServiceChargePercent,
				ccServiceChargeAmount,
				ccServiceChargeLabel,
				achServiceChargePercent,
				achServiceChargeAmount,
				achServiceChargeLabel,
				paymentTerms,
				selectVehicleEnabled,
				selectLocationEnabled,
				activePage,
			});
		}
		initData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	return (
		<div style={{marginTop: -28}} className="splash-screen">
            <Launch />
            {(ticketId && ticketId == "null") ?
				<span>No Check Found</span>
				:
				<div className="loading-dot">.</div>
			}
        </div>
	);
};
