import log from 'loglevel';

export default async function logSmsScan(shortUrl, debug = false) {
	// return new Promise(async (resolve, reject) => {
	try {
		if (debug) log.info('***** LOG SMS SCAN API ****')
		let url = `${global.dashServerUrl}/api/v1/log/logSmsScan`
		let body = {
			smsShortUrl: shortUrl,
			deviceId: localStorage.deviceId
		}
		if (debug) log.info('BODY: ' + JSON.stringify(body))
		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
			cache: "no-store",
		}
		fetch(url, options, debug).then((res) => {
			// if (debug) log.info(`STATUS: ${res.status}`);
			// if (debug) log.info(`RESPONSE:`);
			// if (debug) log.info(JSON.stringify(res));
			return true
		}).catch((err) => {
			return(err.message);
		});
	} catch (err) {
		log.error(err)
	}
	// })
}
