import React from 'react';

export default (props) => {
    return (
        <svg width="80px" height="55px" viewBox="0 0 80 55" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard" transform="translate(-17.000000, -7.000000)">
                    <g id="Group-3" transform="translate(16.000000, 5.000000)">
                        <g id="Group" fill="#000000" fill-rule="nonzero">
                            <g transform="translate(0.052734, 1.998047)">
                                <path d="M7.832,0.0019531 C4.0174,0.0019531 0.9472,3.0740531 0.9472,6.8886531 L0.9472,41.0606531 C0.9472,44.8752531 4.0174,47.9473531 7.832,47.9473531 L66.057,47.9473531 C69.8716,47.9473531 72.9437,44.8752531 72.9437,41.0606531 L72.9437,6.8886531 C72.9437,3.0740531 69.8716,0.0019531 66.057,0.0019531 L7.832,0.0019531 Z M9.1582,2.7871531 L64.7302,2.7871531 C67.5002,2.7871531 69.7302,5.0171531 69.7302,7.7871531 L69.7302,40.1621531 C69.7302,42.9321531 67.5002,45.1621531 64.7302,45.1621531 L9.1582,45.1621531 C6.3882,45.1621531 4.1582,42.9321531 4.1582,40.1621531 L4.1582,7.7871531 C4.1582,5.0171531 6.3882,2.7871531 9.1582,2.7871531 Z" id="Shape"></path>
                                <rect id="Rectangle" x="3.2539" y="11.199" width="67" height="7"></rect>
                                <rect id="Rectangle" x="12.202" y="22.782" width="24.319" height="2.1805"></rect>
                            </g>
                        </g>
                        <g id="Group-2" transform="translate(63.000000, 39.000000)">
                            <circle id="Oval" fill="#000000" cx="9" cy="9" r="9"></circle>
                            <polygon id="Combined-Shape" fill="#FFFFFF" fill-rule="nonzero" points="10.2 3 10.2 7.8 15 7.8 15 10.2 10.2 10.2 10.2 15 7.8 15 7.8 10.2 3 10.2 3 7.8 7.8 7.8 7.8 3"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}