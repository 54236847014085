import React from 'react';

export default (props) => {
    return (
        <svg width="58px" height="55px" viewBox="0 0 58 55" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink">
            <title>Group 2</title>
            <desc>Created with Sketch.</desc>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Artboard" transform="translate(-1349.000000, -326.000000)" fill-rule="nonzero">
                    <g id="Group-2" transform="translate(1349.000000, 326.000000)">
                        <g id="Group" fill="#000000">
                            <path d="M4,18 L10,18 L10,32 L4,32 L4,18 Z M17,18 L23,18 L23,32 L17,32 L17,18 Z M0,36 L40,36 L40,42 L0,42 L0,36 Z M30,18 L36,18 L36,32 L30,32 L30,18 Z M20,0 L0,10 L0,14 L40,14 L40,10 L20,0 Z" id="Shape"></path>
                        </g>
                        <g transform="translate(40.000000, 37.000000)">
                            <circle id="Oval" fill="#000000" cx="9" cy="9" r="9"></circle>
                            <polygon id="Combined-Shape" fill="#FFFFFF" points="10.2 3 10.2 7.8 15 7.8 15 10.2 10.2 10.2 10.2 15 7.8 15 7.8 10.2 3 10.2 3 7.8 7.8 7.8 7.8 3"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}