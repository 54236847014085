import log from 'loglevel';
import dashFetch from './dashFetch';
const uuidv4 = require('uuid/v4');

export default async function (fullName, phoneNumber, checkId, restaurantId, joinLoyaltyProgram, debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('create account api');

		const url = `${global.dashServerUrl}/api/v1/account/createAccount`;
		let deviceId = localStorage.deviceId;
		fullName = fullName.trim()
		const firstName = (fullName.substring(0, fullName.lastIndexOf(" ") + 1)).trim();
		const lastName = (fullName.substring(fullName.lastIndexOf(" ") + 1, fullName.length)).trim();
		localStorage.fullName = fullName
		localStorage.firstName = firstName
		localStorage.lastName = lastName
		localStorage.initials = localStorage.firstName.toUpperCase().substring(0, 1) + localStorage.lastName.toUpperCase().substring(0, 1)
		localStorage.savedCards = []
		const utcDateOffset = new Date().getTimezoneOffset()

		const body = {
			firstName,
			lastName,
			phoneNumber,
			checkId,
			restaurantId,
			joinLoyaltyProgram,
			mobileDeviceInfo: {
				deviceId,
			},
			utcDateOffset
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}


		dashFetch(url, body, debug).then(({ res, json }) => {
			log.info("json")
			log.info(json)
			if (json.success) {
				let data = json.data
				if (debug) log.info('success');
				/* SAVE ACCOUNT DATA LOCALLY */
				localStorage.token = data.token
				localStorage.refreshToken = data.refreshToken
				localStorage.tokenTimeout = data.tokenTimeout
				localStorage.refreshTimeout = data.refreshTimeout
				localStorage.accountId = data.accountId

				if (data.alwaysEmailReceipt) {
					localStorage.alwaysReceiveEmailedReceipt = data.alwaysEmailReceipt
				}
				//TODO - set alwaysReceiveEmailedReceipt from server on re-login
				//localStorage.alwaysReceiveEmailedReceipt = xxx
				if (data.loyaltyAccount) {
					global.set({loyaltyAccount: data.loyaltyAccount})
				}
				resolve(true);
			} else {
				log.error(json.errors)
				reject(json.errors);
			}
		}).catch((err) => { 
			log.error(err)
			reject(err.message);
		 })
	})
}