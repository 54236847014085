import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Button, Slide } from '@material-ui/core';
import checkUtils from '../../util/checkUtils';
import logCheckScan from '../../actions/logCheckScan'
import ScanHandNfc from '../../resources/images/ScanHandNfc.js';
import getCheckListByTable from '../../actions/getCheckListByTable';
import Paid from '../../assets/Paid.js';

export default (props) => {
    const f = global.f;
	let [checks] = global.get("checks")
	let [table] = global.get("table")
	let [geo] = global.get("geo")
	let [payNowPageText] = global.get("payNowPageText")
	let [restaurantId] = global.get("restaurantId")

	if (geo) {
		payNowPageText = "We were unable to find your order."
	}

	useEffect(() => {
		if (geo && checks && checks.length == 1 && checks[0]._id) {
			checkClicked(checks[0]._id)
		}
	}, [checks]);

	global.set({viewingReceipt: false})

	window.onbeforeunload = function() { return "Are you sure you want to leave?"; }

	const checkClicked = (checkId) => {
		global.pageStack.push({ pageName: "CheckStackPage", fn: () => {
			global.set({ footer: true })
			if (global.checkClient) {
				global.checkClient.onclose = null // ensure the ws doesn't try to reconnect
				global.checkClient.close()
				global.checkClient = null
			}
			updateCheckStack(restaurantId, table)
		}
		});
		logCheckScan(checkId)
		global.set({
			allowBack: true,
			checkId: checkId,
			footer: false,
			header: false,
			activePage: "LoadingPage",
		});
	}


	const updateCheckStack = async (restaurantId, table) => {
		try {
			let res = await getCheckListByTable(restaurantId, table, false)
			checks = res.checks
			checks = checks.sort((a, b) => b.createdAt - a.createdAt)
		} catch (err) {
			// console.error(err)
		}
		if (checks && checks.length > 0) {
			global.set({
				checks,
			})
		}
	}

	return (
		<Slide in direction="left">
			<div style={{padding: "0 10px 64px 10px", overflowY: "auto", height: "100%"}}>
				{ checks && checks.length > 0 &&
					<>
						<Grid container justify="center">
							<Typography align="center" color="primary" display="block" style={{marginTop: 32, fontSize: window.innerWidth * 0.06, fontWeight: "300"}}>Please Select Your Check Below</Typography>
						</Grid>
						{checks.length > 1 && (
							<>
								{checks.map((check, index) => {
                                    // console.log('check is', check)
									return (
										<div key={check._id} disabled={check.paidInFull} onClick={(event) => {checkClicked(check._id)}}>
											{index != 0 &&
												<Divider />
											}
											<Grid container alignItems="center" justify="center" style={{position: 'relative'}}>
												<Grid item xs={9} alignItems="center" style={{padding: '5px'}}>
													<p style={{maxWidth: '100%', overflowWrap: 'normal', color: 'var(--purple)'}} color="primary">
														{check.description}
													</p>
												</Grid>
												<Grid item xs={3}>
													<Button variant="contained" color="primary" fullWidth>${checkUtils.formatMoney(check.totals ? check.totals.total : check.total)}</Button>
												</Grid>
												{check.paidInFull && <div style={{position: 'absolute', top: 'auto', bottom: 'auto', left: 'auto', right: 'auto'}}><Paid width={60} height={48} fill={f(check, 'totals.paidDashNow', false)? '#9160DC' : null}/></div>}
											</Grid>
										</div>
									);
								})}
							</>
						)}
						{checks.length === 1 &&
							<Grid container justify="center" spacing={1} style={{marginTop: 25, position: 'relative'}}>
								<Grid container justify="center">
									<Typography variant="h5" style={{color: '#545454', paddingRight: 6}}>{(checks[0] && checks[0].total) ? `$`: ''}</Typography>
									<Typography variant="h3" style={{color: '#545454'}}>{(checks[0] && (checks[0].total || checks[0].totals)) ? `${checkUtils.formatMoney(checks[0].totals && checks[0].totals.total ? checks[0].totals.total : checks[0].total)}`: ''}</Typography>
								</Grid>
								<Grid container justify="center" style={{marginTop: 25}}>
									<Button variant="contained" color="primary" onClick={(event) => { checkClicked(checks[0]._id)}}>View My Check</Button>
									{checks[0].paidInFull && <div style={{position: 'absolute', top: 150, bottom: 'auto', left: 'auto', right: 'auto'}}><Paid width={307} height={247} opacity={0.5} fill={f(checks[0], 'totals.paidDashNow', false)? '#9160DC' : null}/></div>}
								</Grid>
							</Grid>
						}
					</>
				}
				{(!checks || checks.length === 0) &&
					<>

						<Grid container justify="center" style={{marginTop: '30%'}}>
							<ScanHandNfc height={window.innerHeight * 0.25} />
						</Grid>
						<Grid container justify="center" style={{marginTop: '5%'}}>
							<Typography style={{fontSize: "1.8em", fontWeight:600, color: 'var(--purple)'}}>Pay&nbsp;</Typography>
							<Typography autoCapitalize={false} align="center" display="block" style={{fontSize: "1.8em", fontWeight: "300", color: 'var(--purple)'}}>Now</Typography>
						</Grid>
						<Typography align="center" style={{margin: '15%', marginTop: '3%', color: '#545454'}}>
							{ payNowPageText ? payNowPageText : "Your bill is not ready at this time. Please come back here when you are ready to pay."}
						</Typography>
					</>
				}
			</div>
		</Slide>
	);
};
