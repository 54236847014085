import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import saveCardToken from '../../actions/saveCardToken';
import '../../util/modalCSS.css';
import getIFrameKey from '../../actions/getIFrameKey'

export default (props) => {
    let [check] = global.get('check');
    let [restaurant] = global.get('restaurant');
    const myTotal = global.f(check, 'myTotals.youPay', 0)
    const [ fromDashboard ] = global.get('fromDashboard')
    let transactionToken

    const cardTypeMap = {
        "VISA": "visa",
        "DISC": "discover",
        "AMEX": "american_express",
        "MC": "master",
    }

    async function initiateLightbox() {
        const response = await getIFrameKey(restaurant._id, myTotal, localStorage.accountId)
        log.info(response)
        transactionToken = response.data;
        openLightbox()

        var addRule = (function (style) {
            var sheet = document.head.appendChild(style).sheet;
            return function (selector, css) {
                var propText = typeof css === "string" ? css : Object.keys(css).map(function (p) {
                    return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
                }).join(";");
                sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
            };
        })(document.createElement("style"));

        addRule("#id_recaptcha .ng-binding", {
            "text-indent": "-9999px",
            "line-height": "0",
        });

        addRule("#id_recaptcha .ng-binding::after", {
            "content": "Continue",
            "text-indent": "0",
            "display": "block",
            "line-height": "initial",
        });
    }
    function openLightbox() {
        var paymentFields = {
            ssl_txn_auth_token: transactionToken
        };
        var callback = {
            onError: function (error) {
                log.info("error" + JSON.stringify(error))
                window.closeAllPanels();
            },
            onCancelled: function () {
                log.info("cancelled")
                window.closeAllPanels();
            },
            onDeclined: function (response) {
                log.info("declined" + JSON.stringify(response))
                window.closeAllPanels();
            },
            onApproval: async function (response) {
                /*
                {
                    "ssl_merchant_initiated_unscheduled": "N",
                    "ssl_transaction_type": "GETTOKEN",
                    "ssl_card_number": "40**********0002",
                    "ssl_transaction_reference_number": "813161128",
                    "ssl_result": "0",
                    "ssl_txn_id": "130821AD3-5707244A-CDF6-43AE-97AA-1C5D62D002B6",
                    "ssl_avs_response": "A",
                    "ssl_approval_code": "351729",
                    "ssl_avs_address": "1234",
                    "ssl_avs_zip": "12345",
                    "ssl_txn_time": "08/13/2021 12:11:28 PM",
                    "ssl_account_balance": "0.00",
                    "ssl_ps2000_data": "A7512255828986435050VE",
                    "ssl_exp_date": "1123",
                    "ssl_result_message": "APPROVAL",
                    "ssl_card_short_description": "VISA",
                    "ssl_token_response": "SUCCESS",
                    "ssl_card_type": "CREDITCARD",
                    "ssl_cvv2_response": "M",
                    "ssl_token": "4692536310000002"
                }
                */
                log.info("approval" + JSON.stringify(response))
                let type = response.ssl_card_short_description
                if (cardTypeMap[type]) {
                    type = cardTypeMap[type]
                }
                let token = response.ssl_token
                let lastFour = response.ssl_card_number.substr(-4)
                let expMonth = response.ssl_exp_date.substr(0,2)
                let expYear = response.ssl_exp_date.substr(-2)
                let fullName = ''
                let zipCode = response.ssl_avs_zip
                let addressLine1 = response.ssl_avs_address
                let additionalTokenData = response.ssl_ps2000_data
                let resp = await saveCardToken(type, token, lastFour, 'fusebox', false, expMonth, expYear, fullName, zipCode, fromDashboard, null, addressLine1, null, additionalTokenData)
                window.closeAllPanels();
                if (resp.card) {
                    props.next(resp.card)
                }
            }
        };
        window.PayWithConverge.open(paymentFields, callback);
        return false;
    }

    useEffect(()=>{
        log.info(restaurant.paymentMethods.creditCard)

        // <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
        let script = document.createElement('script')
        script.setAttribute('src', 'https://code.jquery.com/jquery-3.6.0.min.js')
        script.setAttribute('integrity', 'sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=')
        script.setAttribute('crossorigin', 'anonymous')
        document.head.appendChild(script);

        // <script src="https://demo.convergepay.com/hosted-payments/PayWithConverge.js"></script>
        let script2 = document.createElement('script')
        script2.setAttribute('src', 'https://api.convergepay.com/hosted-payments/PayWithConverge.js')
        document.head.appendChild(script2);
        initiateLightbox();
    },[])

	return <></>
}