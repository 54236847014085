import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'

export default async function getIFrameKey(restaurantId, amount, accountId, checkId, debug) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** Get iFrame Key Api ****')
		const url = `${global.dashServerUrl}/api/v1/gateway/getIFrameKey`;

		const body = {
            restaurantId,
            amount,
            accountId,
            checkId
		}
		log.info("WOO")

		dashFetch(url, body, debug).then(({ res, json }) => {
			if (!json.success) {
				reject(json.errors)
			} else {
				resolve(json);
			}
		}).catch((err) => { reject(err) });
	})
}