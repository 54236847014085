import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
import saveCardToken from '../../actions/saveCardToken';
import LockIcon from '@material-ui/icons/Lock';
import { NotificationManager } from 'react-notifications';
import '../../util/modalCSS.css'
import DashLoad from '../../assets/Dashnow-Loading.gif';
import { Typography, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from '@material-ui/core';
import ReactGA from 'react-ga';
import userUpdateAccount from '../../actions/userUpdateAccount';
import SpreedlyForm from './SpreedlyForm';
import HeartlandForm from './HeartlandForm';
import DatacapForm from './DatacapForm';
import TSYSForm from './TSYSForm';
import CardConnectForm from './CardConnectForm';
import Zoom from '@material-ui/core/Zoom';
import AuthNetForm from './AuthNetForm';
import WorldpayForm from './WorldpayForm';
import Shift4Form from './Shift4Form';
import FuseboxForm from './FuseboxForm';

export default (props) => {
	const restaurant = props.restaurant
	const [awaitingPaymentResponse, setAwaitingPaymentResponse] = useState(false);
	const [ openDialog, setOpenDialog ] = useState(false);
	const GA_CATEGORY_PAYMENT_ADD_CARD = "Payment add card"
    const [tokenizationPlatform, setTokenizationPlatform] = useState(global.f(restaurant, 'paymentMethods.creditCard.gatewayData.gatewayTypeCode', 'spreedly'));
    const [innerHeight, setInnerHeight] = useState(window.innerHeight)

    const formatted = {
        spreedly: 'Spreedly',
        heartland: 'Heartland',
        datacap: 'Datacap',
        tsys: 'TSYS',
        cardConnect: 'CardConnect',
        authnet: 'Authorize.Net',
        tsysTest: 'TSYS Test',
        worldpay: 'Worldpay',
        shift4: 'Shift4'
    }

	let [check] = global.get("check")
	let spreedlyEnvironmentKey = global.f(restaurant, 'paymentMethods.creditCard.gatewayData.spreedlyEnvironmentKey', "")

	const c = makeStyles((theme) => ({
		separator: {
			height: 1,
			width: '100%',
			backgroundColor: '#ccc',
		},
		topContainer: {
			width: '100%',
			height: 48,
			maxHeight: 48,
			backgroundColor: 'none',
		},
		topWrapper: {
			width: '100%',
			height: '100%',
            display: 'flex',
            marginTop: '2px',
			justifyContent: 'space-between'
		},
		topText: {
			height: 16,
			fontSize: 16,
			fontWeight: 400,
		},
		modalContainer: {
			width: '100%',
			height: '100%',
			backgroundColor: 'var(--mask)',
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: 1400,
			elevation: 100,
			paddingTop: '10px'
		},
		modalWrapper: {
			width: '100%',
			position: 'absolute',
			left: 0,
			bottom: 0,
            right: 0,
            backgroundColor: '#FFFFFF',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
        },
        dialogWrapper: { //isDesktopView
            zIndex: 66600,
            width: tokenizationPlatform == ('worldpay') ? '490px' : '360px',
            // height: tokenizationPlatform == ('worldpay') ? '480px' : '440px',
            position:'fixed',
            top:'calc(50vh - 220px)',
            left:'calc(50vw - 180px)',
            border:'1px solid #F5F5F5',
            backgroundColor:'white',
            paddingBottom:16
        },
		form: {
			width: '100%',
			height: 330,
			backgroundColor: 'none',
			paddingTop: 16,
		},
		label: {
			fontSize: 14,
			fontWeight: 400,
			color: 'rgba(0, 0, 0, 0.6)',
			textAlign: 'center',
			marginBottom: 8,
		},
		cardNumIconWrapper: {
			display: 'inline-flex', 
			position: 'relative',
			width: 250,
			transform: 'translateX(-55%)',
			left: '50%',
			marginBottom: 8,
		},
		nameInput: {
			width: 212,
			height: 44,
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)',
			marginRight: 'auto',
			marginLeft: 'auto',
			border: '1px solid #999999',
			borderRadius: 0,
			padding: 6,
			fontSize: 16,
			fontWeight: '500',
			color: 'rgb(97,97,97)',
			marginBottom: 8,
			appearance: 'none'
		},
		securityFAQ: {
			marginTop: '.37em',
			color: 'var(--light-gray)'
		},
		expCvvWrapper: {
			width: 212,
			height: 44,
			display: 'inline-flex',
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)',
			marginRight: 'auto',
			marginLeft: 'auto',
			marginBottom: 8,
			padding: 0,
		},
		expWrapper: {
			display: 'flex',
			justifyContent: 'center',
			height: 44,
			border: '1px solid #999999',
			padding: 0,
			margin: 0,
			marginRight: 18,
			backgroundColor: '#FFFFFF'
		},
		expSlash: {
			color: '#999999',
			alignSelf: 'center',
			marginLeft: 5,
		},
		inputExpMonth: {
			width: 48,
			color: '#616161',
			backgroundColor: '#FFFFFF',
			fontSize: 16,
			fontPadding: 'none',
			border: 'none',
			padding: 6,
			paddingLeft: 10,
			marginRight: 0,
			outline: 'none',
			'-webkit-border-radius': 0,
			'-moz-border-radius': 0,
			'border-radius': 0,
			'-khtml-border-radius': 0,
		},
		inputExpYear: {
			width: 48,
			color: '#616161',
			backgroundColor: '#FFFFFF',
			fontSize: 16,
			fontPadding: 'none',
			border: 'none',
			padding: 6,
			paddingLeft: 12,
			marginLeft: 0,
			outline: 'none',
			'-webkit-border-radius': 0,
			'-moz-border-radius': 0,
			'border-radius': 0,
			'-khtml-border-radius': 0,
		},
		submitButton: {
			position: 'absolute',
			left: '33%',
			right: '33%',
			bottom: 16,
			marginLeft: 'auto',
			marginRight: 'auto',
        },
        lock: {
            color:'var(--dark-gray)',
            opacity:'.4',
            display:'inline-block',
            position:'relative',
            top:'5px',
            left: '3px'
        }
	}))();
	
	const hideKeyboard = () => {
		//this set timeout needed for case when hideKeyborad
		//is called inside of 'onfocus' event handler
		setTimeout(function () {

			//creating temp field
			var field = document.createElement('input');
			field.setAttribute('type', 'text');
			//hiding temp field from peoples eyes
			//-webkit-user-modify is nessesary for Android 4.x
			field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
			document.body.appendChild(field);

			//adding onfocus event handler for out temp field
			field.onfocus = function () {
				//this timeout of 200ms is nessasary for Android 2.3.x
				setTimeout(function () {

					field.setAttribute('style', 'display:none;');
					setTimeout(function () {
						document.body.removeChild(field);
						document.body.focus();
					}, 14);

				}, 200);
			};
			field.focus();

		}, 50);
		window.Spreedly.on("ready", () => {}) //have to remove the on ready or else the keyboard will keep popping up
	}

	useEffect(() => {
		window.saveCardToken = saveCardToken;
		window.NotificationManager = NotificationManager;
		window.log = log;
		window.closeAllPanels = props.closeAllPanels;
		window.hideKeyboard = hideKeyboard;
        window.addEventListener('resize', function(e) {
			// console.log('window.resize')
			setInnerHeight(window.innerHeight)
		  })
	
	}, []);

	const theOnSubmit = async (e) => {
		if (!awaitingPaymentResponse) {
			setAwaitingPaymentResponse(true)
			const zipCode = document.querySelector('#zipCode').value
			if (zipCode) {
				window.submitPaymentForm();
			} else {
				setAwaitingPaymentResponse(false)
				document.getElementById('zipCode').style.borderColor = '#f00';
			}
			// setTimeout(setAwaitingPaymentResponse(false), 5000);
		}
		e.preventDefault();
		return false;
	}

	const closePanel = () => {
		window.hideKeyboard();
		global.set({ activePanel: '' });
		window.Spreedly.removeHandlers();
		ReactGA.event({
			category: GA_CATEGORY_PAYMENT_ADD_CARD,
			action: "Cancel clicked in Add Card"
		});
	}
    
	return tokenizationPlatform == 'fusebox' ? <FuseboxForm next={props.next} /> :
			<div id="modalContainer" className={c.modalContainer}>
				<div className={props.isDesktopView ? c.dialogWrapper : c.modalWrapper}>
					<div className={c.topContainer}>
						<div className={c.topWrapper}>
							<Typography className={c.topText} style={{ marginLeft: 14, marginTop: 2 }}>
								ADD CREDIT OR DEBIT CARD
								<Zoom in={props.showPanel} timeout={{ appear: 500, enter: 1000, exit: 500 }}>
									<LockIcon onClick={() => setOpenDialog(true)} className={c.lock} />
								</Zoom>
							</Typography>

							<div className={c.topText} style={{ color: '#999999', marginRight: 10, marginTop: 4 }} onClick={() => { closePanel(); }}><Typography variant="overline" color="primary">Cancel</Typography></div>
						</div>
					</div>
					<div className={c.separator}></div>
					<div style={{ maxHeight: innerHeight - 48, overflow: 'auto' }}>
						{tokenizationPlatform == 'spreedly' ? <SpreedlyForm next={props.next} /> : null}
						{tokenizationPlatform == 'heartland' ? <HeartlandForm next={props.next} /> : null}
						{tokenizationPlatform == 'tsys' || tokenizationPlatform == 'tsysTest' ? <TSYSForm next={props.next} /> : null}
						{tokenizationPlatform == 'datacap' ? <DatacapForm next={props.next} /> : null}
						{tokenizationPlatform == 'cardConnect' ? <CardConnectForm next={props.next} /> : null}
						{tokenizationPlatform == 'shift4' || tokenizationPlatform == 'shift4Test' ? <Shift4Form next={props.next} /> : null}
						{tokenizationPlatform == 'authnet' ? <AuthNetForm next={props.next} getAuthnetCards={props.getAuthnetCards} restaurant={restaurant} /> : null}
						{tokenizationPlatform == 'worldpay' ? <WorldpayForm next={props.next} /> : null}
					</div>
				</div>
				<Dialog
					aria-labelledby="simple-dialog-title"
					open={openDialog}
					style={{ zIndex: 1500 }}
				>
					<DialogTitle id="simple-dialog-title">Is my credit card secure?</DialogTitle>
					<DialogContent>
						DashNow never receives or stores any of your credit card information.
						<br /><br />
						Our partner {formatted[tokenizationPlatform]} stores all credit card data in their secure vault. {' '}
						{formatted[tokenizationPlatform]} has been granted the maximum level of PCI compliance ensuring
						your credit card data is always safe.
					</DialogContent>
					<DialogActions style={{ marginTop: 0, height: '10%' }}>
						<Button onClick={() => { setOpenDialog(false) }} color="primary">
							Continue
						</Button>
					</DialogActions>
				</Dialog>
			</div>
	;
};