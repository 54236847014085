import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import saveCardToken from '../../actions/saveCardToken';
import getTsysManifest from '../../actions/getTsysManifest';
import userUpdateAccount from '../../actions/userUpdateAccount';

export default (props) => {
    const [restaurant] = global.get("restaurant")
	const [ fullName, setFullName ] = useState(localStorage.fullName ? localStorage.fullName : '');
    const [isLoading, setIsLoading] = useState(false)
    const [customerName, setCustomerName] = useState('')
    const [ fromDashboard ] = global.get('fromDashboard')

    const merchantId = global.dashTsysMerchantId
    const deviceId = global.dashTsysDeviceId 
    const amount = '000'

	const c = makeStyles((theme) => ({
        formWrapper: {
            textAlign: 'center',
			marginTop: 6,
            width:'300px',
            margin:'auto',
            transform: 'translate(-6px)'
        }
    }))();
    
    useEffect(()=>{
        runTsysScripts()
    }, [])

    async function runTsysScripts() {
        setIsLoading(true)
        const tsepHandler = document.createElement("script");
        tsepHandler.type="text/javascript"
        const scriptText = document.createTextNode(`function tsepHandler(eventType, event) {
                window.tokenEvent = event
        }`)
        tsepHandler.appendChild(scriptText)
        document.head.appendChild(tsepHandler);

        let gatewayId = restaurant.paymentMethods.creditCard.gatewayData.gatewayId
        if (gatewayId && gatewayId !== "") {
            let resp = await getTsysManifest(restaurant, gatewayId, merchantId, deviceId, amount);
            let manifest
            if(resp.success && resp.encryptedManifest){
                // console.log("TSYS MANIFEST RESPONSE: " + JSON.stringify(resp))
                manifest = resp.encryptedManifest
                const script = document.createElement("script");
                script.async = true;
                script.onload = setIsLoading(false)
                if (restaurant.paymentMethods.creditCard.gatewayData.isTest) {
                    script.setAttribute('src',`https://stagegw.transnox.com/transit-tsep-web/jsView/${deviceId}?${manifest}`) // STAGE SANDBOX URL
                } else {
                    script.setAttribute('src',`https://gateway.transit-pass.com/transit-tsep-web/jsView/${deviceId}?${manifest}`) // PROD URL
                }
                document.head.appendChild(script);
            }
        }
    }

	const handleFullNameUpdate = (fullName) => {
		if (fullName.indexOf(' ') > 0 && !localStorage.fullName) {
			localStorage.setItem('fullName', fullName)
			let firstSpaceIndex = fullName.indexOf(' ')
			let firstName = fullName.slice(0, firstSpaceIndex)
			log.info('firstName: ' + firstName)
			let lastName = fullName.slice(firstSpaceIndex)
			log.info('lastName: ' + lastName)
			userUpdateAccount({firstName, lastName})
		}
    }

    const findCardType = (cardInitial) => {
        const cardTypeIdentifier = cardInitial
        if(cardTypeIdentifier === 'X'){
            return 'amex'
        }
        if(cardTypeIdentifier === 'V'){
            return 'visa'
        }
        if(cardTypeIdentifier === 'M'){
            return 'mastercard'
        }
        if(cardTypeIdentifier === 'R'){
            return 'discover '
        }
        if(cardTypeIdentifier === 'J'){
            return 'jcb'
        }
    }

    async function clickSaveCard(){
        setIsLoading(true)
        if (window && window.tokenEvent && window.tokenEvent.responseCode){
            const tsysResp = window.tokenEvent;
            if (tsysResp.responseCode == 'A0000'){
                if(fullName)handleFullNameUpdate(fullName);
                const type = findCardType(tsysResp.cardType);
                const token = tsysResp.tsepToken;
                const lastFour = tsysResp.maskedCardNumber;
                const expMonth = tsysResp.expirationDate.substr(0,2)
                const expYear = tsysResp.expirationDate.split('/')[1]
                if (type != null && token != null && lastFour != null) {
                    let resp = await saveCardToken(type, token, lastFour, 'tsys', true, expMonth, expYear, customerName, null, fromDashboard);
                    if(resp.success){
                        NotificationManager.info('', 'Card save successfully', 3000)
                        window.closeAllPanels();
                        setIsLoading(false)
                        if (resp.card) {
                            props.next(resp.card)	
                        }
                    }
                }
                else {
                    NotificationManager.warning('', 'Unable to save card, please try again', 3000)
                    runTsysScripts()
                    setIsLoading(false)
                }
            } else {
                NotificationManager.warning('', 'One or more fields is incorrect', 3000)
                runTsysScripts()
                setIsLoading(false)
            }
        } else {
            setTimeout(clickSaveCard, 2000)
        }

    }

    if (isLoading) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <CircularProgress
                    color={restaurant && restaurant.branding && restaurant.branding.primaryColor ? restaurant.branding.primaryColor : 'var(--purple)'}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        height: '100px',
                        width: '100px',
                        marginTop: '-30px',
                        marginLeft: '-50px',
                        opacity: '20%'
                    }} />
            </div>
        )
    }

	return (
            <div className={c.formWrapper}>
                <div id="root"></div>
                <form method="POST" id="payment-form">
                    <input placeholder="Customer Name" id="tsep-customer-name" className="tsys-input" 
                        onChange={e => setCustomerName(e.target.value)}>
                    </input>
                    <div id="tsep-cardNumDiv" className="tsys-input"></div>
                    <div id="tsep-datepickerDiv" className="tsys-input"></div>
                    <div id="tsep-cvv2Div" className="tsys-input"></div>
                </form>
                <Button variant="contained" 
                    color={restaurant && restaurant.branding.primaryColor ? restaurant.branding.primaryColor : 'primary'}
                    onClick={clickSaveCard}
                    style={{ marginTop: '10px', marginLeft: '6px', width: '220px' }}>
                    SAVE CARD
                </Button>
            </div>
	);
};