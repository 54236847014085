import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import saveCardToken from '../../actions/saveCardToken';
import { NotificationManager } from 'react-notifications';
import '../../util/modalCSS.css'
import { Typography, Button, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';

export default (props) => {
	const [awaitingPaymentResponse, setAwaitingPaymentResponse] = useState(false);
    const [saveCardBtn, setSaveCardBtn] = useState(false);
    const [isOneTimeUseCard, setIsOneTimeUseCard] = useState(false)
	const [ fromDashboard ] = global.get('fromDashboard')

	let [check] = global.get("check")
	let spreedlyEnvironmentKey = ""
	if (check && check.restaurant && check.restaurant.paymentMethods && check.restaurant.paymentMethods.creditCard && check.restaurant.paymentMethods.creditCard.gatewayData && check.restaurant.paymentMethods.creditCard.gatewayData.spreedlyEnvironmentKey) {
		spreedlyEnvironmentKey = check.restaurant.paymentMethods.creditCard.gatewayData.spreedlyEnvironmentKey
	}
	let currency = global.f(check, 'restaurant.currency', "USD")

	const c = makeStyles((theme) => ({
		form: {
			width: '100%',
			backgroundColor: 'none',
			paddingTop: 8,
		},
		label: {
			fontSize: 14,
			fontWeight: 400,
			color: 'rgba(0, 0, 0, 0.6)',
			textAlign: 'center',
			marginBottom: 8,
		},
		cardNumWrapper: {
			display: 'inline-flex', 
			position: 'relative',
			width: 250,
			transform: 'translateX(-50%)',
			left: '50%',
			marginBottom: 8
		},
		nameInput: {
			width: 213,
			height: 44,
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)',
			marginRight: 'auto',
			marginLeft: 'auto',
			border: '1px solid #999999',
			borderRadius: 0,
			padding: 6,
			fontSize: 16,
			fontWeight: '500',
			color: 'rgb(97,97,97)',
			marginBottom: 8,
			appearance: 'none'
		},
		securityFAQ: {
			marginTop: '.37em',
			color: 'var(--light-gray)'
		},
		expCvvWrapper: {
			width: 212,
			height: 44,
			display: 'inline-flex',
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)',
			marginRight: 'auto',
			marginLeft: 'auto',
			marginBottom: 8,
			padding: 0,
		},
		expWrapper: {
			display: 'flex',
			justifyContent: 'center',
			height: 44,
			border: '1px solid #999999',
			padding: 0,
			margin: 0,
			marginRight: 20,
			marginLeft: -1,
			backgroundColor: '#FFFFFF'
		},
		expSlash: {
			color: '#999999',
			alignSelf: 'center',
			marginLeft: 5,
		},
		inputExpMonth: {
			width: 48,
			color: '#616161',
			backgroundColor: 'transparent',
			fontSize: 16,
			fontPadding: 'none',
			border: 'none',
			padding: 6,
			paddingLeft: 10,
			marginRight: 0,
			outline: 'none',
			'-webkit-border-radius': 0,
			'-moz-border-radius': 0,
			'border-radius': 0,
			'-khtml-border-radius': 0,
		},
		inputExpYear: {
			width: 48,
			color: '#616161',
			backgroundColor: 'transparent',
			fontSize: 16,
			fontPadding: 'none',
			border: 'none',
			padding: 6,
			paddingLeft: 12,
			marginLeft: 0,
			outline: 'none',
			'-webkit-border-radius': 0,
			'-moz-border-radius': 0,
			'border-radius': 0,
			'-khtml-border-radius': 0,
		},
		submitButton: {
			position: 'absolute',
			left: '33%',
			right: '33%',
			bottom: 18,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		formContent: {
			maxWidth: '214px',
			margin: 'auto'
		}
	}))();

	const initSpreedly = () => {
		log.info('INSIDE SPREEDLY!!!');

		var lastToken = null;
		window.Spreedly.init(`${spreedlyEnvironmentKey}`, { "numberEl": "spreedly-number", "cvvEl": "spreedly-cvv" });

		window.Spreedly.on("ready", function () {
			log.info('SPREEDLY READY');
			window.Spreedly.setPlaceholder('number', 'Card Number');
			window.Spreedly.setFieldType('number', 'tel');
			window.Spreedly.setNumberFormat("prettyFormat");
			window.Spreedly.setStyle('number', 'font-family: "Roboto", "Helvetica", "Arial", sans-serif; width: 200px; height: 30px; color: #616161; background-color: #FFF; border: 1px solid #999999; font-size: 16px; font-padding: none; padding: 6px; position: absolute; left: 0; right: 0; margin-right: auto; margin-left: auto; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; -khtml-border-radius: 0px; ');
			window.Spreedly.setPlaceholder('cvv', 'CVV');
			window.Spreedly.setFieldType('cvv', 'tel');
			window.Spreedly.setStyle('cvv', 'font-family: "Roboto", "Helvetica", "Arial", sans-serif; width: 70px; height: 30px; color: #616161; background-color: #FFF; border: 1px solid #999999; font-size: 16px; font-padding: none; padding: 6px; position: absolute; left: 0; right: 0; margin-right: auto; margin-left: auto; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; -khtml-border-radius: 0px; ');
			window.Spreedly.transferFocus("full_name");
			document.getElementById("spreedly-number");
		});

		window.submitPaymentForm = (oneTimeUseBoolean) => {
            window.hideKeyboard()
            setAwaitingPaymentResponse(true)

			var requiredFields = {};
			// Get required, non-sensitive, values from host page
			let fullName = document.getElementById("customerFullName").value;
			requiredFields["full_name"] = fullName
			requiredFields["month"] = document.getElementById("cardExpirationMonth").value;
			requiredFields["year"] = "20" + document.getElementById("cardExpirationYear").value;
			requiredFields["metadata"] = {oneTimeUse: oneTimeUseBoolean};
			// log.info(requiredFields);
            window.Spreedly.tokenizeCreditCard(requiredFields);
            setAwaitingPaymentResponse(false)
		}

		window.Spreedly.on('errors', function (errors) {
			for (var i = 0; i < errors.length; i++) {
				var error = errors[i];
				NotificationManager.warning('', error.message, 3000);
                log.info(error);
			};
		});

		window.Spreedly.on("paymentMethod", async function (token, paymentMethod) {
            const zipCode = currency == "USD" ? document.querySelector('#zipCode').value : ""
            const fullName = document.querySelector('#customerFullName').value
			if (fullName && (currency != "USD" || zipCode) && (token !== lastToken)) {
				lastToken = token
				log.info("RECEIVED TOKEN FROM SPREEDLY")
                log.info("TOKEN: " + token)
                // console.log({paymentMethod})
				var type = paymentMethod.card_type;
				var lastFour = paymentMethod.last_four_digits;
				var expMonth = paymentMethod.month;
				var expYear = paymentMethod.year;
				var oneTimeUse = paymentMethod.metadata.oneTimeUse;
				if (type != null && token != null && lastFour != null) {
					let resp = await saveCardToken(type, token, lastFour, 'spreedly', true, expMonth, expYear, fullName, zipCode, (oneTimeUse || fromDashboard));
					window.closeAllPanels();
					window.Spreedly.removeHandlers();
					if (resp.card) {
						props.next(resp.card)	
					}
				}
			} else {
                NotificationManager.warning('', 'Please complete all fields', 3000);
            }
		});

		window.Spreedly.on('fieldEvent', function(name, type, activeEl, inputProperties) {
			if(name === "number") {
				let numberParent = document.getElementById("spreedly-number");

				if(type === "focus") { numberParent.className = "highlighted"; }

				if(type === "input") {
					// console.log("validNumber: " + inputProperties["validNumber"]);
					if(!inputProperties["validNumber"]) {
						if(inputProperties["numberLength"] > 14) {
							numberParent.className = "error";
							window.Spreedly.setStyle('number', 'border-color: #f00;');
						} else if(inputProperties["numberLength"] <= 14) {
							window.Spreedly.setStyle('number', 'border-color: #999999;');
						}
					} else {
						numberParent.className = "";
						window.Spreedly.setStyle('number', 'border-color: #999999;');
						// console.log("cardType: " + inputProperties["cardType"]);
						if(["visa", "master", "discover", "american_express"].includes(inputProperties["cardType"])) {
							// console.log("cardMonth focus");
							document.getElementById("cardExpirationMonth").focus();
						}
					}
				}
			}

			if(name === "cvv") {
				var expYear = document.getElementById('cardExpirationYear');
				if (expYear.value.length > 0 && expYear.value.length < 2) {
					document.getElementById('expWrapper').style.borderColor = '#f00';
				}

				let cvvParent = document.getElementById("spreedly-cvv");

				if(type === "input") {
					if(!inputProperties["validCvv"]){
						cvvParent.className = "error";
						window.Spreedly.setStyle('cvv', 'border-color: #f00;');
					} else {
						cvvParent.className = "";
						window.Spreedly.setStyle('cvv', 'border-color: #999999;');
					}

				}

				if(type === "focus") { cvvParent.className = "highlighted"; }
			}

			if(type === "input"){
				if(inputProperties["validNumber"]) {
					setSaveCardBtn(true);
				} else {
					setSaveCardBtn(false);
				}
			}
		});

		window.Spreedly.on('validation', function(inputProperties) {
            //log.info('INPUT PROPS: ' + JSON.stringify(inputProperties));
            
		});

    }
    
	const hideKeyboard = () => {
		//this set timeout needed for case when hideKeyborad
		//is called inside of 'onfocus' event handler
		setTimeout(function () {

			//creating temp field
			var field = document.createElement('input');
			field.setAttribute('type', 'text');
			//hiding temp field from peoples eyes
			//-webkit-user-modify is nessesary for Android 4.x
			field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
			document.body.appendChild(field);

			//adding onfocus event handler for out temp field
			field.onfocus = function () {
				//this timeout of 200ms is nessasary for Android 2.3.x
				setTimeout(function () {

					field.setAttribute('style', 'display:none;');
					setTimeout(function () {
						document.body.removeChild(field);
						document.body.focus();
					}, 14);

				}, 200);
			};
			field.focus();

		}, 50);
		window.Spreedly.on("ready", () => {}) //have to remove the on ready or else the keyboard will keep popping up
	}

	useEffect(() => {
		window.saveCardToken = saveCardToken;
		window.NotificationManager = NotificationManager;
		window.log = log;
		window.closeAllPanels = props.closeAllPanels;
		window.hideKeyboard = hideKeyboard;
		log.info(window.Spreedly);
		if (window.Spreedly && !window.Spreedly.isLoaded()) { 
			initSpreedly();
		}
	}, []);

	const theOnSubmit = async (e) => {
		if (!awaitingPaymentResponse) {
            window.submitPaymentForm(isOneTimeUseCard);
            e.preventDefault();
		}
	}

	const thisYear = new Date().getFullYear() - 2000;
	let invalidExpMonth = false
    let invalidExpYear = false

	const onInput = (e) => {
		if (e.target.id === 'cardExpirationMonth') {
			if (e.target.value.length >= 2) {
				document.getElementById('cardExpirationYear').focus();
			}
			invalidExpMonth = false
			if (e.target.value.length === 2 && (e.target.value > 12 || e.target.value < 1)) {
				invalidExpMonth = true
			}
		}

		if (e.target.id === 'cardExpirationYear') {
			if (e.target.value.length >= 2) {
				window.Spreedly.transferFocus("cvv");
			}
			invalidExpYear = false
			if (e.target.value.length === 2 && (e.target.value > thisYear + 7 || e.target.value < thisYear)) {
				invalidExpYear = true
			}
        }

		if (invalidExpMonth || invalidExpYear) {
			document.getElementById('expWrapper').style.borderColor = '#f00';
		} else {
			document.getElementById('expWrapper').style.borderColor = '#999999';
		}
	};

	// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

	return (
		<>	
			<div className={c.separator}></div>
			<div className={c.formContent}>
				<form id="payment-form" className={c.form} onSubmit={(e) => { e.preventDefault() }}>
					<input type="hidden" name="payment_method_token" id="payment_method_token" />
					<input type='text' id="customerFullName" placeholder='Name On Card'className={c.nameInput}/>

					<div className={c.cardNumWrapper}>
						<div
							id="spreedly-number"
							autoComplete="cc-number"
							style={{ height: 44 }}
							type="tel"
						/>
					</div>

					<div className={c.expCvvWrapper}>
						<div id="expWrapper" className={c.expWrapper}>
							<input
								id="cardExpirationMonth"
								autoComplete="cc-exp-month"
								placeholder="MM"
								maxLength={2}
								className={c.inputExpMonth}
								onInput={(e) => onInput(e)}
								type="tel"
							/>
							<div className={c.expSlash}>/</div>
							<input
								id="cardExpirationYear"
								autoComplete="cc-exp-year"
								placeholder="YY"
								maxLength={2}
								className={c.inputExpYear}
								onInput={(e) => onInput(e)}
								type="tel"
							/>
						</div>

						<div
							id="spreedly-cvv"
							autoComplete="off"
							type="tel"
							style={{appearance: 'none'}}
						/>
					</div>
                    
					{currency == "USD" &&
						<input type="tel" id="zipCode" name="zipCode" placeholder='Zip Code' className={c.nameInput} maxLength="5" />
					}

                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', zIndex:99, width:'100%'}}>
                        {!fromDashboard &&
							<FormControlLabel
								label={<span style={{fontSize:'13px', alignSelf:'center'}}>Save card</span>}
								control={
									<Checkbox checked={!isOneTimeUseCard} onChange={()=>setIsOneTimeUseCard(!isOneTimeUseCard)} color="primary" inputProps={{ 'aria-label': 'Retain card for future purchases' }}/>
								}
							/>
						}
                        <Button disabled={awaitingPaymentResponse} variant="contained" color="primary" onClick={(e) => {theOnSubmit(e)}} style={{width:'214px', marginBottom:16, marginTop:4}}>{ awaitingPaymentResponse ? <CircularProgress size={24} style={{color:'white'}}/> : 'CONTINUE'}</Button>
                    </div>
				</form>
			</div>
		</>
	);
};