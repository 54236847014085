import React, { useEffect } from 'react';
import log from 'loglevel';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DashLogoStatic from '../resources/images/DashLogoStatic';
import createAccount from '../actions/createAccount';
import MultiInput from '../components/MultiInput';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import verifyAccount from '../actions/verifyAccount';
import PrivacyPolicy from './PrivacyPolicy';
import {Checkbox, Dialog, DialogActions, Slide} from '@material-ui/core';
import ReactGA from 'react-ga';

export default (props) => {
	const [name, setName] = React.useState("");
	const [verificationCode, setVerificationCode] = React.useState("");
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [phoneNumberFormatted, setPhoneNumberFormatted] = React.useState("");
	const [verificationMode, setVerificationMode] = React.useState(false);
	const [agreeance, setAgreeance] = React.useState(true);
	const [onNameChange, setOnNameChange] = React.useState(false);
	const [onPhoneChange, setOnPhoneChange] = React.useState(false);
	const [showPrivacy, setShowPrivacy] = React.useState(false);
	const debug = true
	const [checkId] = global.get("checkId")
	const [loyaltyProgram] = global.get("loyaltyProgram")
	const [restaurantId] = global.get("restaurantId")
	const GA_PRIVACY_POLICY = "Privacy Policy";
	const GA_CREATE_ACCOUNT = "Create Account";
	const GA_VERIFY_ACCOUNT = "Verify Account";
	let [agreedToLoyaltyTerms] = global.get('agreedToLoyaltyTerms')

	const stringBelowLogo = agreedToLoyaltyTerms ? "Sign up below to start earning rewards" : "Please create an account to begin"
	
	useEffect(() => {
		setVerificationMode(false);
	}, []);

	const c = makeStyles((theme) => ({
		nameField: {
			width: '60%',
			minHeight: 48,
		},
		phoneNumberField: {
			width: '60%',
			minHeight: 48,
		},
		createAccountButton: {
			marginTop: 16,
		},
		button: {
			// margin: theme.spacing(1),
		},
		clickHere: {
			textDecoration: 'underline',
			display: 'inline-block',
			paddingLeft:'.2em'
		},
		privacyText: {
			height: 42,
			color: 'gray',
			marginTop:'.5em'
		},
		modalStyle: {
			backgroundColor: '#FEFEFE',
			position: 'absolute',
			margin: 40,
			top: 300,
			left: 0,
			right: 0,

		},
		logoStyle: {
			height: loyaltyProgram && loyaltyProgram.logoHeight ? loyaltyProgram.logoHeight : 100,
			width: '100%',
			backgroundImage: loyaltyProgram ? 'url(' + loyaltyProgram.logo + ')' : "",
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
		},
		dashLogoStyle: {
			height:80,
			width: '100%',
		},
	}))();


	const handleSetPhoneNumber = (phoneNumberStr) => {
		// remove non numeric values from string & set state
		const newPhoneNumber = phoneNumberStr.replace(/\D/g, '');
		if (newPhoneNumber !== phoneNumber) {
			setPhoneNumber(newPhoneNumber);
			setPhoneNumberFormatted(formatPhoneNumber(newPhoneNumber));
		}
	}

	const formatPhoneNumber = (phoneNumberStr) => {
		if (phoneNumberStr.length < 4) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{1,3})/g, "($1");
		} else if (phoneNumberStr.length < 7) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
		} else if (phoneNumberStr.length <= 10) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
		}
		return phoneNumberStr;
	}

	const onSubmit = async () => {
		if (validate()) {
			createAccount(name, phoneNumber, checkId, restaurantId, agreedToLoyaltyTerms, debug).then((account) => {
				setVerificationMode(true)
				ReactGA.event({
					category: GA_CREATE_ACCOUNT,
					action: "Submitted name and phone"
				});
			}).catch((err) => {
				log.info(err)
				NotificationManager.warning('', err, 3000);
			})
		} else {
			// ReactGA.event({                                 // added more detail on failure in validate()
			// 	category: GA_CREATE_ACCOUNT,
			// 	action: "Create account fields did not verify"
			// });
		}
	}

	const onVerify = async (verificationCodeIn) => {
		// ensure verification code is correct
		// log.info('verificationCode')
		// log.info(verificationCodeIn)

		verifyAccount(verificationCodeIn, debug).then((success) => {
			if (success) {
				ReactGA.event({
					category: GA_VERIFY_ACCOUNT,
					action: "Verificaton Code Entered Correctly"
				});
				navigateToNextPage()
			} else {
				ReactGA.event({
					category: GA_VERIFY_ACCOUNT,
					action: "Verificaton Code Incorrect"
				});
			}
		}).catch((err) => {
			log.info(err)
			NotificationManager.warning('', err, 3000);
		})

	}

	const navigateToNextPage = () => {
		if (props.nextStep) {
			props.nextStep()
		} else {
			let activePage = "ScanQrCodePage"
			if (checkId) {
				activePage = "LoadingPage"
			}
			log.info("setting activePage: " + activePage)
			global.set({ header: true, activePage: activePage })
		}
	}

	const validate = () => {
		let out = true;
		if (_.isEmpty(name)) {
			NotificationManager.warning('', 'Name is required', 3000);
			ReactGA.event({
				category: GA_CREATE_ACCOUNT,
				action: "Valid name is required"
			});
			return false;
		}
		if (!name.split(" ")[1]) {
			NotificationManager.warning('', 'First and Last name are required', 3000);
			ReactGA.event({
				category: GA_CREATE_ACCOUNT,
				action: "First and Last name are required"
			});
			return false;
		}
		if (_.isEmpty(phoneNumber)) {
			NotificationManager.warning('', 'Mobile phone is required', 3000);
			ReactGA.event({
				category: GA_CREATE_ACCOUNT,
				action: "Valid phone number required"
			});
			return false;
		} else {
			if (phoneNumber.length < 10) {
				NotificationManager.warning('', 'Mobile phone is not valid', 3000);
				ReactGA.event({
					category: GA_CREATE_ACCOUNT,
					action: "Valid phone number required"
				});
				return false
			}
		}
		return out;
	}


	const renderCreateAccount = () => {
		return (
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					justifyContent: 'flex-start',
					alignItems: 'center',
					marginTop: -16
				}}
			>
				
				<Grid container direction='row' justify='center' alignItems="center" >
					{(loyaltyProgram && loyaltyProgram.logo) ? <div className={c.logoStyle}></div> : <DashLogoStatic className={c.dashLogoStyle} />}
				</Grid>
		
				<Typography variant="subtitle1" style={{textAlign: 'center', paddingTop: '8%', marginTop: '5%'}}>
					{stringBelowLogo}
				</Typography>
				
					<TextField
						placeholder=""
						className={c.nameField}
						margin="normal"
						onChange={(event) => {
							setName(event.target.value)
							if (!onNameChange) {
								setOnNameChange(true);
								ReactGA.event({
									category: GA_CREATE_ACCOUNT,
									action: "Full name inputted"
								});
								}
						}}
						label="Full Name"
						value={name}
					/>
					<TextField
						className={c.phoneNumberField}
						placeholder=""
						onChange={(event) => {
							handleSetPhoneNumber(event.target.value);
							if (!onPhoneChange) {
								setOnPhoneChange(true);
								ReactGA.event({
									category: GA_CREATE_ACCOUNT,
									action: "Phone number inputted"
								});
								}

						}}
						value={phoneNumberFormatted}
						type="tel"
						pattern="[0-9]*"
						margin="normal"
						label="Phone Number"
						inputProps={{maxLength: 14}}
						onKeyUp={(event) => { if (event.key === 'Enter') onSubmit(); }}
					/>

				{ !loyaltyProgram && (<Grid item xs={12} align="center" style={{paddingTop: '.7em'}}>
					<Checkbox color="primary" checked={agreeance} value="checkedA" style={{marginRight: 4}} onClick={() => {setAgreeance(!agreeance)}}/>
					<Typography className={c.privacyText} component={'span'}>Agree to our
						<Typography className={c.clickHere} onClick={() => {
								setShowPrivacy(true);
								ReactGA.event({
									category: GA_PRIVACY_POLICY,
									action: "Show"
								});
							}}>
							Privacy Policy
						</Typography>
					</Typography>
				</Grid>)}

				<Grid container direction='column' xs={12} align="center" className={c.createAccountButton}>
					<Button color="primary" variant="contained" disabled={!agreeance} className={c.button} onClick={() => {
							onSubmit();
					}}>
						Sign Up
					</Button>

					<Typography className={c.clickHere} noWrap={false} onClick={() => {
							setShowPrivacy(true);
							ReactGA.event({
								category: GA_PRIVACY_POLICY,
								action: "Show"
							});
						}}
						style={{
							color:'var(--dark-gray)',
							marginTop: 16,
						}}	
					>
						Privacy Policy
					</Typography>
				</Grid>
				<Dialog
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={showPrivacy}
					style={{height: '100%'}}
					onClose={() => {setShowPrivacy(false)}}
				>
					<div style={{ height: '90%', overflowY: 'auto', margin:'20px 20px 0px 20px'}} >
						<PrivacyPolicy/>
					</div>
					<DialogActions style={{ marginTop: 0, height: '10%'}}>
						<Button onClick={() => {
								setAgreeance(false); 
								setShowPrivacy(false);
								ReactGA.event({
									category: GA_PRIVACY_POLICY,
									action: "Disagree"
								});
							}} color="primary">
							Disagree
						</Button>
						<Button onClick={() => {
								setAgreeance(true);  
								setShowPrivacy(false);
								ReactGA.event({
									category: GA_PRIVACY_POLICY,
									action: "Agree"
								});
							}} color="primary">
							Agree
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}

	const renderVerify = () => {
		ReactGA.pageview("/VerifyAccount");
		return (
			<Grid
				style={{ margin: 0 }}
				container
				spacing={0} 
			>

				<Grid item xs={12} align="center">
					{/* { props.logo ? <img src={props.logo} height={window.innerHeight * 0.2} /> : <DashLogoStatic />} */}
					{(loyaltyProgram && loyaltyProgram.logo) ? <div className={c.logoStyle}></div> : <DashLogoStatic />}
				</Grid>

				<Grid item xs={12} align="center">
					<Typography variant="subtitle1" style={{marginTop: 30, marginLeft: 30, marginRight: 30}}  >
						A verification code has been sent to<br />{phoneNumberFormatted /* get last 4 digits of phone number */}
					</Typography>
				</Grid>
				<Grid item xs={12} align="center">
					<MultiInput
						placeholder=""
						className={c.nameField}
						margin="normal"
						numberOfInputs={6}
						type="tel"
						handler={setVerificationCode}
						submit={onVerify}
						label="Verification Code"
						value={verificationCode}
					/>
				</Grid>

				<Grid item xs={12} align="center" className={c.createAccountButton}>
					<Button component="span" onClick={() => {
						ReactGA.event({
							category: GA_VERIFY_ACCOUNT,
							action: "Try Again Clicked"
						});
						setVerificationMode(false);
					}}>
						<Typography variant="subtitle1" >
							Try Again
						</Typography>
					</Button>
				</Grid>
			</Grid>
		)
	}

	return (
		<Slide in direction="left">
			<div style={{width: '100%', height: '93%', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center'}}>
				{!verificationMode && renderCreateAccount()}
				{verificationMode && renderVerify()}
			</div>
		</Slide>
	);
};
