import React from 'react';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import checkUtils from '../../util/checkUtils';
import log from 'loglevel'


export default (props) => {
	let [loyaltyProgram] = global.get('loyaltyProgram');
	let [loyaltyAccount] = global.get('loyaltyAccount');


	const myRewardSize = window.innerWidth * 0.04;
	
	const styles = {
		dialogWrapper: {
			padding: 20,
			height: '93%'
		},
		mobileStepper: {
			backgroundColor: 'var(--gray-background)',
		},
		contentWrapper: {
			height: '80%'
		},
		explanationText: {
			color: 'var(--dark-gray)'
		},
		logoStyle: {
			height: loyaltyProgram && loyaltyProgram.logoHeight ? loyaltyProgram.logoHeight : 100,
			width: '100%',
			backgroundImage: 'url(' + loyaltyProgram.logo + ')',
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
		},
	};

	return (
		<div style={styles.dialogWrapper}>
			{loyaltyAccount && loyaltyAccount.rewardsBalance &&
				<div style={styles.contentWrapper}>
					<div styl={{ display: 'flex' }}>
						{(props.small === undefined || !props.small) && 
							<div>
								<div style={styles.logoStyle}></div> 
								<div style={{ height: 1, width: '100%', backgroundColor: '#ccc', margin: '8px 0px' }} />
							</div>
						}
						<div style={{ width: '100%', flexDirection: 'column', flex: 1 }}>
							<div style={{ display: 'inline-flex', width: '100%' }}>
								<div style={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 6, alignItems: 'flex-end', justifyContent: 'center' }}>
									<div style={{ position: 'relative', display: 'flex', height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: 8, paddingTop: '10px', paddingBottom: '10px' }}>
										<CircularProgress variant="static" size={80} thickness={5} value={100} style={{ strokeLinecap: 'round', color: '#E0E0E0', position: 'absolute', left: 0, right: 0 }} />
										<CircularProgress variant="static" size={80} thickness={5} value={loyaltyAccount.rewardsBalance.points} style={{ strokeLinecap: 'round' }} />
										<div style={{ position: 'absolute', top: 'auto', bottom: 'auto', left: 0, right: 0, fontSize: 32, fontWeight: '600', color: 'rgba(0,0,0,0.6)', textAlign: 'center' }}>
											<div style={{ color: 'var(--purple-text)', lineHeight: 1 }}>{loyaltyAccount.rewardsBalance.points}</div>
											<div style={{ fontSize: 12, fontWeight: '300', lineHeight: 1 }}>points</div>
										</div>
									</div>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column', flex: 6, justifyContent: 'center', marginLeft: 8 }}>
									<div style={{ flexDirection: 'row' }}><span style={{ color: 'var(--purple-text)', fontWeight: '600' }}>{loyaltyProgram.rewardConversion.numberOfPoints - loyaltyAccount.rewardsBalance.points}</span> points to</div>
									<div style={{ flexDirection: 'row' }}>your next <span style={{ color: 'var(--purple-text)', fontWeight: '600' }}>${checkUtils.formatMoney(loyaltyProgram.rewardConversion.amount)}</span></div>
								</div>
							</div>
						</div>
						<div style={{ height: 1, width: '100%', backgroundColor: '#ccc', margin: '8px 0px' }} />
						<Grid container justify="center">
							<Typography style={{ fontSize: myRewardSize}} variant="overline">Your Rewards Balance:</Typography> 
						</Grid>
						<Grid container justify="center">
							<Typography style={{fontSize: myRewardSize + 15, lineHeight: .5, paddingTop: '5px', fontWeight: 500}} color="primary" variant="overline">&nbsp;${checkUtils.formatMoney(loyaltyAccount.rewardsBalance.cash)}</Typography> 
						</Grid>
					</div>

				</div>
			}
		</div>
	);
}
