// https://reactjsexample.com/react-component-for-reading-qr-codes-from-webcam/

import React, { useEffect } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import QrReader from "../components/react-qr-reader"
import { NotificationManager } from 'react-notifications';
import { urlParse } from '../util/urlParser';
import 'react-confirm-alert/src/react-confirm-alert.css';
import getTicketIdFromFallbackUtil from "../util/getTicketIdFromFallback";
import FallbackCode from '../resources/images/FallbackCode.svg';
import MultiInput from '../components/MultiInput';
import { Grid, Typography, Button, Slide } from '@material-ui/core';
import logTicketScan from '../actions/logTicketScan';
import ScanHandQrCode from '../assets/ScanHandQrCode'
import ReactGA from 'react-ga';

const THIS_PAGE = "ScanQrCodePage"

export default (props) => {
	const [ isNative ] = global.get("isNative")
	const [ restaurantId ] = global.get("restaurantId")
	
	const [state, setState] = React.useState({
		delay: 300,
		result: "No result",
	});

	const [fallbackCode, setFallbackCode] = React.useState('');
	const [showFallback, setShowFallback] = React.useState(true);
	const TROUBLE_SCANNING_GA_ACTION = "Check Entry"

	ReactGA.event({
		category: TROUBLE_SCANNING_GA_ACTION,
		action: 'Oringinal screen render'
	});

	const c = makeStyles((theme) => ({
		root: {
			height: '100%'
		},
		qrCodeScanner: {
			objectFit: "fill",
			width: "100%",
		},
		container: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		gridContainer: {
			marginTop: 0,
		},
		qrDescriptionText: {
			textAlign: "center",
			left: "20%",
			right: "20%",
			position: "absolute",
			bottom: `18%`,
			color: "white",
			fontSize: "18px",
			zIndex: "100"
		},
		fallbackText: {
			textAlign: "center",
			left: "8%",
			right: "8%",
			position: "absolute",
			bottom: `10%`,
			color: "white",
			fontSize: "14px",
			zIndex: "100"
		},
		clickHere: {
			textDecoration: 'underline',
			display: 'inline-block',
			paddingLeft:'.2em'
		},
		wrapper: {
			height:'100%',
			marginTop: '-57px',
		},
		fallbackWrapper: {
			textAlign: "center",
			marginTop: '-27px',
		},
		fallbackInput: {
			// textAlign: "center",
			width:"80%",
			height: "12%",

		},
		inputWrapper: {
			display: 'flex',
			justifyContent: 'center',
			paddingRight: "3%",
			paddingLeft: "3%",
			marginLeft: "3%",
			marginRight: "3%",

		},
		fallbackSubmit: {
			marginTop: '5%'
		},
		singleInputWrapper: {
			padding: 2
		},
		input: {
			paddingLeft: '.0em',
			// paddingLeft: '.7em',
			paddingRight: '.0em',
			textAlign:'center',
			verticalAlign:'center',

		},
		image: {
			width: "65%",
		},
		scanAgainButton: {
			padding: 15,
			top: '27%'
		},
		dividerHeader: {
			width: '100%', 
			height: '20px',
			textAlign: 'center', 
			borderBottom: '1px solid var(--divider)', 
			lineHeigh: '0.1em',
			// margin: '10px 0 20px', 
		},
		dividerText:{
			fontSize: '30px',
			fontWeight: '350',
			width: "10%",
			backgroundColor: 'var(--white)', 
			padding: '0 10px',
		},
		inlineItemQR: {
			paddingLeft: '4.5%',
			// paddingTop: '5%',
		},
		inlineItemButton: {
			paddingLeft: '6%',
			// paddingTop: '5%',
		}
	}))();

	const navigateToLoadingPage = async (ticketId, isBypass) => {
		if (ticketId) {

			let activePage = "LoadingPage"
			global.pageStack.push({ pageName: THIS_PAGE, fn: null })
			global.set({ header: true, ticketId: ticketId, activePage: activePage, isBypass: isBypass })
		} else {
			ReactGA.event({
				category: TROUBLE_SCANNING_GA_ACTION,
				action: 'Problem scanning receipt' 
			});
			NotificationManager.warning('', 'Problem Scanning Receipt!', 3000);
		}
	}

	const handleScan = data => {
		if (data) {
			setState({
				result: data
			});
			log.info("scanresult " + data)
			let urlObj = urlParse(data);
			log.info(urlObj)
			let ticketId = ''
			if (urlObj.params && (urlObj.params.ticketId || urlObj.params.t)) {
				ticketId = urlObj.params.t ? urlObj.params.t : urlObj.params.ticketId
				logTicketScan(ticketId, null, true)//sets scanFromWeb
				navigateToLoadingPage(ticketId, false);
			}

		}
	}


	const getCheckFromFallback = value => {
		let ticketId = ''
		getTicketIdFromFallbackUtil(value, restaurantId, true).then((res) => {
			if (res) {
				log.info('res '+ JSON.stringify(res))
				ticketId = res
				navigateToLoadingPage(ticketId, false);
			} else {
				NotificationManager.warning('', 'The code entered was not found. Please try again', 3000);
				ReactGA.event({
					category: TROUBLE_SCANNING_GA_ACTION,
					action: 'Code typed in not found'
				});
			}
		}).catch((err) => {
			NotificationManager.warning('', 'The code entered was not found. Please try again', 3000);
			ReactGA.event({
				category: TROUBLE_SCANNING_GA_ACTION,
				action: 'Code typed in not found'
			});
		})
		return (value.length > 5) ? true : false
	}


	useEffect(() => {

		// async function fetchUserDetails() {
		// 	const newAccountDetails = await getAccountDetails()
		// 	localStorage.userIsWaiter = newAccountDetails.isWaiter
		// }

		if (localStorage.userIsWaiter !== true) {
			// fetchUserDetails();
		}
		// localStorage.userIsWaiter = "true"
		// log.info("localStorage.userIsWaiter" + localStorage.userIsWaiter)
		global.set({
			allowBack: false,
			check: null,
			userIsWaiter: localStorage.userIsWaiter,
			footer: true,
		})

	}, []);

	const handleError = err => {
		console.error(err)
	}


	return (
		<Slide in direction="left">
			<div className={c.root}>
				{!showFallback &&
				<Grid container className={c.wrapper}>
					<QrReader
						delay={state.delay}
						onError={handleError}
						onScan={handleScan}
						style={{ overflow: 'hidden', height: "100%", width: "100%", boxShadow: "rgba(0,0,0,0) 0px 0px 0px 0px"}}
						className={c.qrCodeScanner}
						facingMode={'environment'}
					/>
					<Grid item xs={12}
						className={c.qrDescriptionText}
					>Please scan the QR code on your receipt to begin
					</Grid>
					<Grid item xs={12}
						className={c.fallbackText}
						onClick={() => { 
							setShowFallback(true);
							ReactGA.event({
								category: TROUBLE_SCANNING_GA_ACTION,
								action: 'Fallback Page'
							});
							}}>
						<Typography component={'span'}>Having trouble scanning?
							<Typography className={c.clickHere}>Click here</Typography>
						</Typography>

					</Grid>
				</Grid>
				}
				{showFallback &&
					<Grid container className={c.fallbackWrapper}>
						{!isNative && <>
							
							<Grid item xs={12} className={c.inputWrapper} >
								<Typography style={{ fontSize: 18, color: '#313131' }}>
									Please scan the QR code on your receipt to begin  
								</Typography>
							</Grid>
							<Grid item xs={5} className={c.inlineItemQR}
								onClick={() => {
									setShowFallback(false)
									ReactGA.event({
										category: TROUBLE_SCANNING_GA_ACTION,
										action: 'Scan QR'
									});
								}} 
							>
								<ScanHandQrCode color="primary" width={'7em'} style={{fontSize: 125}} />
							</Grid>
							<Grid item xs={7} className={c.inlineItemButton}>
								<Button variant="contained" color="primary" className={c.scanAgainButton}
									onClick={() => {
										setShowFallback(false)
										ReactGA.event({
											category: TROUBLE_SCANNING_GA_ACTION,
											action: 'Scan QR'
										});
									}}
								>
									Scan QR Code
								</Button>
							</Grid>
							<Grid item style={{height: 5}} xs={12}>
								{/* <Button variant='contained' color="primary" onClick={() => getCheckFromFallback(fallbackCode)} disabled={!validFallbackCode(fallbackCode)} className={c.fallbackSubmit}>
									<Typography>
										Submit
									</Typography>
								</Button> */}
							</Grid>
						</>
						}
						<Grid item xs={12} style={{paddingBottom: '5%'}}>
							<h2 className={c.dividerHeader}>
								{/* <Typography className={c.dividerText}>OR</Typography> */}
								<span className={c.dividerText}>OR</span>
							</h2>
						</Grid>
						<Grid item xs={12} className={c.inputWrapper} >
							<Typography style={{ fontSize: 18, paddingTop:15,  color: '#313131' }}>
								Enter the 6 digit code from the bottom of your receipt.
							</Typography>
						</Grid>
						<MultiInput
							placeholder=""
							className={c.nameField}
							margin="normal"
							numberOfInputs={6}
							type="tel"
							handler={setFallbackCode}
							submit={getCheckFromFallback}
							label="Verification Code"
							value={fallbackCode}
						/>
						<Grid item xs={12} >
							<img src={FallbackCode} className={c.image} alt='qr code'/>
						</Grid>

					</Grid>
				}


			</div>
		</Slide>
	);
};
