import dashFetch from './dashFetch';
import log from 'loglevel';

export default async function(checkId, paymentMethod, paymentToken, myTotals, lockedItems, loyaltyAccount, billingInfo, encryptedCard = {}, debug) {
	return new Promise((resolve, reject) => {
		
		if (debug) {
			log.info('checkId');
			log.info(checkId);
			log.info('paymentMethod');
			log.info(paymentMethod);
			log.info('paymentToken');
			log.info(paymentToken);
			log.info('myTotals');
			log.info(myTotals);
			log.info('lockedItems');
			log.info(lockedItems);
			log.info('loyaltyAccount');
			log.info(loyaltyAccount);
		}
		try {

			const url = `${global.dashServerUrl}/api/v1/payment/makePayment`;

			const body = {
				deviceId: localStorage.deviceId,
				checkId,
				paymentMethod,
				paymentToken,
				myTotals,
				lockedItems,
                billingInfo,
                encryptedCard
			};

			dashFetch(url, body, debug).then(({ res, json }) => {
				if (json.success) {
					if (json.loyaltyAccount) {
						log.info('json.loyaltyAccount: '+ JSON.stringify(json.loyaltyAccount))
						global.set({'loyaltyAccount': json.loyaltyAccount})
					}
					resolve({...json})
				} else {
					resolve({...json})
				}
			}).catch((err) => { reject(err) })
			return false
		} catch (err) {
			console.error("THE BIG FAILURE")
		}
	})
}
