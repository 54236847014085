import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, SwipeableDrawer } from '@material-ui/core'
import checkUtils from '../../util/checkUtils'
import log from 'loglevel'
import LoyaltyRewardsSummary from './LoyaltyRewardsSummary';
import Rewards from '../../resources/images/rewards-icon.svg';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const drawerHeight = 320;

export default (props) => {
	const classes = useStyles();
    let [loyaltyProgram] = global.get("loyaltyProgram")
	let [loyaltyAccount] = global.get("loyaltyAccount")
	const [ check ] = global.get('check')
	const [ bottomDrawerOpen, setBottomDrawerOpen ] = useState(false);

	const applyCashReward = () => {
		setBottomDrawerOpen(false);
		let updatedCheck = checkUtils.applyReward(check, loyaltyAccount.rewardsBalance.cash)
		if (updatedCheck.myTotals.youPay == 0) {
			global.set({ check: updatedCheck, activePanel: 'confirmPaymentPanel' })
		} else {
			global.set({ check: updatedCheck })
		}
	}

	const removeCashReward = () => {
		setBottomDrawerOpen(false);
		let updatedCheck = checkUtils.undoRewardApplied(check)
		global.set({ check: updatedCheck })
	}


	useEffect(() => {
		if (loyaltyProgram && loyaltyAccount && loyaltyAccount.rewardsBalance && loyaltyAccount.rewardsBalance.cash > 0 && (!check.myTotals.loyaltyRewardAmount || check.myTotals.loyaltyRewardAmount === 0)) {
			if (!(check.restaurant.allowStackingDiscounts == false && check.myTotals.discounts > 0)) {
				setTimeout(function() {
					setBottomDrawerOpen(true)			
				}, 100)
			}
		}
	}, []);

	log.info('loyaltyAccount: ' + JSON.stringify(loyaltyAccount))


	return (

		<div className={bottomDrawerOpen ? classes.overlayContainer : ''} onClick={() => setBottomDrawerOpen(!bottomDrawerOpen)}>
			<SwipeableDrawer PaperProps={{elevation: 3}} anchor="bottom" open={bottomDrawerOpen} onClose={() => setBottomDrawerOpen(false)} onOpen={() => setBottomDrawerOpen(true)}
				variant="permanent"
				className={clsx(classes.drawer, {
				[classes.drawerOpen]: bottomDrawerOpen,
				[classes.drawerClose]: !bottomDrawerOpen,
				})}
				classes={{
				paper: clsx({
					[classes.drawerOpen]: bottomDrawerOpen,
					[classes.drawerClose]: !bottomDrawerOpen,
				}),
				}}>

				<div>
					<div style={{display: 'flex', height: 58, flex: 1, flexDirection: 'row', justifyContent: 'center', padding: 8}} onClick={() => setBottomDrawerOpen(true)}>
						<img width={28} height={28} style={{ marginRight: 10, marginTop: 0}} src={Rewards} alt="Rewards" />
						<Typography style={{ fontSize: 18, fontWeight: '300', letterSpacing: -0.5, color: '#757575' }}><span style={{ fontWeight: '500' }}>{loyaltyProgram.name} </span>Rewards</Typography>
					</div>

					{loyaltyAccount.rewardsBalance.cash > 0 && (check.restaurant.allowStackingDiscounts == true || (check.restaurant.allowStackingDiscounts == false && check.myTotals.discounts == 0)) &&
					<>
						<Typography variant="h6" style={{fontWeight: 300, paddingTop: 8}} align="center">
						Would you like to apply your 
						</Typography>
						<Typography variant="h6" style={{fontWeight: 300, }} align="center">
							reward to this check?
						</Typography>
						<Grid container justify="center" style={{marginTop: 14}}>
							<Typography variant="h5" style={{fontWeight: '400', fontSize: 'medium', top: 5}} color="primary">$</Typography>
							<Typography variant="h3" color="primary">{checkUtils.formatMoney((!loyaltyAccount || (loyaltyAccount.rewardsBalance.cash > check.myTotals.total)) ? check.myTotals.items : loyaltyAccount.rewardsBalance.cash)}</Typography>
						</Grid>
						<Grid container justify="center" style={{marginTop: 14}}>
							<Button variant="contained" color="primary" onClick={() => applyCashReward()}>
								Use Reward
							</Button>
						</Grid>
						<Grid container justify="center" style={{marginTop: 20}}>
							<Button style={{color:'var(--light-gray)'}} onClick={() => removeCashReward()}>
								Not This Time
							</Button>
						</Grid>
					</>
					}

					{loyaltyAccount.rewardsBalance.cash > 0 && (check.restaurant.allowStackingDiscounts == false && check.myTotals.discounts > 0) &&
						<>
							<Typography variant="h6" style={{ fontWeight: 300, paddingTop: 8 }} align="center">
								There is already a discount applied.
							</Typography>
							<Typography variant="h6" style={{ fontWeight: 300, }} align="center">
								If you would like to use your 
							</Typography>
							<Typography variant="h6" style={{ fontWeight: 300, }} align="center">
								loyalty balance, ask you server to
							</Typography>
							<Typography variant="h6" style={{ fontWeight: 300, }} align="center">
								remove your existing discount.
							</Typography>
							<Grid container justify="center" style={{ marginTop: 20 }}>
								<Button style={{ color: 'var(--light-gray)' }} onClick={() => removeCashReward()}>
									Not This Time
								</Button>
							</Grid>
						</>
					}


					{loyaltyAccount.rewardsBalance.cash == 0 &&
					 	<LoyaltyRewardsSummary small={true}/>
					}
				</div>

			</SwipeableDrawer>
		</div>
			
	)
}


const useStyles = makeStyles(theme => ({
    drawer: {
      height: drawerHeight,
      flexShrink: 0,
	  whiteSpace: 'nowrap',
	},
    drawerOpen: {
      height: drawerHeight,
      overflow: 'hidden',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
	  }),
    },
    drawerClose: {
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflow: 'hidden',
      height: theme.spacing(6) + 1,
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(8) + 1,
	  },
    },
    overlayContainer: {
        position: "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        bottom: "0px",
        background: 'var(--mask)',
        zIndex: 1999,
	},
	
  }));