import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import log from 'loglevel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ReactGA from 'react-ga';
import { Button, Paper, Dialog, DialogActions, TextField, Slide } from '@material-ui/core';

import getPaymentHistory from '../actions/getPaymentHistory';
import getPaymentHTML from '../actions/getPaymentHTML';
import checkUtils from '../util/checkUtils';
import { NotificationManager } from 'react-notifications';
import emailOldReceipt from '../actions/emailOldReceipt';
import DStyles from '../components/DStyles'

const THIS_PAGE = "PaymentHistoryPage"

export default (props) => {

	let [activePaymentHTML, setActivePaymentHTML] = useState("<div />")
	let [activePaymentId, setActivePaymentId] = useState()
	let [showPaymentDetails, setShowPaymentDetails] = useState(false)
	let [userHasEmail, setUserHasEmail] = useState(false)
	let [payments, setPayments] = useState([])
	let [showEmailEntryDialog, setShowEmailEntryDialog] = useState(false)
	let [inputEmail, setInputEmail] = useState(localStorage.getItem("emailAddress"))
    let [showHelpDialog, setShowHelpDialog] = useState(false)
    const [isDesktopView] = global.get('isDesktopView')

	//SIMILAR TO componentWillMount() function, only runs once
	useEffect(() => {
		getPaymentHistory().then(data => {
			setPayments(data.payments)
			setUserHasEmail(data.userHasEmail)
		})
		global.set({header: 1, footer: 1})
	},[]);


	let setOrGetActivePaymentHTML = async (paymentId, paymentHTML) => {
		if (!paymentHTML) {
			paymentHTML = await getPaymentHTML(paymentId)
		}
		setActivePaymentHTML(paymentHTML)
		setActivePaymentId(paymentId)
	}

	const validateEmail = (emailAddress) => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress))

	let emailReceipt = async () => {
		if (validateEmail(inputEmail)){
			NotificationManager.warning('', `Email sent to ${inputEmail}!`)
			localStorage.setItem("emailAddress", inputEmail)
			emailOldReceipt(activePaymentId, inputEmail)
		} else {
			NotificationManager.warning('', `${inputEmail} is not a valid email!`)
		}
	}

	const useStyles = makeStyles((theme) => createStyles({
		gridContainer: {
			margin: '0px 2%',
		},
		emailReceiptPage: {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			alignItems: 'center',
			margin: 0,
		},
		emailInput: {
			width: '100%',
			lineHeight: 2,
			fontSize: 16
		},
		gridItemCenterDialog: {
			textAlign: 'center',
			margin: '15% 0px 10%',
			padding: '0px 10%'
		},
	}));

	const styles = `

		.logo-wrapper img {
			height: 60px !important;
		}
		.receipt-html-wrapper {
			overflow: scroll;
		}
		.receipt-html-wrapper::-webkit-scrollbar,
		.receipt-page::-webkit-scrollbar {
			display: none;
		}
		.body, .page {
			background-color: #F8F8F8 !important;
		}
    `

	return (
		<Slide in direction="left">
			<div className="receipt-page" style={{marginTop: !isDesktopView && '-12px', overflowY: "auto", height: "100%", backgroundColor: "var(--white)", padding:16, borderRadius:'4px'}}>
				<DStyles styles={styles} />
                <Typography align="center" variant="h6">
                        Receipt History
                    </Typography>

						{ payments && (payments.length > 0) && payments.map(p => { return (
                            <div style={{padding:8, margin:'4px 0px 4px 0px', borderRadius:'4px', display:'flex', justifyContent:"space-between", alignItems:"center", width:'100%', flexDirection: 'row', border:'solid 1px #e2e8f0', backgroundColor:'var(--white)', height:'90px', cursor: 'pointer', padding:16}}
							    onClick={() => { setOrGetActivePaymentHTML(p._id, p.receiptEmailHTML); setShowPaymentDetails(true) }}>
								
                                <div style={{flexBasis:'15%'}}>
									{new Date(p.createdAt).toLocaleDateString()}<br/>
								</div>

                                <div style={{display:'flex', flexDirection:'column', alignContent:'center', padding:8}}>
                                    <div style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
									    {p.restaurantName}
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center', fontWeight:600}}>
                                        #{p.checkNumberDisplay ? p.checkNumberDisplay : p.orderNumber ? p.orderNumber : p.checkNumber}
                                    </div>
                                </div>

                                
                                <div style={{flexBasis:'15%'}}>
									${checkUtils.formatMoney(p.paymentAmount)}
                                </div>

                            </div>
						)})}
					
					{ (!payments || !payments.length) && 
						<Typography variant="h5" align="center" style={{paddingTop: '2em'}}>
							Your payments will appear here after paying with your phone!
						</Typography>
					}

				<Dialog // PAYMENT DETAILS DIALOG
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={showHelpDialog}
					style={{height: '100%', width: '100%'}}
					onClose={() => {setShowHelpDialog(false)}}
				>
					<Grid container style={{padding: '5%'}}>
						<Grid item xs={12}>
							<Typography variant="body1" align="center">
								Having Trouble?
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">
								Please call or text Dash Now Support at <a style={{whiteSpace: 'nowrap'}} href="tel:913-370-7577">913-370-7577</a>.
							</Typography>
							<Typography variant="body2">
								We are also available through email: <a style={{whiteSpace: 'nowrap'}} href="mailto:support@dashnow.com?Subject=Customer%20Support%20Inquiry" target="_top">support@dashnow.com</a>.
							</Typography>
						</Grid>
					</Grid>
					<DialogActions>
						<Button onClick={() => setShowHelpDialog(false)}>
							Close
						</Button>
					</DialogActions>
				</Dialog>


				<Dialog // PAYMENT DETAILS DIALOG
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={showPaymentDetails}
					style={isDesktopView ? {minWidth:500} : {height: '100%', width: '100%'}}
					onClose={() => {setShowPaymentDetails(false)}}
					fullScreen = {!isDesktopView}
				>
					{ activePaymentHTML && <div className="receipt-html-wrapper" dangerouslySetInnerHTML={{__html: activePaymentHTML}} />}
					{ !activePaymentHTML && <div>Retreiving payment data</div> }
					<DialogActions style={{borderTop: '1px solid var(--divider)'}}>
						<Button onClick={() => { setShowPaymentDetails(false); setShowEmailEntryDialog(true); }}>
							Email Receipt
						</Button>
						<Button onClick={() => setShowPaymentDetails(false)}>
							Close
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog // SEND EMAIL DIALOG
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={showEmailEntryDialog}
					style={{height: '100%'}}
					onClose={() => {setShowEmailEntryDialog(false)}}
				>
					<Grid container style={{padding: '5%'}}>
						<TextField id="standard-basic" label="Email Address" value={inputEmail} onChange={(e) => setInputEmail(e.target.value)} />
					</Grid>
					<DialogActions>
						<Button onClick={() => { setShowEmailEntryDialog(false);  }}>
							Close
						</Button>
						<Button onClick={() => { setShowEmailEntryDialog(false); emailReceipt();}}>
							Send
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</Slide>
	);
};