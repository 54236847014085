import React from 'react';

export default (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 20 24" version="1.1" xmlns="http://www.w3.org/2000/svg">

            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Group" transform="translate(1.000000, 1.000000)">
                    <g id="Path">
                        <polygon stroke={props.cellColor} strokeWidth="1.5" points="0 0 18 0 18 22 15 19.9047619 12 22 9 19.9047619 6 22 3 19.9047619 0 22"></polygon>
                        <path d="M3,4 L7,4" stroke={props.cellColor} strokeWidth="2"></path>
                        <path d="M11,4 L15,4" stroke={props.cellColor} strokeWidth="2"></path>
                        <path d="M3,9 L7,9" stroke={props.cellColor} strokeWidth="2"></path>
                        <path d="M3,14 L7,14" stroke={props.cellColor} strokeWidth="2"></path>
                        <path d="M12.75,16.219 C12.3357864,16.219 12,15.8832136 12,15.469 L12,15.117 C11.3207351,14.9610871 10.7175017,14.5722581 10.295,14.018 C10.0639078,13.687556 10.1350155,13.2335926 10.4560803,12.9896371 C10.7771451,12.7456816 11.2335615,12.7988149 11.49,13.11 C11.7694484,13.4754509 12.202951,13.6901696 12.663,13.691 L12.72,13.69 C13.495,13.69 14.062,13.269 14.062,12.893 C14.062,12.517 13.501,12.096 12.75,12.096 C11.199,12.096 9.937,11.065 9.937,9.798 C9.937,8.746 10.787,7.855 12,7.585 L12,7.22 C12,6.80578644 12.3357864,6.47 12.75,6.47 C13.1642136,6.47 13.5,6.80578644 13.5,7.22 L13.5,7.575 C14.1789681,7.72981609 14.7822416,8.11760953 15.205,8.671 C15.4348641,9.00120397 15.3636708,9.4538981 15.0435498,9.69760833 C14.7234288,9.94131856 14.2681147,9.88945789 14.011,9.58 C13.735584,9.21277665 13.3030273,8.99705428 12.844,8.998 L12.78,8.999 C12.004,9 11.436,9.421 11.436,9.797 C11.436,10.174 11.998,10.595 12.749,10.595 C14.3,10.595 15.561,11.626 15.561,12.892 C15.561,13.944 14.712,14.835 13.499,15.104 L13.499,15.469 C13.4992654,15.6678204 13.4204704,15.8585884 13.2799768,15.9992695 C13.1394833,16.1399506 12.9488205,16.219 12.75,16.219 L12.75,16.219 Z" fill={props.cardColor} fillRule="nonzero"></path>
                    </g>
                </g>
            </g>

          
        </svg>
    );
}