import log from 'loglevel';
import dashFetch from './dashFetch';
import { NotificationManager } from 'react-notifications';

export default async (check, vehicleType, vehicleColor, pickupLocation, otherDescription, debug) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (debug) log.info('***** Sumbmit PickupInfo API ****');

			const url = `${global.dashServerUrl}/api/v1/check/submitPickupInfo`;
			const body = {
				checkId: check._id,
				vehicleType: vehicleType,
				vehicleColor: vehicleColor,
				otherDescription,
				pickupLocation,
			};

			if (global.tryMe) {
				resolve(true)
			} else {
				dashFetch(url, body, debug).then(({ res, json }) => {
					if (json.success) {
						resolve(true)
					} else {
						NotificationManager.warning('', json.errors, 3000);
						resolve(false)
					}
				}).catch((err) => { reject(err) })
			}
		} catch (e) {
			log.info(e)
		}
	})
};
