import React, { useEffect } from 'react';
// import log from 'loglevel';
// When importing from material, be sure to use the method below
// https://material-ui.com/guides/minimizing-bundle-size/
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import _ from "lodash";
import DStyles from '../../components/DStyles'
import ReceiptTable from './sections/ReceiptTable';
import SelectTipTable from './sections/SelectTipTable';
import { Slide } from '@material-ui/core';

const THIS_PAGE = "MyBillPage"

export default (props) => {
	let [splitCheckEnabled] = global.get("splitCheckEnabled")
	let gridTabHeight = splitCheckEnabled ? 8.5 : 0
	let gridTableWrapperHeight = `${70 - gridTabHeight}`
	let gridReceiptFooterHeight = `30`

	const styles = `
		.gridTableWrapper {
			height: ${gridTableWrapperHeight}%;
			padding: 0px 15px 0px 15px;
		}

		.gridTableWrapper::-webkit-scrollbar {
			display: none;
			width: 0 !important
		}

		.root {
			height: 100%;
		}

		.gridRoot {
			height: 100%;
		}

		.pageHeader {
			height: ${gridTabHeight}%;
		}

		.receiptFooter {
			height: ${gridReceiptFooterHeight}%;
		}
	`

	//SIMILAR TO componentWillMount() function, only runs once
	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<Slide in direction="left">
			<div className = {"root"} >
				<DStyles styles={styles} />
				<Grid className={"gridRoot"} container spacing={0}>
					<Grid item xs={12} align="center" className={"pageHeader"}>
						{
							<Typography style={{fontSize: 15, fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)'}} variant="overline"> YOUR BILL
								<Divider/>
							</Typography>
						}
					</Grid>

					<Grid item xs={12} className={"gridTableWrapper"} align="center">
						{ <ReceiptTable /> }
					</Grid>

					<Grid item xs={12} align="center" className={"receiptFooter"}>
						{ <SelectTipTable page={THIS_PAGE} />}
					</Grid>
				</Grid>
			</div>
		</Slide>
	);
};