export let domains = {
	prod: 'https://prod-server.dashnow.com',
	stage: 'https://stage-server.dashnow.com',
	dev: 'https://stage-server.dashnow.com',
	dev: 'https://local.dashnow.com:3000', // Replace this with your local ip address
	s3: 'https://dash-files-s3.s3-us-west-1.amazonaws.com/[ENV]'
};

export let tryMeDomains = {
	dev: 'tryme.dnow.app',
	stage: 'tryme.dnow.app',
	prod: 'tryme.dnow.app',
	// dev: '96.dnow.app', //Switch this to your dev url to test try me ()
};

export let tsysDomains = {
	dev: '88800000330203', // only on local.dashnow.com
	stage: '88800000330202', // stage.dashnow.com
}

export let vgsDomains = {
	dev: 'https://tntdarsfsh9.sandbox.verygoodproxy.com',
	stage: 'https://tntdarsfsh9.sandbox.verygoodproxy.com',
	prod: 'https://tnturggnfna.live.verygoodproxy.com',
}

export let vgsKeys = {
	dev: "tntdarsfsh9",
	stage: "tntdarsfsh9",
	prod: "tnturggnfna",
}

export let wsDomains = {
	prod: 'wss://prod-server.dashnow.com',
	stage: 'wss://stage-server.dashnow.com',
	dev: 'wss://stage-server.dashnow.com',
};

export let spreedlyEnvironmentKeys = {
	prod: 'STahfxlRS9swSIMS7wWYnk55eDp',
	stage: 'XuDZx7W6lSjCocvaICWgVWO7xCn',
	dev: 'XuDZx7W6lSjCocvaICWgVWO7xCn',
	local: 'XuDZx7W6lSjCocvaICWgVWO7xCn',
}

export const debug = false;
export const live = true;
