import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import saveCardToken from '../../actions/saveCardToken';
import { NotificationManager } from 'react-notifications';
import '../../util/modalCSS.css'
import { Checkbox, FormControlLabel, Button } from '@material-ui/core';
import getIFrameKey from '../../actions/getIFrameKey';
import { truncate } from 'lodash';
// import Shift4Frame from './Shift4Frame';

export default (props) => {
    let [check] = global.get('check');
    let [restaurant] = global.get('restaurant');
    const myTotal = global.f(check, 'myTotals.youPay', 0)
    const [loadingIframe, setLoadingIframe] = useState(false)
    const [loadedIframe, setLoadedIframe]   = useState(false)
    const [isOneTimeUseCard, setIsOneTimeUseCard] = useState(false)
    const [ fromDashboard ] = global.get('fromDashboard')

    useEffect(()=>{
        const script = document.createElement("script");
        // script.onload = alert('loaded1')
        script.setAttribute('src','https://code.jquery.com/jquery-3.5.1.min.js')
        document.head.appendChild(script);

        const script2 = document.createElement("script");
        // script2.onload = alert('loaded2')
        script2.setAttribute('src','./resources/shift4iframe.js')
        document.head.appendChild(script2);
        fetch()
    },[])
    let submit = ()=> 'hi'
    const fetch = async () => {
        if (!loadingIframe && !loadedIframe) {
                
            setLoadingIframe(true)
            
            const resp = await getIFrameKey(restaurant._id, myTotal, localStorage.accountId)

            if (resp.success) {
                const i4go_accessblock = resp.data.i4go_accessblock
                const i4go_server      = resp.data.i4go_server
                const i4go_url         = resp.data.i4go_i4m_url
                log.info("global.isProd: " + global.isProd)
                log.info(document.getElementById("form-main"))
                log.info(window.jQuery)
                if (!window.jQuery || !window.jQuery.fn['i4goTrueToken']) {
                    log.info("Sad")
                    return "sad"
                }

                window.jQuery.fn['i4goTrueToken']({
                    debug:						false,		// Parent side
                    remoteDebug:				false,		// Adds width indicators within the iframe contents to help with frame sizing
                    url:						i4go_url,
                    server:						i4go_server,
                    accessBlock:				i4go_accessblock,
                    self:						document.location,
                    template:                   "bootstrap2",
                    language:					"en",
                    frameName:                  "i4goFrame",
                    submitButton: {
                        label:'Add Card'
                    },
                    cardType: {
                        visible:false
                    },
                    cardholderName: {
                        visible: true,
                        required: true,
                        placeholder: ' '
                    },
                    postalCode: {
                        visible: true,
                        required: true,
                        label:'Zip code',
                        placeholder:' '
                    },
                    cvv2Code: {
                        label: 'CVV',
                        placeholder: ' '
                    },
                    cardNumber: {
                        placeholder: ' '
                    },
                    cssRules: [
                        '.container {display:flex !important; justify-content:space-between; height:320px}',
                        '.optional-field{background-color: red; display:contents}',
                        // '.optional-field{background-color: red; display:inline-block;}',
                        'cvv2help{display:none}',
                        'small{display:none}',
                        '#i4go_postalCode{position:relative;bottom:65px; left:140px}',
                        '#i4go_postalCode-label{position:relative;bottom:65px; left:140px}',
                        `#i4go_submit{width:100%; position:relative;bottom:54px;background:none; background-color:${restaurant.branding.primaryColor ? restaurant.branding.primaryColor : '#8B6BD9'} !important;height:42px}`,
                        'body{height:320px}'
                    ],
                    // streetAddress: {
                    //     visible:true
                    // },
                    encryptedOnlySwipe:			false,
                    onSuccess: async function(form,data) {
                        // console.log("MY onSuccess called!",form,data);

                        const cardTypes = {
                            VS: "visa",
                            MC: "master",
                            AX: "american_express",
                            NS: "discover",
                        }

                        const extractLastFour = (string) => {
                            if (string && string.length) {
                                let lastIndex = string.length;
                                let startIndex = lastIndex - 4;
                                return string.substring(startIndex, lastIndex)
                            } else {
                                return ''
                            }
                        }

                        // console.log(form, data)
                        const type = cardTypes[data.i4go_cardtype] ? cardTypes[data.i4go_cardtype] : ''
                        const token = data.i4go_uniqueid ? data.i4go_uniqueid : ''
                        const lastFour = data.otn && data.otn.cardnumber ? extractLastFour(data.otn.cardnumber) : ''
                        const source = 'shift4'
                        const debug = false
                        const expMonth = data.otn.expirationmonth ? data.otn.expirationmonth : ''
                        const expYear = data.i4go_expirationyear ? data.i4go_expirationyear : ''
                        const fullName = data.i4go_cardholdername ? data.i4go_cardholdername : ''
                        const zipCode = data.i4go_postalcode ? data.i4go_postalcode : ''
                        const address = data.i4go_streetaddress ? data.i4go_streetaddress : ''
                        // const oneTimeUse = window.oneTimeUse
                        // console.log({oneTimeUse})
    
                        // console.log({type})
                        // console.log({token})
                        // console.log({lastFour})
                        // console.log({source})
                        // console.log({expMonth})
                        // console.log({expYear})
                        // console.log({fullName})
                        // console.log({zipCode})
    
                        let resp;
                        if (!window.preventSCT) {
                            // prevents saveCardToken from firing multiple times
                            window.preventSCT= true
                            resp = await saveCardToken(type, token, lastFour, source, debug, expMonth, expYear, fullName, zipCode, fromDashboard, restaurant._id, address) 
                        }
    
                        window.closeAllPanels();
                        if (resp && resp.card) {
                            props.next(resp.card)	
                        }
    
                        setTimeout(()=> window.preventSCT = false, 3000)
                       
                    },
                    onFailure: function(form,data) {
                        console.warn("MY onFailure called!",form,data);
                    } 
            })
        }
        }
    }

	const c = makeStyles((theme) => ({
		label: {
			fontSize: 14,
			fontWeight: 400,
			color: 'rgba(0, 0, 0, 0.6)',
			textAlign: 'center',
			marginBottom: 8,
		},
		nameInput: {
			width: 213,
			height: 44,
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)',
			marginRight: 'auto',
			marginLeft: 'auto',
			border: '1px solid #999999',
			borderRadius: 0,
			padding: 6,
			fontSize: 16,
			fontWeight: '500',
			color: 'rgb(97,97,97)',
			marginBottom: 8,
			appearance: 'none'
		},
		formContent: {
			maxWidth: '350px',
            textAlign:'center',
            overflow:'hidden',
            paddingTop:16
		}
    }))();

	return (
		<div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'450', overflow:'hidden'}}>	
			<div className={c.separator}></div>

            <div className={c.formContent} style={{paddingTop:4}}>

                <div id="i4goFrame" style={{width: '300px', height:'320px', marginTop:16, marginBottom:0}} >
                
                    <form action={"https://dashboard.dashnow.com/api/v1/account/saveToken"} method="post" style={{display: 'none'}} id="form-main">
                        <fieldset id="payment-iframe">
                            <legend>Payment Form (iframe)</legend>
                            <div id="i4goFrame"></div>
                        </fieldset>
                        <input id="i4go_response" name="i4go_response" type="hidden" />
                        <input id="i4go_responsecode" name="i4go_responsecode" type="hidden" />
                        <input id="i4go_responsetext" name="i4go_responsetext" type="hidden" />
                        <input id="i4go_cardtype" name="i4go_cardtype" type="hidden" />
                        <input id="i4go_maskedcard" name="i4go_maskedcard" type="hidden" />
                        <input id="i4go_uniqueid" name="i4go_uniqueid" type="hidden" />
                        <input id="i4go_expirationmonth" name="i4go_expirationmonth" type="hidden" />
                        <input id="i4go_expirationyear" name="i4go_expirationyear" type="hidden" />
                        <input id="i4go_cardholdername" name="i4go_cardholdername" type="hidden" />
                        <input id="i4go_streetaddress" name="i4go_streetaddress" type="hidden" />
                        <input id="i4go_postalcode" name="i4go_postalcode" type="hidden" />
                        <input id="i4go_metatoken" name="i4go_metatoken" type="hidden" />
                        <input id="i4go_extendedcarddata" name="i4go_extendedcarddata" type="hidden" />
                    </form>

                </div>
                {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <FormControlLabel
                        label={<span style={{fontSize:'13px', alignSelf:'center'}}>Save card</span>}
                        control={
                            <Checkbox checked={!isOneTimeUseCard} onChange={()=>handleOneTimeUse()} color="primary" inputProps={{ 'aria-label': 'Retain card for future purchases' }}/>
                        }
                    />
                </div> */}
            </div>
		</div>
	);
}