import React, {useEffect, useState} from 'react'
import checkUtils from '../util/checkUtils';
import moment from 'moment'
import ReceiptTable from '../pages/pay/sections/ReceiptTable';
import { CircularProgress } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import downloadAttachmentApi from '../actions/downloadAttachmentApi'
import SelectTipTable from '../pages/pay/sections/SelectTipTable';
import { Button, Paper } from '@material-ui/core';

export default function Invoice(props) {
    let [check] = global.get("check")
    const [restaurant] = global.get('restaurant')
    const orderTerminology = global.f(restaurant, 'config.features.textToPay.orderTerminology', 'Invoice')
    const logo = restaurant && restaurant.branding.logo
    const [isDesktopView] = global.get('isDesktopView')
    const [finishedPaying] = global.get('finishedPaying')
    const  useMobileWrapper = !isDesktopView && global.textPay
    const ENTIRE_BILL_TAB = 0

    useEffect(()=> {
        if (check && !check.paidInFull) {
            check.items.forEach((item) => {
                item.locked = true
                item.lockedByMe = true
                item.lockedBy = localStorage.firstName + " " + localStorage.lastName
                item.lockedByInitials = localStorage.initials
            })
            let checkWithTip = checkUtils.updateTotals(check, 0)
            global.set({ check:checkWithTip })
        }
    },[])

    if (!restaurant || !check) {
        return <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}><CircularProgress/></div>
    }

	const formatPhoneNumber = (phoneNumberStr) => {
		if (phoneNumberStr.length < 4) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{1,3})/g, "($1");
		} else if (phoneNumberStr.length < 7) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{1,3})/g, "($1) $2");
		} else if (phoneNumberStr.length <= 10) {
			phoneNumberStr = phoneNumberStr.replace(/(\d{3})(\d{3})(\d{1,4})/g, "($1) $2-$3");
		}
        return phoneNumberStr;
    }

    const invoiceDetails = () => {
        return (
            <div style={{display:'flex', justifyContent:'space-between', flexDirection:'column', minWidth: useMobileWrapper ? 120 : 160, fontSize: useMobileWrapper ? 12 : 14}}>
                {!check.persists &&
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div>{orderTerminology} #:</div> <div>{check.checkNumberDisplay ? check.checkNumberDisplay : check.orderNumber ? check.orderNumber : check.checkNumber}</div>
                    </div>
                }

                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div>Date:</div>    <div>{check.createdAt ? moment(check.createdAt).format('MM/DD/YY') : ''}</div>
                </div>

                {check && check.dueDate &&
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>Due:</div>    <div>{check.dueDate ? moment(check.dueDate).format('MM/DD/YY') : ''}</div>
                    </div>
                }
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                    {check && check.attachment &&
                        <div onClick={()=> downloadAttachmentApi(check)}style={{ display:'flex', alignContent:'middle', justifyContent:'space-between', cursor:'pointer', textDecoration:'underline', width:'100%'}}>
                            <span style={{display:'flex', justifyContent:'flex-end'}}>Download {orderTerminology}</span>
                            <GetAppIcon/>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const renderInvoice = () => {
        return (
            <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignContent:'stretch', padding: useMobileWrapper ? 0 : 32, height:'100%', width:'100%'}}>
               
                {/* Header */}
                <div style={{width:'100%', padding:0, display:'flex', justifyContent: 'space-between', alignItems:'center', maxHeight: useMobileWrapper ? 60 : 80}}>
                   
                    {/* Left-side */}
                    {logo ?
                        <div>
                            <img src={logo} style={{maxHeight: useMobileWrapper? 60 : 80}}></img> <br/><br/>
                        </div>    
                        :
                        <span style={{fontSize:useMobileWrapper ? 20 : 30, fontWeight:300}}>{restaurant.name}</span>
                    }

                    {/* Right-side */}
                    <div style={{fontWeight:900}}>
                        {useMobileWrapper ? orderTerminology : <h1>{orderTerminology}</h1> }
                    </div>
                </div>
    
                {/* Sub-header */}
                <div style={{width:'100%', padding:0, display:'flex', justifyContent:'space-between', alignItems:'center', fontSize: useMobileWrapper && 12}}>
    
                    {/* Sender Details*/}
                    <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column'}}>
    
                        {restaurant && restaurant.locationInfo && <>
                            {restaurant.locationInfo.addressLine1 && restaurant.locationInfo.addressLine1}<br/>
                            {restaurant.locationInfo.addressLine2 && <> {restaurant.locationInfo.addressLine2}<br/> </>}
                            {restaurant.locationInfo.city && restaurant.locationInfo.state && <> {restaurant.locationInfo.city}, {restaurant.locationInfo.state} {restaurant.locationInfo.zipCode} <br/> </>}
                        </>}
                        {global.f(restaurant, 'locationInfo.phone') && restaurant.locationInfo.phone && formatPhoneNumber(restaurant.locationInfo.phone)}
                    </div>                
    
                </div>
                <br/>

                {/* Recipient details */}
                <div style={{width:'100%', padding:0, display:'flex', justifyContent:'space-between', alignItems:'center', fontSize: useMobileWrapper && 12}}>
                    <div style={{maxWidth: '70%', paddingRight: '16px'}}>
                        {check.customer && check.customer.firstName && <>
                            <h4>Bill To:</h4>
                            {`${check.customer.firstName} ${check.customer.lastName}`}<br/>
                            <span style={{wordBreak: 'break-all'}}>{check.customer.email}</span><br/>
                            {/* {check.customer.phone} */}
                            </>
                        }
                    </div>
                    
                    <div>
                        {invoiceDetails()}
                    </div>
                </div>
                <br/>
    
                <ReceiptTable tabIndex={ENTIRE_BILL_TAB} invoicePage check={check} textPayMobile={useMobileWrapper ? true : false}/>
            </div>
        )
    }

    const mobileWrapper = (invoice) => {
        return (
            <div style={{background: '#F5F5F5', height:'100%', overflow: 'auto', display:'flex', flexDirection:'column', flexWrap:'nowrap', justifyContent:'space-between', alignItems:'stretch', maxWidth:1024, margin:'auto', padding:4}}>
                
                <div style={{display:'flex',flexGrow:1, padding: finishedPaying ? '8px 8px 8px 8px' : '8px 8px 0px 8px', overflow: 'hidden'}}>
                    <Paper style={{width:'100%', height:'100%', padding:16}}>    
                        {invoice}
                    </Paper>
                </div>

                {!finishedPaying && 
                    <div style={{marginTop:10, padding:0, display:'flex', justifyContent:'center', flexShrink: 0}}>
                        <SelectTipTable textPayMobile/>
                    </div>
                }
            </div>
        )
    }

    if ( useMobileWrapper ) {
        return mobileWrapper( renderInvoice() )
    } else {
        return renderInvoice()
    }
}