import React from 'react';

export default (props) => {
    return (
        <svg width="72" height="48" version="1.1" viewBox="0 0 72 48" xmlns="http://www.w3.org/2000/svg">
            <path d="m61.6 10.105h-6.976c0.352-0.78316 0.576-1.6421 0.576-2.5263 0-4.1937-4.288-7.5789-9.6-7.5789-3.36 0-6.272 1.3642-8 3.4105l-1.6 1.6926-1.6-1.7179c-1.728-2.0211-4.64-3.3853-8-3.3853-5.312 0-9.6 3.3853-9.6 7.5789 0 0.88421 0.224 1.7432 0.576 2.5263h-6.976c-3.552 0-6.368 2.2484-6.368 5.0526l-0.032 27.789c0 2.8042 2.848 5.0526 6.4 5.0526h51.2c3.552 0 6.4-2.2484 6.4-5.0526v-27.789c0-2.8042-2.848-5.0526-6.4-5.0526zm-16-5.0526c1.76 0 3.2 1.1368 3.2 2.5263 0 1.3895-1.44 2.5263-3.2 2.5263s-3.2-1.1368-3.2-2.5263c0-1.3895 1.44-2.5263 3.2-2.5263zm-19.2 0c1.76 0 3.2 1.1368 3.2 2.5263 0 1.3895-1.44 2.5263-3.2 2.5263s-3.2-1.1368-3.2-2.5263c0-1.3895 1.44-2.5263 3.2-2.5263zm35.2 37.895h-51.2v-5.0526h51.2zm0-12.632h-51.2v-15.158h16.256l-6.656 7.1495 5.184 2.9558 7.616-8.1853 3.2-3.4358 3.2 3.4358 7.616 8.1853 5.184-2.9558-6.656-7.1495h16.256z" fill='#424242' strokeWidth="2.8433" />
            <path d="m0 0h24v24h-24z" fill="none" />
        </svg>

    );
}