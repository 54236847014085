import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
import saveCardToken from '../../actions/saveCardToken';
import { NotificationManager } from 'react-notifications';
import '../../util/modalCSS.css'
import DashLoad from '../../assets/Dashnow-Loading.gif';
import { Typography, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ReactGA from 'react-ga';
import userUpdateAccount from '../../actions/userUpdateAccount';

export default (props) => {
	const [awaitingPaymentResponse, setAwaitingPaymentResponse] = useState(false);
	const [saveCardBtn, setSaveCardBtn] = useState(false);
	const [ fullName, setFullName ] = useState(localStorage.fullName ? localStorage.fullName : '');
	const GA_CATEGORY_PAYMENT_ADD_CARD = "Payment add card"
	const [submitHandler, setSubmitHandler] = useState(()=>{})
	const [ token, setToken ] = useState('');
	const [ nameOnCard, setNameOnCard ] = useState('');
	let [restaurant] = global.get('restaurant');

	const c = makeStyles((theme) => ({
        formWrapper: {
            textAlign: 'center',
			height: '100%',
            marginTop: '16px',
            width:'306px',
            margin:'auto',
            position:'relative',
            left:'-10px'
        },
        paymentForm: {
            display: 'inline-block',
        },
        input: {
            height: 44,
            marginBottom: 2,
        },
        nameOnCard:{
            width: '100% !important',
            height: '58px',
            marginLeft:'8px',
            overflow:'hidden'
        },
        cardNumIconWrapper: {
            marginRight: 25,
            height: 33,
		},
		submit: {
            width: '200px',
            height: '56px',
            margin: 'auto',
            marginBottom:'10px',
            display: 'block',
            fontSize:'.85rem'
        },
        nameOnCardLabel: {
            float: 'left',
            fontWeight: 'bold',
            fontSize:'13px',
            color: '#555',
            marginLeft:'8px',
            fontFamily : 'Arial, sans-serif'
        }
	}))();
	
	const handleFullNameUpdate = (fullName) => {
		if (fullName.indexOf(' ') > 0 && !localStorage.fullName) {
			localStorage.setItem('fullName', fullName)
			let firstSpaceIndex = fullName.indexOf(' ')
			let firstName = fullName.slice(0, firstSpaceIndex)
			log.info('firstName: ' + firstName)
			let lastName = fullName.slice(firstSpaceIndex)
			log.info('lastName: ' + lastName)
			userUpdateAccount({firstName, lastName})
		}
	}

	const hideKeyboard = () => {
		//this set timeout needed for case when hideKeyborad
		//is called inside of 'onfocus' event handler
		setTimeout(function () {
			//creating temp field
			var field = document.createElement('input');
			field.setAttribute('type', 'text');
			//hiding temp field from peoples eyes
			//-webkit-user-modify is nessesary for Android 4.x
			field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
			document.body.appendChild(field);

			//adding onfocus event handler for out temp field
			field.onfocus = function () {
				//this timeout of 200ms is nessasary for Android 2.3.x
				setTimeout(function () {

					field.setAttribute('style', 'display:none;');
					setTimeout(function () {
						document.body.removeChild(field);
						document.body.focus();
					}, 14);

				}, 200);
			};
			field.focus();

		}, 50);
		window.DatacapHostedWebToken.on("ready", () => {}) //have to remove the on ready or else the keyboard will keep popping up
	}
	
	const tokenCallback = async function(response) {
		if (response.Error) {
			// Handle tokenization errors
			var error = response.Error;
			log.info("Datacap Token error: " + error);
		} else {
			// Add token to payment form control
            log.info("Token received: \n" + JSON.stringify(response));
            handleFullNameUpdate(nameOnCard)
			if (response && response.Token) {
				let resp = await saveCardToken(response.Brand.toLowerCase(), response.Token, response.Last4, 'datacap', true)
				window.closeAllPanels();
				if (resp.card) {
					props.next(resp.card)	
				}
			}
		}
	}

	const font = `font-family : Roboto, Arial, sans-serif`
	
	const initDatacap = () => {
		var customCSS = `
			.card-data { 
				border: 1px solid; 
				background-color: white; 
				color: black; 
				height: 58px !important; 
				border-color: #bbb3b9 #c7c1c6 #c7c1c6;
				margin-bottom:10px;
				padding: 6px 12px;
			} 
			.card-data-number {
				width: 280px;
				height: 44px !important;
			}
			.card-data-number::placeholder {
				opacity:0;
			}
			.card-data-exp-month {
				width: 150px; 
				display: inline-block;
				height: 44px;
			} 
			.card-data-exp-year {
				width:150px;
				height: 44px;
			} 
			.card-data-cvv {
				width:60px;
				height: 44px !important;
			} 
			.card-data-cvv::placeholder {
				opacity:0;
			} 
			.card-data-number-label {
				${font};
				text-transform: uppercase;
				float: left;
				font-weight: bold;
				font-size:13px;
				color: #555;
			} 
			.card-data-exp-month-label {
				${font}; 
				text-transform: uppercase;
				float: left;
				font-weight: bold;
				font-size:13px;
				color: #555;
			} 
			.card-data-exp-year-label{
				${font}; 
				text-transform: uppercase;
				float: left;
				font-weight: bold;
				font-size:13px;
				color: #555;
			}  
			.card-data-cvv-label{
				${font};
				text-transform: uppercase;
				float: left;
				font-weight: bold;
				font-size:13px;
				color: #555;
			} 
			.row {
				display:inline-block; 
				text-align: center;
			} 
			left-col {
				display:inline-block
			} 
			.container {
				width: 320px;
				margin: auto;
			}`
		window.DatacapHostedWebToken.init(restaurant.paymentMethods.creditCard.gatewayData.datacapTokenKey, 'token-iframe', tokenCallback, customCSS);
	}

	useEffect(() => {
		window.saveCardToken = saveCardToken;
		window.NotificationManager = NotificationManager;
		window.log = log;
		window.closeAllPanels = props.closeAllPanels;
		window.hideKeyboard = hideKeyboard;
		if (window.DatacapHostedWebToken) {
			initDatacap();
		}
	  }, []);

	const handleSubmit = async () => {
		await window.DatacapHostedWebToken.requestToken();
	}

	return (
		<>
            <div className={c.formWrapper}>
                <label className={c.nameOnCardLabel}>CARDHOLDER NAME</label>
                <input className={c.nameOnCard} type="text" onChange={(e) => setNameOnCard(e.target.value)}></input>
				<iframe id="token-iframe" frameBorder="0" style={{height:'265px', width: '320px', overflow:'hidden'}} scrolling='no'></iframe>
				<Button onClick={()=> handleSubmit()} variant="contained" color="primary" className={c.submit}>SAVE CARD</Button>
				<input type="hidden" id="token" />
            </div>
		</>
	);
};