import log from 'loglevel';

export default async function(restaurantId, restaurantCode, debug = true) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('restaurantWebsite/getData api'); 

		const url = `${global.dashServerUrl}/api/v1/restaurant-website/getData`;

		if (debug) log.info('fetching from url: ' + url);

		const body = {
			restaurantId,
			restaurantCode,
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}
		if (debug) log.info('BODY: ' + JSON.stringify(body))
		fetch(url, options, debug).then((res) => {
			if (res.ok) {
				log.info("res")
				log.info(res)
				res.json().then((json) => {
					log.info("json")
					log.info(json)
					if (json.success) {
						let data = json.data
						if (debug) log.info('success');
						resolve(data);
					} else {
						log.error(json.errors)
						reject(json.errors);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				log.info('getRestaurantData failed');
				reject("An error occured trying to retrieve info. Please try again.");
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		});
	})
}