

import { webSocketMessageHandler } from '../websocket/WebsocketMessageHandler';
import log from 'loglevel';
import getCheckListByTable from '../actions/getCheckListByTable';




export default async function openTableWs(restaurantId, table, backOffice, debug = false) {
	
	return new Promise(async (resolve, reject) => {
		//IF THE CHECK CHANGES, CLEAR THE WS CONNECTION
		const tableChannel = 'r' + restaurantId + '_tb' + table

		//IF THE TABLE CHANGES, CLEAR THE WS CONNECTION
		if (global.tableClient !== undefined && global.tableClient !== null && !global.tableClient.url.includes(`channel=${tableChannel}&`)) {
			if (global.tableClient) {
				log.info('Table number changed force closing websocket connection to table')
				// New connection is being made, close current connection
				global.tableClient.onclose = null // ensure the ws doesn't try to reconnect
				global.tableClient.close()
				global.tableClient = null
			}
		}
	
		var myInterval = null;
		let connectingToWebSocket = false
		
		/* WS is not listening to correct channel */
		const connect = () => {
			if (!connectingToWebSocket && (global.tableClient === undefined || global.tableClient === null) && backOffice) {
				connectingToWebSocket = true
				let token = localStorage.token
				// log.info("CONNECTING TO WS WITH TOKEN: " + token)
				const client = new WebSocket(`${global.dashServerWsUrl}?channel=${tableChannel}&token=${token}`);
				global.tableClient = client
	
				client.onopen = () => {
					log.info('Opening websocket connection to table')
					//ping the websocket to prevent the automatic 60 second timeout from ELB
					if (myInterval) clearInterval(myInterval)
					myInterval = setInterval(() => {
						client.send("ping")
					}, 30000);
				}
	
				client.onclose = () => {
					log.info('Closing websocket connection to table')
					if (myInterval) clearInterval(myInterval)
					//ATTEMPT TO AUTO RE-CONNECT TO THE WS SERVER EVERY SECOND IF DISCONNECTED
					setTimeout(async function() {
						if (restaurantId !== undefined && restaurantId !== "" && table !== undefined && table !== "") {
							updateCheckStack(restaurantId, table)
						}
						connect()
					}, 3000)
				}
	
				client.onmessage = async (message) => {
					// log.info("MSG: " + message.data)
					let updateChecks = webSocketMessageHandler(JSON.parse(message.data), null); // currently dont use the handler since we only have one message for this websocket
					updateCheckStack(restaurantId, table)
				}
				connectingToWebSocket = false
			}
		}
	
		const updateCheckStack = async (restaurantId, table) => {
			let checks
			try {
				let res = await getCheckListByTable(restaurantId, table, false)
				checks = res.checks
			} catch (err) {
				console.error(err)
			}
			global.set({
				checks,
			})
		}

		connect();
	});
}