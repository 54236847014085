import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Group from '../resources/images/Group';


export default (props) => {
	
	return (
		<Grid container direction="row"  justify="center" alignItems="center" style={{ paddingBottom:'1%' }}> 
			<Typography style={{color:'var(--light-gray)'}}>powered by&nbsp;</Typography>
			<Group width={17} height={17} viewBox={'0, 0, 34, 34'} />
			<Typography style={{color:'var(--dark-gray)'}}>&nbsp;dashnow</Typography>
		</Grid>
	);
};
