import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { makeStyles } from '@material-ui/core/styles';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
import saveCardToken from '../../actions/saveCardToken';
import LockIcon from '@material-ui/icons/Lock';
import { NotificationManager } from 'react-notifications';
import '../../util/modalCSS.css'
import DashLoad from '../../assets/Dashnow-Loading.gif';
import { Typography, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, TextField, Input, FormControl, RadioGroup, Radio, FormLabel} from '@material-ui/core';
import ReactGA from 'react-ga';
import CircularProgress from '@material-ui/core/CircularProgress';

export default (props) => {
	const restaurant = props.restaurant
	const [awaitingPaymentResponse, setAwaitingPaymentResponse] = useState(false);
	const [ openDialog, setOpenDialog ] = useState(false);
	const [ achData, setAchData ] = global.get('achData')
	const [ hasACHData, setHasACHData ] = global.get('hasACHData')
	const [ fromDashboard ] = global.get('fromDashboard')
	const GA_CATEGORY_PAYMENT_ADD_CARD = "Payment add ach"
    const [agreeance, setAgreeance] = useState(true)
    const [error, setError] = useState(false)
	const [tokenizationPlatform, setTokenizationPlatform] = useState(global.f(restaurant, 'paymentMethods.ach.gatewayData.gatewayTypeCode', 'vgs'));
    
    
	let [check] = global.get("check")
    let currency = global.f(check, 'restaurant.currency', "USD")

	const c = makeStyles((theme) => ({
		separator: {
			height: 1,
			width: '100%',
			backgroundColor: '#ccc',
		},
        clickHere: {
            fontSize:'13px',
			textDecoration: 'underline',
			display: 'inline-block',
			paddingLeft:'.2em',
            pointerEvents: "auto" 
		},
		topContainer: {
			width: '100%',
			height: 48,
			maxHeight: 48,
			backgroundColor: 'none',
		},
		topWrapper: {
			width: '100%',
			height: '100%',
            display: 'inline',
            marginTop: '2px'
		},
		topText: {
			height: 16,
			display: 'inline-block',
			position: 'absolute',
			fontSize: 16,
			fontWeight: 400,
			top: 5,
			bottom: 16,
		},
		modalContainer: {
			width: '100%',
			height: '100%',
			backgroundColor: 'var(--mask)',
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: 1400,
			elevation: 100,
			paddingTop: '10px'
		},
		modalWrapper: {
			width: '100%',
			minHeight: 500,
			padding:15,
			position: 'absolute',
			left: 0,
			bottom: 0,
            right: 0,
            backgroundColor: '#FFFFFF',
        },
        dialogWrapper: { //isDesktopView
            zIndex: 66600,
            width: '360px',
            padding:15,
            paddingTop:0,
            height: '530px',
            position:'fixed',
            top:'calc(50vh - 220px)',
            left:'calc(50vw - 180px)',
            border:'1px solid #F5F5F5',
            backgroundColor:'white',
            paddingBottom:16,
        },
		form: {
			width: '100%',
			height: 330,
			backgroundColor: 'none',
			paddingTop: 16,
		},
		label: {
			fontSize: 14,
			fontWeight: 400,
			color: 'rgba(0, 0, 0, 0.6)',
			textAlign: 'center',
			marginBottom: 8,
		},
		cardNumIconWrapper: {
			display: 'inline-flex', 
			position: 'relative',
			width: 250,
			transform: 'translateX(-55%)',
			left: '50%',
			marginBottom: 8,
		},
		nameInput: {
			width: '100%',
			height: 44,
			position: 'relative',
			left: '50%',
			transform: 'translateX(-50%)',
			marginRight: 'auto',
			marginLeft: 'auto',
			border: '1px solid #999999',
			borderRadius: 0,
			padding: 6,
			fontSize: 16,
			fontWeight: '500',
			color: 'rgb(97,97,97)',
			marginBottom: 8,
			appearance: 'none'
		},
		securityFAQ: {
			marginTop: '.37em',
			color: 'var(--light-gray)'
		},
		submitButton: {
			position: 'absolute',
			left: '33%',
			right: '33%',
			bottom: 16,
			marginLeft: 'auto',
			marginRight: 'auto',
        },
        lock: {
            color:'var(--dark-gray)',
            opacity:'.4',
            display:'inline-block',
            position:'relative',
            top:'5px',
            left: '3px'
        }
	}))();
	
	const hideKeyboard = () => {
		//this set timeout needed for case when hideKeyborad
		//is called inside of 'onfocus' event handler
		setTimeout(function () {

			//creating temp field
			var field = document.createElement('input');
			field.setAttribute('type', 'text');
			//hiding temp field from peoples eyes
			//-webkit-user-modify is nessesary for Android 4.x
			field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
			document.body.appendChild(field);

			//adding onfocus event handler for out temp field
			//adding onfocus event handler for out temp field
			field.onfocus = function () {
				//this timeout of 200ms is nessasary for Android 2.3.x
				setTimeout(function () {

					field.setAttribute('style', 'display:none;');
					setTimeout(function () {
						document.body.removeChild(field);
						document.body.focus();
					}, 14);

				}, 200);
			};
			field.focus();

		}, 50);
	}

	const vgsForm = window.VGSCollect.create(global.vgsKey, !global.isProd ? "sandbox" : "live", function(state) {
		// console.log("VGS state")
		// console.log(state)
	});


	useEffect(() => {
		// window.saveCardToken = saveCardToken;
		window.log = log;
		window.closeAllPanels = props.closeAllPanels;
		window.hideKeyboard = hideKeyboard;
        if (!achData) {
            setAchData({
				fullName:'',
				address:'',
				routingNumber:'',
				accountNumber:'',
				zipCode:'',
				type:'ach',
				checkType:'personal',
				accountType:'checking'
			})
			setHasACHData(true)
        }
	
	}, []);


	useEffect(() => {
		if (document.getElementById('accountNumber')) {
			// console.log('nice')
			vgsForm.field('#accountNumber', {
				type: 'text',
				name: 'AccountNumber',
				placeholder: '0000000000',
				validations: ['required'],
			})
		}
	}, [hasACHData])	

	const theOnSubmit = async (e) => {
		if (!awaitingPaymentResponse) {
			setAwaitingPaymentResponse(true)

			///TODO
			if ((!achData.getVal('fullName') || !achData.getVal('address') || !achData.getVal('routingNumber') || achData.getVal('routingNumber').length != 9 || !achData.getVal('accountNumber') || achData.getVal('accountNumber').length < 8 || !achData.getVal('zipCode') || achData.getVal('zipCode').length != 5)) {
				setError(true)
				setAwaitingPaymentResponse(false)
				return false
			} else {
				setError(false)
			}


			/// TODO: 

			let source = restaurant.getVal('paymentMethods.ach.gatewayData.tokenizationPlatform') == 'vgs' ? 'vgs' : tokenizationPlatform
			let achDetail = { //for enc card
				bankName: '_unused_for_web_',
				nameOnAccount: achData.getVal('fullName'),
				routingNumberLast4: achData.routingNumber ? achData.routingNumber.toString().substring(achData.routingNumber.length-4, achData.routingNumber.length) : '',
				accountNumberLast4: achData.accountNumber ? achData.accountNumber.toString().substring(achData.accountNumber.length-4, achData.accountNumber.length) : '',
				...achData,
			}
			
			let resp = await saveCardToken('ach', achData.getVal('accountNumber'), achDetail.accountNumberLast4, source, true, null, null, achData.getVal('fullName'), achData.getVal('zipCode'), fromDashboard, restaurant._id, achData.getVal('address'), achDetail);

			if (resp.card) {
				props.next(resp.card)	
			}
            // props.next(achData)
			setTimeout(setAwaitingPaymentResponse(false), 5000);
		}
		e.preventDefault();
		return false;
	}

	const closePanel = () => {
		window.hideKeyboard();
		global.set({ activePanel: '' });
	}
	
    
	return (
		<div id="modalContainer" className={c.modalContainer}>
			<div className={props.isDesktopView ? c.dialogWrapper :  c.modalWrapper}>
				<div className={c.topContainer}>
					<div className={c.topWrapper}>
                       
						<div className={c.topText} style={{ color: '#999999', right: 10, left: 'auto', marginTop:4 }} onClick={() => { closePanel(); }}><Typography variant="overline" color="primary">Cancel</Typography></div>
					</div>
				</div>
				{achData && <>
					<div className={c.separator}></div>
					{/* ACH FORM */}

					<TextField type='text' error={(error && !achData.fullName)} value={achData.fullName} onChange={(e) => setAchData({...achData, fullName:e.target.value})} id="customerFullName" placeholder='Name on Check'className={c.nameInput}/>
					<TextField type='text' error={(error && !achData.address)} value={achData.address} onChange={(e) => setAchData({...achData, address:e.target.value})} id="customerAddress" placeholder='Address'className={c.nameInput}/>
					<TextField type='tel'  error={error && (!achData.routingNumber || achData.routingNumber.length != 9)} value={achData.routingNumber} onChange={(e) => setAchData({...achData, routingNumber:e.target.value})} id="routingNumber" inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', maxLength: 9 }} placeholder='Routing Number' className={c.nameInput}/>
					<TextField type='tel'  error={error && (!achData.accountNumber || achData.accountNumber.length < 8)} value={achData.accountNumber} onChange={(e) => setAchData({...achData, accountNumber:e.target.value})} id="accountNumber" inputProps={{type: 'number'}} placeholder='Account Number' className={c.nameInput}/>
					

					
					{currency == "USD" &&
						<TextField type="tel" error={error && (!achData.zipCode || achData.zipCode.length != 5)} value={achData.zipCode} onChange={(e) => setAchData({...achData, zipCode:e.target.value})} id="zipCode" name="zipCode" placeholder='Zip Code' className={c.nameInput} inputProps={{ inputmode: 'numeric', pattern: '[0-9]*', maxLength: 5}} />
					}

					<div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>

						<FormControl component="fieldset">
							<FormLabel component="legend">Account Type</FormLabel>
							<RadioGroup aria-label="accountType" name="accountType" value={achData.accountType} onChange={(e) =>setAchData({...achData, accountType:e.target.value})}>
								<FormControlLabel value="checking" control={<Radio color="primary"/>} label="Checking" />
								<FormControlLabel value="savings" control={<Radio color="primary"/>} label="Savings" />
							</RadioGroup>
						</FormControl>
						
						<FormControl component="fieldset">
							<FormLabel component="legend">Check Type</FormLabel>
							<RadioGroup aria-label="checkType" name="checkType" value={achData.checkType} onChange={(e) =>setAchData({...achData, checkType:e.target.value})}>
								<FormControlLabel value="personal" control={<Radio color="primary"/>} label="Personal" />
								<FormControlLabel value="business" control={<Radio color="primary"/>} label="Business" />
							</RadioGroup>
						</FormControl>
					</div>

					<div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', zIndex:99, width:'100%'}}>

						<div>
							<Checkbox checked={agreeance} onClick={()=>setAgreeance(!agreeance)} color="primary" inputProps={{ 'aria-label': 'I agree to the ACH Terms of Use' }}/>
							<span style={{fontSize:'13px', alignSelf:'center'}}>I agree to the 
								<Typography className={c.clickHere} onClick={() => {
									setOpenDialog(true);
								}}>
									ACH Terms of Use
								</Typography>
							</span>
						</div>
						{/* <FormControlLabel
							label={<span style={{fontSize:'13px', alignSelf:'center'}}>Save ACH</span>}
							control={
								<Checkbox checked={!isOneTimeUseCard} onChange={()=>setIsOneTimeUseCard(!isOneTimeUseCard)} color="primary" inputProps={{ 'aria-label': 'Retain card for future purchases' }}/>
							}
						/> */}
						<Button disabled={awaitingPaymentResponse || !agreeance} variant="contained" color="primary" onClick={(e) => {theOnSubmit(e)}} style={{width:'214px', marginBottom:16, marginTop:4}}>{ awaitingPaymentResponse ? <CircularProgress size={24} style={{color:'white'}}/> : 'Continue'}</Button>
					</div>
				</>}
			</div>
			<Dialog
				aria-labelledby="simple-dialog-title" 
				open={openDialog}
				style={{zIndex: 1500}}
			>
				<DialogTitle id="simple-dialog-title">ACH Terms & Conditions</DialogTitle>
				<DialogContent>
					DashNow never stores any of your ACH information.
					<br /><br />
					By agreeing to the 'ACH Terms & Conditions' below I authorize my bank to debit my specified account for the amount of my payment. This is a one-time payment which will occur on the next business day or as soon as practical thereafter.
					<br /><br />
                    If your payment cannot be completed for any reason, including insufficient funds or error in the information which you submitted, you will retain the same liability, which is your sole responsibility, for payment as though you had not attempted to make the payment. You also understand that additional fees and penalties may be collected to the extent of applicable law.
					<br /><br />
					If for any reason you wish to make a change to the payment after submission, please contact {restaurant.name} {global.f(restaurant, 'locationInfo.phone') &&  "at " + global.formatPhoneNumber(restaurant.locationInfo.phone)}

				</DialogContent>
				<DialogActions style={{ marginTop: 0, height: '10%'}}>
					<Button onClick={() => { setOpenDialog(false) }} color="primary">
						Continue
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};