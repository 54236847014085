import React, { useEffect, useState } from 'react';
import { Slide } from '@material-ui/core';
import findNearestSiteApi from '../actions/findNearestSiteApi'
import Ripple from '../components/loaders/Ripple'
import Launch from '../resources/images/Launch';

export default (props) => {
    const f = global.f;
	let [checks] = global.get("checks")
	let [table] = global.get("table")
	let [payNowPageText] = global.get("payNowPageText")
	let [restaurantId] = global.get("restaurantId")
	let timer
	let [coords, setCoords] = useState(null)
	let [declined, setDeclined] = useState(false)
	let [geoStatus, setGeoStatus] = useState(null)

	let primaryColor = '#d0112b'

	function onError(error) {
		// if (error.code == error.PERMISSION_DENIED) {
		// 	console.log("you denied me :-(");
		// 	setDeclined(true)
		// }

		// console.warn(`ERROR(${error.code}): ${error.message}`);
		// if (timer) clearTimeout(timer)
	}

	var options = {
		enableHighAccuracy: true,
		timeout: 5000,
		maximumAge: 0
	}

	async function onSuccess(position) {
		if (timer) clearTimeout(timer)
		let lat = position.coords.latitude
		let lng = position.coords.longitude
		// console.log('Your current position is:');
		// console.log(`  lat: ${lat}`);
		// console.log(`  lng: ${lng}`); 
		try {
			let data = await findNearestSiteApi(lat, lng)
			if (data) {
				global.set({restaurantId: data._id, locationInfo: data.locationInfo, table: '3274', activePage: 'LoadingPage'}) 
			}
		} catch (error) {}
		setCoords({lat, lng})
	}

	
	let checkGeoPermission = () => {
		if (navigator && navigator.permissions) {
			navigator.permissions.query({name:'geolocation'}).then(function(result) {
				setGeoStatus(result.state)
				console.log('result.state: ', result.state)
			})
		}
	}

	let getCoordinates = () => {
		if (navigator && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
		}
		timer = setTimeout(() => {
			getCoordinates()
		}, 5000)
	}
	
	checkGeoPermission()
	
	useEffect(() => {
		getCoordinates()
	},[]);

	return (
		<Slide in direction="left">
			<div style={{padding: "0px", overflow: "hidden", height: "100%", display: 'flex', flex: 'auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
				{!restaurantId && 
					<div style={{marginBottom: '30px'}}>
						<Launch />
					</div>
				}
				{!(coords && !restaurantId) &&
					<div style={{marginTop: '20px'}}>
						<Ripple color={primaryColor} />
					</div>
				}
				{!coords && 
					<div style={{fontSize: '1.2em', marginTop: '80px'}}>
						Please wait while we locate your order
					</div>
				}
				{coords && !restaurantId &&
					<div style={{fontSize: '1.4em', textAlign: 'center', marginTop: '80px', color: primaryColor}}>
						We were unable to find<br/>a restaurant near you
					</div>
				}
				{geoStatus != 'granted' && !(coords && !restaurantId) &&
					<div style={{fontSize: '1em', marginTop: '80px'}}>
						Requires access to your location to continue
					</div>
				}
			</div>
		</Slide>
	)
}