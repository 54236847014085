import log from 'loglevel';
import dashFetch from './dashFetch';

export default async function(restaurantId, checkId, debug = true) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('getLoyaltyAccountInfo api');

		const url = `${global.dashServerUrl}/api/v1/loyalty/getLoyaltyAccount`;

		if (debug) log.info('fetching from url: ' + url);

		const body = {
			restaurantId,
		};

		if (debug) log.info('BODY: ' + JSON.stringify(body))
		dashFetch(url, body, debug).then(({ res, json }) => {
			if (json.success) {
				resolve(json.data.loyaltyAccount)
			} else {
				// NotificationManager.warning('', json.errors, 3000)
				resolve(null)
			}
		}).catch((err) => { reject(err) })
	})
}