import React, { useEffect, useState } from 'react';
import checkUtils from '../../util/checkUtils.js';
import getRestaurantGroupLocations from "../../actions/getRestaurantGroupLocations.js";

export default () => {
    let [loyaltyProgram] = global.get('loyaltyProgram');

    const [groupedRestaurants, setGroupedRestaurants] = useState([]);

    useEffect(() => {
        if (loyaltyProgram && loyaltyProgram.restaurantGroup) {
            getRestaurantGroupLocations(loyaltyProgram.restaurantGroup)
            .then((res) => {
                // console.log('PARSED RESPONSE:', res);
                setGroupedRestaurants(res);
                // console.log('GROUPED RESTAURANTS:', groupedRestaurants);
            })
            .catch((err) =>{
                // console.log(err);
            });
        }
    },[]);

    return (
        <div>
            <h2>{loyaltyProgram.name} Program Terms and Conditions</h2>

            <p>Effective Date: January 20th, 2020</p>

            <p>
                PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR PARTICIPATING IN A {loyaltyProgram.name.toUpperCase()} PROGRAM, YOU AGREE TO BE BOUND BY THESE TERMS OF USE AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT ACCESS OR PARTICIPATE IN THE {loyaltyProgram.name.toUpperCase()} PROGRAM.
            </p>

            <h2>Terms of Use</h2>

            <p>
                These Terms of Use (“Terms”) apply to the {loyaltyProgram.name} locations, including online, in-restaurant (“Participating Restaurants”), mobile, or social locations (collectively, the "Sites"). The Sites are operated by {loyaltyProgram.legalEntity} ("The Restaurant”).
            </p>

            <p>
                You agree that The Restaurant and Dash Now, LLC (“The Supplier"), collectively referred to as “we,” may provide notices, disclosures and amendments to these Terms, and other information relating to the {loyaltyProgram.name} program ("{loyaltyProgram.name}”) by electronic means.  These Terms apply to your access to, and participation in, {loyaltyProgram.name} at any of The Sites. We reserve the right to change or modify these Terms or any policy, FAQ, or guideline pertaining to {loyaltyProgram.name}, in whole or in part, at any time and in its sole discretion. Any changes or modifications will be effective immediately upon posting the revisions and you waive any right to receive specific notice of such changes or modifications. Your continued access to or participation in {loyaltyProgram.name} following any such changes or modifications confirms your acceptance of these Terms and such changes or modifications; therefore, you should review these Terms and the policies, FAQs and guidelines pertaining to {loyaltyProgram.name} frequently to understand the terms and conditions that apply to your access to and participation in {loyaltyProgram.name}. If you do not agree to these Terms, you must stop accessing and participating in {loyaltyProgram.name}.
            </p>

            <p>
                {loyaltyProgram.name} is intended for personal use only. Commercial use is prohibited. {loyaltyProgram.name} is not targeted towards, nor intended for use by, anyone under the age of 13. If you are between the ages of 13 and 18, you may access and participate in {loyaltyProgram.name} only under the supervision of a parent or legal guardian who agrees to be bound by these Terms. Please read the Dash Now Privacy Policy (dashnow.com/privacy-policy/) carefully to understand how {loyaltyProgram.name} collects, uses and discloses information.
            </p>

            <h2>About {loyaltyProgram.name}</h2>

            <p>
                {loyaltyProgram.name} is one way in which The Restaurant rewards loyal customers for their business. {loyaltyProgram.name} members are able to earn and accumulate "Points" that can be redeemed for discounts at Sites where the points were earned. Please review the {loyaltyProgram.name} program details when you sign up to understand how to earn and redeem points.
            </p>

            <p>
                To be eligible to join {loyaltyProgram.name} in accordance with these Terms, you must register an active mobile phone number at the time you join {loyaltyProgram.name}.
            </p>

            <p>
                There are two (2) ways to join {loyaltyProgram.name} and begin accumulating Points:
            </p>

            <ol style={{paddingLeft: 20, listStylePosition: 'outside'}}>
                <li style={{marginBottom: 8}}>Seated in any {loyaltyProgram.name} Sites, you are able to scan QR Codes or tap near-field communication stickers (NFC’s) on placards positioned on each table and follow prompts to join {loyaltyProgram.name} using your name, phone number.</li>
                <li>When the paper meal receipt is delivered to the table, you are able to scan the Dash Now QR Code or go to <a href="https://dnow.app">https://dnow.app</a> in a web browser and enter the six digit Dash Now code on the receipt and follow prompts to join {loyaltyProgram.name} using your name, phone number.</li>
            </ol>

            <p>
                Using your phone in one of these two ways allows points to be automatically accumulated and applied to future bills without any additional steps.
            </p>

            <p>
                If you would like to pay with cash or physical card, you can still participate in {loyaltyProgram.name}.  Please ask your server for details.
            </p>

            <p>
                If you are already a member, your current {loyaltyProgram.name} balance will display, otherwise details of the program points will display. By registering your {loyaltyProgram.name} profile, you may receive promotional texts, including exclusive events, ad hoc savings opportunities, and introductions of new specials menus, unless explicitly opted out. Standard text messaging rates may apply to all promotions. Points and rewards are associated to the Mobile Phone Number used when enrolling in {loyaltyProgram.name}.
            </p>

            <p>
                The discounts available to you through {loyaltyProgram.name} are equal to the points earned through eligible purchases at The Sites. {loyaltyProgram.name} awards {loyaltyProgram.pointsPerDollar} Point(s) for every one dollar ($1) spent and you can redeem {loyaltyProgram.rewardConversion.numberOfPoints} Points for ${checkUtils.formatMoney(loyaltyProgram.rewardConversion.amount)} towards future purchases at participating Sites.
            </p>

            <p>
                You will earn Points on qualified purchases and based on the subtotal of your paid bill after discounts, but before tax and tip, and may exclude alcohol, or other ineligible items at the discretion of The Restaurant or The Supplier.
            </p>

            <p>
                You will redeem {loyaltyProgram.name} Points through Dash Payment as a discount to eligible items on your current bill subtotal, excluding gratuity, donations, and all other exclusions set forth by The Restaurant or The Supplier, and before taxes are assessed.
            </p>

            <p>
                As a {loyaltyProgram.name} member, you are eligible to redeem Points as discounts towards items at the Sites where the points were earned. Points earned on items voided after {loyaltyProgram.name} accrual are subject to complete deduction for the entirety of the transaction, regardless of the value of the reimbursement, potentially resulting in a negative Point balance. Points redeemed to discount bills paid through Dash Payment are permanently deducted and cannot be reused, reinstated, or reimbursed, should all or any part of the transaction be voided or reimbursed. Furthermore, {loyaltyProgram.name} Points have no cash value and cannot be redeemed for cash and any reimbursement of a bill with {loyaltyProgram.name} discounts applied will be reimbursed at the discretion of The Sites minus {loyaltyProgram.name} discounts.
            </p>

            <h2>Additional Information and Disclosures</h2>

            <p>
                The Restaurant or The Supplier reserve the right to change the number and type of additional benefits offered to {loyaltyProgram.name} members at any time. There are no participation or membership fees associated with {loyaltyProgram.name}. Redemption of Points accrued in connection with {loyaltyProgram.name} may not be combined with any other offers or discounts. Your {loyaltyProgram.name} Points and Discounts are personal to you, have no cash value and may not be sold, transferred or assigned to, or shared with, family, friends or others, or used by you for any commercial purpose. You may have only one (1) {loyaltyProgram.name} account that is personal to you.
            </p>

            <p>
                Without notice to you, The Restaurant or The Supplier reserve the right to suspend any {loyaltyProgram.name} account and/or terminate your {loyaltyProgram.name} participation if The Restaurant or The Supplier determines, in its sole discretion, that you have violated these Terms or that the use of your {loyaltyProgram.name} account is unauthorized, deceptive, fraudulent or otherwise unlawful. The Restaurant and The Supplier may, in its sole discretion, suspend, cancel or combine {loyaltyProgram.name} accounts that appear to be duplicative. In the event that your participation in {loyaltyProgram.name} is terminated, all Points in your {loyaltyProgram.name} account are void.
            </p>

            <p>
                Without notice to you, The Restaurant or The Supplier also reserves the right to "unregister" and make ineligible for {loyaltyProgram.name} Points any {loyaltyProgram.name} account that has been inactive for 395 consecutive days. Inactivity is defined as no points earned during a 395 consecutive day period. In the event that your {loyaltyProgram.name} account is unregistered or rendered inactive, all accrued Points or Discounts in your {loyaltyProgram.name} account are void.
            </p>

            <p>
                The Restaurant or The Supplier reserves the right to change, modify, discontinue or cancel {loyaltyProgram.name} or any part of {loyaltyProgram.name}, at any time and in its sole discretion, without notice to you.
            </p>

            <p>
                Please allow up to fourty-eight (48) hours for {loyaltyProgram.name} Point accrual and deduction on purchases made through Dash Payment. {loyaltyProgram.name} Points expire in 395 days. All {loyaltyProgram.name} Points displayed as currency are expressed in U.S. Dollars, exclusively.
            </p>

            <p>
                You may check your {loyaltyProgram.name} rewards balance at any time by visiting www.dnow.app.
            </p>

            <h2>Participating Locations</h2>

            <ul style={{paddingLeft: '1.2em', listStyle: 'circle outside none'}}>
                {groupedRestaurants.map((obj, index) => {
                    return (<li style={{margin: '4px 0px'}} key={index}>{obj.name}, {obj.locationInfo.addressLine1}, {obj.locationInfo.city}, {obj.locationInfo.state}</li>);
                })}
            </ul>

            <h2>
                Governing Law
            </h2>

            <p>
                Any dispute arising from these Terms or your access to or participation in {loyaltyProgram.name} will be governed by and construed and enforced in accordance with the laws of Kansas, without regard to conflict of law rules or principles. The United Nations Convention on Contracts for the International Sale of Goods shall have no applicability. Any dispute between the parties that is not subject to arbitration or cannot be heard in small claims court will be resolved in the state or federal courts located in Johnson County, Kansas.
            </p>

            <h2>
                Severability
            </h2>

            <p>
                If any provision or part of a provision of these Terms is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms and does not affect the validity and enforceability of any remaining provisions.
            </p>
        </div>
    );
}