import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'

export default async function getTsysManifest(restaurant, gatewayId, merchantId, deviceId, amount) {
	return new Promise(async (resolve, reject) => {

		log.info('***** Get Tsys Manifest API ****')
		const url = `${global.dashServerUrl}/api/v1/restaurant/getTsysManifest`;

		log.info('get manifest url:', url);

		const body = {
			restaurant,
            gatewayId,
            merchantId,
            deviceId,
            amount
		}

		dashFetch(url, body).then(({ res, json }) => {
			if (!json.success) {
				NotificationManager.warning('', json.errors, 3000)
				reject(json.errors)
			} else {
				resolve({success:true, encryptedManifest: json.encryptedManifest});
			}
		}).catch((err) => { reject(err) });
	})
}