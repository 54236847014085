import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'
import checkUtils from '../util/checkUtils'

export default async function getCheckDetails(lat, lng,  debug = false) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** Get Nearest Site API ****')
		const url = `${global.dashServerUrl}/api/v1/restaurant/findNearestSite`

		const body = {
			lat, lng
		}
		dashFetch(url, body, debug).then(({ res, json }) => {
			if (!json.success) {
				if (global.isProd) {
					NotificationManager.info('', json.errors, 3000)
				}
				reject(json.errors)
			} else {
				resolve(json.data)
			}
		}).catch((err) => {
			console.error(err)
			reject(err)
		})
	})
}
