import React from 'react';

export default (props) => {
    return (
        <svg version="1.1" width="72" height="48" viewBox="0 0 72 48" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-3.8203 -33.402)" display="none">
                <rect x="18" y="48" width="31" height="3" ry="0" />
                <g transform="matrix(.79067 0 0 .75744 .75185 19.824)" strokeWidth=".15152">
                    <path transform="translate(3.873 17.834)" d="m7.5001 63.217c-3.4973-0.86738-6.1432-3.4589-7.1483-7.0013-0.26157-0.92196-0.27598-2.1947-0.27598-24.382 0-26.188-0.085179-24.114 1.0891-26.515 0.81339-1.6632 2.5752-3.3882 4.2982-4.2085 2.3142-1.1018-0.95774-1.0189 40.219-1.0189 35.698 0 37.314 0.011747 38.258 0.27806 3.33 0.94019 5.8714 3.4566 6.9189 6.8511 0.26476 0.85795 0.27809 2.0383 0.27809 24.613v23.712l-0.42156 1.2446c-0.99498 2.9376-3.1684 5.1229-6.1944 6.2282l-1.1113 0.40594-37.424 0.02764c-35.074 0.025897-37.491 0.011118-38.485-0.23541zm73.92-3.6508c2.3062-0.17855 3.1341-0.4707 4.1353-1.4593 0.61429-0.60653 0.85826-0.99688 1.1364-1.8182l0.35413-1.0458 0.04129-15.72 0.04129-15.72h-82.894l0.041941 15.72 0.041941 15.72 0.35413 1.0458c0.2781 0.8213 0.52208 1.2117 1.1364 1.8182 0.97136 0.95909 1.876 1.2991 3.8572 1.4498 2.1744 0.16535 69.625 0.17426 71.754 0.0095zm5.9727-49.058c-0.050823-3.2715-0.084982-3.6619-0.37587-4.2959-0.4341-0.94619-1.2068-1.7932-1.9394-2.1261-0.58234-0.26455-2.2048-0.27606-39.385-0.27925l-38.778-0.0033275c-1.2753 0.46958-2.0219 1.6517-2.5546 2.5758-0.29934 0.75243-0.33702 1.168-0.38811 4.2803l-0.056591 3.447h83.534z" />
                    <path transform="translate(3.873 17.834)" d="m14.243 31.606v-1.4394h30.758v2.8788h-30.758z" />
                </g>
            </g>
            <g transform="translate(.052734 -.0019531)">
                <path d="m6.832 0.0019531c-3.8146 0-6.8848 3.0721-6.8848 6.8867v34.172c0 3.8146 3.0702 6.8867 6.8848 6.8867h58.225c3.8146 0 6.8867-3.0721 6.8867-6.8867v-34.172c0-3.8146-3.0721-6.8867-6.8867-6.8867zm1.3262 2.7852h55.572c2.77 0 5 2.23 5 5v32.375c0 2.77-2.23 5-5 5h-55.572c-2.77 0-5-2.23-5-5v-32.375c0-2.77 2.23-5 5-5z" strokeWidth="1.0024" />
                <rect x="2.2539" y="11.199" width="67" height="7" ry="0" strokeWidth=".96504" />
                <rect x="11.202" y="22.782" width="24.319" height="2.1805" ry="0" />
            </g>
        </svg>
    );
}