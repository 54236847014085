import dashFetch from './dashFetch'
import log from 'loglevel';
import { NotificationManager } from 'react-notifications'

export default async function updateOptInAccountRestaurant(accountRestaurant, optIn, debug) {
	return new Promise(async (resolve, reject) => {

		if (debug) log.info('***** in upsertAccountRestaurant ****')
		const url = `${global.dashServerUrl}/api/v1/account/updateOptInAccountRestaurant`;

        const body = {
            accountRestaurant,
            optIn
		};
        
		if (debug) log.info('BODY: ' + JSON.stringify(body))
		dashFetch(url, body, debug).then(({ res, json }) => {
			if (res.ok) {
				if (json.success) {
					if (debug) log.info('success');
				}
				let data = json.data
				resolve(data);
			} else {
				log.info('Failed to get the data');
				reject("An error occured trying to retrieve info. Please try again.");
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		});
	})
}