import log from 'loglevel';
const uuidv4 = require('uuid/v4');

export default async function (debug) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('create account api');

		const url = `${global.dashServerUrl}/api/v1/account/initAccount`;
		let deviceId = localStorage.deviceId;
		if (!deviceId) {
			deviceId = uuidv4()
			localStorage.deviceId = deviceId
		}
		localStorage.savedCards = []
		const utcDateOffset = new Date().getTimezoneOffset()

		const body = {
			mobileDeviceInfo: {
				deviceId,
			},
			utcDateOffset
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}

		fetch(url, options, debug).then((res) => {
			if (res.ok) {
				log.info("res")
				log.info(res)
				res.json().then((json) => {
					log.info("json")
					log.info(json)
					if (json.success) {
						let data = json.data
						if (debug) log.info('success');
						/* SAVE ACCOUNT DATA LOCALLY */
						// console.log("initAccount > token: " + data.token)
						localStorage.token = data.token
						// console.log("localStorage > token: " + localStorage.token)
						localStorage.refreshToken = data.refreshToken
						localStorage.tokenTimeout = data.tokenTimeout
						localStorage.refreshTimeout = data.refreshTimeout
						localStorage.accountId = data.accountId
						//TODO - set alwaysReceiveEmailedReceipt from server on re-login
						//localStorage.alwaysReceiveEmailedReceipt = xxx
						resolve(true);
					} else {
						log.error(json.errors)
						reject(json.errors);
					}
				}).catch((err) => {
					reject(err.message);
				})
			} else {
				log.info('initAccount Server post failed');
				reject("An error occured trying to create an account. Please try again.");
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		});
	})
}