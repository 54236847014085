import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import saveCardToken from '../../actions/saveCardToken';
import '../../util/modalCSS.css'
import { Checkbox, FormControlLabel } from '@material-ui/core';
import getIFrameKey from '../../actions/getIFrameKey';
import getWorldpayToken from '../../actions/getWorldpayToken';
import { NotificationManager } from 'react-notifications';
import log from 'loglevel';

export default (props) => {
    let [check] = global.get('check');
    let [restaurant] = global.get('restaurant');
    const [isOneTimeUseCard, setIsOneTimeUseCard] = useState(false)
    const [ fromDashboard ] = global.get('fromDashboard')
    const [transactionSetupId, setTransactionSetupId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isDesktopView] = global.get('isDesktopView')

    const fetchForm = async () => {
        const myTotal = global.f(check, 'myTotals.youPay', 0)
        
        if (!loading) {
            setLoading(true)
            const resp = await getIFrameKey(restaurant._id, myTotal, localStorage.accountId, check._id)
            if (resp.success) {
                setTransactionSetupId(resp.data.data)
            } else {
                log.info('Error occurred fetchForming iFrame')
                log.info(resp)
            }
        }
        setTimeout(setLoading(false), 2000) 
    }

    useEffect(()=> {
        if (!loading) {
            fetchForm()
        }
    },[])

	const c = makeStyles((theme) => ({
		label: {
			fontSize: 14,
			fontWeight: 400,
			color: 'rgba(0, 0, 0, 0.6)',
			textAlign: 'center',
			marginBottom: 8,
		},
		formContent: {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
			width: 300,
            flexDirection:'column',
            textAlign:'center',
            alignSelf:'center'
		},
		desktopViewContent: {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
			width: 300,
            flexDirection:'column',
            textAlign:'center',
		}
	}))();

    useEffect(()=>{
        // listens for query params from iFrame redirect URL
        window.callsInProgress = false
        window.document.addEventListener('myCustomEvent', handleEvent, false)

        async function handleEvent(e) {

            if (loading) return
            const data = e.detail;
            const zipCode = data.BillingZipcode
            const lastFour = data.LastFour
            const transactionId = data.TransactionID
            const customerFullName = document.querySelector('#customerFullName').value
            const accountId = localStorage.getItem('accountId')
            const addressLine1 = data.BillingAddress1

            let cardType = data.CardLogo.toLowerCase()
            if (cardType) {
                cardType = formatCardType(cardType)
            } else {
                cardType = 'generic'
            }

            // validation
            const approved = data.ExpressResponseCode == 0 || data.ExpressResponseCode == '0'
            const avsZipCorrect = data.AVSResponseCode == 'Y' || data.AVSResponseCode == 'Z'
            const cvvCorrect = data.CVVResponseCode == 'M'

            // leaving these logs temporarily for testing
            // log.info({data})
            // log.info({approved})
            // log.info({avsZipCorrect})
            // log.info({cvvCorrect})

            if (!approved || !avsZipCorrect || !cvvCorrect) {
                if (!avsZipCorrect) NotificationManager.warning('', 'Incorrect zip code', 3000)
                if (!cvvCorrect) NotificationManager.warning('', 'Incorrect CVV', 3000)
                fetchForm() //reset form
            } else {
                // disappear iFrame
                const iFrame = document.querySelector('#worldpayIFrame')
                iFrame.style.display = 'none'
                
                if (!loading && !window.callsInProgress) {
                    setLoading(true)
                    window.callsInProgress = true
                    try {
                        // get token, then save new encryptedCard
                        const tokenResp = await getWorldpayToken(transactionId, accountId)
                        const token = tokenResp.data.PaymentAccount.PaymentAccountID
                        const resp = await saveCardToken(cardType, token, lastFour, 'worldpay', null, null, null, customerFullName, zipCode, (isOneTimeUseCard || fromDashboard), restaurant._id, addressLine1);
                        if (resp && resp.card) {
                            props.next(resp.card)	
                        } else {
                            NotificationManager.warning('', 'Unable to save card. Please try again.', 4000)
                            fetchForm()
                        }
                    } catch (e) {
                        NotificationManager.warning('', 'Unable to save card. Please try again.', 4000)
                        fetchForm()
                    }    
                    setTimeout(setLoading(false), 2000) 
                    setTimeout(()=> window.callsInProgress = false, 2000)          
                }
            }
        }
    },[])

    const formatCardType = (cardType) => {
        const cardTypeIdentifier = cardType.toLowerCase()
        if(cardTypeIdentifier === 'mastercard'){
            return 'master'
        }
        if(cardTypeIdentifier === 'amex'){
            return 'american_express'
        }
        if(cardTypeIdentifier === 'mastercard'){
            return 'master'
        }
        return cardTypeIdentifier
    }

	return (
		<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>	
			<div className={c.separator}></div>

			<div className={isDesktopView ? c.desktopViewContent : c.formContent} style={{opacity: loading ? 0 : 1, paddingTop:4}}>
                <div style={{display:'flex', padding:`4px 0px 0px ${isDesktopView ? '0px' : '0px'}`, justifyContent:'flex-start', }}>
                    <div style={{color: '#333333', fontSize:'12px', fontWeight:'bold', width:isDesktopView? 100 : 100, display:'flex', flexBasis: '60px', alignItems:'left', justifyContent:'flex-end', textAlign:'left', marginLeft: '5%', marginRight: 40, marginBottom:'0px', marginTop: '4px'}}>
                        Name on card:
                    </div>
                    <div style={{width: isDesktopView ? 208 : 208 , marginLeft: 4, paddingLeft: isDesktopView ? 3.5 : 3.5}}>
                        <input type='text' id="customerFullName" style={{height:42, border:'1px solid #c7c1c6', borderColor:'rgb(96 92 95) #c7c1c6 #c7c1c6 rgb(96 92 95)', padding:'1px 2px'}}/>
                    </div>
                </div>

                {transactionSetupId && 
                    <iframe title="worldpay" id='worldpayIFrame' onLoad={()=> setLoading(false)} 
                        src={`https://certtransaction.hostedpayments.com/?TransactionSetupID=${transactionSetupId}`} 
                        style={{width:310, height: 340, border:'none', display: loading? false : true}}>
                    </iframe>
                }
                
                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                    <FormControlLabel
                        label={<span style={{fontSize:'13px', alignSelf:'center'}}>Save card</span>}
                        control={
                            <Checkbox checked={!isOneTimeUseCard} onChange={()=>setIsOneTimeUseCard(!isOneTimeUseCard)} color="primary" inputProps={{ 'aria-label': 'Retain card for future purchases' }}/>
                        }
                    />
                </div>
			</div>

		</div>
	);
};