import log from 'loglevel';
import dashFetch from './dashFetch';

export default async function (debug = true) {
	return new Promise((resolve, reject) => {
		if (debug) log.info('get Payment History api');

		const url = `${global.dashServerUrl}/api/v1/account/getPaymentHistory`;

		if (debug) log.info('fetching from url: ' + url);

		const body = {
		};

		let options = {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		}
		if (debug) log.info('BODY: ' + JSON.stringify(body))
		dashFetch(url, options, debug).then(({ res, json }) => {
			if (res.ok) {
				if (json.success) {
					if (debug) log.info('success');
				}
				let data = json.data
				resolve(data);
			} else {
				log.info('Failed to get the data');
				reject("An error occured trying to retrieve info. Please try again.");
			}
		}).catch((err) => {
			log.error(err)
			reject(err.message);
		});
	})
}