import log from 'loglevel';
import dashFetch from './dashFetch';
import { NotificationManager } from 'react-notifications';

export default async function(verificationCode, debug) {
	return new Promise(async (resolve, reject) => {
		if (debug) log.info('verifyAccount api');

		const url = `${global.dashServerUrl}/api/v1/account/verifyAccount`;
		const body = {
			verificationCode
		};
		
		if (debug) log.info(body);

		try {
			dashFetch(url, body, debug)
				.then(({ res, json }) => {
					if (json.success) {
						let data = json.data
						if (debug) log.info('success');
						// if (debug) log.info('data');
						// if (debug) log.info(data);
						/* REFRESHED TOKEN WITH VERIFIED FLAG */
						/* SAVE UPDATED ACCOUNT DATA LOCALLY */
						localStorage.token = data.token
						localStorage.refreshToken = data.refreshToken
						localStorage.tokenTimeout = data.tokenTimeout
						localStorage.refreshTimeout = data.refreshTimeout
						localStorage.accountId = data.accountId
						global.set({signedIn: true})
						resolve(true);
					} else {
						NotificationManager.warning('', json.errors, 3000);
						resolve(false);
					}
				})
				.catch((err) => {
					reject(err);
				});
		} catch (e) {
			log.info(e);
		}
	})
}
