import React from 'react';



export default (props) => {
	return (
<svg width={props.width} viewBox="0 0 159 193" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.745814732">
		<g id="scan-hand-qr-code">
			<rect id="Rectangle" stroke="#000000" strokeWidth="7" transform="translate(22.696699, 79.196699) rotate(-45.000000) translate(-22.696699, -79.196699) " x="13.1966991" y="63.6966989" width="19" height="31" rx="9.5"></rect>
			<rect id="Rectangle" stroke="#000000" strokeWidth="7" transform="translate(22.696699, 104.196699) rotate(-45.000000) translate(-22.696699, -104.196699) " x="13.1966991" y="88.6966989" width="19" height="31" rx="9.5"></rect>
			<rect id="Rectangle" stroke="#000000" strokeWidth="7" transform="translate(22.696699, 129.196699) rotate(-45.000000) translate(-22.696699, -129.196699) " x="13.1966991" y="113.696699" width="19" height="31" rx="9.5"></rect>
			<path d="M97.196699,160.696699 L23,160.696699" id="Line" stroke="#000000" strokeWidth="7" strokeLinecap="square"></path>
			<path d="M98.376699,133.696699 L50.8908117,133.696699" id="Line" stroke="#000000" strokeWidth="7" strokeLinecap="square"></path>
			<path d="M98,160.696699 L98,134.053375" id="Line" stroke="#000000" strokeWidth="7" strokeLinecap="square"></path>
			<path d="M23.2132034,152.443881 L23.2132034,157.369802 L23.2132034,152.443881 C23.2132034,152.443881 23.2132034,152.443881 23.2132034,152.443881 Z" id="Combined-Shape" stroke="#000000" strokeWidth="7" strokeLinecap="square"></path>
			<path d="M14.0692821,27.3585747 C15.0565037,27.581012 16.0334228,27.895308 17.0000396,28.3014626 L16.9995985,37.3745747 L15.1139745,35.6812739 C11.8976297,34.4513499 9.62961831,34.844393 8.30994048,36.860403 C6.99026264,38.8764131 6.99026264,40.917574 8.30994048,42.9838857 L16.9995985,50.9045747 L16.9995985,60.4125747 L2.06144346,47.3009484 C-0.438349865,41.854565 -0.438349865,37.243583 2.06144346,33.4680025 C4.56123679,29.692422 8.56384968,27.655946 14.0692821,27.3585747 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero"></path>
			<path d="M18.9163102,58.1580241 L18.9163102,6.58776607 C19.4423018,2.28871648 21.5917824,0.139191691 25.364752,0.139191691 C29.1377215,0.139191691 57.2779541,0.139191691 109.78545,0.139191691 C114.084411,0.633071556 116.233891,2.78259635 116.233891,6.58776607 C116.233891,10.3929358 116.233891,30.5587079 116.233891,67.0850824 C113.497733,68.3076091 111.348252,70.2336187 109.78545,72.8631113 C109.27257,54.5617047 109.27257,39.74911 109.78545,28.4253274 L26.6472255,28.4253274 L26.6472255,59.3356738 L18.9163102,58.1580241 Z M26.6966991,8.19669914 L26.6966991,20.1966991 L108.696699,20.1966991 L108.696699,8.19669914 L26.6966991,8.19669914 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero" opacity="0.99"></path>
			<rect id="Rectangle" fill="#000000" fillRule="nonzero" x="55.6966991" y="10.1966991" width="25" height="8"></rect>
			<path d="M86.288675,192.462614 L79.0777892,183.76857 C61.6845368,187.739439 50.4333124,187.739439 45.3241161,183.76857 C40.2149199,179.797701 36.1108345,175.882805 33.0118601,172.023881 L43.314076,172.023881 C47.2361231,179.243644 54.9934221,181.332147 66.5859733,178.289392 C73.6148852,176.91744 78.9069116,176.320232 82.4620527,176.497767 L92.02084,187.283766 L86.288675,192.462614 Z" id="Path-4" fill="#000000" fillRule="nonzero"></path>
			<path d="M139.339132,174.825238 L119.699307,152.647309 L119.699307,147.375037 L132.029123,131.977873 L132.029123,83.0580664 C128.042473,77.4509151 123.932534,77.4509151 119.699307,83.0580664 C119.292949,97.5025223 119.292949,110.982371 119.699307,123.497611 L117.707955,125.651704 C111.82809,125.817404 107.691768,127.46996 105.298989,130.609373 C102.90621,133.748786 101.480504,136.619677 101.021872,139.222046 L95.233486,131.977873 C101.050721,123.489721 106.582667,119.245645 111.829323,119.245645 C111.966913,110.171134 111.966913,98.1086075 111.829323,83.0580664 C114.075636,75.2105203 118.720645,71.2867473 125.764352,71.2867473 C132.808058,71.2867473 137.332985,75.2105203 139.339132,83.0580664 L139.339132,131.977873 C139.339132,133.361541 135.279533,139.342918 127.160336,149.922004 C135.141034,159.564213 140.909408,166.06035 144.465459,169.410416 L139.339132,174.825238 Z" id="Path-5" fill="#000000" fillRule="nonzero"></path>
			<circle id="Oval" fill="#000000" fillRule="nonzero" cx="67.6966991" cy="146.196699" r="6"></circle>
			<g id="qr" transform="translate(41.000000, 51.000000)">
				<rect id="Rectangle" stroke="#000000" strokeWidth="4" x="0" y="1" width="15" height="15"></rect>
				<rect id="Rectangle" stroke="#000000" strokeWidth="4" x="39" y="1" width="15" height="15"></rect>
				<rect id="Rectangle" stroke="#000000" strokeWidth="4" x="39" y="40" width="15" height="15"></rect>
				<path d="M22.5,7.44736842 L22.5,23.7951018" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M31.5,0.44736842 L31.5,16.7951018" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M22.5,40.4210526 L22.5,55.8071546" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M15.5,39.4473684 L15.5,55.7951018" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M38.5,22.3157895 L38.5,33.855366" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M32,33.1842105 L32,39.9156301" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M32,47.2105263 L32,54.9035773" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M15,23.0789474 L15,25.9638415" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M47,30.0789474 L47,32.9638415" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M8,46.0789474 L8,48.9638415" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M15.25,33.014263 L18.8483496,33.014263" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M25.4375,33.014263 L31.7346117,33.014263" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M48.4375,33.014263 L54.7346117,33.014263" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M41.875,22.2535657 L54.4692235,22.2535657" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M23.5625,23.7606972 L31.6587865,23.7606972" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M3.625,23.014263 L12.6208739,23.014263" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M8.25,39.014263 L11.8483496,39.014263" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M8.25,55.7606972 L11.8483496,55.7606972" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M24.0625,55.7606972 L24.9620874,55.7606972" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M22.25,0.76069721 L25.8483496,0.76069721" id="Line-3" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<path d="M8,32.0263158 L8,32.9879472" id="Line-2" stroke="#000000" strokeWidth="4" strokeLinecap="square"></path>
				<rect id="Rectangle" fill="#000000" fillRule="nonzero" x="43" y="5" width="7" height="7"></rect>
				<rect id="Rectangle" fill="#000000" fillRule="nonzero" x="43" y="44" width="7" height="7"></rect>
				<rect id="Rectangle" fill="#000000" fillRule="nonzero" x="4" y="44" width="7" height="7"></rect>
				<rect id="Rectangle" fill="#000000" fillRule="nonzero" x="4" y="5" width="7" height="7"></rect>
			</g>
		</g>
    </g>
</svg>
	)
}
